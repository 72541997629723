import { Container } from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";

import { setHeading } from "../../../accounts/account.actions";
import { useEffect, useRef } from "react";
import { deleteReceiving, deleteReceivingProduct, generatePurchasePDF, getReceiving, updateReceiving, updateReceivingProduct } from "./Receiving.action";
import MaterialTable from "material-table";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const ReceivingList = (props) => {
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  useEffect(() => {
    // props.getReciving();
    props.setHeading("Purchase List");
    console.log(props.ReceivingList, "uuuuuuuu");
  }, []);
  return (
    <>
      {console.log(props.ReceivingList, "uuuuuuuu")}
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
            tableRef={tableRef}
              title="Purchase Details"
              columns={[
                // { title: 'Flavors Id', field: 'table_id', editable: 'never' },
                { title: "Purchase Date", field: "receivingDate" },
                {
                  title: "WareHouse Name",
                  field: "restaurantId.wareHouseName",
                  editable: "never",
                },
                { title: "Received By", field: "receivedBy" },
                {
                  title: "Purchase Description",
                  field: "recivingDescription",
                },
                { title: "Sub Total", field: "total",editable: "never", },
                { title: "Discount", field: "discount",editable: "never", },
                // { title: "Freight", field: "freight",editable: "never", },
                { title: "Grand Total", field: "grandTotal",editable: "never", },
             
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/receiving?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      { title: "Product Name ", field: "ProductName" ,editable: "never",},
                      // {
                      //   title: "Available Quantity",
                      //   field: "availableProductQuantity",
                      //   editable: "never",
                      // },
                      {
                        title: "Add Quantity",
                        field: "requiredProductQuantity",
                      },
                      {
                        title: "Price",
                        field: "productPrice",
                      },
                      {
                        title: "Total Price",
                        field: "TotalProductPrice",
                        editable: "never",
                      },
                      
                    ]}
                    data={rowData.receivingData}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         let url = `${baseURL}/receiving?`;
                    //         url += "limit=" + query.pageSize;
                    //         url += "&page=" + (query.page);
                    //         url += "&search=" + query.search;
                    //         fetch(url, {
                    //             method: 'GET',
                    //             headers: headers,
                    //         })
                    //             .then((response) => response.json())

                    //             .then((result) => {
                    //                 let a=-1;
                    //                 resolve({

                    //                     data: result.data[a+1].receivingData,
                    //                     page: result.page,
                    //                     totalCount: result.total_results,
                    //                 });
                    //             });

                    //     })
                    // }
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      // onRowUpdate: (newData, oldData) =>
                      //   new Promise((resolve) => {
                      //     setTimeout(() => {
                      //       resolve();
                      //       {
                      //         props.updateReceivingProduct(newData, oldData,rowData,updateTableQuery)
                      //       }
                      //     }, 600);
                      //   }),
                     onRowDelete: (oldData) =>
                       new Promise((resolve) => {
                         setTimeout(() => {
                           resolve();
                           props.deleteReceivingProduct(oldData,rowData,updateTableQuery);
                         }, 600);
                       }),
                   }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                toolbar: false,
                exportButton: false,
                sorting: true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.generatePurchasePDF(rowData._id);
                  },
                },
              ]}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                //  onRowUpdate: (newData, oldData) =>
                //    new Promise((resolve) => {
                //      setTimeout(() => {
                //        resolve();
                //        {
                //          props.updateReceiving(newData, oldData,updateTableQuery)
                //        }
                //      }, 600);
                //    }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteReceiving(oldData._id,updateTableQuery);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReciving: () => dispatch(getReceiving()),
      deleteReceiving: (tableId,updateTableQuery) => dispatch(deleteReceiving(tableId,updateTableQuery)),
      updateReceiving: (newData, oldData,updateTableQuery) => dispatch(updateReceiving(newData, oldData,updateTableQuery)),
    setHeading: (data) => dispatch(setHeading(data)),
    generatePurchasePDF: (_id) => dispatch(generatePurchasePDF(_id)),
    updateReceivingProduct: (newData, oldData,rowData,updateTableQuery) => dispatch(updateReceivingProduct(newData, oldData,rowData,updateTableQuery)),
    deleteReceivingProduct: (data,rowData,updateTableQuery) => dispatch(deleteReceivingProduct(data,rowData,updateTableQuery)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  ReceivingList: state.RecivingReducer.ReceivingList,
  isFetchingReceiving: state.RecivingReducer.isFetchingReceiving,
  generatePurchasePDFList: state.RecivingReducer.generatePurchasePDFList,
  isFetchingGeneratePurchasePDF:
    state.RecivingReducer.isFetchingGeneratePurchasePDF,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingList);
