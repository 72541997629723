import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";

import { setHeading } from "../../../accounts/account.actions";
import { getCategoryData } from "../categories/Catagory.action";
import { addSubCategory, getCategoryByWarehouseId } from "./SubCategory.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
const AddSubCategory = (props) => {
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  // const [SubCategoryId, setSubCategoryId] = useState();
  const [SubCategoryname, setSubCategoryName] = useState();

  const [restaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.getCategoryByWarehouseId(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  const getCategoryList = 
  
  !isEmpty(props.categoryListByWarehouseId) &&
  props.categoryListByWarehouseId.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });
  const handleChangeCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
  };
  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles, pictureDataURLs, "yyy");
    props.categoryImage({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  function uploadCategory() {
    props.addCategoryName(
      {
        categoryId:CategoryId,
        wareHouseId:restaurantId,
        subCategoryName: SubCategoryname,
      },
      handleVisible,
      handleDangerVisible
    );
    setSubCategoryName("");
    setRestaurantId("");
    setCategoryId("");
    // props.reset();
  }
  useEffect(() => {
    // props.getCategoryData();
    props.getRestaurantData();
    props.setHeading("Add Sub Category");
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingCategoryByWarehouseId||props.isFetchingRestaurant  ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
            <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select WareHouse *</b>
                      </Form.Label>
                      <Select
                      value={{label:restaurantName}}
                        isDisabled={isEmpty(props.restaurantDataList)}
                        placeholder="Select WareHouse..."
                        onChange={handleChangeRestaurant}
                        options={getRestaurantList}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="6" lg="6" md="6" xs="12">
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Category *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.categoryListByWarehouseId)||isEmpty(restaurantId)}
                        placeholder="Select Category..."
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                         </Form.Group>
                  </Col>
                  </Row>
                  <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Sub Category Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={isEmpty(CategoryId)?true:false}
                    placeholder="Sub Category Name...."
                    onMouseLeave={(e) => setSubCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>Upload Image</b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDrop}
                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <div className="sendDiv">
              {isEmpty(SubCategoryname) ? (
                <Button disabled>
                  Save
                </Button>
              ) : (
                <Button  onClick={uploadCategory}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // reset: () => dispatch(resetReducerCategory),
    getCategoryData: () => dispatch(getCategoryData()),
    // categoryImage: (data) => dispatch(addCategoryImage(data)),
    addCategoryName: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSubCategory(data, handleVisible, handleDangerVisible)),
      getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
     getCategoryByWarehouseId:(id)=>dispatch(getCategoryByWarehouseId(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  categoryList: state.CategoryReducer.categoryList,
  isFetchingcategoryList:state.CategoryReducer.isFetchingcategoryList,
  categoryListByWarehouseId:state.subCategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId:state.subCategoryReducer.isFetchingCategoryByWarehouseId,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubCategory);
