import React, { useEffect, useRef, useState } from "react";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { setHeading } from "../../../accounts/account.actions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { deleteIssuance, generateIssuancePDF, getIssuanceByDate, getIssuanceById } from "./Issuance.action";
import Modal from "react-bootstrap/Modal";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { isEmpty } from "lodash";
import moment from "moment";
const ViewIssuanceWithDate = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const tableRef = useRef();
  function updateTableQuery() {
    tableRef.current.onQueryChange()
  };
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  function uploadOrder() {
    props.getIssuanceByDate(
      {
        startDate: startDate,
        endDate: endDate
      },
    );

    setStartDate(moment(new Date()).format("YYYY-MM-DD"))
    setEndDate(moment(new Date()).format("YYYY-MM-DD"))
  }
  useEffect(() => {
    props.setHeading("View Issuance");
    // props.getArea();
  }, []);
  return (
    <>

      <Container fluid>
        <Row>
          <Col >
            <FormGroup>
              <Form.Label>
                <b>Start Date :</b>
              </Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              ></Form.Control>
            </FormGroup>
          </Col>
          <Col >
            <FormGroup>
              <Form.Label>
                <b>End Date :</b>
              </Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              ></Form.Control>
            </FormGroup>
          </Col>

        </Row>
        <div className="sendDiv" style={{ marginTop: 10 }}>
          {isEmpty(startDate) || isEmpty(endDate) ? (
            <Button disabled>Get Issuance</Button>
          ) : (
            <Button onClick={uploadOrder}>Get Issuance</Button>

          )}
        </div>
        {props.isFetchingIssuance ? (
          <div className="loader-div">
            <Spinner
              variant="primary"
              animation="grow"
              size="xl"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <>
            <div className="main" hidden={props.tableShowIssuance == false ? true : false}>
              <MaterialTable
                // tableRef={tableRef}
                title="Issuance Details"
                columns={[
                  { title: 'Issuance Number', field: 'number' },
                  { title: "Date", field: "date" },
                  { title: "SalesMen Name", field: "salesmenId.userName", editable: "never" },
                  { title: "Warehouse Name", field: "wareHouseId.wareHouseName", editable: "never" },
                  // { title: "Status", field: "status" },
                  { title: "Total", field: "subTotal" },
                  { title: "Discount", field: "discount" },
                  { title: "Grand Total", field: "grandTotal" },
                ]}
                data={props.issuanceList}
                // data={(query) =>
                //   new Promise((resolve, reject) => {
                //     let url = `${baseURL}/issuance?`;
                //     url += "limit=" + query.pageSize;
                //     url += "&page=" + query.page;
                //     url += "&search=" + query.search;
                //     fetch(url, {
                //       method: "GET",
                //       headers: headers,
                //     })
                //       .then((response) => response.json())
                //       .then((result) => {
                //         resolve({
                //           data: result.data,
                //           page: result.page,
                //           totalCount: result.total_results,
                //         });
                //       });
                //   })
                // }
                detailPanel={(rowData) => {
                  return (
                    <MaterialTable
                      title="Product Detail"
                      columns={[
                        {
                          title: "Code",
                          field: "code",
                        },
                        {
                          title: "Product Name",
                          field: "productLabel",
                        },
                        {
                          title: "Quantity",
                          field: "quantity",
                        },

                        {
                          title: "Total Amount",
                          field: "productTotalAmount",
                        },
                      ]}
                      data={rowData.data}
                      options={{
                        actionsColumnIndex: -1,
                        toolbar: false,
                        //   exportButton: true,
                        sorting: true,
                        paging: true,
                        pageSize: 50, // make initial page size
                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                        pageSizeOptions: [50, 100, 150, 200],
                        headerStyle: {
                          position: "sticky",
                          top: 0,
                          color: "#00BBBB",
                          fontWeight: "550",
                          onRowAdd: "none",
                        },
                      }}
                    />
                  );
                }}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                actions={[
                  // (rowData) => {
                  //   return isEmpty(rowData.returnProducts)
                  //     ? {
                  //       icon: () => <AutorenewIcon />,
                  //       tooltip: "View Return Products",
                  //       disabled: true
                  //     } :
                  //     {
                  //       icon: () => <AutorenewIcon />,
                  //       tooltip: "View Return Products",
                  //       onClick: (event, rowData) => {
                  //         props.getIssuance(rowData._id);
                  //         setOpen(true);
                  //       },
                  //     }
                  // },
                  {
                    icon: () => <PictureAsPdfIcon />,
                    tooltip: "Generate PDF",
                    onClick: (event, rowData) => {
                      props.generateIssuancePDF(rowData._id);
                    },
                  },
                  // (rowData) => {
                  //   return rowData.status == "Close"
                  //     ? {
                  //       icon:'delete',
                  //       disabled:true
                  //     }:
                  // {
                  //   icon: 'delete',
                  //   tooltip: "Delete Issuance",
                  //   disabled: false,
                  //   onClick: (event, rowData) => {
                  //     props.deleteIssuance(rowData._id,updateTableQuery);
                  //   },
                  // }}
                ]}
                editable={{
                  isDeletable: (rowData) => rowData.status == "Close" ? false : true,
                  // isEditHidden: (rowData) =>
                  //   Checkpermissions("permissionsSubCatName", "Issuance Edit"),
                  // isDeleteHidden: (rowData) =>
                  //   Checkpermissions("permissionsSubCatName", "Issuance Delete"),
                  // onRowUpdate: (newData, oldData) =>
                  //   new Promise((resolve) => {
                  //     setTimeout(() => {
                  //       resolve();
                  //       {
                  //         // props.updateArea(newData, oldData);
                  //       }
                  //     }, 600);
                  //   }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        props.deleteIssuance(oldData._id, updateTableQuery);
                      }, 600);
                    }),
                }}
              />
            </div>
            <Modal
              show={open}
              onHide={handleClose}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ marginLeft: "15%" }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Return Product
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {props.isFetchingGenerateIssuancePDF ? (
                  <div className="loader-div">
                    <Button variant="info" disabled>
                      <Spinner
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                      />
                      Loading...
                    </Button>
                  </div>
                ) : (
                  <MaterialTable
                    title="Product Detail"
                    columns={[
                      // {
                      //   title: "Code",
                      //   field: "product.code",
                      // },
                      {
                        title: "Product Name",
                        field: "productLabel",
                      },
                      {
                        title: "Quantity",
                        field: "quantity",
                      },
                    ]}
                    data={
                      !isEmpty(props.generateIssuancePDFList) &&
                      props.generateIssuancePDFList[0].returnProducts
                    }
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      // filtering:true,
                      paging: true,
                      maxBodyHeight: "600px",
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                )}
              </Modal.Body>
              {/* <Modal.Footer>
              <Button onClick={handleClose}>Close</Button>
            </Modal.Footer> */}
            </Modal>
          </>
        )}
      </Container>

    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getArea: () => dispatch(getArea()),
    // getShop: () => dispatch(getShop()),
    // getOrder: () => dispatch(getOrder()),
    // updateArea: (newData, oldData) => dispatch(updateArea(newData, oldData)),
    deleteIssuance: (id, updateTableQuery) => dispatch(deleteIssuance(id, updateTableQuery)),
    setHeading: (data) => dispatch(setHeading(data)),
    generateIssuancePDF: (_id) => dispatch(generateIssuancePDF(_id)),
    getIssuance: (id) => dispatch(getIssuanceById(id)),
    getIssuanceByDate: (data) => dispatch(getIssuanceByDate(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  generateIssuancePDFList: state.CreateIssuanceReducer.generateIssuancePDFList,
  isFetchingGenerateIssuancePDF:
    state.CreateIssuanceReducer.isFetchingGenerateIssuancePDF,
  isFetchingIssuance:
    state.CreateIssuanceReducer.isFetchingIssuance,
  issuanceList:
    state.CreateIssuanceReducer.issuanceList,
  tableShowIssuance:
    state.CreateIssuanceReducer.tableShowIssuance,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewIssuanceWithDate);
