import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { UpdateHRDivision, deleteHRDivision, getHRDivision } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const DivisionList = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
      tableRef.current.onQueryChange()
    };
    const divisionData = isEmpty(props.divisionList) ? [] : props.divisionList.reverse();
    console.log(divisionData);
  
    useEffect(() => {
        props.setHeading("Division List")
        // props.getHRDivision();
    }, []);
    return (
        <>
            {(props.isFetchingDivisionData ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                            <MaterialTable
                               tableRef={tableRef}
                            title="Division"
                            columns={[
                                // {title:'Division Id',field:'_id'},
                                { title: 'Division Name', field: 'name' },
                                { title: 'Division Description', field: 'description' },
                            ]}
                            // data={divisionData}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                  let url = `${baseURL}/division?`;
                                  url += "limit=" + query.pageSize;
                                  url += "&page=" + query.page;
                                  url += "&search=" + query.search;
                                  fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total_results,
                                      });
                                    });
                                })
                              }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                sorting: true,
                                paging: true,
                                pageSize: 50, // make initial page size
                                emptyRowsWhenPaging: false, // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200,500], // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                // console.log('newData', newData),
                                                props.UpdateHRDivision(newData, oldData,updateTableQuery)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteHRDivision(oldData._id,updateTableQuery);
                                            console.log(oldData, 'old')
                                        }, 600);
                                    }),
                            }}
                        />
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getHRDivision: () => dispatch(getHRDivision()),
        UpdateHRDivision: (newData, oldData,updateTableQuery) => dispatch(UpdateHRDivision(newData, oldData,updateTableQuery)),
        deleteHRDivision: (division_id,updateTableQuery) => dispatch(deleteHRDivision(division_id,updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
    divisionList: state.HrReducer.divisionList
    
    

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DivisionList);