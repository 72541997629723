import { filter, isEmpty, toLower } from "lodash";
const initialState = {
    isAddingDayOpening: false,

    isGettingDayOpening: false,
    dayOpeningList: [],

    isAddingDayClosing: false,

};

const DayReducer = (state = initialState, action) => {
    switch (action.type) {
        // Add OPening
        case "REQUEST_ADD_DAY_OPENING":
            return { ...state, isAddingDayOpening: true };
        case "SUCCESS_ADD_DAY_OPENING":
            return {
                ...state,
                // dayOpeningList: action.payload,
                isAddingDayOpening: false,
            };
        case "ERROR_ADD_DAY_OPENING":
            return { ...state, isAddingDayOpening: false };

        // Get OPening
        case "REQUEST_GET_DAY_OPENING":
            return { ...state, isGettingDayOpening: true,dayOpeningList: [], };
        case "SUCCESS_GET_DAY_OPENING":
            return {
                ...state,
                dayOpeningList: action.payload,
                isGettingDayOpening: false,
            };
        case "ERROR_GET_DAY_OPENING":
            return { ...state, isGettingDayOpening: false,dayOpeningList: [], };

        // Add Closing
        case "REQUEST_ADD_DAY_CLOSING":
            return { ...state, isAddingDayClosing: true };
        case "SUCCESS_ADD_DAY_CLOSING":
            return {
                ...state,
                isAddingDayClosing: false,
            };
        case "ERROR_ADD_DAY_CLOSING":
            return { ...state, isAddingDayClosing: false };
        
            case "SET_EDIT_FIELDS":
                let valueEmp= action.payload;
                
                console.log('payloaddddddd ', action.payload)
                // console.log('valueEmp', valueEmp)
    
                // let keyEmp = action.payload.key;
                // console.log('keyEmp', keyEmp)
    
                // valueEmp[keyEmp] = action.payload.value
                // console.log('valueEmp', valueEmp)
                // let valueEmp2 = [valueEmp]
                // console.log('valueEmp2', valueEmp2)
    
                // console.log('EDIT', action.payload)
    
                return { ...state, dayOpeningList: valueEmp };
        default:
            return state;
    }
};
export default DayReducer;