
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty, get } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus, getInfoLTLedger, getLtLedger } from "./HRDetails.actions";
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import { baseURL, headers } from "../../../../../global/api";

import Select from 'react-select';
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";
import { setHeading } from "../../../accounts/account.actions";

const HRLTLedger = (props) => {

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState();
  console.log(item, 'item')
  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
  !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const LTLedger = props.ltLedger;
  console.log(LTLedger, 'ledger')

  const exportPDF = () => {
    props.getLtLedger({
      startDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
    });
    setShow(true);
  }
  useEffect(() => {
    props.setHeading("Long Term Advance Detail")
    // props.getEmployeeData();
    // props.getEmployeeDataWithStatus(1);

  }, []);

  return (
    <>
      {props.isFetchingLtLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLtLedger ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>

                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col >
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>

            </Row>

            <Row style={{marginTop:10}}>

              <Col lg="12" sm="12" className="text-center" >
                {startDate == "" || endDate == "" ?
                  <Button disabled >Search</Button> :

                  <Button onClick={() => exportPDF()}>Search</Button>

                }
              </Col>

            </Row>

            {/* <div className="sendDiv">
              {item == "" ?
                <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Generate Ledger Report</Button> :
                <Button className="sendButton" onClick={() => searchLedger()}>Generate Ledger Report</Button>}
            </div> */}
            {show &&

              <MaterialTable
                title={`Long Term Advance Report ${startDate} - ${endDate}`}
                columns={[
                  { title: 'Emloyee Name', field: 'employeeDetails.employeeName' },
                  { title: 'Department Name', field: 'employeeDetails.departmentDetails.department' },
                  { title: 'Designation Name', field: 'employeeDetails.designationDetails.designation' },
                  { title: 'Date', field: 'date' },
                  { title: 'Advance Given', field: 'debitValue' },
                  { title: 'Advance Deduction', field: 'creditValue' },
                  { title: 'Account Type', field: 'accType' },
                ]}
                // data={
                //   // LTLedger
                //   (query) =>
                //     new Promise((resolve, reject) => {
                //       let url = `${baseURL}long_term_adv/get_lt_employee_ledger?`;
                //       url += "size=" + query.pageSize;
                //       url += "&page=" + (query.page + 1);
                //       url += "&search=" + query.search;
                //       fetch(url, {
                //         method: 'GET',
                //         headers: headers,
                //       })
                //         .then((response) => response.json())
                //         .then((result) => {
                //           resolve({
                //             data: result.data,
                //             page: result.page - 1,
                //             totalCount: result.total_results,
                //           });
                //         });
                //     })
                // }
                data={props.ltLedger}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  exportAllData: true,
                  emptyRowsWhenPaging: false,
                  headerStyle: {
                    position: 'sticky', top: 0,
                    color: '#00BBBB',
                    fontWeight: '550',
                    onRowAdd: 'none',
                  },

                }}
              />
            }
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getInfoLTLedger: (data) => dispatch(getInfoLTLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    getLtLedger: (data) => dispatch(getLtLedger(data)),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingLtLedger: state.HrReducer.isFetchingLtLedger,
  ltLedger: state.HrReducer.ltLedger,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  ltLedger: state.HrReducer.ltLedger,
  isFetchingLTLedger: state.HrReducer.isFetchingLTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger: state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HRLTLedger);