import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import {
  deleteRestaurant,
  getRestaurantData,
  updateRestaurant,
} from "./Resturant.action";
import { setHeading } from "../../../accounts/account.actions";
const ViewResturant = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    console.log(
      permissionsType,
      permissionsName,
      "typeeeeeeeeeeeeeeeeeeeeeeeee"
    );
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.setHeading("Warehouse List");
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingRestaurantData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Warehouse Details"
              columns={[
                // { title: 'Restaurant Id', field: 'restaurant_id', editable: 'never' },
                { title: "Warehouse Name", field: "wareHouseName" },
                { title: "Warehouse Location", field: "wareHouseLocation" },
                { title: "Warehouse Description", field: "wareHouseDesc" },
                //  { title: 'Warehouse tax', field: 'tax' },
                //  { title: 'Warehouse NTN Number', field: 'ntn' },
                //  { title: 'Tax Type', field: 'taxType', },
                //  { title: 'Warehouse PRA On Cash', field: 'praTaxCash' },
                //  { title: 'Warehouse PRA On Card', field: 'praTaxCard' },
                //  { title: 'Warehouse FBR On Cash', field: 'fbrTaxCash' },
                //  { title: 'Warehouse FBR On Card', field: 'fbrTaxCard' },
              ]}
              // data={wareHouseData}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 20, 50], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/wareHouse?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              editable={{
                //  isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Resturant Edit"),
                //  isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Resturant Delete"),

                isEditHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "WareHouse Edit"),
                // isDeleteHidden: (rowData) =>
                //   Checkpermissions("permissionsSubCatName", "WareHouse Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateRestaurant(newData, oldData);
                      }
                    }, 600);
                  }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     setTimeout(() => {
                //       resolve();
                //       props.deleteRestaurant(oldData._id);
                //     }, 600);
                //   }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRestaurantData: () => dispatch(getRestaurantData()),
    deleteRestaurant: (restaurantId) =>
      dispatch(deleteRestaurant(restaurantId)),
    updateRestaurant: (newData, oldData) =>
      dispatch(updateRestaurant(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResturant);
