import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { setHeading } from "../../../accounts/account.actions";
import { getReporting } from "./Reporting.action";
// import { deleteArea, getArea, updateArea } from "./Area.action";

const ViewReporting = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.setHeading("Reporting List");
    // props.getArea();
  }, []);
  return (
    <>
      {props.isFetchingReporting ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Reporting Details"
              columns={[
                { title: "Start Date", field: "" },
                { title: "End Date", field: "" },
                { title: "Booker Name", field: "" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/area/?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              //   options={{
              //     actionsColumnIndex: -1,
              //     exportButton: true,
              //     paging: true,
              //     pageSize: 50, // make initial page size
              //     emptyRowsWhenPaging: false, // To avoid of having empty rows
              //     pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
              //     headerStyle: {
              //       position: "sticky",
              //       top: 0,
              //       color: "#00BBBB",
              //       fontWeight: "550",
              //       onRowAdd: "none",
              //     },
              //   }}
              //   editable={{
              //     // isEditHidden: (rowData) =>
              //     //   Checkpermissions("permissionsSubCatName", "Area Edit"),
              //     // isDeleteHidden: (rowData) =>
              //     //   Checkpermissions("permissionsSubCatName", "Area Delete"),
              //     onRowUpdate: (newData, oldData) =>
              //       new Promise((resolve) => {
              //         setTimeout(() => {
              //           resolve();
              //           {
              //             props.updateArea(newData, oldData);
              //           }
              //         }, 600);
              //       }),
              //     onRowDelete: (oldData) =>
              //       new Promise((resolve) => {
              //         setTimeout(() => {
              //           resolve();
              //           props.deleteArea(oldData._id);
              //         }, 600);
              //       }),
              //   }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    getReporting: () => dispatch(getReporting()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingReporting: state.ReportingReducer.isFetchingReporting,
  reportingDataList: state.ReportingReducer.reportingDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReporting);
