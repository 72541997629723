const initialState = {
  saleReportData: [],
  isFetchingSaleReport: false,
  monthlySaleReportData: [],
  isFetchingMonthlySaleReport: false,
  discountReportData: [],
  isFetchingDiscountReport: false,
  monthlyDiscountReportData: [],
  isFetchingMonthlyDiscountReport: false,
  monthlyPromoReportData: [],
  isFetchingMonthlyPromoReport: false,
  invoiceReportData: [],
  isFetchingInvoiceReport: false,
  monthlyInvoiceReportData: [],
  isFetchingMonthlyInvoiceReport: false,
  orderBilledReportData: [],
  isFetchingOrderBilledReport: false,
  orderCancelReportData: [],
  isFetchingOrderCancelReport: false,
  monthlyOrderCancelReportData: [],
  isFetchingMonthlyOrderCancelReport: false,
  specificProductReportData: [],
  isFetchingspecificProductReport: false,
  productSaleReportData: [],
  isFetchingProductReport: false,
  hotSellingReportData: [],
  isFetchinghotSellingReport: false,
  promoReportData: [],
  isFetchingPromoReport: false,
  Data: [],
  isFetchingData: false,
  dayOpenData: [],
  isFetchingDayOpenData: false,
  dayOpenSaleReportData: [],
  isFetchingDayOpenSaleReport: false,
  vendorWiseSaleReportData: [],
  isFetchingVendorWiseSaleReport: false,
};
const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_DAY_OPEN_SALE":
      return { ...state, isFetchingDayOpenSaleReport: true };
    case "SUCCESS_GET_DAY_OPEN_SALE":
      return {
        ...state,
        dayOpenSaleReportData: action.payload,
        isFetchingDayOpenSaleReport: false,
      };
    case "ERROR_GET_DAY_OPEN_SALE":
      return { ...state, isFetchingDayOpenSaleReport: false };

    case "REQUEST_GET_DAILY_SALE":
      return { ...state, isFetchingSaleReport: true };
    case "SUCCESS_GET_DAILY_SALE":
      return {
        ...state,
        saleReportData: action.payload,
        isFetchingSaleReport: false,
      };
    case "ERROR_GET_DAILY_SALE":
      return { ...state, isFetchingSaleReport: false };
    //DISCOUNT
    case "REQUEST_GET_DAILY_DISCOUNT":
      return { ...state, isFetchingDiscountReport: true };
    case "SUCCESS_GET_DAILY_DISCOUNT":
      return {
        ...state,
        discountReportData: action.payload.data,
        isFetchingDiscountReport: false,
      };
    case "ERROR_GET_DAILY_DISCOUNT":
      return { ...state, isFetchingDiscountReport: false };
    //INVOICE
    case "REQUEST_GET_DAILY_INVOICE":
      return { ...state, isFetchingInvoiceReport: true };
    case "SUCCESS_GET_DAILY_INVOICE":
      return {
        ...state,
        invoiceReportData: action.payload.data,
        isFetchingInvoiceReport: false,
      };
    case "ERROR_GET_DAILY_INVOICE":
      return { ...state, isFetchingInvoiceReport: false };
    //order billed
    case "REQUEST_GET_DAILY_ORDER_BILLED":
      return { ...state, isFetchingOrderBilledReport: true };
    case "SUCCESS_GET_DAILY_ORDER_BILLED":
      return {
        ...state,
        orderBilledReportData: action.payload.data,
        isFetchingOrderBilledReport: false,
      };
    case "ERROR_GET_DAILY_ORDER_BILLED":
      return { ...state, isFetchingOrderBilledReport: false };
    //order cacel

    case "REQUEST_GET_DAILY_ORDER_CANCEL":
      return { ...state, isFetchingOrderCancelReport: true };
    case "SUCCESS_GET_DAILY_ORDER_CANCEL":
      return {
        ...state,
        orderCancelReportData: action.payload.data,
        isFetchingOrderCancelReport: false,
      };
    case "ERROR_GET_DAILY_ORDER_CANCEL":
      return { ...state, isFetchingOrderCancelReport: false };
    //promo
    case "REQUEST_GET_DAILY_PROMO":
      return { ...state, isFetchingPromoReport: true };
    case "SUCCESS_GET_DAILY_PROMO":
      return {
        ...state,
        promoReportData: action.payload.data,
        isFetchingPromoReport: false,
      };
    case "ERROR_GET_DAILY_PROMO":
      return { ...state, isFetchingPromoReport: false };

    //monthly
    //sale
    case "REQUEST_GET_MONTHLY_SALE":
      return { ...state, isFetchingMonthlySaleReport: true };
    case "SUCCESS_GET_MONTHLY_SALE":
      return {
        ...state,
        monthlySaleReportData: action.payload,
        isFetchingMonthlySaleReport: false,
      };
    case "ERROR_GET_MONTHLY_SALE":
      return { ...state, isFetchingMonthlySaleReport: false };
    //Discount
    case "REQUEST_GET_MONTHLY_DISCOUNT":
      return { ...state, isFetchingMonthlyDiscountReport: true };
    case "SUCCESS_GET_MONTHLY_DISCOUNT":
      return {
        ...state,
        monthlyDiscountReportData: action.payload,
        isFetchingMonthlyDiscountReport: false,
      };
    case "ERROR_GET_MONTHLY_DISCOUNT":
      return { ...state, isFetchingMonthlyDiscountReport: false };
    //order cacel
    case "REQUEST_GET_MONTHLY_ORDER_CANCEL":
      return { ...state, isFetchingMonthlyOrderCancelReport: true };
    case "SUCCESS_GET_MONTHLY_ORDER_CANCEL":
      return {
        ...state,
        monthlyOrderCancelReportData: action.payload,
        isFetchingMonthlyOrderCancelReport: false,
      };
    case "ERROR_GET_MONTHLY_ORDER_CANCEL":
      return { ...state, isFetchingMonthlyOrderCancelReport: false };
    //promo
    case "REQUEST_GET_MONTHLY_PROMO":
      return { ...state, isFetchingMonthlyPromoReport: true };
    case "SUCCESS_GET_MONTHLY_PROMO":
      return {
        ...state,
        monthlyPromoReportData: action.payload,
        isFetchingMonthlyPromoReport: false,
      };
    case "ERROR_GET_MONTHLY_PROMO":
      return { ...state, isFetchingMonthlyPromoReport: false };
    //all report headers
    case "REQUEST_DATA":
      return { ...state, isFetchingData: true };
    case "SUCCESS_DATA":
      return {
        ...state,
        Data: action.payload,
        isFetchingData: false,
      };
    case "ERROR_DATA":
      return { ...state, isFetchingData: false };
    //specific product sale report
    case "REQUEST_GET_SPECIFIC_PRODUCT_SALE_REPORT":
      return { ...state, isFetchingspecificProductReport: true };
    case "SUCCESS_GET_SPECIFIC_PRODUCT_SALE_REPORT":
      return {
        ...state,
        specificProductReportData: action.payload,
        isFetchingspecificProductReport: false,
      };
    case "ERROR_GET_PRODUCT_SALE_REPORT":
      return { ...state, isFetchingspecificProductReport: false };
    //product sale report
    case "REQUEST_GET_PRODUCT_SALE_REPORT":
      return { ...state, isFetchingProductReport: true };
    case "SUCCESS_GET_PRODUCT_SALE_REPORT":
      return {
        ...state,
        productSaleReportData: action.payload,
        isFetchingProductReport: false,
      };
    case "ERROR_GET_PRODUCT_SALE_REPORT":
      return { ...state, isFetchingProductReport: false };
    //hot sellig report
    case "REQUEST_HOT_SELLING_REPORT":
      return { ...state, isFetchinghotSellingReport: true };
    case "SUCCESS_HOT_SELLING_REPORT":
      return {
        ...state,
        hotSellingReportData: action.payload,
        isFetchinghotSellingReport: false,
      };
    case "ERROR_HOT_SELLING_REPORT":
      return { ...state, isFetchinghotSellingReport: false };
    ///day ope record
    case "REQUEST_GET_DAY_OPEN_REPORT":
      return { ...state, isFetchingDayOpenData: true };
    case "SUCCESS_GET_DAY_OPEN_REPORT":
      return {
        ...state,
        dayOpenData: action.payload,
        isFetchingDayOpenData: false,
      };
    case "ERROR_GET_DAY_OPEN_REPORT":
      return { ...state, isFetchingDayOpenData: false };

    case "REQUEST_GET_VENDOR_WISE_SALE_REPORT":
      return { ...state, isFetchingVendorWiseSaleReport: true };
    case "SUCCESS_GET_VENDOR_WISE_SALE_REPORT":
      return {
        ...state,
        vendorWiseSaleReportData: action.payload,
        isFetchingVendorWiseSaleReport: false,
      };
    case "ERROR_GET_VENDOR_WISE_SALE_REPORT":
      return { ...state, isFetchingVendorWiseSaleReport: false };

    default:
      return state;
  }
};
export default ReportReducer;
