import API from "../../../../../global/api";
export const requestAddShop = () => {
  return {
    type: "REQUEST_ADD_SHOP",
  };
};
export const successAddShop = (data) => {
  return {
    type: "SUCCESS_ADD_SHOP",
    payload: data,
  };
};
export const errorAddShop = () => {
  return {
    type: "ERROR_ADD_SHOP",
  };
};
export const addShop = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddShop());
    API.post(`/shop`, data)
      .then((res) => {
        dispatch(successAddShop(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddShop());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetShop = () => {
  return {
    type: "REQUEST_GET_SHOP",
  };
};
export const successGetShop = (data) => {
  return {
    type: "SUCCESS_GET_SHOP",
    payload: data,
  };
};
export const errorGetAllShop = () => {
  return {
    type: "ERROR_GET_SHOP",
  };
};
export const getShop = (data) => {
  return (dispatch) => {
    dispatch(requestGetShop());
    API.get(`/shop?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetShop(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllShop());
      });
  };
};

export const UpdateRequestShop = () => {
  return {
    type: "UPDATE_REQUEST_SHOP",
  };
};
export const UpdateSuccessShop = (data) => {
  return {
    type: "UPDATE_SUCCESS_SHOP",
    payload: data,
  };
};

export const UpdateErrorShop = () => {
  return {
    type: "UPDATE_ERROR_SHOP",
  };
};

export const updateShop = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestShop());
    API.put(
      `/shop/${oldData._id}`,
      {
        // areaId: data.areaId,
        shopName: data.shopName,
        shopDesc: data.shopDesc,
        location: data.location,
        ownerName: data.ownerName,
        ownerNumber: data.ownerNumber,
        ownerCnic: data.ownerCnic,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessShop([data]));
      })
      .catch((error) => dispatch(UpdateErrorShop()));
  };
};
export const DeleteRequestShop = () => {
  return {
    type: "DELETE_REQUEST_SHOP",
  };
};
export const DeleteSuccessShop = (id) => {
  return {
    type: "DELETE_SUCCESS_SHOP",
    id: id,
  };
};
export const DeleteErrorShop = () => {
  return {
    type: "DELETE_ERROR_SHOP",
  };
};

export const deleteShop = (id) => {
  return (dispatch) => {
    dispatch(DeleteRequestShop());
    API.delete(`/shop/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessShop(id));
      })
      .catch((error) => dispatch(DeleteErrorShop()));
  };
};

export const requestGetShopByArea = () => {
  return {
    type: "REQUEST_GET_SHOP_BY_AREA",
  };
};
export const successGetShopByArea = (data) => {
  return {
    type: "SUCCESS_GET_SHOP_BY_AREA",
    payload: data,
  };
};
export const errorGetShopByArea = () => {
  return {
    type: "ERROR_GET_SHOP_BY_AREA",
  };
};
export const getShopByArea = (id) => {
  return (dispatch) => {
    dispatch(requestGetShopByArea());
    API.get(`/shop/area_wise_shops/${id}`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetShopByArea(data1));
      })
      .catch((error) => {
        dispatch(errorGetShopByArea());
      });
  };
};
