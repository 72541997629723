import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs, Breadcrumb, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import jsPDF from "jspdf";
import ClearIcon from '@mui/icons-material/Clear';
import { getHotSellingReport, getMonthWiseSaleReport, getProductSaleReport } from "./Report.action";


import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { getProductType } from "../Recipe/Recipe.action";
import IconMenu from "../../../drawer/IconMenu";
import { getUserRes } from "../../AddUser/user.actions";
const HotSellingReport = (props) => {


    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [bookerId, setBookerId] = useState("");
    const [bookerName, setBookerName] = useState("");
    const filterdUserList =
        !isEmpty(props.userList) &&
        props.userList.filter((e) => e.role == "booker" || "bookerwholesale");
    const BookerList =
        !isEmpty(filterdUserList) &&
        filterdUserList.map((x) => {
            let data = { value: x._id, label: x.userName };
            return data;
        });
    const handleChangeBooker = (selectedOption) => {
        setBookerId(selectedOption.value);
        setBookerName(selectedOption.label);

    };
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };


    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Report Generated Successfully",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    }

    const [state, setState] = React.useState(
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const exportPDF = () => {
        props.getHotSellingReport({ startDate: startDate, endDate: endDate,bookerId:bookerId },
            startDate,endDate,bookerName,
            handleVisible, handleDangerVisible);
            setBookerId("");
            setBookerName("")
    }
    // console.log(props.productSaleReportData, "ddddddddd")

    useEffect(() => {
        props.getUser()
    }, []);
    return (
        <>
            <Navbar style={{ backgroundColor: '#81abd3' }}  >
                <Container>
                    <Navbar.Brand style={{ color: 'white' }}><b>Booker Wise Profit Report</b></Navbar.Brand>
                    <IconMenu />
                </Container>
            </Navbar>

            {props.isFetchinghotSellingReport || props.isFetchingUser ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>

                    <div className="main">
                        <Row>
                            <Breadcrumb>
                                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                                <Breadcrumb.Item active>Booker Wise Profit Report</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col >
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label>
                                        <b>Select Booker *</b>
                                    </Form.Label>
                                    <Select
                                        isDisabled={isEmpty(BookerList)}
                                        value={{ label: bookerName }}
                                        placeholder="Select Booker..."
                                        onChange={handleChangeBooker}
                                        options={BookerList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv" style={{ marginTop: '2%' }}>
                            {isUndefined(startDate)||isUndefined(endDate)||bookerName=="" ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", border: "1px solid" }}>Generate Booker Wise Profit Report</Button> : props.isFetchinghotSellingReport ? <Button >Downloading PDF</Button> : <Button onClick={() => exportPDF()}>Generate Booker Wise Profit Report</Button>}
                        </div>

                    </div>

                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getHotSellingReport: (data,startDate,endDate,bookerName, handleVisible, handleDangerVisible) => dispatch(getHotSellingReport(data,startDate,endDate,bookerName, handleVisible, handleDangerVisible)),
        getUser: () => dispatch(getUserRes())
    };
};
const mapStateToProps = (state, ownProps) => ({
    hotSellingReportData: state.ReportReducer.hotSellingReportData,
    isFetchinghotSellingReport: state.ReportReducer.isFetchinghotSellingReport,
    isFetchingUser: state.UserReducer.isFetchingUser,
    userList: state.UserReducer.userList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HotSellingReport);