import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getArea } from "../AddArea/Area.action";
import { addIssuance, getProductByArea } from "./Issuance.action";
import { getUserRes } from "../../AddUser/user.actions";
import moment from "moment";
import { getCustomerData } from "../AddCustomer/Customer.action";

const AddIssuances = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [bookerId, setBookerId] = useState("");
  const [bookerName, setBookerName] = useState("");
  const [salesMenId, setSalesMenId] = useState();
  const [salesMenName, setSalesMenName] = useState("");
  const [totalQty, setTotalQty] = useState();
  const [totalPrice, setTotalPrice] = useState("");
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [issuanceDate, setIssuanceDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  // const filterdUserList =
  //   !isEmpty(props.userList) &&
  //   props.userList.filter((e) => e.role == "salesmen");

  const SalesMenList =
    !isEmpty(props.customerDataList) &&
    props.customerDataList.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });

  function uploadIssuance() {
    props.addIssuance(
      {
        areaId: areaId,
        salesMenId: salesMenId,
        bookerId: bookerId,
        data: props.productByAreaList.data,
        date: date,
        issuanceDate: issuanceDate,
        bookingDiscount: props.productByAreaList.totalDiscount,
      },
      handleVisible,
      handleDangerVisible
    );
    setAreaId("");
    setAreaName("");
    setBookerId("");
    setBookerName("");
    setIssuanceDate(moment(new Date()).format("YYYY-MM-DD"))
  }
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);


  };
  const handleChangeSalesMen = (selectedOption) => {
    setSalesMenId(selectedOption.value);
    setSalesMenName(selectedOption.label);
  };
  const AreaListData =
    !isEmpty(props.areaDataList) &&
    props.areaDataList.map((x) => {
      let data = { value: x._id, label: x.areaName };
      return data;
    });
  const filterdUserList =
    !isEmpty(props.userList) &&
    props.userList.filter((e) => e.role == "booker" || "bookerwholesale");
  const BookerList =
    !isEmpty(filterdUserList) &&
    filterdUserList.map((x) => {
      let data = { value: x._id, label: x.userName };
      return data;
    });
  const handleChangeBooker = (selectedOption) => {
    setBookerId(selectedOption.value);
    setBookerName(selectedOption.label);
    props.getProductByArea(areaId, { date: date, bookerId: selectedOption.value });
  };
  useEffect(() => {
    props.setHeading("Add Issuance");
    props.getArea();
    props.getUser();
    props.getCustomerData();
  }, []);
  return (
    <>
      {props.isFetchingAreaData || props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              {/* <Col xl="6" lg="6" md="6" xs="6" style={{ marginTop: 10 }}> */}
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Area *</b>
                  </Form.Label>
                  <Select
                    value={{ label: areaName }}
                    isDisabled={date == ""}
                    placeholder="Select Area..."
                    onChange={handleChangeArea}
                    options={AreaListData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Booker *</b>
                  </Form.Label>
                  <Select
                    value={{ label: bookerName }}
                    isDisabled={date == "" || areaName == ""}
                    placeholder="Select Booker..."
                    onChange={handleChangeBooker}
                    options={BookerList}
                  />
                </Form.Group>
              </Col>
            </Row>
            {props.isFetchingProductByArea || props.isFetchingUser ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <div hidden={isEmpty(bookerName)}>
                {!isEmpty(props.productByAreaList) &&
                  isEmpty(props.productByAreaList.data) ? (
                  <Row>
                    <b style={{ textAlign: "center", fontSize: "30px" }}>
                      No Booking Found
                    </b>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col xl="4" lg="4" md="6" xs="6">
                        <b>Product</b>
                      </Col>
                      <Col>
                        <b>Quantity</b>
                      </Col>
                      <Col>
                        <b>Rate</b>
                      </Col>
                      <Col>
                        <b>Total</b>
                      </Col>
                    </Row>
                    {!isEmpty(props.productByAreaList) &&
                      props.productByAreaList.data.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Row>
                              <Col xl="4" lg="4" md="6" xs="6">
                                {item.productLabel}
                              </Col>
                              <Col>{item.quantity}</Col>
                              <Col>{item.productSellingPrice}</Col>
                              <Col>{item.totalSaleValue}</Col>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                    {/* {setTotalQty(sumBy(props.productByAreaList,'quantity'))}
            {setTotalPrice(sumBy(props.productByAreaList,'productSellingPrice'))} */}
                    <Row>
                      <Col xl="4" lg="4" md="6" xs="6"></Col>
                      <Col></Col>
                      <Col>
                        <b>Sub Total</b>
                      </Col>
                      <Col>
                        <b>
                          {!isEmpty(props.productByAreaList) &&
                            sumBy(
                              props.productByAreaList.data,
                              "totalSaleValue"
                            )}
                        </b>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4" lg="4" md="6" xs="6"></Col>
                      <Col></Col>
                      <Col>
                        <b>Discount</b>
                      </Col>
                      <Col>
                        <b>
                          {!isEmpty(props.productByAreaList) &&
                            props.productByAreaList.totalDiscount}
                        </b>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4" lg="4" md="6" xs="6"></Col>
                      <Col></Col>
                      <Col>
                        <b>Total</b>
                      </Col>
                      <Col>
                        <b>
                          {Number(
                            !isEmpty(props.productByAreaList) &&
                            sumBy(
                              props.productByAreaList.data,
                              "totalSaleValue"
                            )
                          ) -
                            Number(
                              !isEmpty(props.productByAreaList) &&
                              props.productByAreaList.totalDiscount
                            )}
                        </b>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Issuance Date </b>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={issuanceDate}
                            onChange={(e) => setIssuanceDate(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="garana">
                          <Form.Label>
                            <b>Select SalesMen *</b>
                          </Form.Label>
                          <Select
                            isDisabled={isEmpty(SalesMenList)}
                            value={{ label: salesMenName }}
                            placeholder="Select SalesMen..."
                            onChange={handleChangeSalesMen}
                            options={SalesMenList}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="sendDiv">
                      {salesMenName == "" ? (
                        <Button disabled>Save</Button>
                      ) : (
                        <Button onClick={uploadIssuance}>Save</Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch(getArea()),
    addIssuance: (data, handleVisible, handleDangerVisible) =>
      dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    getProductByArea: (id, data) => dispatch(getProductByArea(id, data)),
    getUser: () => dispatch(getUserRes()),
    getCustomerData: () => dispatch(getCustomerData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // isFetchingUser: state.UserReducer.isFetchingUser,
  // userList: state.UserReducer.userList,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  productByAreaList: state.CreateIssuanceReducer.productByAreaList,
  isFetchingProductByArea: state.CreateIssuanceReducer.isFetchingProductByArea,
  customerDataList: state.CustomerReducer.customerDataList,
  isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIssuances);
