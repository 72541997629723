import { filter, isEmpty, toLower } from "lodash";
const initialState = {
    subCategoryList: [],
    subCategory: [],
    categoryListByWarehouseId:[],
    isFetchingCategoryByWarehouseId:false,
    isAddingSubCategory: false,

    subCategoryListByCategoryId:[],
    isFetchingSubCategoryByCategoryId:false,
    // dataAddedCategory: false,
    // dataNotAddedCategory: false,
    isFetchingsubCategoryList:false,
    // isAddingCategoryImage:false,
    // categoryImageURL:[],
};

const SubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_SUBCATEGORY":
      return { ...state, isAddingSubCategory: true,  };
    case "SUCCESS_ADD_SUBCATEGORY":
      return {
        ...state,
        subCategory: action.payload,
        isAddingSubCategory: false,

      };
    case "ERROR_ADD_SUBCATEGORY":
      return { ...state, isAddingSubCategory: false,  };

    case "REQUEST_GET_ALL_SUBCATEGORY_DATA":
      return { ...state, isFetchingsubCategoryList: true,  };
    case "SUCCESS_GET_ALL_SUBCATEGORY_DATA":

      return {
        ...state,
        subCategoryList: action.payload,
        isFetchingsubCategoryList: false,

      };
    case "ERROR_GET_ALL_SUBCATEGORY_DATA":
      return { ...state, isFetchingcategoryList: false, };

      case "REQUEST_CATEGORY_BY_WAREHOUSE_ID":
        return { ...state, isFetchingCategoryByWarehouseId: true, };
    case "SUCCESS_CATEGORY_BY_WAREHOUSE_ID":
        return {
            ...state,
            categoryListByWarehouseId: action.payload,
            isFetchingCategoryByWarehouseId: false,
        };
    case "ERROR_CATEGORY_BY_WAREHOUSE_ID":
        return { ...state,isFetchingCategoryByWarehouseId:false};

        case "REQUEST_SUB_CATEGORY_BY_CATEGORY_ID":
          return { ...state, isFetchingSubCategoryByCategoryId: true, };
      case "SUCCESS_SUB_CATEGORY_BY_CATEGORY_ID":
          return {
              ...state,
              subCategoryListByCategoryId: action.payload,
              isFetchingSubCategoryByCategoryId: false,
          };
      case "ERROR_SUB_CATEGORY_BY_CATEGORY_ID":
          return { ...state,isFetchingSubCategoryByCategoryId:false};

    // case "DELETE_REQUEST_USERS":
    //   return { ...state };
    // case "DELETE_SUCCESS_USERS":
    //   return { ...state, categoryList: state.categoryList.filter(item => item.categoryId !== action.categoryId) };
    // case "DELETE_ERROR_USERS":
    //   return { ...state };
    // case "UPDATE_REQUEST_USERS":
    //   return { ...state };
    // case "UPDATE_SUCCESS_USERS":
    //   return { ...state, category: state.category.map(x => action.payload.find(({ categoryId }) => categoryId === x.categoryId) || x) };
    // case "UPDATE_ERROR_USERS":
    //   return { ...state };
    //   case "REQUEST_ADD_SUBCATEGORY_IMAGE":
    //     return { ...state, isAddingCategoryImage: true};
    //   case "SUCCESS_ADD_SUBCATEGORY_IMAGE":
    //     return {
    //       ...state,
    //       categoryImageURL: action.payload,
    //       isAddingCategoryImage: false,
    //     };
    //   case "ERROR_ADD_CATEGORY_IMAGE":
    //     return { ...state, isAddingCategoryImage: false};
    //   case "RESET_CATEGORY_URL":
    //       return { ...state, categoryImageURL: [] }
      default:
        return state;
    }
  };
  export default SubCategoryReducer;