import API from "../../../../../global/api";
export const requestGenerateBarcode = () => {
    return {
        type: "REQUEST_GENERATE_BARCODE",
    };
};
export const successGenerateBarcode = (data) => {
    return {
        type: "SUCCESS_GENERATE_BARCODE",
        payload: data,
    };
};
export const errorGenerateBarcode = () => {
    return {
        type: "ERROR_GENERATE_BARCODE",
    };
};
export const generateBarcode = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestGenerateBarcode());
        API.post(`products/generate_barcode`, data).then((res) => {
           // dispatch(getHallData());
            dispatch(successGenerateBarcode(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorGenerateBarcode());
            handleDangerVisible(error.response.data.message);
        })
    };
};
