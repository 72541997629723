const initialState = {
  shopList: [],
  isAddingShop: false,
  shopDataList: [],
  isFetchingShopData: false,
  isFetchingShopsByArea:false,
  shopsByAreaList:[],
};

const ShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_SHOP":
      return { ...state, isAddingShop: true };
    case "SUCCESS_ADD_SHOP":
      return {
        ...state,
        shopList: action.payload,
        isAddingShop: false,
      };
    case "ERROR_ADD_SHOP":
      return { ...state, isAddingShop: false };
    case "REQUEST_GET_SHOP":
      return { ...state, isFetchingShopData: true };
    case "SUCCESS_GET_SHOP":
      return {
        ...state,
        shopDataList: action.payload.data,
        isFetchingShopData: false,
      };
    case "ERROR_GET_SHOP":
      return { ...state, isFetchingShopData: false };
      case "REQUEST_GET_SHOP_BY_AREA":
        return { ...state, isFetchingShopsByArea: true };
      case "SUCCESS_GET_SHOP_BY_AREA":
        return {
          ...state,
          shopsByAreaList: action.payload.data,
          isFetchingShopsByArea: false,
        };
      case "ERROR_GET_SHOP_BY_AREA":
        return { ...state, isFetchingShopsByArea: false };
    default:
      return state;
  }
};
export default ShopReducer;
