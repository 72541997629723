import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import PlaceIcon from '@mui/icons-material/Place';
import { setHeading } from "../../../accounts/account.actions";
import { deleteShop, getShop, updateShop } from "./Shop.action";

const ViewShop = (props) => {
    const permissions = JSON.parse(window.localStorage.getItem("permissions"));
    const Checkpermissions = (permissionsType, permissionsName) => {
        return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
            ? false
            : true;
    };
    console.log(props.getShop.ownerName, "Ownerrrr");
    useEffect(() => {
        props.setHeading("Shop List");
        // props.getShop();
    }, []);
    return (
        <>
            {props.isFetchingShopData ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Shop Details"
                            columns={[
                                {
                                    title: "Area Name",
                                    field: "areaDetails.areaName",
                                    editable: "never",
                                },
                                { title: "Shop Name", field: "shopName" },
                                { title: "Shop Location", field: "location" },
                                { title: "Shop Description", field: "shopDesc" },
                                { title: "Shop Vat Id/Reg#", field: "ownerCnic" },
                                { title: "Owner Name", field: "ownerName" },
                                { title: "Owner Number", field: "ownerNumber" },
                                { title: "Latitude", field: "geoLocation.coordinates[1]" },
                                { title: "Longitude", field: "geoLocation.coordinates[0]" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/shop?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + query.page;
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                filtering: false,
                                paging: true,
                                pageSize: 50, // make initial page size
                                emptyRowsWhenPaging: false, // To avoid of having empty rows
                                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                isEditHidden: (rowData) =>
                                    Checkpermissions("permissionsSubCatName", "Shop Edit"),
                                isDeleteHidden: (rowData) =>
                                    Checkpermissions("permissionsSubCatName", "Shop Delete"),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.updateShop(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteShop(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: () => <PlaceIcon />,
                                    tooltip: "Location Direction",
                                    onClick: (event, rowData) => window.open(`https://maps.google.com/?q=${rowData.geoLocation.coordinates[1]},${rowData.geoLocation.coordinates[0]}`)
                                },
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getShop: () => dispatch(getShop()),
        updateShop: (newData, oldData) => dispatch(updateShop(newData, oldData)),
        deleteShop: (id) => dispatch(deleteShop(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    shopDataList: state.ShopReducer.shopDataList,
    isFetchingShopData: state.ShopReducer.isFetchingShopData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewShop);