import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getArea } from "../AddArea/Area.action";
import { addShop } from "./Shop.action";

const AddShop = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [shopName, setShopName] = useState("");
  const [shopDesc, setShopDesc] = useState("");
  const [shopLocation, setShopLocation] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerNumber, setOwnerNumber] = useState("");
  const [ownerCNIC, setOwnerCNIC] = useState("");
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadShop() {
    props.addShop(
      {
        areaId: areaId,
        shopName: shopName,
        shopDesc: shopDesc,
        location: shopLocation,
        ownerName: ownerName,
        ownerNumber: ownerNumber,
        ownerCnic: ownerCNIC,
        latitude:Number(latitude),
        longitude:Number(longitude)
      },
      handleVisible,
      handleDangerVisible
    );
    setAreaName("");
    setShopName("");
    setShopDesc("");
    setShopLocation("");
    setOwnerName("");
    setOwnerCNIC("");
    setOwnerNumber("");
    setLongitude(0);
    setLatitude(0)
  }
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
  };
  const AreaListData =
    !isEmpty(props.areaDataList) &&
    props.areaDataList.map((x) => {
      let data = { value: x._id, label: x.areaName };
      return data;
    });
  useEffect(() => {
    props.setHeading("Add Shop");
    props.getArea();
  }, []);
  return (
    <>
      {props.isFetchingAreaData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" xs="6" style={{ marginTop: 10 }}>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Area *</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(CouponTypeData)}
                    placeholder="Select Area..."
                    onChange={handleChangeArea}
                    options={AreaListData}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Shop Name *</b>
                  </Form.Label>
                  <Form.Control
                    value={shopName}
                    type="text"
                    placeholder="Enter Shop Name...."
                    onChange={(e) => {
                      setShopName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Shop Address *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={shopLocation}
                    placeholder="Enter Shop Address...."
                    onChange={(e) => {
                      setShopLocation(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Shop Description</b>
                  </Form.Label>
                  <Form.Control
                    value={shopDesc}
                    type="text"
                    placeholder="Enter Shop Description...."
                    onChange={(e) => {
                      setShopDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Owner Name </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={ownerName}
                    placeholder="Enter Owner Name...."
                    onChange={(e) => {
                      setOwnerName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Owner Phone No# </b>
                  </Form.Label>
                  <Form.Control
                    value={ownerNumber}
                    type="number"
                    placeholder="Enter Owner Phone Number...."
                    onChange={(e) => {
                      setOwnerNumber(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col xl="6" lg="6" md="6" sm="6"> */}
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Shop Vat Id/Reg# *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={ownerCNIC}
                    placeholder="Enter Shop Vat Id/Reg#...."
                    onChange={(e) => {
                      setOwnerCNIC(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Latitude *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={latitude}
                    placeholder="Enter Shop latitude...."
                    onChange={(e) => {
                      setLatitude(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>longitude *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={longitude}
                    placeholder="Enter Shop Longitude...."
                    onChange={(e) => {
                      setLongitude(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
           
            </Row>

            <div className="sendDiv">
              {isEmpty(areaName) ||
              isEmpty(shopName) ||
              isEmpty(shopLocation) ||
              longitude==0||
              latitude==0 ||
              // isEmpty(ownerName) ||
              // isEmpty(ownerNumber) ||
              isEmpty(ownerCNIC) 
              ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadShop}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch(getArea()),
    addShop: (data, handleVisible, handleDangerVisible) =>
      dispatch(addShop(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  shopList: state.ShopReducer.shopList,
  isAddingShop: state.ShopReducer.isAddingShop,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddShop);
