import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Navbar, Breadcrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getUserRes } from "../../AddUser/user.actions";
import {
  addReporting,
  generateNetSaleReportPDF,
  generateSaleReportPDF,
  generateStockReportPDF,
} from "./Reporting.action";
import moment from "moment";
import { getVendorData } from "../Vendor/Vendor.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getCategoryByWarehouseId } from "../SubCategories/SubCategory.action";
import IconMenu from "../../../drawer/IconMenu";
import { Link } from "react-router-dom";
const StockReport = (props) => {
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantName, setRestaurantName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Data not Found",
      icon: "error",
      button: "Ok",
    });
  };

  function getSaleReport() {
    props.generateStockReportPDF(
      { vendorId: vendorId, CategoryId: CategoryId, warehouseID: restaurantId },

      handleDangerVisible,vendorName,Categoryname,restaurantName
    );
    setRestaurantId("");
    setRestaurantName("");
    setCategoryId("");
    setCategoryName("");
    setVendorId("");
    setVendorName("");
  }
  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.getCategoryByWarehouseId(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  const getCategoryList =
    !isEmpty(props.categoryListByWarehouseId) &&
    props.categoryListByWarehouseId.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  const handleChangeCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };

  useEffect(() => {
    props.getRestaurantData();
    props.getVendorData();
    props.setHeading("Stock Report");
  }, []);
  return (
    <>
     <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Stock Report With Purchase Price</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      {props.isFetchingCategoryByWarehouseId ||
      props.isFetchingVendor ||
      props.isFetchingRestaurant ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          
          <div className="main">
          <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Stock Report With Purchase Price</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select WareHouse *</b>
                  </Form.Label>
                  <Select
                    value={{ label: restaurantName }}
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select WareHouse..."
                    onChange={handleChangeRestaurant}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select Category </b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(props.categoryListByWarehouseId) ||
                      isEmpty(restaurantId)
                    }
                    placeholder="Select Category..."
                    onChange={handleChangeCategory}
                    options={getCategoryList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select Vendor </b>
                  </Form.Label>
                  <Select
                    value={{ label: vendorName }}
                    isDisabled={isEmpty(props.vendorDataList)}
                    placeholder="Select Vendor..."
                    onChange={handleChangeVendor}
                    options={getVendorList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {restaurantId == "" ? (
                <Button disabled>Generate Stock Report</Button>
              ) : (
                <Button onClick={getSaleReport}>Generate Stock Report</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    getVendorData: () => dispatch(getVendorData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryByWarehouseId: (id) => dispatch(getCategoryByWarehouseId(id)),
    // addReport: (id, data, handleVisible, handleDangerVisible) =>
    //   dispatch(addReporting(id, data, handleVisible, handleDangerVisible)),
    generateStockReportPDF: (data, handleDangerVisible,vendorName,Categoryname,restaurantName) =>
      dispatch(generateStockReportPDF(data, handleDangerVisible,vendorName,Categoryname,restaurantName)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  categoryListByWarehouseId: state.subCategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId:
    state.subCategoryReducer.isFetchingCategoryByWarehouseId,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(StockReport);
