import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter } from "lodash";
import { getLTAccount, getEmployeeData, addLTAccount, getEmployeeDataWithStatus, addLTReciving } from "./HRDetails.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from 'sweetalert';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { setHeading } from "../../../accounts/account.actions";

const LTReciving = (props) => {

  const [accountNo, setaccountNo] = useState();
  const [selectedAccount, setSelectedAccount] = useState('');
  const [cashAccountTitle, setCashAccountTitle] = useState();
  const [cashAccountTitleKey, setCashAccountTitleKey] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [employeeId, setEmployeeId] = useState('');
  const [descriptionLT, setLTDescription] = useState();
  const [amount, setAmount] = useState('');
  const [deduction, setDeduction] = useState('');
  const [accType, setAccType] = useState("");
  const [date, setDate] = useState('');
  const [ltadv, setLtAdv] = useState();
  const [datestate, setDatestate] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Operation Failed: Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  }
  console.log(datestate, "sssssssssssssssss");
  // const [values, setValues] = useState({
  //   voucherDescription: "",
  //   voucherAmount: "",
  //   PONo: "",
  //   billNo: "",
  //   accountTittle: "",
  //   // payTo: "",
  //   levelFiveId: "",
  //   voucherDate: "",
  //   accountType: "2",
  //   account: "",
  // });

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];

  const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { 
    value: x._id,
    label: `${x.employeeName}/${x.fatherName}/${x.cnic}`, 
  }; return data })

  // const inputEvent = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   setDatestate(event.target.value);
  // };


  // const cashAccountNo =
  //   !isEmpty(props.level5List) &&
  //   props.level5List.map((x) => {
  //     let data = {
  //       value: x.levelfiveData.levelFiveId,
  //       label:
  //         x.levelfiveData.allLevelKey + " /" + x.levelfiveData.levelFiveName,
  //     };
  //     return data;
  //   });

  // const cashAccountNoTop =
  //   !isEmpty(props.level5List) &&
  //   props.level5List
  //     .filter((x) => x.levelfiveData.levelFourId == level4Id)
  //     .map((y) => {
  //       let data = {
  //         value: y.levelfiveData.levelFiveId,
  //         label:
  //           y.levelfiveData.allLevelKey + " /" + y.levelfiveData.levelFiveName,
  //       };
  //       return data;
  //     });

  // const handleChangeAccountNo = (selectedOption) => {
  //   setaccountNo(selectedOption.value);
  //   setCashAccountTitle(
  //     find(
  //       props.ltAccount,
  //       (x) => x.levelFiveId == selectedOption.label,
  //     ).levelFiveName,
  //     console.log(cashAccountTitle,'accccc')
  //   );

  //   setCashAccountTitleKey(
  //     find(
  //       props.ltAccount,
  //       (x) => x.levelFiveId == selectedOption.value
  //     ).levelFiveId
  //   );
  //   // setSelectedAccount(selectedOption)
  // };
  const clearStates = () => {
    setEmployeeId('');
    setAmount('');
    setDeduction('');
    setDate('');
  }

  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label: x.levelFourData.allLevlKey + "/ " + x.levelFourData.levelFourName,
      };
      return data;
    });


  const cash = props.ltAccount;
  console.log(cash, "cashhhhhhhhhhhhhhhhhhh");

  // const addPayment = () => {
  //   let newVal = values;
  //   if (!isEmpty(datestate)) newVal.voucherDate = datestate;
  //   props.submitCashPayment(newVal);
  //   setValues({
  //     voucherDescription: "",
  //     voucherAmount: "",
  //     PONo: "",
  //     billNo: "",
  //     accountTittle: "",
  //     // payTo: "",
  //     levelFiveId: "",
  //     voucherDate: "",
  //     accountType: "2",
  //     account: "",
  //   });
  //   setCashAccountTitle("");
  //   setLevel4Lablel("")
  // };
  const handleChangeEmployee = (selectedOption) => {
   // props.getEmployeeData();
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    console.log(selectedOption.value, selectedOption.label, props.employeeList,'dddd')
   // console.log(props.employeeList[employeeId].lt_adv,"abcd")
  //  console.log(selectedOption.value,"abcd")
  //   console.log(props.employeeList[selectedOption.value].lt_adv,"abcd1")
  //   console.log( find(props.employeeList[selectedOption.value]),"abcde")
  setLtAdv(find(props.employeeList, x => x._id == selectedOption.value).ltAdv)
  };
  useEffect(() => {
    //props.getLTAccount();
     props.getEmployeeData();
     props.setHeading("Long Term Receiving")
    //props.getEmployeeDataWithStatus(1);
  }, []);
  const SaveLTAccount = () => {
    props.addLTReciving({
      employeeId: employeeId,
      accType: accType,
      description: descriptionLT,
      creditValue: amount,
     // long_term_data_deduction_value: deduction,
     date: date,
    }, handleVisible, handleDangerVisible);
    props.getEmployeeData();
  };
  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Employee</Form.Label>
                      <Select
                        //value={name}
                        placeholder="Select Employee..."
                        onChange={handleChangeEmployee}
                        options={getEmployeeList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Method</Form.Label>
                      <Select
                        // isDisabled={isEmpty(getDepartmentNameList)}
                        placeholder="Select Method..."
                        onChange={(selectedOption) => { setAccType(selectedOption.label)}}
                        options={[{value: "1", label: "Cash"}, {value: "2", label: "Bank"}]}
                        // value={{
                        //   value: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].levelFiveId : STAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                        //   label: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].levelFiveName : STAccounts.cashname
                        // }}
                      />
                      </Form.Group>
                  </Col>

                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        id="descriptionID"
                        name="voucherDescription"
                        //value={values.voucherDescription}
                        onBlur={(e) => setLTDescription((e.target.value).toUpperCase())}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        //value={values.voucherAmount}
                        //maxValue={props.employeeList.lt_adv}
                        
                       disabled={true}
                       value={ltadv}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        value={amount}
                        //maxValue={props.employeeList.lt_adv}
                        
                        max={ltadv}
                        onChange={
                          (e) =>  Number(e.target.value) <= ltadv && setAmount(e.target.value)
                        }
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Deduction Value</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Deduction..."
                        id="billno"
                        name="billNo"
                        // min="0"
                        // max="99"
                        // isInvalid={deduction < 2 || deduction > 99}
                        //value={values.billNo}
                        onBlur={(e) => setDeduction(e.target.value)}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </div>

              <Row>
                <Col lg="12" sm="12" className="text-center">
                  {((employeeId === '') || (amount === '')  || (date === '')) ? 
                  <Button  disabled size="large" onClick={SaveLTAccount}>Save</Button> : 
                  <Button  onClick={SaveLTAccount}>Save</Button>}
                </Col>
              </Row>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
   // getLTAccount: () => dispatch(getLTAccount()),
    getEmployeeData: () => dispatch(getEmployeeData()),
    addLTReciving: (data, handleVisible, handleDangerVisible) => dispatch(addLTReciving(data, handleVisible, handleDangerVisible)),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  ltAccount: state.HrReducer.ltAccount,
  isFetchingLTAccount: state.accountsLevelReducer.isFetchingLTAccount,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LTReciving);
