import React, { useEffect, useState, forwardRef } from "react";
import { Row, Col, Form, Container, Button, Modal, Alert } from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import { deleteCategory, getCategoryData, updateCategory, updateCategoryStatusApprove, updateCategoryStatusDisapprove } from "./Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";
import { deleteSubCategory, getSubCategory, updateSubCategory } from "./SubCategory.action";
const SubCategoryTableList = (props) => {
    const permissions=JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType,permissionsName) => {
      console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
     return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
    }
    const categoryData = isEmpty(props.categoryList) ? [] : props.categoryList;

    
    useEffect(() => {
        //  props.getSubCategory();
    
            props.setHeading("Sub Category List");
           
    }, []);

    return (
        <>

            {props.isFetchingcategoryList ? (
                <div className="loader-div">

                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />

                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <MaterialTable
                            title="Sub Category Details"
                            columns={[
                               { title: 'WareHouse Name', field: 'wareHouseId.wareHouseName', editable: 'never' },
                                { title: 'Category Name', field: 'categoryId.name', editable: 'never' },
                                { title: 'Sub Category Name', field: 'subCategoryName' },
                                // { title: 'Image', field: 'image', editable:'never',render: item => <img src={item.image} alt="no image" border="3" height="100" width="100" />},
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/subCategory?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    })
                                        .then((response) => response.json())
                                        .then((result) => {
                                            resolve({
                                                data: result.data,
                                                page: result.page,
                                                totalCount: result.total_results,
                                            });
                                        });
            
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                // filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 50,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [30, 50, 100,150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                    //             isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Menu Edit"),
                    // isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Menu Delete"),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updateSubCategory(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        console.log(oldData,'hmh')
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteSubCategory(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />

                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSubCategory: () => dispatch(getSubCategory()),
        deleteSubCategory: (_Id) => dispatch(deleteSubCategory(_Id)),
        updateSubCategory: (newData, oldData) => dispatch(updateSubCategory(newData, oldData)),
        setHeading:(data)=>dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    subCategoryList: state.subCategoryReducer.subCategoryList,
    isFetchingsubCategoryList: state.subCategoryReducer.isFetchingsubCategoryList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubCategoryTableList);