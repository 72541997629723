import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./hr-styles.css";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { getEmployeeData, HRUpdateEmployee, deleteHR } from "./HRDetails.actions";
import VisibilityIcon from '@material-ui/icons/Visibility';
// import { Redirect, Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";

const ViewEmployee = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
      tableRef.current.onQueryChange()
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    console.log(anchorEl, "eeeeeeeeee");
    const [employeeDataList, setEmployeeDataList] = React.useState('');
    console.log(employeeDataList, "emidddd")

    const handleClick = (event, rowData) => {
        setAnchorEl(event.currentTarget);
        setEmployeeDataList(rowData);
        console.log(rowData, "rowwwwwwww");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const employeeData = props.employeeList.reverse();

    useEffect(() => {
        props.setHeading("Employee List")
        // props.getEmployeeData();
    }, []);


    const [callEdit, setcallEdit] = React.useState(false);
    // if (callEdit) {
    //     return <Redirect push to="/user/view-user" />;
    // }

    return (
        <>
            {props.isFetchingEmployeeData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                        tableRef={tableRef}
                            title="Employees Details"
                            columns={uniqBy([
                                // { title: 'CNIC', field: 'cnic' },employeeId
                                // { title: 'Employee Id', field: 'employeeId',   },
                                // { title: 'Machine Id', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].machine_id} ` : '-'   },
                                { title: 'Name', field: 'employeeName' },
                                { title: 'Father Name', field: 'fatherName' },
                                { title: 'Department', field: 'departmentDetails.department' },
                                { title: 'Designation', field: 'designationDetails.designation' },
                    
                                // { title: 'Total Salary', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance + rowData.salaries[0].basic_salary} ` : '-' },
                                { title: 'Joining Date', field: 'joiningDate' },
                                { title: 'Employee Type', field: 'employeeTypeDetails.employeeType' },
                                { title: 'Active Status', field: 'active_status', render: rowData=> (rowData.active_status===true) ? 'Active' : 'InActive'},
                    
                                // { title: 'Gender'
                            ] , 'title')}
                            // data={employeeData}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                  let url = `${baseURL}/employee?`;
                                  url += "limit=" + query.pageSize;
                                  url += "&page=" + query.page;
                                  url += "&search=" + query.search;
                                  fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total_results,
                                      });
                                    });
                                })
                              }
                              detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        // title="Salary Details"
                                        columns={[
                                            { title: 'Machine Id', field:'machineId'   },
                                            { title: 'Basic Salary', field: 'basicSalary' },
                                            { title: 'Medicial Allowance', field: 'medicialAllowance' },
                                            { title: 'Petrol Allowance', field: 'petrolAllowance' },
                                            { title: 'Accomodation Allowance', field: 'accomodationAllowance' },
                                            // { title: 'Total Salary', render: rowData=> (!isEmpty(rowData.salaries)) ? `${rowData.salaries[0].accomodation_allowance + rowData.salaries[0].other_allowance + rowData.salaries[0].petrol_allowance + rowData.salaries[0].medicial_allowance + rowData.salaries[0].special_allowance + rowData.salaries[0].basic_salary} ` : '-' },
                                            { title: 'Special Allowance', field: 'specialAllowance' },
                                            { title: 'Total Allowances', field: 'totalAllowances' },
                                            { title: 'Total Salary', field: 'totalSalary'},
                                
                                        ]}
                                        data={[rowData.salary]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging: false,
                                            pageSize: 30,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}

                                    />
                                )
                            }}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                sorting: true,
                                paging: true,
                                pageSize: 50, // make initial page size
                                emptyRowsWhenPaging: false, // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200,500], // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            // actions={[
                            //     // (rowData) => {
                            //     //     return { icon: EditIcon, disabled: false, onClick: (event, rowData) => { handleClick(event, rowData) } }
                            //     // },
                            // ]}
                            editable={{

                                // onRowUpdate: (newData, oldData) =>
                                //     new Promise((resolve) => {
                                //         setTimeout(() => {
                                //             resolve();
                                //             if (oldData) {
                                //                 props.HRUpdateEmployee(newData, oldData)

                                //             }
                                //         }, 600);
                                //     }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteHR(oldData._id,updateTableQuery);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>

                    {/* <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <Link
                            to={{
                                pathname: "/user/edit-employee",
                                state: employeeDataList
                            }}>
                            <MenuItem>
                                Edit Employee
                            </MenuItem>
                        </Link>

                        <Link
                            to={{
                                pathname: "/user/edit-salary",
                                state: employeeDataList
                            }}>
                            {console.log('employeeDataList', employeeDataList)}
                            <MenuItem>
                                Edit Salary
                            </MenuItem>
                        </Link>
                    </Menu> */}
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEmployeeData: () => dispatch(getEmployeeData()),
        updateHR: (data, oldData) => dispatch(HRUpdateEmployee(data, oldData)),
        deleteHR: (data,updateTableQuery) => dispatch(deleteHR(data,updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewEmployee);