import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Breadcrumb, Navbar, } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Swal from "sweetalert";
import moment from "moment";
import { getDailySalemenStockReport, getDailyStockReport, getDateWiseSaleReport2 } from "./Report.action";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";


const SalemenStockReport = (props) => {
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "info",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const exportPDF = () => {
    props.getDateWiseSaleReport2({ startDate: startDate,endDate:startDate },
      handleDangerVisible, startDate);
  };

  useEffect(() => {
  }, []);
  return (
    <>
      <Navbar style={{ backgroundColor: '#81abd3' }}  >
        <Container>
          <Navbar.Brand style={{ color: 'white' }}><b>Salesmen Stock Report</b></Navbar.Brand>
          <IconMenu />
        </Container>
      </Navbar>

      <Container fluid>
        <div className="main">
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
              <Breadcrumb.Item active>Salesmen Stock Report</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row>
            <Col xl="12" lg="12" md="12" sm="12">
              <FormGroup>
                <Form.Label><b>Date *</b></Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
          </Row>

          {/* <div style={{ marginTop: '20px', textAlign: 'center' }}>
            {isEmpty(startDate) ? (
              <Button  disabled> Genrate PDF </Button>
            ) : (
              <Button  onClick={exportPDF}> Genrate PDF </Button>
            )}
          </div> */}
                  <div className="sendDiv" style={{ marginTop: "2%" }}>
              {isEmpty(startDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    border: "1px solid",
                  }}
                >
                  Generate Stock Report
                </Button>
              ) : props.isFetchingSaleReport ? (
                <Button>Generatig PDF</Button>
              ) : (
                <Button onClick={() => exportPDF()}>
                  Generate Stock Report
                </Button>
              )}
            </div>

        </div>
      </Container>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDateWiseSaleReport2: (data, handleDangerVisible, date) => dispatch(getDailySalemenStockReport(data, handleDangerVisible, date)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingSaleReport: state.ReportReducer.isFetchingSaleReport,
  saleReportData: state.ReportReducer.saleReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SalemenStockReport);