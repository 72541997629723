import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { getEmployeeData, getEmployeeDataWithStatus } from '../AddDetails/HRDetails.actions';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty, find, forEach, get } from "lodash";
import Select from 'react-select';
import logo from '../../../../../Images/logo.png'
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import { baseURL } from "../../../../../global/api";
import { setHeading } from '../../../accounts/account.actions';


function EmpCard(props) {

  const [item, setItem] = useState("");
  const [employee, setEmployee] = useState([]);
  //  const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];
  //  !isEmpty(getEmployeeList) && getEmployeeList.push({ value: 0, label: "All" });

  // const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });
  // console.log(getEmployeeList,"abcd")
  const getEmployeeList = () => {
    let dt = [];
    if (!isEmpty(props.employeeList)) {
      props.employeeList.map((x) => {
        dt.push({
          value: x._id,
          label: `${x.employeeName}/${x.fatherName}/${x.cnic}`,///${x.employee_cnic}
        });
      });
      console.log(dt, "ddddddd");
      dt.push({
        value: 0,
        label: `ALL`, ///${x.employee_cnic}
      });
    }
    return dt;
  };
  console.log(getEmployeeList(), "test");
  const handleChangeEmployee = (selectedOption) => {
    setItem(selectedOption.value);
    let empId = selectedOption.value;
    console.log(empId, "test12");
    console.log(props.employeeList, "test123");
    let getEmp = [];
    if (selectedOption.value == 0) {
      getEmp = props.employeeList;
    } else {
      getEmp = !isEmpty(props.employeeList) && [
        find(props.employeeList, (x) => x._id == empId),
      ];
    }
    setEmployee(getEmp);
    console.log(getEmp, "test1");
  };
  useEffect(() => {
    props.getEmployeeData();
    props.setHeading("Employee Card")
    //  console.log(props.employeeList,"hmhm")
  }, []);
  function DownloadSchedulePDF(emp1) {
    const unit = "pt";
    const size = ('l', 'mm', [200, 125]);; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape


    const marginLeft = 20;
    const marginLeft1 = 200;
    const marginLeft2 = 100;
    const marginLeft3 = 300;
    const doc = new jsPDF(orientation, unit, size);
    forEach(emp1, (dt, idex) => {
      console.log(dt,'ttttttttttttttttt')
      doc.setFontSize(5);
      //const date = "Date : 01-08-2022";
      const title = "JAYSON SPARK ENTERPRISES";
      const companyName = "HADI TOWN, DEFENCE ROAD SIALKOT 052-3572070";
      const empCode = `Code : ${dt._id}`;
      const empName = `Name : ${dt.employeeName}`;
      const dept = `Department : ${dt.departmentDetails.department}`;
      const address = `Address : ${dt.address}`;
      const FName = `Father Name: ${dt.fatherName}`;
      const empCnic = `CNIC :${dt.cnic}`;
      // const Image=`${baseURL}get_image_data_by_path/${dt.uploadImage}`;
      const note = "**If anyone find this card return it to XYZ Company."

      //setImage(`${baseURL}get_image_data_by_path/${state.uploadImage}`);
      //const headers = [["Sr No.", "EMPLOYEE'S NAME", "DESCRIPTION", "BILL NO", "DEBIT", "CREDIT", "BALANCE", "DR/CR"]];
      // const data = getData.a.map((x) => [x.voucher_date, x.cross_acc, x.voucher_description, x.voucher_bill_no, isNull(x.debit) ? 0 : x.debit, isNull(x.credit) ? 0 : x.credit, x.accBalance, x.accType == 2 ? "Debit" : x.accType == 1 ? "Credit" : ""])
      //doc.text(date, marginLeft, 20, 0, 20)

      doc.addImage(logo, 'png', 10, 1, 40, 40)
      // console.log(Image,'ii')
      // doc.addImage(Image,"png",140, 40, 40, 40)
      doc.setFont(undefined, 'bold');
      doc.text(empCode, marginLeft, 50, 0, 20);
      doc.text(empName, marginLeft, 60, 0, 20);
      doc.text(FName, marginLeft, 70, 0, 20);
      doc.text(empCnic, marginLeft, 80, 0, 20);
      doc.text(dept, marginLeft, 90, 0, 20);
      doc.text(address, marginLeft, 100, { maxWidth: 150 });
      doc.text(note, marginLeft, 110, 0, 20);

      doc.setFont(undefined, 'bold');
      doc.setFontSize(12);
      !(idex === emp1.length - 1) && doc.addPage();
    });
    var string = doc.output('datauristring', 'dddd');
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    var x = window.open('Demand Report');
    x.document.open('Demand Report');
    x.document.write(embed);
    x.document.close();
    //  doc.save('demo.pdf');
  };

  return (
    <div className="sendDiv">
      <Row style={{ textAlign: 'left' }}>
        <Col >
          <Form.Group>
            <Form.Label>
              <b> Select Employee </b>
            </Form.Label>
            <Select
              placeholder="Select Employee..."
              onChange={handleChangeEmployee}
              options={getEmployeeList()}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="sendDiv" style={{ marginTop: '15px' }}>
        {item === "" ?
          <Button disabled onClick={() => DownloadSchedulePDF(employee)}>Generate Employee Card</Button> :
          <Button onClick={() => DownloadSchedulePDF(employee)}>Generate Employee Card</Button>}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  employeeList: state.HrReducer.employeeList,
  // isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmpCard);