import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import moment from "moment";
import { getProductSaleReport } from "./Report.action";

import { Link } from "react-router-dom";

import IconMenu from "../../../drawer/IconMenu";
const ProductSaleReport = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const tableRef = useRef();
  function updateTableQuery() {
    tableRef.current.onQueryChange();
  }

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Report Generated Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const [state, setState] = React.useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportPDF = () => {
    props.getProductSaleReport(
      { startDate: startDate, endDate: endDate,product:'All' },
      startDate,
      endDate,
      handleVisible,
      handleDangerVisible
    );
  };
  console.log(props.productSaleReportData, "ddddddddd");

  useEffect(() => {
    // props.getProductSerivceAndHybrid()
  }, []);
  return (
    <>
      <Navbar style={{ backgroundColor: "#81abd3" }}>
        <Container>
          <Navbar.Brand style={{ color: "white" }}>
            <b>Product Profit Report</b>
          </Navbar.Brand>
          <IconMenu />
        </Container>
      </Navbar>

      {props.isFetchingProductReport ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/home"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/report">Report</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Product Profit Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>End Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "2%" }}>
              {isUndefined(startDate)||isUndefined(endDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    border: "1px solid",
                  }}
                >
                  Generate Product Profit Report
                </Button>
              ) : props.isFetchingProductReport ? (
                <Button>Generating PDF</Button>
              ) : (
                <Button onClick={() => exportPDF()}>
                  Generate Product Profit Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProductSaleReport: (
      data,
      startDate,
      endDate,
      handleVisible,
      handleDangerVisible
    ) =>
      dispatch(
        getProductSaleReport(
          data,
          startDate,
          endDate,
          handleVisible,
          handleDangerVisible
        )
      ),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productSaleReportData: state.ReportReducer.productSaleReportData,
  isFetchingProductReport: state.ReportReducer.isFetchingProductReport,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSaleReport);
