import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { getMasterAccounts, addAccounts, resetMasterData, getSelectedMasterAccounts } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';
import { setHeading } from "../../../accounts/account.actions";



const MasterControl = (props) => {
    // var _ = require('lodash');
    const [LTAccountId, setLTAccountId] = useState('');
    const [LTAccountName, setLTAccountName] = useState('');
    const [STAccountId, setSTAccountId] = useState('');
    const [STAccountName, setSTAccountName] = useState('');
    const [STAccounts, setsTAccounts] = useState(
        {
            bankId: '',
            bankName: '',
            cashId: '',
            cashName: '',
            adjustId: '',
            adjustName: '',
        }
    );
    const [LTAccounts, setlTAccounts] = useState(
        {
            bankId: '',
            bankName: '',
            cashId: '',
            cashName: '',
            adjustId: '',
            adjustName: '',
        }
    );
    const [miscAccounts, setMiscAccounts] = useState(
        {

            sExpenseId: '',
            sExpenseName: '',
            ovetimeId: '',
            ovetimeName: '',
            allowancesId: '',
            allowancesName: '',
            deductionsId: '',
            deductionsName: '',
            salaryId: '',
            salaryName: '',

        }
    );


    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);



    useEffect(() => {
        props.setHeading("Account Integration")
        props.getMasterAccounts();
        // props.getEmployeeData();
        props.getSelectedMasterAccounts();
    }, []);
    // const getMasterAccounts = props.
    function saveAccounts() {
        props.addAccounts(
            {


                longTermAdv: LTAccountId,
                longTermBank: LTAccounts.bankId,
                longTermCash: LTAccounts.cashId,
                longTermAdjustment: LTAccounts.adjustId,
                shortTermAdv: STAccountId,
                shortTermBank: STAccounts.bankId,
                shortTermCash: STAccounts.cashId,
                shortTermAdjustment: STAccounts.adjustId,
                salaryExpense: miscAccounts.sExpenseId,
                overtimeExpense: miscAccounts.ovetimeId,
                allowances: miscAccounts.allowancesId,
                deductions: miscAccounts.deductionsId,
                salaryPayable: miscAccounts.salaryId
            }, handleVisible, handleDangerVisible,);

        props.resetMasterData();
    }
    console.log(props.masterData, 'masterDatamasterData')
    const getAccountsList = !isEmpty(props.masterData) && props.masterData.map((x) => { let data = { value: x.levelFourData._id, label: x.levelFourData.levelFourName }; return data })
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Saved successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    const handleChangeLTAccount = (selectedOption) => {
        setLTAccountId(selectedOption.value);
        setLTAccountName(selectedOption.label);
    };
    const handleChangeSTAccount = (selectedOption) => {
        setSTAccountId(selectedOption.value);
        setSTAccountName(selectedOption.label);
    };
    return (
        <>
            {(props.isFetchingMasterAccounts) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <div>
                    <Form>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Account..."
                                        onChange={handleChangeLTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccountId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdv._id : LTAccountId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccountId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdv.levelFourName : LTAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Account..."
                                        onChange={handleChangeSTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccountId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdv._id : STAccountId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccountId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdv.levelFourName : STAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Bank Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Bank Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermBank._id : LTAccounts.bankId,
                                            label: (LTAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermBank.levelFourName : LTAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Bank Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Bank Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermBank._id : STAccounts.bankId,
                                            label: (STAccounts.bankId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermBank.levelFourName : STAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Cash Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Cash Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermCash._id : LTAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermCash.levelFourName : LTAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Cash Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Cash Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermCash._id : STAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermCash.levelFourName : STAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Adjustment Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Adjustment Account..."
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdjustment._id : LTAccounts.adjustId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (LTAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdjustment.levelFourName : LTAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Adjustment Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Adjustment Account..."
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdjustment._id : STAccounts.adjustId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (STAccounts.adjustId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdjustment.levelFourName : STAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Expense Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Expense Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, sExpenseId: selectedOption.value, sExpenseName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.sExpenseId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryExpense._id : miscAccounts.sExpenseId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.sExpenseId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryExpense.levelFourName : miscAccounts.sExpenseName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Overtime Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Overtime Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, ovetimeId: selectedOption.value, ovetimeName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.ovetimeId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].overtimeExpense._id : miscAccounts.ovetimeId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.ovetimeId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].overtimeExpense.levelFourName : miscAccounts.ovetimeName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Allowances Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Allowances Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, allowancesId: selectedOption.value, allowancesName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.allowancesId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].allowances._id : miscAccounts.allowancesId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.allowancesId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].allowances.levelFourName : miscAccounts.allowancesName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Deductions Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Deductions Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, deductionsId: selectedOption.value, deductionsName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.deductionsId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].deductions._id : miscAccounts.deductionsId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.deductionsId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].deductions.levelFourName : miscAccounts.deductionsName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Salary Payable Account:</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Salary Payable Account..."
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, salaryId: selectedOption.value, salaryName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.salaryId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryPayable._id : miscAccounts.salaryId, /*Sometimes the indexing is opposite, then both values get swapped*/
                                            label: (miscAccounts.salaryId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryPayable.levelFourName : miscAccounts.salaryName
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <div className="sendDiv">
                            <Button onClick={saveAccounts}
                            // disabled={(LTAccountId === '') || (STAccountId === '')}
                            >Save</Button>
                        </div>
                    </Form>

                </div>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMasterAccounts: () => dispatch(getMasterAccounts()),
        addAccounts: (data, handleVisible, handleDangerVisible) => dispatch(addAccounts(data, handleVisible, handleDangerVisible)),
        resetMasterData: () => dispatch(resetMasterData()),
        getSelectedMasterAccounts: () => dispatch(getSelectedMasterAccounts()),
        setHeading: (data) => dispatch(setHeading(data)),


    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingMasterAccounts: state.HrReducer.isFetchingMasterAccounts,
    masterData: state.HrReducer.masterData,
    selectedMasterData: state.HrReducer.selectedMasterData,
    isFetchingSelectedMasterAccounts: state.HrReducer.isFetchingSelectedMasterAccounts,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterControl);