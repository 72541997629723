import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import useScanDetection from "use-scan-detection";

import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getProductData } from "../AddProduct/Product.action";
import { generateBarcode } from "./Barcode.action";

const GenerateBarcode = (props) => {

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [productId, setProductId] = useState("");
  const [productname, setProductName] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.generateBarcode(
      {
        productId: productId,
        quantity:productQuantity
      },
      handleVisible,
      handleDangerVisible
    );
  }





 

  const getMList =
    !isEmpty(props.productDataList) &&
    props.productDataList.map((x) => {

      let data = {
        value: x._id,
        label: x.name,
        // productQuantityCount: x.productBalance,
        // productPrice:x.purchasePrice
      };
      return data;
    });
  
  const handleSelectProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductName(selectedOption.label);
  };
  useEffect(() => {
    props.getProduct();
    props.setHeading("Generate Barcode");
  }, []);

  return (
    <>
      {props.isFetchingProduct || props.isGeneratingBarcode ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
          <Row>
                    <Col xl="6" lg="6" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                        isDisabled={isEmpty(props.productDataList)}
                        placeholder="Select Product..."
                        onChange={handleSelectProduct}
                        options={getMList}
                      />
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Quantity :</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={productQuantity}
                        placeholder="Quantity...."
                        onChange={(e) => setProductQuantity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

               
                  </Row>
         
            <div className="sendDiv">
              {isEmpty(productId) ||productQuantity==""? (
                <Button
                  disabled
                  // style={{ color: "black" }}
                >
                  Save
                </Button>
              ) : (
                <Button onClick={uploadPurchase}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    generateBarcode: (data, handleVisible, handleDangerVisible) =>
      dispatch(generateBarcode(data, handleVisible, handleDangerVisible)),
    getMatrial: () => dispatch(getHybridAndCompleteData()),
    getProduct: () => dispatch(getProductData()),
    setHeading:(data)=>dispatch(setHeading(data)),
    // getRestaurantData: () => dispatch(getRestaurantData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData:
    state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isAddingReceiving: state.RecivingReducer.isAddingReceiving,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct:state.ProductReducer.isFetchingProduct,
  isGeneratingBarcode:state.barCodeReducer.isGeneratingBarcode,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateBarcode);
