import API from "../../../../../global/api";
import { get, forEach, isUndefined } from "lodash";

//SalaryIncrement-ADD

export const requestAddSalaryIncrement = () => {
  return {
    type: "REQUEST_ADD_SALARY_INCREMENT",
  };
};

export const successAddSalaryIncrement = (data) => {
  return {
    type: "SUCCESS_ADD_SALARY_INCREMENT",
    payload: data,
  };
};

export const errorAddSalaryIncrement = () => {
  return {
    type: "ERROR_ADD_SALARY_INCREMENT",
  };
};

export const addSalaryIncrement = (
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestAddSalaryIncrement());
    API.post(`/salaryIncrement`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successAddSalaryIncrement(data));
        getSalaryIncrementById(data.employee_id);

        handleVisible();
        // getSalaryIncrement();
      })
      .catch((error) => {
        dispatch(errorAddSalaryIncrement());
        getSalaryIncrementById(data.employee_id);
        handleDangerVisible();
      });
  };
};

//SalaryIncrement-GET

export const requestGetSalaryIncrement = () => {
  return {
    type: "REQUEST_GET_SALARY_INCREMENT",
  };
};

export const successGetSalaryIncrement = (data) => {
  return {
    type: "SUCCESS_GET_SALARY_INCREMENT",
    payload: data,
  };
};

export const errorGetSalaryIncrement = () => {
  return {
    type: "ERROR_GET_SALARY_INCREMENT",
  };
};

export const getSalaryIncrement = () => {
  return (dispatch) => {
    dispatch(requestGetSalaryIncrement());
    API.get(`/salaryIncrement`) //Clarify
      .then((res) => {
        let getData = res.data;
        console.log("get_increment", getData);
        dispatch(successGetSalaryIncrement(getData));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorGetSalaryIncrement());
        // handleDangerVisible();
      });
  };
};

//SalaryIncrement-GET

export const requestGetSalaryIncrementById = () => {
  return {
    type: "REQUEST_GET_SALARY_INCREMENT_BY_ID",
  };
};

export const successGetSalaryIncrementById = (data) => {
  return {
    type: "SUCCESS_GET_SALARY_INCREMENT_BY_ID",
    payload: data,
  };
};

export const errorGetSalaryIncrementById = () => {
  return {
    type: "ERROR_GET_SALARY_INCREMENT_BY_ID",
  };
};

export const getSalaryIncrementById = (employee_id) => {
  return (dispatch) => {
    dispatch(requestGetSalaryIncrementById());
    API.get(`/salary/get_salary_with_employeeID/${employee_id}`) //Clarify
      .then((res) => {
        let getData = res.data;
        //    console.log('get_increment', getData);
        dispatch(successGetSalaryIncrementById(getData));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorGetSalaryIncrementById());
        // handleDangerVisible();
      });
  };
};

//SalaryIncrement-DELETE

export const requestDeleteSalaryIncrement = () => {
  return {
    type: "REQUEST_DELETE_SALARY_INCREMENT",
  };
};

export const successDeleteSalaryIncrement = (data) => {
  return {
    type: "SUCCESS_DELETE_SALARY_INCREMENT",
    payload: data,
  };
};

export const errorDeleteSalaryIncrement = () => {
  return {
    type: "ERROR_DELETE_SALARY_INCREMENT",
  };
};

export const deleteSalaryIncrement = (data,updateTableQuery) => {
  return (dispatch) => {
    dispatch(requestDeleteSalaryIncrement());
    API.delete(`/salaryIncrement/${data._id}`)
      .then((res) => {
        let getData = res.data;
        //  console.log('get_increment', getData);
        dispatch(successDeleteSalaryIncrement(getData));
        updateTableQuery()
        // dispatch(getSalaryIncrementById(data.employee_id));
      })
      .catch((error) => {
        dispatch(errorDeleteSalaryIncrement());
      });
  };
};

//Attendance-Add
export const requestAddAttendance = () => {
  return {
    type: "REQUEST_ADD_ATTENDANCE",
  };
};

export const successAddAttendance = (data) => {
  return {
    type: "SUCCESS_ADD_ATTENDANCE",
    payload: data,
  };
};

export const errorAddAttendance = () => {
  return {
    type: "ERROR_ADD_ATTENDANCE",
  };
};

export const addAttendance = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddAttendance());
    API.post(`/attendance/add_manual_attendance`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successAddAttendance(data));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddAttendance());
        handleDangerVisible();
      });
  };
};

//Attendance-Get
export const requestGetAttendance = () => {
  return {
    type: "REQUEST_GET_ATTENDANCE",
  };
};

export const successGetAttendance = (data) => {
  return {
    type: "SUCCESS_GET_ATTENDANCE",
    payload: data,
  };
};

export const errorGetAttendance = () => {
  return {
    type: "ERROR_GET_ATTENDANCE",
  };
};

export const getAttendance = (data) => {
  return (dispatch) => {
    dispatch(requestGetAttendance());
    API.post(`/attendance`, data)
      .then((res) => {
        let getData = res.data;
        console.log("getData", getData);
        dispatch(successGetAttendance(getData));
      })
      .catch((error) => {
        dispatch(errorGetAttendance());
      });
  };
};

export const requestAddAttendanceFile = () => {
  return {
    type: "REQUEST_ADD_ATTENDANCE_FILE",
  };
};

export const successAddAttendanceFile = (data) => {
  return {
    type: "SUCCESS_ADD_ATTENDANCE_FILE",
    // payload: data
  };
};

export const errorAddAttendanceFile = () => {
  return {
    type: "ERROR_ADD_ATTENDANCE_FILE",
  };
};

//Attendance-Update
export const requestUpdateAttendance = () => {
  return {
    type: "REQUEST_UPDATE_ATTENDANCE",
  };
};
export const successUpdateAttendance = (data) => {
  return {
    type: "SUCCESS_UPDATE_ATTENDANCE",
    payload: data,
  };
};
export const errorUpdateAttendance = () => {
  return {
    type: "ERROR_UPDATE_ATTENDANCE",
  };
};

export const UpdateAttendance = (newData, oldData, employeeData) => {
  console.log("newData", newData);
  // let division_id=newData.division_id;
  return (dispatch) => {
    dispatch(requestUpdateAttendance());
    // API.get(`/division/add_division/:${division_id}`, newData).then((res) => {
    API.put(`/attendance/update_attendance/${oldData.id}`, newData)
      .then((res) => {
        let getData = res.data;
        dispatch(successUpdateAttendance([newData]));
        dispatch(getAttendance(employeeData));
      })
      .catch((error) => {
        dispatch(errorUpdateAttendance());
      });
  };
};

//Attendance-Delete
export const requestDeleteAttendance = () => {
  return {
    type: "REQUEST_DELETE_ATTENDANCE",
  };
};
export const successDeleteAttendance = (data) => {
  return {
    type: "SUCCESS_DELETE_ATTENDANCE",
    payload: data,
  };
};
export const errorDeleteAttendance = () => {
  return {
    type: "ERROR_DELETE_ATTENDANCE",
  };
};
export const deleteAttendance = (id, employeeData) => {
  return (dispatch) => {
    dispatch(requestDeleteAttendance());
    API.delete(`/attendance/delete_attendance/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successDeleteAttendance());
        dispatch(getAttendance(employeeData));
      })
      .catch((error) => {
        dispatch(errorDeleteAttendance());
      });
  };
};

export const addAttendanceFile = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("attendanceFile", get(data, "attendance_file"));
  formData.append("attendanceMonthYear", `${data.attendance_month_year}`);
  // formData.append("uploadDocument", get(data, 'uploadDocument'))

  // forEach(data.uploadCnicImage, (x, index) => {
  //     formData.append("uploadCnicImage", data.uploadCnicImage[index]);
  // })
  // forEach(data.uploadDocument, (x, index) => {
  //     formData.append("uploadDocument", data.uploadDocument[index]);
  // })
  // // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
  // //formData.append("uploadDocument", get(data, 'uploadDocument'));

  return (dispatch) => {
    dispatch(requestAddAttendanceFile());
    API.post(
      `/attendance`,
      formData
      // , {
      //     headers: {
      //         'Content-Type': 'multipart/form-data'
      //     }
      // }
    )
      .then((res) => {
        let getData = res.data;
        dispatch(successAddAttendanceFile(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddAttendanceFile());
        handleDangerVisible();
      });
  };
};
//adjustment
export const requestAddAdjustment = () => {
  return {
    type: "REQUEST_ADD_ADJUSTMENT",
  };
};

export const successAddAdjustment = (data) => {
  return {
    type: "SUCCESS_ADD_ADJUSTMENT",
    payload: data,
  };
};

export const errorAddAdjustment = () => {
  return {
    type: "ERROR_ADD_ADJUSTMENT",
  };
};

export const addAdjustment = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddAdjustment());
    API.post(`/attendance/add_attendance_adjustment`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successAddAdjustment(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddAdjustment());
        handleDangerVisible(error.message);
        // handleDangerVisible(error);
      });
  };
};

export const requestGetAdjustment = () => {
  return {
    type: "REQUEST_GET_ADJUSTMENT",
  };
};

export const successGetAdjustment = (data) => {
  return {
    type: "SUCCESS_GET_ADJUSTMENT",
    payload: data,
  };
};

export const errorGetAdjustment = () => {
  return {
    type: "ERROR_GET_ADJUSTMENT",
  };
};

export const getAdjustment = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetAdjustment());
    API.post(`/attendance/get_attendance_adjustment_report`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetAdjustment(getData));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorGetAdjustment());
        console.log(error, error.response.data.message, "dddddd");

        // handleDangerVisible(!isUndefined(getData) && getData.message);
        handleDangerVisible(error.response.data.message);
        // console.log(error)
      });
  };
};
