import API from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../../src/Images/logo.png";
import moment from "moment";
import { isEmpty, isNull, sortBy, sumBy, map } from "lodash";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
export const requestGetDailySaleReport = () => {
  return {
    type: "REQUEST_GET_DAILY_SALE",
  };
};
export const successGetDailySaleReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_SALE",
    payload: data,
  };
};
export const errorGetDailySaleReport = () => {
  return {
    type: "ERROR_GET_DAILY_SALE",
  };
};
// daily sale report
export const getDateWiseSaleReport2 = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/daily_sale_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = "Daily Sale Report";
          const companyName = `${getData.resImage.wareHouseName}`;
          const companyAddress = `${getData.resImage.wareHouseLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 60, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 80, 0, 20);
          doc.text(companyNtn, 150, 100, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(11);
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpenDate; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const Date2 = getData.data[i].dayCloseDate;
            // const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].cashInHand;
            const closecash = getData.data[i].totalCash;
            const todaysOrderAmount = sumBy(getData.data[i].salesmenClosing, 'receivedAmount');
            // const todaysOrderLength = getData.data[i].dayOpen.todaysOrderLength;
            const dayopenname = getData.data[i].dayOpenName;
            const status = getData.data[i].status;
            const headers = [
              [
                "Salemen Name",
                "Sub Total",
                "Discount",
                // "Tax",
                "Grand Total",
                "Paid Amount",
                "Cash Received",
                "Short"
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].salesmenClosing.map((elt, index) => [
              elt.salemenName,
              elt.subTotal,
              elt.discount,
              elt.grandTotal,
              elt.paidAmount,
              elt.receivedAmount,
              elt.shortAmount,
            ]);

            let content = {
              startY: yAxis + 80,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Day Open Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            ); doc.text(
              `Day Close Date: ${moment(Date2).format("YYYY-MM-DD")}`,
              350,
              yAxis + 20,
              0,
              20
            );

            // doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            doc.text(
              `Total Received Amount: ${todaysOrderAmount}`,
              marginLeft,
              yAxis + 60,
              0,
              20
            );
            // doc.text(
            //   `Today Order Length: ${todaysOrderLength}`,
            //   350,
            //   yAxis + 60,
            //   0,
            //   20
            // );

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 40;
            //doc.addPage();
          }


          doc.setProperties({ title: `Daily Sale Report ${moment().format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        console.log(error, "aaaaaaaaaaaaaaaaaa")
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
export const getDailyStockReport = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/stock_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.allInventoryLedger)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = `Stock Report On ${moment(date).format("YYYY-MM-DD")}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 60, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 80, 0, 20);
          doc.text(companyNtn, 150, 100, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 200, 120);
          doc.setFontSize(11);
          const headers = [
            [
              "Code",
              "Product",
              "Balance",
            ],
          ];
             const data = getData.allInventoryLedger.map((elt, index) => [
              elt.code,
              elt.productName,
              elt.closing_balance,
            ]);

            let content = {
              startY: 140,
              startX: 10,
              head: headers,
              body: data,
            };
            doc.autoTable(content);
          doc.setProperties({ title: `Stock Report ${moment(date).format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        console.log(error, "aaaaaaaaaaaaaaaaaa")
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
export const getDailySalemenStockReport = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/salesmen_stock_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = `Salemens Stock Report On ${moment(date).format("YYYY-MM-DD")}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 60, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 80, 0, 20);
          doc.text(companyNtn, 150, 100, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 160, 120);
          doc.setFontSize(11);
          let yAxis = 140;
          for (let i = 0; i < getData.length; i++) {
            doc.text(`Salesmen Name: ${getData[i].salesMenId}`,40,yAxis)
          const headers = [
            [
              "Code",
              "Product",
              "Balance",
            ],
          ];
             const data = getData[i].products.map((elt, index) => [
              elt.code,
              elt.name,
              elt.remainingBalance,
            ]);

            let content = {
              startY: yAxis+20,
              startX: 10,
              head: headers,
              body: data,
            };
            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 20;
          }
          doc.setProperties({ title: `Stock Report ${moment(date).format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        console.log(error, "aaaaaaaaaaaaaaaaaa")
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
// monthly Sale Report
export const getMonthlySaleReport = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/monthly_sale_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        }
        else {
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = "Monthly Sale Report";
          const companyName = `${getData.resImage.wareHouseName}`;
          const companyAddress = `${getData.resImage.wareHouseLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 60, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 80, 0, 20);
          doc.text(companyNtn, 150, 100, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(11);
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpenDate; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const Date2 = getData.data[i].dayCloseDate;
            // const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].cashInHand;
            const closecash = getData.data[i].totalCash;
            const todaysOrderAmount = sumBy(getData.data[i].salesmenClosing, 'receivedAmount');
            // const todaysOrderLength = getData.data[i].dayOpen.todaysOrderLength;
            const dayopenname = getData.data[i].dayOpenName;
            const status = getData.data[i].status;
            const headers = [
              [
                "Salemen Name",
                "Sub Total",
                "Discount",
                // "Tax",
                "Grand Total",
                "Paid Amount",
                "Cash Received",
                "Short"
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].salesmenClosing.map((elt, index) => [
              elt.salemenName,
              elt.subTotal,
              elt.discount,
              elt.grandTotal,
              elt.paidAmount,
              elt.receivedAmount,
              elt.shortAmount,
            ]);

            let content = {
              startY: yAxis + 80,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Day Open Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            ); doc.text(
              `Day Close Date: ${moment(Date2).format("YYYY-MM-DD")}`,
              350,
              yAxis + 20,
              0,
              20
            );

            // doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            doc.text(
              `Total Received Amount: ${todaysOrderAmount}`,
              marginLeft,
              yAxis + 60,
              0,
              20
            );
            // doc.text(
            //   `Today Order Length: ${todaysOrderLength}`,
            //   350,
            //   yAxis + 60,
            //   0,
            //   20
            // );

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 40;
            //doc.addPage();
          }


          doc.setProperties({ title: `Monthly Sale Report ${moment().format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        console.log(error, "aaaaaaaaaaaaaaaaaa")
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
// Today Sale Report
export const getSaleReportWithOutDayClose = (handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.get(`/reports/sale_report_without_dayclose`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = "Today Sale Report";
          const companyName = `${getData.resImage.wareHouseName}`;
          const companyAddress = `${getData.resImage.wareHouseLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 60, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 80, 0, 20);
          doc.text(companyNtn, 150, 100, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(11);
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpenDate; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const Date2 = getData.data[i].dayCloseDate;
            // const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].cashInHand;
            const closecash = getData.data[i].totalCash;
            const todaysOrderAmount = sumBy(getData.data[i].salesmenClosing, 'receivedAmount');
            // const todaysOrderLength = getData.data[i].dayOpen.todaysOrderLength;
            const dayopenname = getData.data[i].dayOpenName;
            const status = getData.data[i].status;
            const headers = [
              [
                "Salemen Name",
                "Sub Total",
                "Discount",
                // "Tax",
                "Grand Total",
                "Paid Amount",
                "Cash Received",
                "Short"
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].salesmenClosing.map((elt, index) => [
              elt.salesmenId.userName,
              elt.subTotal,
              elt.discount,
              elt.grandTotal,
              elt.paidAmount,
              elt.receivedAmount,
              elt.shortAmount,
            ]);

            let content = {
              startY: yAxis + 80,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Day Open Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            );
            // doc.text(
            //   `Day Close Date: ${moment(Date2).format("YYYY-MM-DD")}`,
            //   350,
            //   yAxis + 20,
            //   0,
            //   20
            // );

            // doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            doc.text(
              `Total Received Amount: ${todaysOrderAmount}`,
              marginLeft,
              yAxis + 60,
              0,
              20
            );
            // doc.text(
            //   `Today Order Length: ${todaysOrderLength}`,
            //   350,
            //   yAxis + 60,
            //   0,
            //   20
            // );

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 40;
            //doc.addPage();
          }


          doc.setProperties({ title: `Today Sale Report ${moment().format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        console.log(error, "reportError");
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
// salemen Wise Sale Report 
export const getSalemenWiseSaleReport = (
  data,
  startDate,
  endDate,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/salesmen_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        }
        else {
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = "Salemen Wise Sale Report";
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
          const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 190, 120);
          doc.setFontSize(11);
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {

            const Date1 = getData.data[i].date; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const Date2 = getData.data[i].dayCloseDate;
            // const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].cashInHand;
            const closecash = getData.data[i].totalCash;
            const todaysOrderAmount = sumBy(getData.data[i].salesmenClosing, 'receivedAmount');
            // const todaysOrderLength = getData.data[i].dayOpen.todaysOrderLength;
            const dayopenname = getData.data[i].dayOpenName;
            const status = getData.data[i].status;
            const headers = [
              [
                "Code",
                "Product",
                "Price",
                // "Tax",
                "Quantity",
                "Total",
                // "Cash Received",
                // "Short"
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].items.map((elt, index) => [
              elt.code,
              elt.productLabel,
              elt.productSellingPrice,
              elt.quantity,
              elt.productTotalAmount,
              // elt.receivedAmount,
              // elt.shortAmount,
            ]);

            let content = {
              startY: yAxis + 60,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Area: ${getData.data[i].areaId.areaName}`, marginLeft, yAxis, 0, 20);
            doc.text(`Date: ${getData.data[i].date}`, 370, yAxis, 0, 20);
            doc.text(`Shop: ${getData.data[i].shopId.shopName}`, marginLeft, yAxis + 20, 0, 20);
            doc.text(`Sub Total: ${getData.data[i].subTotal}`, 370, yAxis + 20, 0, 20);

            // doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(`Discount: ${getData.data[i].discount}`, marginLeft, yAxis + 40, 0, 20);
            doc.text(`Tax: ${getData.data[i].tax}`, 230, yAxis + 40, 0, 20);
            doc.text(`Grand Total: ${getData.data[i].grandTotal}`, 370, yAxis + 40, 0, 20);
            // doc.text(
            //   `Total Received Amount: ${todaysOrderAmount}`,
            //   marginLeft,
            //   yAxis + 60,
            //   0,
            //   20
            // );
            // doc.text(
            //   `Today Order Length: ${todaysOrderLength}`,
            //   350,
            //   yAxis + 60,
            //   0,
            //   20
            // );

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 20;
            //doc.addPage();
          }


          doc.setProperties({ title: `Monthly Sale Report ${moment().format("YYYY-MM-DD")}` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
      })
      .catch((error) => {
        console.log(error, "testtttttt")
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};





export const getDateWiseSaleReport = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/issuance/issuance_with_dates`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(getData, getData.data, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Profit Report of ${date}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 190, 100);
          doc.setFontSize(11);
          let yAxis = 120;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Grand Total",
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
          ]);
          // data.push(["","Total",sumBy(data,'2'),sumBy(data,'3'),sumBy(data,'4'),sumBy(data,'5'),sumBy(data,'6')])
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          doc.text(`Total Sale:`, 400, yAxis)
          doc.text(`Total Discount:`, 400, yAxis + 20)
          doc.text(`Total Return:`, 400, yAxis + 40)
          doc.text(`Total Purchase:`, 400, yAxis + 60)
          doc.text(`Net Profit:`, 400, yAxis + 80)

          doc.text(`${getData.subTotal}`, 500, yAxis)
          doc.text(`${getData.bookingDiscount}`, 500, yAxis + 20)
          doc.text(`${getData.returnAmount}`, 500, yAxis + 40)
          doc.text(`${sumBy(getData.data, 'purchasePriceTotal').toFixed(2)}`, 500, yAxis + 60)
          let total = getData.finalPaidAmount - Number(sumBy(getData.data, 'purchasePriceTotal'))
          doc.text(`${total.toFixed(2)}`, 500, yAxis + 80)
          doc.setProperties({
            title: `Daily Profit Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));

          // doc.save(
          //   `Daily Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          // );
        }
        //  doc.output('dataurlnewwindow')
        dispatch(successGetDailySaleReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
export const requestGetDailySaleReport2 = () => {
  return {
    type: "REQUEST_GET_DAILY_SALE",
  };
};
export const successGetDailySaleReport2 = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_SALE",
    payload: data,
  };
};
export const errorGetDailySaleReport2 = () => {
  return {
    type: "ERROR_GET_DAILY_SALE",
  };
};

export const getDateWiseSaleReport234 = (data, handleDangerVisible, date) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/daily_sale_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const Dataday = getData.data.length;
          console.log(getData.data, "check2");
          const unit = "pt";
          const size = "A4";
          const orientation = "potrait";
          const doc = new jsPDF(orientation, unit, size);
          const title = `Profit Report of ${date}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;

          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 190, 100);
          doc.setFontSize(11);

          // let yAxis = 120;
          // console.log(yAxis, "aaaaaaaaaaaaaaa");
          // const headers = [
          //   [
          //     "Sr",
          //     "product Name",
          //     "Booking Qty",
          //     "Return Qty",
          //     "Final Qty",
          //     "Price",
          //     "Grand Total",
          //   ],
          // ];
          // const data = getData.data.map((x, i) => [
          //   i + 1,
          //   x.productLabel,
          //   Number(x.quantity)+Number(`${x.returnProductQuantity==0?0:x.returnProductQuantity.quantity}`),
          //   x.returnProductQuantity==0?0:x.returnProductQuantity.quantity,
          //   x.quantity,
          //   x.productSellingPrice,
          //   x.totalSaleValue,
          // ]);
          // // data.push(["","Total",sumBy(data,'2'),sumBy(data,'3'),sumBy(data,'4'),sumBy(data,'5'),sumBy(data,'6')])
          // let content = {
          //   startY: yAxis,
          //   startX: 10,
          //   head: headers,
          //   body: data,
          // };
          // doc.autoTable(content);
          // yAxis = doc.lastAutoTable.finalY + 20;
          // doc.text(`Sub Total Amount: ${sumBy(getData.data, 'subTotal')}`, 50, 130)
          // doc.text(`Discount Total Amount: ${sumBy(getData.data, 'discount')}`, 50, 150)
          // doc.text(`Grand Total Amount: ${sumBy(getData.data, 'grandTotal')}`, 50, 170)
          // doc.text(`Total Order Length: ${getData.data.length}`, 50, 190)


          // for (var i = 0; i < getData.data.length; i++) {
          //   doc.text(`Final Total = ${getData.data.length}`, 50, 210 + (i * 20));
          //   // doc.text(`Total Order Length: ${getData.data.length}`, doc.lastAutoTable.finalY + 60, 80, 80);
          // }



          doc.setProperties({ title: `Report` });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));


        }
        dispatch(successGetDailySaleReport2(getData));
      })
      .catch((error) => {
        console.log(error, "aaaaaaaaaaaaaaaaaa")
        dispatch(errorGetDailySaleReport2());
        handleDangerVisible();
      });
  };
};


//Discout
export const requestGetDailyDiscountReport = () => {
  return {
    type: "REQUEST_GET_DAILY_DISCOUNT",
  };
};
export const successGetDailyDiscountReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_DISCOUNT",
    payload: data,
  };
};
export const errorGetDailyDiscountReport = () => {
  return {
    type: "ERROR_GET_DAILY_DISCOUNT",
  };
};

export const getDateWiseDiscountReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyDiscountReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          //  doc.setFontSize(13);
          //  //doc.setFont('TimesNewRoman', 'Normal')
          const title = "Daily Discount Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 200, 120);
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "Normal");
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const restaurant =
              getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].dayOpen.cashInHand;
            const closecash = getData.data[i].dayOpen.closingCash;
            const todaysOrderAmount =
              getData.data[i].dayOpen.todaysDiscountAmount;
            const todaysOrderLength =
              getData.data[i].dayOpen.todaysDiscountOrderLength;
            const dayopenname = getData.data[i].dayOpen.user.firstName;
            const status = getData.data[i].dayOpen.status;
            const headers = [
              [
                "Sr",
                "Date",
                "Order No.",
                "Sub Total",
                "Discount",
                "Tax",
                "Grand Total",
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].orders.map((elt, index) => [
              index + 1,
              moment(elt.createdAt).format("YYYY-MM-DD"),
              elt.number,
              elt.subTotal,
              elt.discount,
              elt.tax,
              elt.grandTotal,
            ]);

            let content = {
              startY: yAxis + 80,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            );
            doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            doc.text(
              `Today Discount Amount: ${todaysOrderAmount}`,
              marginLeft,
              yAxis + 60,
              0,
              20
            );
            // doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 40;
            //doc.addPage();
          }

          doc.setProperties({
            title: `Daily Discount Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          doc.save(
            `Daily Discount Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`
          );
        }
        dispatch(successGetDailyDiscountReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetDailyDiscountReport());
        handleDangerVisible();
      });
  };
};
//INVOICE
export const requestGetDailyInvoiceReport = () => {
  return {
    type: "REQUEST_GET_DAILY_INVOICE",
  };
};
export const successGetDailyInvoiceReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_INVOICE",
    payload: data,
  };
};
export const errorGetDailyInvoiceReport = () => {
  return {
    type: "ERROR_GET_DAILY_INVOICE",
  };
};

export const getDateWiseInvoiceReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDailyInvoiceReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        dispatch(successGetDailyInvoiceReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyInvoiceReport());
      });
  };
};
//order Billed

export const requestGetDailyOrderBilledReport = () => {
  return {
    type: "REQUEST_GET_DAILY_ORDER_BILLED",
  };
};
export const successGetDailyOrderBilledReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_ORDER_BILLED",
    payload: data,
  };
};
export const errorGetDailyOrderBilledReport = () => {
  return {
    type: "ERROR_GET_DAILY_ORDER_BILLED",
  };
};

export const getDateWiseOrderBilledReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDailyOrderBilledReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        dispatch(successGetDailyOrderBilledReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyOrderBilledReport());
      });
  };
};
//order cancel
export const requestGetDailyOrderCancelReport = () => {
  return {
    type: "REQUEST_GET_DAILY_ORDER_CANCEL",
  };
};
export const successGetDailyOrderCancelReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_ORDER_CANCEL",
    payload: data,
  };
};
export const errorGetDailyOrderCancelReport = () => {
  return {
    type: "ERROR_GET_DAILY_ORDER_CANCEL",
  };
};

export const getDateWiseOrderCancelReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyOrderCancelReport());
    API.post(`/reports/daily_order_cancel_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          //  doc.setFontSize(13);
          //  //doc.setFont('TimesNewRoman', 'Normal')
          const title = "Daily Order cancel Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 200, 120);
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "Normal");
          let yAxis = 150;
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const restaurant =
              getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].dayOpen.cashInHand;
            const closecash = getData.data[i].dayOpen.closingCash;
            const todaysOrderAmount =
              getData.data[i].dayOpen.todaysCancelledAmount;
            const todaysOrderLength =
              getData.data[i].dayOpen.todaysCancelledOrderLength;
            const dayopenname = getData.data[i].dayOpen.user.firstName;
            const status = getData.data[i].dayOpen.status;
            const headers = [
              [
                "Sr",
                "Date",
                "Order No.",
                "Sub Total",
                "Discount",
                "Tax",
                "Grand Total",
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].orders.map((elt, index) => [
              index + 1,
              moment(elt.createdAt).format("YYYY-MM-DD"),
              elt.number,
              elt.subTotal,
              elt.discount,
              elt.tax,
              elt.grandTotal,
            ]);

            let content = {
              startY: yAxis + 100,
              startX: 10,
              head: headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            );
            doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            doc.text(
              `Today Cancel Order Amount: ${todaysOrderAmount}`,
              marginLeft,
              yAxis + 60,
              0,
              20
            );
            doc.text(
              `Today Cancel Order Length: ${todaysOrderLength}`,
              350,
              yAxis + 60,
              0,
              20
            );

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 20;
            //doc.addPage();
          }
          doc.setProperties({
            title: `Daily Order cancel Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          doc.save(
            `Daily Order cancel Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`
          );
        }
        dispatch(successGetDailyOrderCancelReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyOrderCancelReport());
        handleDangerVisible();
      });
  };
};
//promo
export const requestGetDailyPromoReport = () => {
  return {
    type: "REQUEST_GET_DAILY_PROMO",
  };
};
export const successGetDailyPromoReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_PROMO",
    payload: data,
  };
};
export const errorGetDailyPromoReport = () => {
  return {
    type: "ERROR_GET_DAILY_PROMO",
  };
};

export const getDailyPromoReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyPromoReport());
    API.post(`/reports/daily_promo_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "checkrrr");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          //  doc.setFontSize(13);
          //  //doc.setFont('TimesNewRoman', 'Normal')
          const title = "Daily Promo Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.data[0].dayOpen.restaurant.image;
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "Normal");
          let yAxis = 150;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          for (let i = 0; i < getData.data.length; i++) {
            const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
            const restaurant =
              getData.data[i].dayOpen.restaurant.restaurantName;
            const cashinhand = getData.data[i].dayOpen.cashInHand;
            const closecash = getData.data[i].dayOpen.closingCash;
            // const todaysOrderAmount = getData.data[i].dayOpen.todaysOrderAmount;
            // const todaysOrderLength= getData.data[i].dayOpen.todaysOrderLength;
            const dayopenname = getData.data[i].dayOpen.user.firstName;
            const status = getData.data[i].dayOpen.status;
            const headers = [
              [
                "Sr",
                "Date",
                "Order Number",
                "Coupon Name",
                "Coupon Type",
                "Coupon Amount",
              ],
            ];
            // // console.log(getData[0].booking, "pppppp")

            const data = getData.data[i].orders.map((elt, index) => [
              index + 1,
              moment(elt.createdAt).format("YYYY-MM-DD"),
              elt.number,
              elt.coupon.name,
              elt.coupon.type,
              elt.discount,
            ]);

            let content = {
              startY: yAxis + 70,
              startX: 10,
              head: isEmpty(getData.data[i].orders) ? "" : headers,
              body: data,
            };

            doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
            doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
            doc.text(
              `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
              marginLeft,
              yAxis + 20,
              0,
              20
            );
            doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
            doc.text(
              `Cash In Hand: ${cashinhand}`,
              marginLeft,
              yAxis + 40,
              0,
              20
            );
            doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
            //  doc.text(`Today Order Amount: ${todaysOrderAmount}`, marginLeft, yAxis+60, 0, 20);
            //  doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

            doc.autoTable(content);
            yAxis = doc.lastAutoTable.finalY + 40;
            //doc.addPage();
          }

          doc.setProperties({
            title: `Daily Promo Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          doc.save(
            `Daily Promo Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successGetDailyPromoReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetDailyPromoReport());
        handleDangerVisible();
      });
  };
};
//Monthly
//Sale
export const requestGetMonthlySaleReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_SALE",
  };
};
export const successGetMonthlySaleReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_SALE",
    payload: data,
  };
};
export const errorGetMonthlySaleReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_SALE",
  };
};

export const getMonthWiseSaleReport = (data, handleDangerVisible, startDate, endDate) => {
  return (dispatch) => {
    dispatch(requestGetMonthlySaleReport());
    API.post(`/issuance/issuance_with_dates`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(getData, getData.data, data, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Profit Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 130, 100);
          doc.setFontSize(11);
          let yAxis = 120;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Grand Total",
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            `${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
          ]);
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          doc.text(`Total Sale:`, 400, yAxis)
          doc.text(`Total Discount:`, 400, yAxis + 20)
          doc.text(`Total Return:`, 400, yAxis + 40)
          doc.text(`Total Purchase:`, 400, yAxis + 60)
          doc.text(`Net Profit:`, 400, yAxis + 80)

          doc.text(`${getData.subTotal}`, 500, yAxis)
          doc.text(`${getData.bookingDiscount}`, 500, yAxis + 20)
          doc.text(`${getData.returnAmount}`, 500, yAxis + 40)
          doc.text(`${sumBy(getData.data, 'purchasePriceTotal')}`, 500, yAxis + 60)
          doc.text(`${(getData.finalPaidAmount - sumBy(getData.data, 'purchasePriceTotal')).toFixed(2)}`, 500, yAxis + 80)
          doc.setProperties({
            title: `Date Wise Profit Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));

          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Date Wise Sale Report ${moment(new Date()).format(
          //     "YYYY-MM-DD"
          //   )}.pdf`
          // );
        }
        dispatch(successGetMonthlySaleReport(res.data));
      })
      .catch((error) => {
        console.log(error, "rrrrrrrrrrrrrr")
        dispatch(errorGetMonthlySaleReport());
        handleDangerVisible();
      });
  };
};
//DISCOUNT
export const requestGetMonthlyDiscountReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_DISCOUNT",
  };
};
export const successGetMonthlyDiscountReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_DISCOUNT",
    payload: data,
  };
};
export const errorGetMonthlyDiscountReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_DISCOUNT",
  };
};

export const getMonthWiseDiscountReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyDiscountReport());
    API.post(`/reports/monthly_discount_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape
          const logo1 = getData.restaurantImage;
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = "Discount Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const Date1 = getData.startDate;
          const Date2 = getData.endDate;
          const restaurant = getData.data[0].restaurant.restaurantName;
          const cashinhand = getData.totalCashInHand;
          const closecash = getData.totalClosingCash;
          const todaysOrderAmount = getData.totalDiscountAmount;
          const todaysOrderLength = getData.totalDiscountOrderLength;
          const headers = [
            [
              "Sr",
              "Day Open Date",
              "Day Open Name",
              "Cash In Hand",
              "Closing Cash",
              "Discount Amount",
              "No of Discount Orders",
            ],
          ];
          const data = getData.data.map((elt, index) => [
            index + 1,
            moment(elt.dayOpenDate).format("YYYY-MM-DD"),
            elt.user.firstName,
            elt.cashInHand,
            elt.closingCash,
            elt.todaysDiscountAmount,
            elt.todaysDiscountOrderLength,
          ]);
          let content = {
            startY: 220,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
          //doc.text(`Status: ${status}`, 350, 150, 0, 20);
          doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
          //  doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
          //  doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
          doc.text(
            `Total Discount Amount: ${todaysOrderAmount}`,
            marginLeft,
            190,
            0,
            20
          );
          doc.text(
            `Total Discount Length: ${todaysOrderLength}`,
            350,
            190,
            0,
            20
          );
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(
            `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
              Date2
            ).format("YYYY-MM-DD")}`,
            185,
            140
          );
          doc.setFontSize(11);
          doc.autoTable(content);
          doc.setProperties({
            title: `Date Wise Discount Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          doc.save(
            `Date Wise Discount Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`
          );
        }
        dispatch(successGetMonthlyDiscountReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyDiscountReport());
        handleDangerVisible();
      });
  };
};
//ordercacnel
export const requestGetMonthlyOrderCancelReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_ORDER_CANCEL",
  };
};
export const successGetMonthlyOrderCancelReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_ORDER_CANCEL",
    payload: data,
  };
};
export const errorGetMonthlyOrderCancelReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_ORDER_CANCELT",
  };
};

export const getMonthWiseOrderCancelReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyOrderCancelReport());
    API.post(`/reports/monthly_order_cancel_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log("abcdefghijklm");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = "Order Cancel Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const Date1 = getData.startDate;
          const Date2 = getData.endDate;
          const restaurant = getData.data[0].restaurant.restaurantName;
          const cashinhand = getData.data[0].cashInHand;
          const closecash = getData.data[0].closingCash;
          const todaysOrderAmount = getData.totalCanceledOrderAmount;
          const todaysOrderLength = getData.totalCanceledOrderLength;
          const logo1 = getData.restaurantImage;
          const headers = [
            [
              "Sr",
              "Day Open Date",
              "Day Open Name",
              "Cash In Hand",
              "Closing Cash",
              "Order Cancel Amount",
              "No of Order Cancel",
            ],
          ];
          const data = getData.data.map((elt, index) => [
            index + 1,
            moment(elt.dayOpenDate).format("YYYY-MM-DD"),
            elt.user.firstName,
            elt.cashInHand,
            elt.closingCash,
            elt.todaysCancelledAmount,
            elt.todaysCancelledOrderLength,
          ]);
          let content = {
            startY: 240,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
          //doc.text(`Status: ${status}`, 350, 150, 0, 20);
          doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
          // doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
          // doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
          doc.text(
            `Total Cancel Order Amount: ${todaysOrderAmount}`,
            marginLeft,
            190,
            0,
            20
          );
          doc.text(
            `Total Cancel Order Length: ${todaysOrderLength}`,
            350,
            190,
            0,
            20
          );
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(
            `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
              Date2
            ).format("YYYY-MM-DD")}`,
            185,
            140
          );
          doc.setFontSize(11);
          doc.autoTable(content);
          doc.setProperties({
            title: `Date Wise Order Cancel Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          //  doc.output('dataurlnewwindow')
          doc.save(
            `Date Wise Order Cancel Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`
          );
        }
        dispatch(successGetMonthlyOrderCancelReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyOrderCancelReport());
        handleDangerVisible();
      });
  };
};
//promo
export const requestGetMonthlyPromoReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_PROMO",
  };
};
export const successGetMonthlyPromoReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_PROMO",
    payload: data,
  };
};
export const errorGetMonthlyPromoReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_PROMO",
  };
};

export const getMonthWisePromoReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyPromoReport());
    API.post(`/reports/monthly_promo_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = "Promo Report";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const Date1 = getData.startDate;
          const Date2 = getData.endDate;
          // const restaurant = getData.data[0].restaurant.restaurantName;
          //const cashinhand = getData.totalCashInHand;
          //const closecash = getData.totalClosingCash;
          const todaysOrderAmount = getData.couponDiscountAmount;
          const todaysOrderLength = getData.couponDiscountLength;
          const logo1 = getData.restaurantImage;
          const headers = [
            ["Order Number", "Order Amount", "Promo Amount", "Order Status"],
          ];
          const data = getData.data.map((elt) => [
            elt.number,
            elt.grandTotal,
            elt.discount,
            elt.status,
          ]);
          let content = {
            startY: 190,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
          //doc.text(`Status: ${status}`, 350, 150, 0, 20);
          // doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
          //  doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
          //  doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
          doc.text(
            `Total Promo Amount: ${todaysOrderAmount}`,
            marginLeft,
            160,
            0,
            20
          );
          doc.text(`Total Promo Length: ${todaysOrderLength}`, 350, 160, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(
            `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
              Date2
            ).format("YYYY-MM-DD")}`,
            185,
            140
          );
          doc.setFontSize(11);
          doc.autoTable(content);
          doc.setProperties({
            title: `Date Wise Promo Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          doc.save(
            `Date Wise Promo Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`
          );
        }
        dispatch(successGetMonthlyPromoReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyPromoReport());
        handleDangerVisible();
      });
  };
};
export const requestData = () => {
  return {
    type: "REQUEST_DATA",
  };
};
export const successData = (data) => {
  return {
    type: "SUCCESS_DATA",
    payload: data,
  };
};
export const errorData = () => {
  return {
    type: "ERROR_DATA",
  };
};
export const getData = () => {
  return (dispatch) => {
    dispatch(requestData());
    API.get(`/reports/reports_heading`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successData(data1));
      })
      .catch((error) => {
        dispatch(errorData());
      });
  };
};
//order reprit
export const requestGetOrderPrintReport = () => {
  return {
    type: "REQUEST_GET_ORDER_PRINT",
  };
};
export const successGetOrderPrintReport = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_PRINT",
    payload: data,
  };
};
export const errorGetOrderPrintReport = () => {
  return {
    type: "ERROR_GET_ORDER_PRINT",
  };
};
export const getOrderPdfReport = (id) => {
  return (dispatch) => {
    dispatch(requestGetOrderPrintReport());
    API.get(`/orders/order_pdf/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "kkkkkkk");
        if (isEmpty(getData)) {
          handleNoDataVisible();
        } else {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
          const title = "Order Reprint";
          const companyName = `${getData.resImage.restaurantName}`;
          const companyAddress = `${getData.resImage.restaurantLocation}`;
          const companyNtn = `${getData.resImage.ntn}`;
          const logo1 = getData.restaurantImage;
          const Date1 = getData.orderDate;
          // const Date2 = getData.endDate;
          // const restaurant = getData.data[0].restaurant.restaurantName;
          const DisName = isNull(getData.reprintOrderResult.coupon)
            ? "Null"
            : getData.reprintOrderResult.coupon.name;
          const DiscountAmount = getData.reprintOrderResult.discount;
          //  const closecash = getData.totalClosingCash;
          const totalOrderAmount = getData.reprintOrderResult.grandTotal;
          const Customer = getData.reprintOrderResult.customer.name;
          const headers = [
            [
              "Sr",
              "Product Name",
              "Product Selling Price",
              "Product Quantity",
              "Order Total Amount",
            ],
          ];
          const data = getData.reprintOrderResult.items.map((elt, index) => [
            index + 1,
            elt.productLabel,
            elt.productSellingPrice,
            elt.quantity,
            elt.productTotalAmount,
          ]);
          let content = {
            startY: 240,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 50, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 70, 0, 20);
          doc.text(companyNtn, 150, 90, 0, 20);
          // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
          //doc.text(`Status: ${status}`, 350, 150, 0, 20);
          doc.text(`Customer Name: ${Customer}`, marginLeft, 170, 0, 20);
          doc.text(`Coupon: ${DisName}`, 350, 170, 0, 20);
          doc.text(
            `Discount Amount: ${DiscountAmount}`,
            marginLeft,
            190,
            0,
            20
          );
          doc.text(`Total Order Amount: ${totalOrderAmount}`, 350, 190, 0, 20);
          // doc.text(`Total Order Length: ${todaysOrderLength}`, 350, 210, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 215, 120);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(`Date :${moment(Date1).format("YYYY-MM-DD")}`, 210, 140);
          doc.setFontSize(11);
          doc.autoTable(content);
          doc.setProperties({
            title: `Order Reprint${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          //  doc.output('dataurlnewwindow')
          doc.save(
            `Order Reprint ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successGetOrderPrintReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetOrderPrintReport());
        console.log(error, "error");
      });
  };
};
export const getOrderPrintReport = (id) => {
  return (dispatch) => {
    dispatch(requestGetOrderPrintReport());
    API.get(`/orders/reprint_order/${id}`)
      .then((res) => {
        let getData = res.data;
        const pdfData = res.data.item;
        const resData = res.data;
        const unit = "mm";
        const size = [80, 210]; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 0;
        const doc = new jsPDF(orientation, unit, size);

        const title = `Order ${pdfData.number}`;
        const companyName = `${resData.resImage.restaurantName}`;
        const companyAddress = `${resData.resImage.restaurantLocation}`;
        const companyNtn = `${resData.resImage.ntn}`;
        const logo1 = resData.restaurantImage;
        // doc.addImage(logo1, "PNG", 1, 1, 70, 20);
        doc.setFontSize(18);
        doc.setFont(undefined, "bold");
        const xOffset =
          doc.getStringUnitWidth(companyName) * doc.internal.getFontSize();
        const xOffset1 = doc.getStringUnitWidth(
          "ttttttttttttttttttttttttttttttttttttttttttttttttttttttttt"
        );
        console.log(xOffset, xOffset1, "xoffffffffffffffff");
        doc.text(companyName, 17, 6);
        doc.setFontSize(12);
        doc.setFont("TimesNewRoman", "Normal");
        // doc.text(companyAddress, 1, 28);
        // doc.text(companyNtn, 1, 32);
        // // doc.setFontSize(18);
        // doc.setFontSize(12);
        // doc.setFont("TimesNewRoman", "Normal");
        // doc.text(title, 2, 26 + 2);
        doc.text("Customer Copy", 1, 14);
        doc.text(`Date : ${pdfData.orderDate}`, 1, 19);
        doc.text(`Order No : ${pdfData.number}`, 1, 24);
        //    doc.text(`Order Type : ${pdfData.orderType}`,1,29)
        // doc.text(`Order Type ${pdfData.orderType}`, 25, 26 + 2);
        // doc.text(`Customer Name ${pdfData.customer.name}`, 2, 26 + 4);
        // doc.text(`Waiter Name ${pdfData.waiterId.userName}`, 25, 26 + 4);
        // doc.text(`Table # ${pdfData.tableName}`, 10, 135);
        doc.setFontSize(12);
        const headers = [
          [
            // "Sr",
            "Product",
            "Qty",
            "Price",
            "Total",
          ],
        ];
        // // console.log(getData[0].booking, "pppppp")

        const data = pdfData.items.map((elt, index) => [
          // index + 1,
          // moment(elt.createdAt).format("YYYY-MM-DD"),
          `${elt.productLabel}-(${elt.productVariationLabel})`,
          elt.quantity,
          elt.productSellingPrice,
          elt.productTotalAmount,
        ]);

        let content = {
          startY: 29,
          // startX: 2,
          head: headers,
          body: data,
          theme: "plain",
          //  textColor: [0, 0, 0],
          headerStyles: {
            lineWidth: 0.5,
            lineColor: [255, 0, 0],
            // textColor: [0, 0, 0],
          },
          bodyStyles: {
            lineWidth: 0.5,
            lineColor: [255, 0, 0],
            // textColor: [0, 0, 0],
          },
          columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 10 },
            2: { cellWidth: 14.5 },
            3: { cellWidth: 15 },
            // 4: { cellWidth: 60 },
          },
          // headStyles: {
          //     fillColor: '#2980ba'
          // },
          tableWidth: "wrap",
          styles: { fontSize: 8 },
          margin: {
            left: 0.1,
          },
        };
        doc.autoTable(content);
        doc.text(
          `Sub Total : ${pdfData.subTotal}`,
          44,
          doc.lastAutoTable.finalY + 6
        );
        doc.text(
          `Discount : ${pdfData.discount}`,
          44,
          doc.lastAutoTable.finalY + 11
        );
        doc.text(`Tax : ${pdfData.tax}`, 44, doc.lastAutoTable.finalY + 16);
        doc.text(
          `Grand Total : ${pdfData.grandTotal}`,
          44,
          doc.lastAutoTable.finalY + 21
        );
        doc.text(
          `Thanks For Visiting At "${companyName}"`,
          4,
          doc.lastAutoTable.finalY + 30
        );
        doc.text(`Powerd By eBeaconsofts`, 13, doc.lastAutoTable.finalY + 35);
        doc.setProperties({
          title: `Recipt Order ${pdfData.number}`,
        });
        doc.autoPrint();

        doc.output("dataurlnewwindow");

        // doc.save(
        //   `Recipt Order ${pdfData.number} ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        // );
        // doc.save(
        //     `Recipt Order ${pdfData.number}.pdf`
        // );
        dispatch(successGetOrderPrintReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetOrderPrintReport());
        console.log(error, "error");
      });
  };
};
//Specific product sale report
export const requestGetSpecificProductSaleReport = () => {
  return {
    type: "REQUEST_GET_SPECIFIC_PRODUCT_SALE_REPORT",
  };
};
export const successGetSpecificProductSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_SPECIFIC_PRODUCT_SALE_REPORT",
    payload: data,
  };
};
export const errorGetSpecificProductSaleReport = () => {
  return {
    type: "ERROR_GET_SPECIFIC_PRODUCT_SALE_REPORT",
  };
};

export const getSpecificProductSaleReport = (
  data,
  startDate,
  endDate,
  productName,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetSpecificProductSaleReport());
    // http://localhost:3006/v1/reports/single_product_report
    API.post(`/issuance/product_profit_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(getData, getData.data, data, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Profit Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 130, 100);
          doc.setFontSize(11);
          let yAxis = 120;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Sale Total",
              "Purchase Total",
              "Profit"
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            `${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
            x.purchasePriceTotal.toFixed(2),
            (x.totalSaleValue - x.purchasePriceTotal).toFixed(2)
          ]);
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          doc.text(`Total Sale:`, 400, yAxis)
          doc.text(`Total Discount:`, 400, yAxis + 20)
          doc.text(`Total Return:`, 400, yAxis + 40)
          doc.text(`Total Purchase:`, 400, yAxis + 60)
          doc.text(`Net Profit:`, 400, yAxis + 80)

          doc.text(`${getData.subTotal}`, 500, yAxis)
          doc.text(`${getData.bookingDiscount}`, 500, yAxis + 20)
          doc.text(`${getData.returnAmount}`, 500, yAxis + 40)
          doc.text(`${sumBy(getData.data, 'purchasePriceTotal')}`, 500, yAxis + 60)
          doc.text(`${(getData.finalPaidAmount - sumBy(getData.data, 'purchasePriceTotal')).toFixed(2)}`, 500, yAxis + 80)
          doc.setProperties({
            title: `Product Profit Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Specific Product Sale Report ${moment(new Date()).format(
          //     "YYYY-MM-DD"
          //   )}.pdf`
          // );
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));
        }
        dispatch(successGetSpecificProductSaleReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        console.log(error, "error");
        dispatch(errorGetSpecificProductSaleReport());
        handleDangerVisible();
      });
  };
};
//product sale report
export const requestGetProductSaleReport = () => {
  return {
    type: "REQUEST_GET_PRODUCT_SALE_REPORT",
  };
};
export const successGetProductSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_PRODUCT_SALE_REPORT",
    payload: data,
  };
};
export const errorGetProductSaleReport = () => {
  return {
    type: "ERROR_GET_PRODUCT_SALE_REPORT",
  };
};

export const getProductSaleReport = (
  data,
  startDate,
  endDate,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetProductSaleReport());
    API.post(`/issuance/product_profit_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(getData, getData.data, data, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Profit Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(18);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 130, 100);
          doc.setFontSize(11);
          let yAxis = 120;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Sale Total",
              "Purchase Total",
              "Profit"
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            `${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
            x.purchasePriceTotal.toFixed(2),
            (x.totalSaleValue - x.purchasePriceTotal).toFixed(2)
          ]);
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          doc.text(`Total Sale:`, 400, yAxis)
          doc.text(`Total Discount:`, 400, yAxis + 20)
          doc.text(`Total Return:`, 400, yAxis + 40)
          doc.text(`Total Purchase:`, 400, yAxis + 60)
          doc.text(`Net Profit:`, 400, yAxis + 80)

          doc.text(`${getData.subTotal}`, 500, yAxis)
          doc.text(`${getData.bookingDiscount}`, 500, yAxis + 20)
          doc.text(`${getData.returnAmount}`, 500, yAxis + 40)
          doc.text(`${sumBy(getData.data, 'purchasePriceTotal')}`, 500, yAxis + 60)
          doc.text(`${(getData.finalPaidAmount - sumBy(getData.data, 'purchasePriceTotal')).toFixed(2)}`, 500, yAxis + 80)
          doc.setProperties({
            title: `Product Profit Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));
          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Product Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          // );
        }
        dispatch(successGetProductSaleReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorGetProductSaleReport());
        handleDangerVisible();
      });
  };
};
//hot sellig report
export const requestHotSellingReport = () => {
  return {
    type: "REQUEST_HOT_SELLING_REPORT",
  };
};
export const successHotSellingReport = (data) => {
  return {
    type: "SUCCESS_HOT_SELLING_REPORT",
    payload: data,
  };
};
export const errorHotSellingReport = () => {
  return {
    type: "ERROR_HOT_SELLING_REPORT",
  };
};

export const getHotSellingReport = (
  data,
  startDate, endDate, bookerName,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestHotSellingReport());
    API.post(`/issuance/booker_profit_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(getData, getData.data, data, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Booker Profit Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(16);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 120, 100);
          doc.setFont("TimesNewRoman", "Normal");
          doc.setFontSize(14);
          doc.text(`Booker Name: ${bookerName}`, 40, 120)
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "bold");
          let yAxis = 140;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Grand Total",
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            `${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
          ]);
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          doc.text(`Total Sale:`, 400, yAxis)
          doc.text(`Total Discount:`, 400, yAxis + 20)
          doc.text(`Total Return:`, 400, yAxis + 40)
          doc.text(`Total Purchase:`, 400, yAxis + 60)
          doc.text(`Net Profit:`, 400, yAxis + 80)

          doc.text(`${getData.subTotal}`, 500, yAxis)
          doc.text(`${getData.bookingDiscount}`, 500, yAxis + 20)
          doc.text(`${getData.returnAmount}`, 500, yAxis + 40)
          doc.text(`${sumBy(getData.data, 'purchasePriceTotal')}`, 500, yAxis + 60)
          doc.text(`${(getData.finalPaidAmount - sumBy(getData.data, 'purchasePriceTotal')).toFixed(2)}`, 500, yAxis + 80)
          doc.setProperties({
            title: `Booker Wise Profit Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));
          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Hot Selling Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          // );
        }
        dispatch(successHotSellingReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        console.log(error, "rrrrrrrrrrrrr")
        dispatch(errorHotSellingReport());
        handleDangerVisible();
      });
  };
};
///day open record
export const requestGetDayOpenReport = () => {
  return {
    type: "REQUEST_GET_DAY_OPEN_REPORT",
  };
};
export const successGetDayOpenReport = (data) => {
  return {
    type: "SUCCESS_GET_DAY_OPEN_REPORT",
    payload: data,
  };
};
export const errorGetDayOpenReport = () => {
  return {
    type: "ERROR_GET_DAY_OPEN_REPORT",
  };
};

export const getDayOpenReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDayOpenReport());
    API.post(`/dayOpen/dayOpen_record`, data)
      .then((res) => {
        dispatch(successGetDayOpenReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDayOpenReport());
      });
  };
};
export const requestGetDayOpenSaleReport = () => {
  return {
    type: "REQUEST_GET_DAY_OPEN_SALE",
  };
};
export const successGetDayOpenSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_DAY_OPEN_SALE",
    payload: data,
  };
};
export const errorGetDayOpenSaleReport = () => {
  return {
    type: "ERROR_GET_DAY_OPEN_SALE",
  };
};



export const requestGetVendorWiseSaleReport = () => {
  return {
    type: "REQUEST_GET_VENDOR_WISE_SALE_REPORT",
  };
};
export const successGetVendorWiseSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_VENDOR_WISE_SALE_REPORT",
    payload: data,
  };
};
export const errorGetVendorWiseSaleReport = () => {
  return {
    type: "ERROR_GET_VENDOR_WISE_SALE_REPORT",
  };
};

export const getVendorWiseSaleReport = (
  data,
  startDate,
  endDate,
  vendorName,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetVendorWiseSaleReport());
    API.post(`/issuance/vendor_profit_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(vendorName, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Vendor Profit Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(16);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 120, 100);
          doc.setFont("TimesNewRoman", "Normal");
          doc.setFontSize(14);
          doc.text(`Vendor Name: ${vendorName}`, 40, 120)
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "bold");
          let yAxis = 140;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Sale Total",
              "Purchase Total",
              "Profit"
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            `${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
            x.purchasePriceTotal.toFixed(2),
            (x.totalSaleValue - x.purchasePriceTotal).toFixed(2)
          ]);
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          // doc.text(`Total Sale:`,400,yAxis)
          // doc.text(`Total Discount:`,400,yAxis+20)
          // doc.text(`Total Return:`,400,yAxis+40)
          // doc.text(`Total Purchase:`,400,yAxis+60)
          doc.text(`Net Profit:`, 400, yAxis)

          // doc.text(`${getData.subTotal}`,500,yAxis)
          // doc.text(`${getData.bookingDiscount}`,500,yAxis+20)
          // doc.text(`${getData.returnAmount}`,500,yAxis+40)
          // doc.text(`${sumBy(getData.data,'purchasePriceTotal')}`,500,yAxis+60)
          doc.text(`${(sumBy(data, '8')).toFixed(2)}`, 500, yAxis)
          doc.setProperties({
            title: `Vendor Wise Profit Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));

          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Day Open Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          // );
          //  doc.output('dataurlnewwindow')
          dispatch(successGetVendorWiseSaleReport(getData));
        }
      })
      .catch((error) => {
        dispatch(errorGetVendorWiseSaleReport());
        handleDangerVisible();
      });
  };
};

export const requestGetVendorWiseReport = () => {
  return {
    type: "REQUEST_GET_VENDOR_WISE_SALE_REPORT",
  };
};
export const successGetVendorWiseReport = (data) => {
  return {
    type: "SUCCESS_GET_VENDOR_WISE_SALE_REPORT",
    payload: data,
  };
};
export const errorGetVendorWiseReport = () => {
  return {
    type: "ERROR_GET_VENDOR_WISE_SALE_REPORT",
  };
};

export const getVendorWiseReport = (
  data,
  startDate,
  endDate,
  vendorName,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetVendorWiseReport());
    API.post(`/issuance/vendor_profit_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getData");
        if (isEmpty(getData.data)) {
          handleNoDataVisible();
        } else {
          console.log(vendorName, "check");
          const Dataday = getData.data.length;
          console.log(Dataday, "check2");
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "potrait"; // portrait or landscape

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          const title = `Vendor Sale Report From ${startDate} to ${endDate}`;
          // const companyName = `${getData.resImage.wareHouseName}`;
          // const companyAddress = `${getData.resImage.wareHouseLocation}`;
          // const companyNtn = `${getData.resImage.ntn}`;
          const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
          const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
          const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
          // const logo1 = getData.restaurantImage;
          // doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(18);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 50, 30, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 50, 50, 0, 20);
          doc.text(companyNtn, 50, 70, 0, 20);
          doc.setFontSize(16);
          doc.setFont("TimesNewRoman", "bold");
          doc.text(title, 120, 100);
          doc.setFont("TimesNewRoman", "Normal");
          doc.setFontSize(14);
          doc.text(`Vendor Name: ${vendorName}`, 40, 120)
          doc.setFontSize(11);
          doc.setFont("TimesNewRoman", "bold");
          let yAxis = 140;
          console.log(yAxis, "aaaaaaaaaaaaaaa");
          const headers = [
            [
              "Sr",
              "product Name",
              "Booking Qty",
              "Return Qty",
              "Final Qty",
              "Price",
              "Sale Total",
              // "Purchase Total",
              // "Profit"
            ],
          ];
          const data = getData.data.map((x, i) => [
            i + 1,
            x.productLabel,
            Number(x.quantity) + Number(`${x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity}`),
            x.returnProductQuantity == 0 ? 0 : x.returnProductQuantity.quantity,
            x.quantity,
            x.productSellingPrice,
            x.totalSaleValue,
            // x.purchasePriceTotal.toFixed(2),
            // (x.totalSaleValue-x.purchasePriceTotal).toFixed(2)
          ]);
          data.push(["", "Total", sumBy(data, '2'), sumBy(data, '3'), sumBy(data, '4'), sumBy(data, '5'), sumBy(data, '6')])
          let content = {
            startY: yAxis,
            startX: 10,
            head: headers,
            body: data,
          };
          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          // doc.text(`Total Sale:`,400,yAxis)
          // doc.text(`Total Discount:`,400,yAxis+20)
          // doc.text(`Total Return:`,400,yAxis+40)
          // doc.text(`Total Purchase:`,400,yAxis+60)
          // doc.text(`Net Sale:`,400,yAxis)

          // doc.text(`${getData.subTotal}`,500,yAxis)
          // doc.text(`${getData.bookingDiscount}`,500,yAxis+20)
          // doc.text(`${getData.returnAmount}`,500,yAxis+40)
          // doc.text(`${sumBy(getData.data,'purchasePriceTotal')}`,500,yAxis+60)
          // doc.text(`${(sumBy(data,'6')).toFixed(2)}`,500,yAxis)
          doc.setProperties({
            title: `Vendor Wise Sale Report ${moment(new Date()).format(
              "YYYY-MM-DD"
            )}.pdf`,
          });
          var blob = doc.output("blob");
          window.open(URL.createObjectURL(blob));

          // doc.output("dataurlnewwindow");
          // doc.save(
          //   `Day Open Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          // );
          //  doc.output('dataurlnewwindow')
          dispatch(successGetVendorWiseReport(getData));
        }
      })
      .catch((error) => {
        dispatch(errorGetVendorWiseReport());
        handleDangerVisible();
      });
  };
};
