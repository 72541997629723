const initialState = {
  returnList: [],
  isAddingReturn: false,
  returnDataList: [],
  isSearchingReturn:false,
  show:false,
  searchList:[],
  isFetchingReturnData: false,
};

const ReturnReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case "REQUEST_ADD_RETURN":
      return { ...state, isAddingReturn: true };
    case "SUCCESS_ADD_RETURN":
      return {
        ...state,
        returnList: action.payload,
        isAddingReturn: false,
      };
    case "ERROR_ADD_RETURN":
      return { ...state, isAddingReturn: false };
      case "REQUEST_SEARCH_RETURN":
        return { ...state, isSearchingReturn: true,show:false };
      case "SUCCESS_SEARCH_RETURN":
        {console.log(action.payload,'uuuuuuuuuuuuuuuuuuu')}
        return {
          ...state,
          searchList: action.payload.data,
          isSearchingReturn: false,
          show:true
        };
      case "ERROR_SEARCH_RETURN":
        return { ...state, isSearchingReturn: false,show:false };
    case "REQUEST_GET_RETURN":
      return { ...state, isFetchingReturnData: true };
    case "SUCCESS_GET_RETURN":
      return {
        ...state,
        returnDataList: action.payload.data,
        isFetchingReturnData: false,
      };
    case "ERROR_GET_RETURN":
      return { ...state, isFetchingReturnData: false };
    default:
      return state;
  }
};
export default ReturnReducer;
