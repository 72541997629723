import React, { useEffect, useState } from "react";
import "./seats.css";
//import Seatbooking1 from "./Seatbooking1";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Navbar,
} from "react-bootstrap";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Icon } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
//import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Spinner from "react-bootstrap/Spinner";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  isEmpty,
  find,
  isUndefined,
  sumBy,
  groupBy,
  map,
  forEach,
} from "lodash";
import { redirect } from "react-router-dom";
import DrawGrid from "./DrawGrid";
import {
  setSeatAvaliable,
  setSeatReserved,
  setSeatSelected,
} from "./POSView.action";
import {
  filterTableByHall,
  getTableData,
  getTableReserved,
} from "../AddTable/Table.action";
//import post from "./post.json";

import DeckIcon from "@mui/icons-material/Deck";
import { getHallData } from "../AddHall/Hall.action";
import moment from "moment";
import { getUserRes } from "../../AddUser/user.actions";
// import { socket } from "../../../../../socket";
const TableView = (props) => {
  const [hall, setHall] = useState("");
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const onClickData = (seat) => {
    console.log(props.tableListReserved, "yyyyy2");
    navigate("/pos");
    props.setSeatSelected(seat);
    if (props.seatReserved.indexOf(seat) > -1) {
      console.log(
        props.seatReserved.filter((res) => res._id != seat._id),
        "yyyyyyyyyy"
      );
      props.setSeatAvaliable(props.seatAvaliable.concat(seat)); //Make a actio to set it i reducer
      props.setSeatReserved(
        props.seatReserved.filter((res) => res._id != seat._id)
      ); //Make a actio to set it i reducer
    } else {
      console.log(
        props.seatAvaliable.filter((res) => res._id != seat._id),
        "yyyyyyyyyy1"
      );
      console.log(props.seatReserved, "r0");
      console.log(props.seatReserved.concat(seat), "r2");
      props.setSeatReserved(props.seatReserved.concat(seat)); //Make a actio to set it i reducer
      props.setSeatAvaliable(
        props.seatAvaliable.filter((res) => res._id != seat._id)
      ); //Make a actio to set it i reducer
    }
  };
  const checkTrue=(item)=>{
    console.log(item,"checktrue")
    isEmpty(item.orderDetail)?navigate('/pos',{state:{id:item.tableData._id,numberOfPeople:item.tableData.tableSittingCapacity}}):
    // navigate('/pos/Editpos',{state:{id:item.orderDetail._id}})
    item.orderDetail.status=="Pending"?
      navigate("/pos/Editpos", {state:{id:item.orderDetail._id}})
      :
        navigate("/pos/EditposCooking", {state:{id:item.orderDetail._id}});
      
   // (e) => navigate('/pos',{state:item._id})
   }
  // const checkTrue = (item) => {
  //   console.log(item, "checktrue");
  //   navigate("/pos", {
  //         state: {
  //           id: item.tableData._id,
  //           numberOfPeople: item.tableData.tableSittingCapacity,
  //         },
  //       })
     
        
  // };
  const checkTrue1 = (item) => {
    console.log(item, "checktrue");
  
     
        navigate("/order", { state: { id: item.orderDetail._id } });
  };
  const handleSubmited = () => {
    props.setSeatSelected(props.seatselected.concat(props.seatReserved));
    props.setSeatReserved([]);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getHallList =
  // !isEmpty(props.hallListById) &&
  // props.hallListById.map((x) => {
  //   let data = { value: x._id, label: x.hallName };
  //   return data;
  // });
  let getHallList =
    !isEmpty(props.hallDataList) &&
    props.hallDataList.map((x) => {
      let data = { value: x._id, label: x.hallName };
      return data;
    });
  !isEmpty(props.hallDataList) &&
    getHallList.unshift({ value: "All", label: "ALL" });
  const handleChangeHall = (item) => {
    setHall(item.label);
    // setcategoryId1(selectedOption.value);
    // setcategoryId(selectedOption.label);
    // console.log(selectedOption.value, "valueeeeeeeeeeee");
    item.value == "All" ? props.getTable() : props.getTableByHall(item.value);
  };
  useEffect(() => {
    // props.getTable();
    // socket.on("addOrderSocket", (dispatch) => {
    //   props.getTable();
    //   // playAudio()
    // });
    // socket.on("updateTableSocket", (dispatch) => {
    //   console.log("updateOrder")
    //   props.getTable();
    // });
    // socket.on("timeUpdate", (dispatch) => {
    //   props.getTable();
    // });
      props.getTable();
    props.getHall();
    //   props.getReservedTable();
  }, []);
  let z = [];
  let y =
    !isEmpty(props.tableDataList) &&
    groupBy(props.tableDataList, (x) => x.tableData.hall._id);
  const groupedLevel1 = Object.keys(y).map(function (key, index) {
    z.push({ name: y[key][0].tableData.hall.hallName, val: y[key] });
  });
  console.log(z, "listcheck");

  return (
    <>
      <div>
        <Navbar style={{ backgroundColor: "#81abd3" }}>
          <Container>
            <Navbar.Brand style={{ color: "white" }}>
              <b>Table Management</b>
            </Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>

      {props.isFetchingTable ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingTable ? "Loading..." : "Saving..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {/* {isEmpty(z) ? "No Table Found": 
      map(z,(x,ide) => ( */}
          <>
            <Row>
              <Col xl="2" lg="4" md="4" sm="4">
                <Row
                  style={{ height: "auto", overflow: "auto" }}
                  className="overflow-auto"
                >
                  {!isEmpty(getHallList)
                    ? getHallList.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Col xl="12" lg="12" md="12" sm="12">
                              <Card
                                style={{
                                  marginTop: "1%",
                                  cursor: "pointer",

                                  height: "auto",
                                  
                                  backgroundColor:
                                    item.label == hall ? "#c1c1c1" : "#81abd3",
                                }}
                                onClick={(e) => handleChangeHall(item)}
                              >
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="body3"
                                    component="div"
                                    style={{marginTop:'-10px',marginBottom:"-10px"}}
                                    // style={{
                                    //   color:
                                    //     item._id == idEdit
                                    //       ? "#008BFF"
                                    //       : "black",
                                    // }}
                                  >
                                    <b> {item.label} </b>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Col>
                          </React.Fragment>
                        );
                      })
                    : []}
                </Row>
              </Col>
              <Col xl="10" lg="8" md="8" sm="8">
                {hall != "" && (
                  <Row>
                    <Col>
                      <Card
                        style={{
                          height: "50px",
                          backgroundColor: "#81abd3",
                          marginBottom: "5px",
                          marginTop: "10px",
                        }}
                      >
                        <CardActionArea>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="body3"
                              component="div"
                              style={{ fontSize: "12px" }}
                            >
                              {/* {let arry=!isEmpty(props.tableSearchList) &&props.tableSearchList.filter(e => !isEmpty(e.orderDetail))} */}
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                <b>
                                  {hall}{" "}
                                  <span style={{ float: "right" }}>
                                    {" "}
                                    {props.tableSearchList.length}{" "}
                                  </span>
                                </b>
                              </p>
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Col>
                  </Row>
                )}
                {/* <p style={{textAlign:'center',fontSize:'18px',marginTop:'10px',marginBottom:'10px'}}><b>{hall}</b></p> */}
                <Row style={{ height: props.tableSearchList.length>12?'100vh':"auto" }} className="overflow-auto">
                  {isEmpty(props.tableSearchList)
                    ? "No Table Founnd"
                    : props.tableSearchList.map((item, i) => {
                        console.log(item, i, "today");
                        return (
                          <React.Fragment key={i}>
                            <Col
                              xl="2"
                              lg="3"
                              md="4"
                              sm="6"
                              // style={{ marginBottom: 10, }}
                            >
                              <Card
                                style={{
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                  //  paddingRight:'20px',
                                  backgroundColor: !isEmpty(item.orderDetail)
                                    ? "#81abd3"
                                    : "#c1c1c1",
                                }}
                                
                                  // onClick={(e) =>checkTrue(item)}
                                // onClick={()=>navigate("/order")}
                              >
                                <CardActionArea>
                                  <CardContent>
                                    {!isEmpty(props.tableDataList) && (
                                      <Typography
                                        gutterBottom
                                        // variant="body3"
                                        // component="div"
                                        // style={{marginLeft:'-15px',marginRight:'-15px'}}
                                      >
                                        {/* <Row style={{height:'10px'}}> <Col xl="6" lg="6" md="6" sm="6" ><Button>Edit</Button></Col>
                 <Col xl="6" lg="6" md="6" sm="6" >
                 <Button>Bill</Button></Col></Row>
                 <Row> */}
                                        {!isEmpty(item.orderDetail) ? (
                                          <Row style={{ marginTop: "-10px" }}>
                                            <Col xl="3" lg="3" md="3" sm="3" >
                                              <button
                                                style={{
                                                  backgroundColor: "#c1c1c1",
                                                  borderRadius:'4px'
                                                }}
                                                onClick={(e) => checkTrue1(item)}
                                              >
                                                Bill
                                              </button>
                                            </Col>
                                            <Col
                                              xl="5"
                                              lg="5"
                                              md="5"
                                              sm="5"
                                            ></Col>
                                            <Col xl="2" lg="2" md="3" sm="3">
                                              <button
                                                style={{
                                                  backgroundColor: "#c1c1c1",
                                                  borderRadius:'4px'
                                                }}
                                                onClick={(e) => checkTrue(item)}
                                              >
                                                Edit
                                              </button>
                                            </Col>
                                          </Row>
                                        ) : (
                                          <Row
                                            style={{
                                              marginTop: "-11px",
                                              // height: "20px",
                                            }}
                                          >
                                             <Col xl="3" lg="3" md="3" sm="3">
                                          
                                  
                                           </Col>
                                            <Col xl="4" lg="4" md="4" sm="4">
                                          
                                             <button
                                                style={{
                                                  backgroundColor: "#c1c1c1",
                                                  borderRadius:'4px'
                                                }}
                                                onClick={(e) => checkTrue(item)}
                                              >
                                                Order
                                              </button>
                                              </Col>
                                              <Col xl="3" lg="3" md="3" sm="3">
                                          
                                  
                                          </Col>
                                          </Row>
                                        )}
                                        <Row>
                                          <Col xl="6" lg="6" md="6" sm="6">
                                          

                                            <Row
                                              style={{ marginBottom: "-2px", }}
                                            >
                                              <b>{item.tableData.tableName}</b>
                                            </Row>
                                            <Row
                                              style={{
                                                marginBottom: "-2px",
                                                marginLeft: "3px",
                                                fontSize: 14,
                                              }}
                                            >
                                              {!isEmpty(item.orderDetail) &&
                                                item.orderDetail.status}
                                            </Row>
                                            <Row
                                              style={{
                                                marginBottom: "-2px",
                                                marginLeft: "3px",
                                                fontSize: 14,
                                              }}
                                            >
                                              {!isEmpty(item.orderDetail) &&
                                                "Rs:" +
                                                  item.orderDetail.grandTotal}
                                            </Row>
                                          </Col>
                                          <Col
                                            xl="1"
                                            lg="1"
                                            md="1"
                                            sm="1"
                                          ></Col>
                                          <Col xl="2" lg="2" md="2" sm="2">
                                            <DeckIcon
                                              style={{ fontSize: "50px" }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row
                                          style={{
                                            marginTop: "-2px",
                                            marginBottom: "-5px",
                                          }}
                                        >
                                          <Col
                                            xl="5"
                                            lg="5"
                                            md="5"
                                            sm="5"
                                            style={{
                                              fontSize: 12,
                                              marginLeft: "1px",
                                            }}
                                          >
                                            Sitting:
                                            {
                                              item.tableData
                                                .tableSittingCapacity
                                            }
                                            
                                           
                                          </Col>
                                          {/* <Col xl="2" lg="2" md="2" sm="2"></Col> */}

                                          <Col
                                            xl="5"
                                            lg="5"
                                            md="5"
                                            sm="5"
                                            style={{
                                              fontSize: 12,
                                              // marginLeft: "20px",
                                              float: "right",
                                            }}
                                          >
                                            {!isEmpty(item.orderDetail) &&
                                              `${parseInt(
                                                moment
                                                  .duration(
                                                    moment(new Date()).diff(
                                                      moment(
                                                        item.orderDetail
                                                          .createdAt
                                                      )
                                                    )
                                                  )
                                                  .asMinutes()
                                              )} min`}
                                          </Col>
                                        </Row>
                                       <Row    style={{
                                             marginLeft: "1px",
                                            fontSize:'12px',
                                            marginBottom: "-5px",
                                          }}> {!isEmpty(item.orderDetail)&&!isEmpty(item.orderDetail.shiftFromTableId)?"Shifted from "+item.orderDetail.shiftFromTableId.tableName:""}
                                       </Row>
                                        {isEmpty(item.orderDetail) && (
                                          <Row style={{ height: "19px" }}></Row>
                                        )}
                                        {/* <p style={{marginTop:'-11px',marginBottom:'-2px'}} > <button style={{paddingTop:'-10px',paddingBottom:'-10px'}} onClick={(e) =>checkTrue(item)}>Bill </button><span style={{float:'right'}}><button style={{paddingTop:'-10px',paddingBottom:'-10px'}}>Edit</button></span></p> */}

                                        {/* <b>{zz.tableData.tableName}</b>
                {!isEmpty(zz.orderDetail)?<p style={{textAlign:'center',height:'22px'}} >{zz.orderDetail.status}</p>:<p style={{height:'22px'}} ></p>}
                <p style={{fontSize:10,color:'gray',marginBottom:'-10px'}}>Sitting<span style={{float:'right',fontSize:10,color:'gray',marginBottom:'-10px'}}>{zz.tableData.tableSittingCapacity}</span></p> */}

                                        {/* <p> <b style={{fontSize:'17px',float:'left'}}>{item.tableData.tableName}</b><DeckIcon style={{fontSize:'50px',float:'right'}} /></p>
                {!isEmpty(item.orderDetail)?<p style={{height:'8px',fontSize:14}} >{item.orderDetail.status}</p>:<p style={{height:'8px'}} ></p>}
                {!isEmpty(item.orderDetail)?<p style={{height:'8px',fontSize:14}} >Rs:{item.orderDetail.grandTotal}</p>:<p style={{height:'8px'}} ></p>} */}

                                        {/* {!isEmpty(zz.orderDetail)? <p style={{height:'16px',fontSize:14,color:'gray'}}>{zz.orderDetail.status}<span style={{float:'right',fontSize:14,color:'gray',}}>Rs:{zz.orderDetail.grandTotal}</span></p> :<p style={{height:'16px'}} ></p>}  */}
                                        {/* <p style={{fontSize:10,marginBottom:'-10px'}}>Sitting : {item.tableData.tableSittingCapacity}<span style={{float:'right',fontSize:14,marginBottom:'-10px'}}>    
                {!isEmpty(item.orderDetail)&&`${parseInt(
                                      moment
                                        .duration(
                                          moment(new Date()).diff(
                                            moment(item.orderDetail.createdAt)
                                          )
                                        )
                                        .asMinutes()
                                    )} min`}</span></p>  */}
                                        {/* </Row> */}
                                      </Typography>
                                    )}
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Col>
                          </React.Fragment>
                        );
                      })}
                </Row>
              </Col>
            </Row>
          </>

          {/* ))
    } */}
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTable: () => dispatch(getTableData()),
    getHall: () => dispatch(getHallData()),
    setSeatSelected: (seat) => dispatch(setSeatSelected(seat)),
    setSeatAvaliable: (seat) => dispatch(setSeatAvaliable(seat)),
    setSeatReserved: (seat) => dispatch(setSeatReserved(seat)),
    getReservedTable: () => dispatch(getTableReserved()),
    getTableByHall: (id) => dispatch(filterTableByHall(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  tableListReserved: state.TableReducer.tableListReserved,

  tableDataList: state.TableReducer.tableDataList,
  seatAvaliable: state.POSViewReducer.seatAvaliable,
  seatReserved: state.POSViewReducer.seatReservedx,
  seatselected: state.POSViewReducer.seatSelected,
  isFetchingTable: state.TableReducer.isFetchingTable,
  hallDataList: state.hallReducer.hallDataList,
  tableSearchList: state.TableReducer.tableSearchList,
  ReserveTable: state.TableReducer.ReserveTable,
});

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
