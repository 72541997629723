import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import useScanDetection from "use-scan-detection";
import { getProductData } from "../AddProduct/Product.action";
// import { getVendorData } from "../Vendor/Vendor.action";
import { setHeading } from "../../../accounts/account.actions";
import { addExternalReceiving } from "./ReceivingByVendor.action";

const AddReceivingByVendor = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      product: 0,
      productLabel: "",
      availableProductQuantity: 0,
      quantity: 0,
      productSellingPrice: 0,
      marketPrice: 0,
    },
  ]);
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [receivedBy, setReceivedBy] = useState("");
  const [requistionType, setRequistionType] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] =
    useState(false);
  const [barcode, setBarocode] = useState("");
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadExternalReceiving() {
    props.addExternalReceiving(
      {
        receivingDate: date,
        receivedBy: receivedBy,
        receivingDescription: requistionType,
        data: inputField1,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setInputField1([
      {
        product: 0,
        productLabel: "",
        availableProductQuantity: 0,
        quantity: 0,
        productSellingPrice: 0,
        marketPrice: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        product: 0,
        productLabel: "",
        availableProductQuantity: 0,
        quantity: 0,
        productSellingPrice: 0,
        marketPrice: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type, itemId) => {
    console.log(e, index, type, "checkBar");
    //  console.log(itemId,"lllll")
    //  let z = find(props.allInventory, (x) => x._id == e.value);
    let z = find(props.productDataList, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    console.log(z, "lllllll");
    if (type == "master") list[index]["product"] = e.value;
    if (type == "master") list[index]["productLabel"] = e.label;
    console.log(e.label1, e.labelPrice, "hhhhh");
    if (type == "master")
      list[index]["availableProductQuantity"] = e.productQuantityCount;
    if (type == "master") list[index]["productSellingPrice"] = e.productPrice;
    if (type == "master") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity")
      list[index]["quantity"] =
        e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
    if (type == "intPrice") list[index]["marketPrice"] = e.target.value;
    setInputField1(list);
  };

  const getMList =
    !isEmpty(props.productDataList) &&
    props.productDataList.map((x) => {
      let data = {
        value: x._id,
        label: `${x.code}-${x.name}`,
        productQuantityCount: x.productBalance,
        productPrice: x.purchasePrice,
      };
      return data;
    });
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("External Receiving Vendor");
    props.getProduct();
    // props.getRestaurantData();
    // props.getVendorData();
  }, []);
  return (
    <>
      {props.isFetchingProduct ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="4" lg="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date:</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By...."
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField1.map((item, i) => {
              console.log(item, "check");
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>

                        <Select
                          style={{ zIndex: 1 }}
                          value={{
                            label: item.productLabel,
                          }}
                          placeholder="Select Items..."
                          name="master"
                          onChange={(e) => handleChange1(e, i, "master")}
                          //   isDisabled={isEmpty(vendorId)}
                          options={getMList}
                          isOptionDisabled={(option) => (
                            
                            !isEmpty(
                              find(
                                inputField1,
                                (x) => x.product == option.value
                              )
                            )
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Available Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.availableProductQuantity}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Purchase Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Purchase Price...."
                          name="productQuantityCount"
                          disabled
                          value={item.productSellingPrice}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          value={item.quantity}
                          onChange={(e) =>
                            handleChange1(e, i, "intquantity", item.Product)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {isEmpty(receivedBy) ||
              inputField1[0].product == "" ||
              inputField1[0].quantity == 0 ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadExternalReceiving}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    addExternalReceiving: (data, handleVisible, handleDangerVisible) =>
      dispatch(addExternalReceiving(data, handleVisible, handleDangerVisible)),
    getProduct: () => dispatch(getProductData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  externalReceivingList: state.ExternalReceivingReducer.externalReceivingList,
  isAddingExternalReceiving:
    state.ExternalReceivingReducer.isAddingExternalReceiving,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddReceivingByVendor);
