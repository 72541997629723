import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  Table,
  Container,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../forms/AddDetails/add-details.css";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { setValue } from "../forms/Vouchers/Voucher.action";
import { setHeading, setModuleKey } from "./account.actions";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Fab from "@mui/material/Fab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import InventoryPic from "../../../../src/Images/Inventory.jpg";
import backupLogo from "../../../../src/Images/backupLogo.jpg";
import AccountsPic from "../../../../src/Images/Accounts.jpg";
import OrderPic from "../../../../src/Images/view order.jpg";
import PosPic from "../../../../src/Images/Point of Sale (1).jpg";
import MasterControlPic from "../../../Images/Master Control.jpg";
import HRPic from "../../../Images/hr.png";
import ReportPic from "../../../../src/Images/Reports.jpg";
import SettingPic from "../../../../src/Images/settings.jpg";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import OpeningDay from "../forms/AddDetails/Day/OpeningDay";
import IconMenu from "../drawer/IconMenu";


//  src/Images/InventoryCrop.jpg';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 30,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserMainPage = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const [userClick, setUserClick] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => { }, []);
  function Logout() {
    window.localStorage.clear();
    window.location.replace("/login");
  }
  return (
    <>
      <div >
        <Navbar style={{ backgroundColor: '#81abd3' }}  >
          <Container>
            <Navbar.Brand href="/home" style={{ color: 'white' }}><b>Home page</b></Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>
      {/* <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Home Page
            </Typography>
          
            <IconMenu/>
          </Toolbar>
        </AppBar>
      </Box> */}
      <div style={{ width: "100%", }}>
        <Box sx={{ flexGrow: 1, mt: 30, mx: "auto", ml: 30 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 5, sm: 6, md: 6 }}
          >


            {permissions.some(p => p['permissionsName'] === "Store") &&
              <Link to="/store">
                {/* <LightTooltip title="Inventory"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    // padding:"50px",
                    mr: 5,
                    mb: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("storeList");
                    window.localStorage.setItem("module_key", "storeList");
                    props.setHeading("Store")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserInventory"
                    height="180"
                    width="180"
                    image={InventoryPic}
                  />


                </Card>
                {/* </LightTooltip> */}
              </Link>
            }

            {permissions.some(p => p['permissionsName'] === "Accounts") && <Link to="/accounts">
              {/* <LightTooltip title="Accounts"> */}
              <Card
                sx={{
                  maxWidth: 250,
                  maxHeight: 250,
                  // borderRadius: "50%",
                  mr: 5,
                  mb: 5,
                }}
                onClick={() => {
                  props.setModuleKey("UserAccounts");
                  window.localStorage.setItem("module_key", "user_accounts");
                  props.setHeading("Accounts")
                }}
              >
                <CardMedia
                  component="img"
                  alt="UserAccounts"
                  height="180"
                  width="180"
                  image={AccountsPic}
                />

              </Card>
              {/* </LightTooltip> */}
            </Link>}
            {/* { permissions.some(p => p['permissionsName'] === "HR")&&  */}
            {/* <Link to="/hr">
              <Card
                sx={{
                  maxWidth: 250,
                  maxHeight: 250,
                  // borderRadius: "50%",
                  mr: 5,
                  mb: 5,
                }}
                onClick={() => {
                  props.setModuleKey("UserHR");
                  window.localStorage.setItem("module_key", "UserHR");
                  props.setHeading("HR")
                }}
              >
                <CardMedia
                  component="img"
                  alt="UserHR"
                  height="180"
                  width="180"
                  image={HRPic}
                />

              </Card>
            </Link> */}
            {/* } */}
            {permissions.some(p => p['permissionsName'] === "POS") &&
              <Link to="/pos">
                {/* <LightTooltip title="POS"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                    mb: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("UserPOS");
                    window.localStorage.setItem("module_key", "pos");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={PosPic}
                  />

                </Card>
                {/* </LightTooltip> */}
              </Link>}
            {permissions.some(p => p['permissionsName'] === "Reports") &&
              <Link to="/report">
                {/* <LightTooltip title="Report"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                    mb: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("UserReport");
                    window.localStorage.setItem("module_key", "Report");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={ReportPic}
                  />

                </Card>
                {/* </LightTooltip> */}
              </Link>}



            {permissions.some(p => p['permissionsName'] === "BackUp") &&
              <Link to="/backUp">
                {/* <LightTooltip title="BackUp"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                    mb: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("Backup");
                    window.localStorage.setItem("module_key", "user_backup");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserBackUp"
                    height="180"
                    width="180"
                    image={backupLogo}
                  />


                </Card>
                {/* </LightTooltip> */}
              </Link>}
            {permissions.some(p => p['permissionsName'] === "Setting") &&
              <Link to="/setting">
                {/* <LightTooltip title="Setting"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("setting");
                    window.localStorage.setItem(
                      "module_key",
                      "setting"
                    );
                    props.setHeading("Setting")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Setting"
                    height="180"
                    width="180"
                    image={SettingPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}
            {permissions.some(p => p['permissionsName'] === "Order View") &&
              <Link to="/order">
                {/* <LightTooltip title="View Order"> */}
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                    mb: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("Userorder");
                    window.localStorage.setItem("module_key", "order");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={OrderPic}
                  />

                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {/* <Link to="/user">
              <LightTooltip title="HR Modules">
                <Card
                  sx={{
                    maxWidth: 250,
                    maxHeight: 250,
                    // borderRadius: "50%",
                    mr: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("UserHRModules");
                    window.localStorage.setItem("module_key", "user_hr");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserHRModules"
                    height="180"
                    width="180"
                    image={HRMod}
                  />
                </Card>
              </LightTooltip>
            </Link>
            <Link to="/user">
              <LightTooltip title="BackUp">
                <Card
                  sx={{
                    minWidth: 200,
                    minHeight: 200,
                    borderRadius: "50%",
                    mr: 5,
                  }}
                  onClick={() => {
                    props.setModuleKey("BackUpModule");
                    window.localStorage.setItem(
                      "module_key",
                      "user_backUpModule"
                    );
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="BackUpModule"
                    height="200"
                    width="200"
                    image={Backup}
                  />
                </Card>
              </LightTooltip>
            </Link>  */}
          </Grid>
        </Box>
      </div>

    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setModuleKey: (data) => dispatch(setModuleKey(data)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserMainPage);
