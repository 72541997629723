import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Container,
  Card,
  Button,
  FormGroup,
  Modal,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Select from "react-select";
import { getData } from "./Report.action";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import IconMenu from "../../../drawer/IconMenu";
const ReportView = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState("");
  const [itemCode, setItemCode] = useState("");

  const getledger = props.ledgerSearchlist;
  const getItem = props.List.map((x) => {
    let data = { value: x._id, label: x.reportName };
    return data;
  });
  const handleChangeLedgerItem = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Date",
        field: "updatedAt",
        render: (rowData) => moment(rowData.updatedAt).format("YYYY-MM-DD"),
      },
      { title: "Item Name", field: "metrialName" },
      { title: "Item Code", field: "metrialCode" },
      { title: "D-P-I", field: "" },
      { title: "Previous Balance", field: "balance" },
      { title: "Add Item", field: "add" },
      { title: "Less Item", field: "less" },
      { title: "New Balance", field: "newBalance" },
    ],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    // props.getData();
  }, []);
  function searchData() {
    //   props.SearchLedgerByDate({
    //     startedDate: startDate,
    //     endDate: endDate,
    //     item: item,
    //     itemCode: itemCode
    //   });
  }
  return (
    <>
      <div>
        <Navbar style={{ backgroundColor: "#81abd3" }}>
          <Container>
            <Navbar.Brand style={{ color: "white" }}>
              <b>Reports</b>
            </Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>
      {props.isFetchingData ? (
        <div className="loader-div">
          <Button
            variant="info"
            disabled
            style={{ backgroundColor: "#0D6EFD" }}
          >
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading
          </Button>
        </div>
      ) : (
        <Container fluid style={{ backgroundColor: "#f6f7fb" }}>
          <div className="main" style={{ backgroundColor: "#f6f7fb" }}>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item>
                  {" "}
                  <Link to="/home"> Home</Link>
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/report">Report</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <div>
              <Form>
                {/* <Row>
                  <Col lg="4" md="4" sm="4"></Col>
                  <Col lg="4" md="4" sm="4"></Col>
                  <Col lg="4" md="4" sm="4">
                    <Form.Group>
                      <Select
                        placeholder="Select item..."
                        onChange={handleChangeLedgerItem}
                        options={getItem}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Accordion>
                  {/* Profit */}
                  {/* <Card style={{ marginTop: 10 }}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header style={{ padding: 8 }}>
                        Profit Reports
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/daliy/sale-report">
                                Daily Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>

                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/monthly/sale-report">
                                Monthly Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/Product-Sale-report">
                                Product Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>

                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/Specific-Product-Sale-report">
                                Specific Product Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/Report/hot-selling-report">
                                Booker Wise Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Form.Label>
                              <Link to="/vendorReport/vendor-wise-sale-report">
                                Vendor Wise Profit Report
                              </Link>
                            </Form.Label>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* Sales */}
                  <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header style={{ padding: 8 }}>
                        Sales Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                        <Col lg="5" md="5" sm="5">
                            <Link to="/Report/daily/sale-report">
                              Daily Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/monthly/sale-report">
                              Monthly Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        <Row>
                        <Col lg="5" md="5" sm="5">
                            <Link to="/Report/sale-report-withOutDayClose">
                             Today Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/sale-report-salemenWise">
                              Salemen Wise Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/saleReport">
                              Booker Wise Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/netsaleReport">
                              Booker Wise Net Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                        <Row>
                        <Col lg="5" md="5" sm="5">
                            <Link to="/Report/sale/booker-company">
                              Booker Company Wise Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        <Col lg="5" md="5" sm="5">
                            <Link to="/Report/sale/vendorWiseSale">
                              Vendor Wise Net Sale Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                  {/* Payment */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header style={{ padding: 8 }}>
                        Payment Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                        <Col lg="5" md="5" sm="5">
                            <Link to="/Report/Payment/dateWise">
                            Payment Report With Date
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/payment/salemen">
                              Salemen Payment Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* Purchase */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header style={{ padding: 8 }}>
                        Purchase Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/vendorPurchaseReport">
                              Purchase Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/vendorPurchaseReport/itemWise">
                              Purchase Report Item Wise
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* Stock */}
                  <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header style={{ padding: 8 }}>
                        Stock Report
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/stock/warehouse_stock_report">
                              Warehouse Stock Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/stock/salesmen_stock_report">
                            Salesmen Stock Report
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card>
                  {/* Area Wise Shops */}
                  {/* <Card style={{ marginTop: "5px" }}>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header style={{ padding: 8 }}>
                        Area Wise Shops
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg="5" md="5" sm="5">
                            <Link to="/Report/areaWiseShops">
                            Area Wise Shops
                            </Link>
                          </Col>
                          <Col lg="1" md="1" sm="1">
                            <StarBorderIcon style={{ color: "#0D6EFD" }} />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Card> */}
                  {/* <Card style={{marginTop:"5px"}}>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{padding:8}}>Invoices Report</Accordion.Header>
        <Accordion.Body>
        <Row>
        <Col lg="5" md="5" sm="5">
        <Link to="/daliy/invoice-report">
        Daily Invoices Report
                </Link>
            </Col>
            <Col lg="1" md="1" sm="1">
              <StarBorderIcon style={{color:"#0D6EFD"}}/>
            </Col>
            <Col lg="5" md="5" sm="5">
            <Link to="/monthly/invoice-report">
            Monthly Invoices Report
                </Link>
              
            </Col>
            <Col lg="1" md="1" sm="1">
              <StarBorderIcon style={{color:"#0D6EFD"}}/>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      </Card> */}
                </Accordion>
                {/* <Row>

                  <Col lg="12" sm="12" className="text-center" style={{marginTop: '30px'}}>
                    <Link to="/inventory/material-ledger-details">
                      <Button className="sendButton" onClick={(e) => searchData(e.target.value)} >Search</Button>
                    </Link>
                  </Col>
                </Row> */}
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getData: () => dispatch(getData()),
    // SearchLedger: () => dispatch(SearchLedger()),
    // SearchLedgerByDate: (data) => dispatch(SearchLedgerByDate(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // ledgerlist: state.MaterialLegderReducer.ledgerlist,
  // ledgerSearchlist: state.MaterialLegderReducer.ledgerSearchlist,
  List: state.ReportReducer.Data,
  isFetchingData: state.ReportReducer.isFetchingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
