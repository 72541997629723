import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { addArea } from "./Area.action";

const AddArea = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaDesc, setAreaDesc] = useState("");
  const [areaLocation, setAreaLocation] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadArea() {
    props.addArea(
      {
        areaName: areaName,
        location: areaLocation,
        areaDesc: areaDesc,
      },
      handleVisible,
      handleDangerVisible
    );
    setAreaName("");
    setAreaDesc("");
    setAreaLocation("");
  }

  useEffect(() => {
    props.setHeading("Add Area");
  }, []);
  return (
    <>
      {props.isAddingCoupons ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Area Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={areaName}
                    placeholder="Enter Area Name...."
                    onChange={(e) => {
                      setAreaName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Area Location *</b>
                  </Form.Label>
                  <Form.Control
                    // type="text"
                    value={areaLocation}
                    placeholder="Enter Area Location...."
                    onChange={(e) => {
                      setAreaLocation(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Area Description</b>
                  </Form.Label>
                  <Form.Control
                    // type="text"
                    value={areaDesc}
                    placeholder="Enter Area Description...."
                    onChange={(e) => {
                      setAreaDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(areaName) || isEmpty(areaLocation) ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadArea}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addArea: (data, handleVisible, handleDangerVisible) =>
      dispatch(addArea(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  areaList: state.AreaReducer.areaList,
  isAddingArea: state.AreaReducer.isAddingArea,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArea);
