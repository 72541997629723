import API from "../../../../../global/api";
export const requestCategoryByWarehouseId = () => {
    return {
      type: "REQUEST_CATEGORY_BY_WAREHOUSE_ID",
    };
  };
  export const successCategoryByWarehouseId = (data) => {
    return {
      type: "SUCCESS_CATEGORY_BY_WAREHOUSE_ID",
      payload: data,
    };
  };
  export const errorCategoryByWarehouseId = () => {
    return {
      type: "ERROR_CATEGORY_BY_WAREHOUSE_ID",
    };
  };
  export const getCategoryByWarehouseId = (id) => {
    return (dispatch) => {
      dispatch(requestCategoryByWarehouseId());
      API.get(`/categories/categoryWithWarehouseId/${id}`)
        .then((res) => {
          let getData = res.data;
          console.log(getData, "List");
          dispatch(successCategoryByWarehouseId(getData));
        })
        .catch((error) => {
          dispatch(errorCategoryByWarehouseId());
        });
    };
  };
export const requestAddSubCategory = () => {
    return {
        type: "REQUEST_ADD_SUBCATEGORY",
    };
};
export const successAddSubCategory = (data) => {
    return {
        type: "SUCCESS_ADD_SUBCATEGORY",
        payload: data,
    };
};
export const errorAddSubCategory = () => {
    return {
        type: "ERROR_ADD_SUBCATEGORY",
    };
};
export const addSubCategory = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSubCategory());
        API.post(`/subCategory`, data).then((res) => {
            // dispatch(getCategoryData());
            dispatch(successAddSubCategory(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSubCategory());
            handleDangerVisible(error.response.data.message);
        })
    };
};

export const DeleteRequestSubCategory = () => {
    return {
        type: "DELETE_REQUEST_SUBCATEGORY",
    };
};
export const DeleteSuccessSubCategory = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SUBCATEGORY",
        user_id: user_id,
    };
};
export const DeleteErrorSubCategory = () => {
    return {
        type: "DELETE_ERROR_SUBCATEGORY",
    };
};

export const deleteSubCategory = (categoryId) => {
    return dispatch => {
        dispatch(DeleteRequestSubCategory());
        API.delete(`/subCategory/${categoryId}`)
            .then(res => {
                dispatch(getSubCategory());
                dispatch(DeleteSuccessSubCategory(categoryId))
            }).catch((error) =>
                dispatch(DeleteErrorSubCategory()));
    }
};
export const UpdateRequestSubCategory = () => {
    return {
        type: "UPDATE_REQUEST_SUBCATEGORY",
    };
};
export const UpdateSuccessSubCategory = (data) => {
    return {
        type: "UPDATE_SUCCESS_SUBCATEGORY",
        payload: data,
    };
};

export const UpdateErrorSubCategory = () => {
    return {
        type: "UPDATE_ERROR_SUBCATEGORY",
    };
};

export const updateSubCategory = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestSubCategory());
        console.log(data,'hmhm')
        API.put(`/subCategory/${oldData._id}`,
            {
                name: data.name,
               // image: "https://www.chanchao.com.tw/vietnamwood/images/default.jpg",      
            },{
        })
            .then(res => {
                dispatch(getSubCategory());
                dispatch(UpdateSuccessSubCategory([data]))
            }).catch((error) =>
                dispatch(UpdateErrorSubCategory()));
    }
};
// export const updateCategoryStatusApprove = (data, oldData) => {
//     return dispatch => {
//         dispatch(UpdateRequestCategory());
//         console.log(data,'hmhm')
//         API.put(`/categories/${oldData._id}`,
//             {
//                // status:"Approve",
//                 name: "test2",
//                // image: "https://www.chanchao.com.tw/vietnamwood/images/default.jpg",      
//             },{
//         })
//             .then(res => {
//                 dispatch(getCategoryData());
//                 dispatch(UpdateSuccessCategory([data]))
//             }).catch((error) =>
//                 dispatch(UpdateErrorCategory()));
//     }
// };
// export const updateCategoryStatusDisapprove = (data, oldData) => {
//     return dispatch => {
//         dispatch(UpdateRequestCategory());
//         console.log(data,'hmhm')
//         API.put(`/categories/${oldData._id}`,
//             {
//                 status:"DisApprove",
//                 //name: data.name,
//                 //image: "https://www.chanchao.com.tw/vietnamwood/images/default.jpg",      
//             },{
//         })
//             .then(res => {
//                // dispatch(getCategoryData());
//                 dispatch(UpdateSuccessCategory([data]))
//             }).catch((error) =>
//                 dispatch(UpdateErrorCategory()));
//     }
// };
export const requestGetAllSubCategoryData = () => {
    return {
        type: "REQUEST_GET_ALL_SUBCATEGORY_DATA",
    };
};
export const successGetAllSubCategoryData = (data) => {
    return {
        type: "SUCCESS_GET_ALL_SUBCATEGORY_DATA",
        payload: data,
    };
};
export const errorGetAllSubCategoryData = () => {
    return {
        type: "ERROR_GET_ALL_SUBCATEGORY_DATA",
    };
};
export const getSubCategory = () => {
    return (dispatch) => {
        dispatch(requestGetAllSubCategoryData());
        API.get(`/subCategory?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            dispatch(successGetAllSubCategoryData(data1));
        }).catch((error) => {
            dispatch(errorGetAllSubCategoryData());
        })
    };
};

// export const requestAddCategoryImage = () => {
//     return {
//         type: "REQUEST_ADD_CATEGORY_IMAGE",
//     };
// };
// export const successAddCategoryImage = (data) => {
//     return {
//         type: "SUCCESS_ADD_CATEGORY_IMAGE",
//         payload: data,
//     };
// };
// export const errorAddCategoryImage = () => {
//     return {
//         type: "ERROR_ADD_CATEGORY_IMAGE",
//     };
// };
// export const addCategoryImage = (data) => {
//     return (dispatch) => {
//         console.log(data,"dddddddddddddddddddddd")
//         const formData = new FormData();
//        // formData.append("image", get(data, 'image'));
//         formData.append("image",data.image)
//         // .then(x => {
//         //     console.log(formData, "dt")
//         // })
//         console.log(formData,"iiiiiiiiiiiii")
//         console.log(formData,"iiiiiiiiiiiii11")
//             dispatch(requestAddCategoryImage());
//             API.post(`/files/image`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             }).then((res) => {
//                 let getData = res.data;
//                 dispatch(successAddCategoryImage(getData));
//             }).catch((error) => {
//                 dispatch(errorAddCategoryImage());
//             })
//         }};
        export const resetReducerCategory = () => {
            return {
                type: "RESET_CATEGORY_URL",
            };
        };

        export const requestSubCategoryByCategoryId = () => {
            return {
              type: "REQUEST_SUB_CATEGORY_BY_CATEGORY_ID",
            };
          };
          export const successSubCategoryByCategoryId = (data) => {
            return {
              type: "SUCCESS_SUB_CATEGORY_BY_CATEGORY_ID",
              payload: data,
            };
          };
          export const errorSubCategoryByCategoryId = () => {
            return {
              type: "ERROR_SUB_CATEGORY_BY_CATEGORY_ID",
            };
          };
          export const getSubCategoryByCategoryId = (id) => {
            return (dispatch) => {
              dispatch(requestSubCategoryByCategoryId());
              API.get(`/subCategory/get_subCategory_with_category/${id}`)
                .then((res) => {
                  let getData = res.data;
                  console.log(getData, "List");
                  dispatch(successSubCategoryByCategoryId(getData));
                })
                .catch((error) => {
                  dispatch(errorSubCategoryByCategoryId());
                });
            };
          };