const initialState = {
  isFetchingHybridAndCompleteData: false,
  hybridAndCompleteData: [],
  searchInventoryLedgerListReport: [],
  isFetchingSearchInventoryLedgerReport: false,
  searchSalesmenLedgerList: [],
  isFetchingSearchSalesmenLedger: false,
};

const SalesmenLegderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_HYBRID_AND_COMPLETE_DATA":
      return { ...state, isFetchingHybridAndCompleteData: true };
    case "SUCCESS_HYBRID_AND_COMPLETE_DATA":
      return { ...state, hybridAndCompleteData: action.payload.data, isFetchingHybridAndCompleteData: false, };
    case "ERROR_HYBRID_AND_COMPLETE_DATA":
      return { ...state, isFetchingHybridAndCompleteData: false };


    case "REQUEST_SEARCH_INVENTORY_REPORT":
      return { ...state, isFetchingSearchInventoryLedgerReport: true };
    case "SUCCESS_SEARCH_INVENTORY_REPORT":
      return { ...state, searchInventoryLedgerListReport: action.payload, isFetchingSearchInventoryLedgerReport: false, };
    case "ERROR_SEARCH_INVENTORY_REPORT":
      return { ...state, isFetchingSearchInventoryLedgerReport: false };



    case "REQUEST_SEARCH_SALESMEN_LEDGER":
      return { ...state, isFetchingSearchSalesmenLedger: true };
    case "SUCCESS_SEARCH_SALESMEN_LEDGER":
      return { ...state, searchSalesmenLedgerList: action.payload, isFetchingSearchSalesmenLedger: false };
    case "ERROR_SEARCH_SALESMEN_LEDGER":
      return { ...state, isFetchingSearchSalesmenLedger: false };


    default:
      return state;
  }
};
export default SalesmenLegderReducer;
