import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "./hr-styles.css";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import moment from "moment";
import jsPDF from "jspdf";
import Swal from "sweetalert";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postSalaryData } from "../SalaryManagment/generateSalary.action";
import { GetHRDepartment } from "./HRDetails.actions";
import { setHeading } from "../../../accounts/account.actions";

const GenerateSalary = (props) => {
  const getOverTimeList = [
    { value: 1, label: "Permanent" },
    { value: 2, label: "Temporary" },
  ];
  const [department, setDepartment] = useState("");
  const [ste, setSte] = useState("");
  const [typeId, setTypeId] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeType = (selectedOption) => {
    setSte(selectedOption.label);
    setTypeId(selectedOption.value);
  };
  const [date, setDate] = useState();
  // let listData= props.departmentList.push({
  //    departmentId:"All",
  //   departmentName:"All"
  // })
  let getDepartmentNameList =
    !isEmpty(props.departmentList) &&
    props.departmentList.map((x) => {
      let data = { value: x._id, label: x.department };
      return data;
    });
  !isEmpty(props.departmentList) &&
    getDepartmentNameList.unshift({ value: "All", label: "ALL" });


  const handleChangeDepartment = (selectedOption) => {
    setDepartment(selectedOption.label);
  };
  console.log(date);
  const postSalary = () => {
    props.postSalaryData({
      date: moment(date).format("YYYY-MM"),
      type: ste,
      department: department,

    }, ste, department, handleVisible, handleDangerVisible);
  };
  useEffect(() => {
    props.GetHRDepartment();
    props.setHeading("Generate Salary")

  }, []);
  return (
    <div>
      <Container fluid>
        <div className="main">
          <Row>
            <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group>
                <Form.Label>
                  <b> Select Month </b>
                </Form.Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // disableFuture
                    // label="Select Month"
                    openTo="month"
                    inputFormat="YYYY-MM"
                    views={["year", "month"]}
                    value={date}
                    onChange={(newVal) => (
                      console.dir(newVal),
                      setDate(moment(newVal).format("YYYY-MM"))
                    )}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* </Form.Control> */}
              </Form.Group>
            </Col>

            <Col xl="6" lg="6" md="6" xs="6">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Type:</b>
                </Form.Label>
                <Select
                  // isDisabled={isEmpty(getDepartmentNameList)}
                  placeholder="Select Type..."
                  onChange={handleChangeType}
                  options={getOverTimeList}
                />
              </Form.Group>
            </Col>
            {typeId == 2 ? (
              <Col xl="6" lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Department:</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getDepartmentNameList)}
                    placeholder="Select Department..."
                    onChange={handleChangeDepartment}
                    options={getDepartmentNameList}
                  />
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>

        <div className="sendDiv">
          {ste == "" ? (
            <Button
              // className="sendButton"
              disabled
              // style={{ backgroundColor: "black" }}
              onClick={() => postSalary()}
            >
              Generate Salary Report
            </Button>
          ) : (
            <Button onClick={() => postSalary()}>
              Generate Salary Report
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postSalaryData: (data, type, department, handleVisible, handleDangerVisible) => dispatch(postSalaryData(data, type, department, handleVisible, handleDangerVisible)),
    GetHRDepartment: () => dispatch(GetHRDepartment()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingSalaryData: state.GenerateSalaryReducer.isFetchingSalaryData,
  salaryData: state.GenerateSalaryReducer.salaryData,
  departmentList: state.HrReducer.departmentList,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSalary);
