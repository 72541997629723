import API from "../../../../../global/api";
import jsPDF from "jspdf";
import { sumBy } from "lodash";
import moment from "moment";
export const requestAddExternalIssuance = () => {
  return {
    type: "REQUEST_ADD_EXTERNAL_ISSUANCE",
  };
};
export const successAddExternalIssuance = (data) => {
  return {
    type: "SUCCESS_ADD_EXTERNAL_ISSUANCE",
    payload: data,
  };
};
export const errorAddExternalIssuance = () => {
  return {
    type: "ERROR_ADD_EXTERNAL_ISSUANCE",
  };
};
export const addExternalIssuance = (
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestAddExternalIssuance());
    API.post(`/externalIssuance/`, data)
      .then((res) => {
        dispatch(successAddExternalIssuance(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddExternalIssuance());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetExternalIssuance = () => {
  return {
    type: "REQUEST_GET_EXTERNAL_ISSUANCE",
  };
};
export const successGetExternalIssuance = (data) => {
  return {
    type: "SUCCESS_GET_EXTERNAL_ISSUANCE",
    payload: data,
  };
};
export const errorGetExternalIssuance = () => {
  return {
    type: "ERROR_GET_EXTERNAL_ISSUANCE",
  };
};
export const getExternalIssuance = (data) => {
  return (dispatch) => {
    dispatch(requestGetExternalIssuance());
    API.get(`/externalIssuance/?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetExternalIssuance(data1));
      })
      .catch((error) => {
        dispatch(errorGetExternalIssuance());
      });
  };
};

export const UpdateRequestExternalIssuance = () => {
  return {
    type: "UPDATE_REQUEST_EXTERNAL_ISSUANCE",
  };
};
export const UpdateSuccessExternalIssuance = (data) => {
  return {
    type: "UPDATE_SUCCESS_EXTERNAL_ISSUANCE",
    payload: data,
  };
};

export const UpdateErrorExternalIssuance = () => {
  return {
    type: "UPDATE_ERROR_EXTERNAL_ISSUANCE",
  };
};

export const updateExternalIssuance = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestExternalIssuance());
    API.put(
      `/externalIssuance/${oldData._id}`,
      {
        issuedBy: data.issuedBy,
        // recivingDescription: data.recivingDescription,
        // location: data.location,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessExternalIssuance([data]));
      })
      .catch((error) => dispatch(UpdateErrorExternalIssuance()));
  };
};
export const DeleteRequestExternalIssuance = () => {
  return {
    type: "DELETE_REQUEST_EXTERNAL_ISSUANCE",
  };
};
export const DeleteSuccessExternalIssuance = (id) => {
  return {
    type: "DELETE_SUCCESS_EXTERNAL_ISSUANCE",
    id: id,
  };
};
export const DeleteErrorExternalIssuance = () => {
  return {
    type: "DELETE_ERROR_EXTERNAL_ISSUANCE",
  };
};

export const deleteExternalIssuance = (id) => {
  return (dispatch) => {
    dispatch(DeleteRequestExternalIssuance());
    API.delete(`/externalIssuance/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessExternalIssuance(id));
      })
      .catch((error) => dispatch(DeleteErrorExternalIssuance()));
  };
};

export const pdfExternalIssuance = (data) => {
  return()=>{
  console.log(data,"testtttttttttt")
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "External Issuance Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
    const IssuanceDate = data.date;
    const issuedBy = data.issuedBy;
    const issuedDesc = data.issuanceDescription;
   
    const headers = [
      ["Product NAME", "Quantity", "Rate", "Amount"],
    ];
    const data2 = data.data.map((elt, index) => [
      elt.productLabel,
      elt.quantity,
      elt.productSellingPrice,
      elt.totalSaleValue,
    ]);
    const Total = sumBy(data2, "1");
    const TotalAmount = sumBy(data2, "3");
    let content = {
      startY: 200,
      startX: 10,
      head: headers,
      body: data2,
    };
    doc.setFontSize(20);
    doc.setFont(undefined, "bold");
    doc.text(companyName, 40, 50, 0, 20);
    doc.setFontSize(13);
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(companyAddress, 40, 70, 0, 20);
    doc.text(`Ph: ${companyPhone}`, 40, 90, 0, 20);
    doc.setFontSize(16);
    doc.setFont(undefined, "bold");
    doc.text(title, 240, 120);
    doc.setFontSize(14);
    doc.setFont(undefined, "normal");
    doc.text(`Issued Date: ${IssuanceDate}`,40,140)
    doc.text(`Issued By: ${issuedBy}`,40,160)
    doc.text(`Description: ${issuedDesc}`,40,180)
    doc.autoTable(content);
    doc.text(
      `Total Quantity: ${Total.toFixed(2)}`,
      400,
      doc.lastAutoTable.finalY + 20,
      0,
      20
    );
    doc.text(
      `Total Amount: ${TotalAmount.toFixed(2)}`,
      400,
      doc.lastAutoTable.finalY + 40,
      0,
      20
    );
    doc.setProperties(
      `External Issuance Report ${moment(new Date()).format("YYYY-MM-DD")}`
    );
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  }
};

export const requestGetExternalIssuanceByDate = () => {
  return {
    type: "REQUEST_GET_EXTERNAL_ISSUANCE_BY_DATE",
  };
};
export const successGetExternalIssuanceByDate = (data) => {
  return {
    type: "SUCCESS_GET_EXTERNAL_ISSUANCE_BY_DATE",
    payload: data,
  };
};
export const errorGetExternalIssuanceByDate = () => {
  return {
    type: "ERROR_GET_EXTERNAL_ISSUANCE_BY_DATE",
  };
};
export const getExternalIssuanceByDate = (data) => {
  return (dispatch) => {
    dispatch(requestGetExternalIssuanceByDate());
    API.post(`/externalIssuance/external_issuance_with_dates`,data)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetExternalIssuanceByDate(data1));
      })
      .catch((error) => {
        dispatch(errorGetExternalIssuanceByDate());
      });
  };
};

export const UpdateRequestExternalIssuanceProduct = () => {
  return {
    type: "UPDATE_REQUEST_EXTERNAL_ISSUANCE_PRODUCT",
  };
};
export const UpdateSuccessExternalIssuanceProduct = (data) => {
  return {
    type: "UPDATE_SUCCESS_EXTERNAL_ISSUANCE_PRODUCT",
    payload: data,
  };
};

export const UpdateErrorExternalIssuanceProduct = () => {
  return {
    type: "UPDATE_ERROR_EXTERNAL_ISSUANCE_PRODUCT",
  };
};

export const updateExternalIssuanceProduct = (data, oldData,rowData,updateTableQuery) => {
  console.log(data,rowData,oldData,"hmhmmmmmm")
  return (dispatch) => {
    dispatch(UpdateRequestExternalIssuanceProduct());
    API.put(
      `/externalIssuance/update_ex_issuance_product/${rowData._id}`,
      {
        _id: data._id,
        product:data.product,
        quantity:Number(data.quantity),
        productSellingPrice:Number(data.productSellingPrice),
        difference:Number(data.quantity)-Number(oldData.quantity)
        // recivingDescription: data.recivingDescription,
        // location: data.location,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessExternalIssuanceProduct([data]));
        updateTableQuery()
      })
      .catch((error) => dispatch(UpdateErrorExternalIssuanceProduct()));
  };
};

export const DeleteRequestExternalIssuanceProduct = () => {
  return {
    type: "DELETE_REQUEST_EXTERNAL_ISSUANCE_PRODUCT",
  };
};
export const DeleteSuccessExternalIssuanceProduct = (id) => {
  return {
    type: "DELETE_SUCCESS_EXTERNAL_ISSUANCE_PRODUCT",
    id: id,
  };
};
export const DeleteErrorExternalIssuanceProduct = () => {
  return {
    type: "DELETE_ERROR_EXTERNAL_ISSUANCE_PRODUCT",
  };
};

export const deleteExternalIssuanceProduct = (data,rowData,updateTableQuery) => {
  return (dispatch) => {
    dispatch(DeleteRequestExternalIssuanceProduct());
    API.post(`/externalIssuance/delete_ex_issuance_product/${rowData._id}`,{
      product:data.product,
      _id:data._id,
      quantity:Number(data.quantity),

    })
      .then((res) => {
        dispatch(DeleteSuccessExternalIssuanceProduct(rowData._id));
        updateTableQuery()
      })
      .catch((error) => dispatch(DeleteErrorExternalIssuanceProduct()));
  };
};
