import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../il8n'// Ensure to import the i18n configuration

function Urdu() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    console.log(lng,'llllllllllllllllllllllllllll')
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <h1>{t('welcome')}</h1>
      <p>{t('hello_world')}</p>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('ur')}>اردو</button>
      <button onClick={() => changeLanguage('ar')}>Arabic</button>
    </div>
  );
}

export default Urdu;
