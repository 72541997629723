import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, subtract, find, sumBy, divide, multiply, add } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getArea } from "../AddArea/Area.action";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { getProductData } from "../AddProduct/Product.action";
import { getShop, getShopByArea } from "../AddShop/Shop.action";
import { addOrder } from "./OrderBooking.action";
import moment from "moment";
import "../add-details.css";

const EditOrderBooking = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [shopName, setShopName] = useState("");
  const [shopId, setShopId] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState();
  const [subTotal, setSubTotal] = useState();
  const [tax, setTax] = useState(0);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [filteredShopList, setFilteredShopList] = useState([]);
  const [inputField, setInputField] = useState([
    {
      productLabel: "",
      quantity: "",
      productSellingPrice: 0,
      productTotalAmount: 0,
      code: "",
      avaliableQuantity: "",
    },
  ]);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadOrder() {
    props.addOrder(
      {
        areaId: areaId,
        shopId: shopId,
        subTotal: subTotal,
        discount: totalDiscount,
        items: inputField,
        // tax: tax,
        grandTotal: grandTotal,
        date: date
      },
      handleVisible,
      handleDangerVisible
    );
    setAreaName("");
    setAreaId("");
    setShopName("");
    setShopId("");
    setGrandTotal("");
    setSubTotal("");
    setTax("");
    setTotalDiscount("");
    setInputField([
      {
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
        code: "",
        avaliableQuantity: "",
      },
    ]);
  }
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
    props.getShopByArea(selectedOption.value);
  };
  const AreaListData =
    !isEmpty(props.areaDataList) &&
    props.areaDataList.map((x) => {
      let data = { value: x._id, label: x.areaName };
      return data;
    });
  const handleChangeShop = (selectedOption) => {
    setShopId(selectedOption.value);
    setShopName(selectedOption.label);
  };
  const ShopListData =
    // !isEmpty(props.shopDataList) &&
    // props.shopDataList.map((x) => {
    //   let data = { value: x._id, label: x.shopName };
    //   return data;
    // });
    !isEmpty(props.shopsByAreaList) &&
    props.shopsByAreaList.map((x) => {
      let data = { value: x._id, label: x.shopName };
      return data;
    });
  console.log(props.shopsByAreaList, "Shopppppp");
  const product = props.productDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
      code: x.code,
      avaliableQuantity: x.productBalance,
    };
    return data;
  });
  const handleChange = (e, index, type) => {
    console.log(e, index, type, "test");
    const list = [...inputField];
    if (type == "intquantity") list[index]["quantity"] = Number(e.target.value)<=list[index]["avaliableQuantity"]?Number(e.target.value):list[index]["avaliableQuantity"];
    let z = find(props.productDataList, (x) => x._id == e.value);
    console.log(z, "zzzzzzzzzzzzzzzzz");
    if (type == "intname") {
      list[index]["product"] = e.value;
      list[index]["productLabel"] = e.label;
      list[index]["code"] = e.code;
      list[index]["avaliableQuantity"] = e.avaliableQuantity;
      list[index]["quantity"] = 1
    }

    if (type == "intname")
      list[index]["productSellingPrice"] = Number(z.salePrice);
    if (type == "productSellingPrice")
      list[index]["productSellingPrice"] = Number(e.target.value);

    list[index]["productTotalAmount"] =
      list[index]["productSellingPrice"] * list[index]["quantity"];

    setSubTotal(sumBy(list, "productTotalAmount"));
    setGrandTotal(sumBy(list, "productTotalAmount") - totalDiscount)
    setInputField(list);
  };
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        code: "",
        avaliableQuantity: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        productTotalAmount: 0,
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
    setSubTotal(
      // list.length === 1
      //   ? list[0].productTotalAmount:
      sumBy(list, "productTotalAmount")
    );
    setGrandTotal(
      // list.length === 1
      // ? list[0].productTotalAmount
      // : 
      sumBy(list, "productTotalAmount") - totalDiscount
    )
  };
  // useEffect(() => {
  //   const calculateGrandTotal = () => {
  //     const discountValue = multiply(subTotal, divide(totalDiscount, 100));
  //     const totalAfterDiscount = subtract(subTotal, discountValue);
  //     const taxValue = multiply(totalAfterDiscount, divide(tax, 100));
  //     const finalTotal = add(totalAfterDiscount, taxValue);
  //     setGrandTotal(finalTotal);
  //   };

  //   calculateGrandTotal();
  // }, [subTotal, totalDiscount, tax]);
  console.log(props.orderDetailById,"orderrrrrrrrrrrrrrrr")
  useEffect(() => {
    props.setHeading("Edit Order Booking");
    // props.getArea();
    // props.getShop();
    props.getProductData();
    // props.getShopById();
  }, []);
  return (
    <>
      {props.isFetchingAreaData || props.isFetchingProduct || props.isFetchingShopsByArea||props.isFetchingOrderDetailById ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col >
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={!isEmpty(props.orderDetailById)&&props.orderDetailById[0].date}
                    disabled
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => setDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col >
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Area *</b>
                  </Form.Label>
                  <Select
                    isDisabled
                    value={{ label: !isEmpty(props.orderDetailById)&&props.orderDetailById[0].areaId?.areaName}}
                    
                    placeholder="Select Area..."
                    onChange={(e) => {
                      handleChangeArea(e);
                    }}
                    options={AreaListData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Shop *</b>
                  </Form.Label>
                  <Select
                    isDisabled
                    value={{label:!isEmpty(props.orderDetailById)&&props.orderDetailById[0].shopId?.shopName}}
                    placeholder="Select Shop..."
                    onChange={handleChangeShop}
                    options={ShopListData}
                  />
                </Form.Group>
              </Col>

            </Row>

            {!isEmpty(props.orderDetailById)&&props.orderDetailById[0].items.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="4">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.productLabel }}
                          // id="inventoryId"
                          placeholder="Select Product..."
                          // name="inventoryId"
                          isDisabled={isEmpty(shopName)}
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={product}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(inputField, (x) => x.product == option.value)
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Avaliable Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled
                          placeholder="Quantity...."
                          name="Quantity"
                          value={item.avaliableQuantity}
                          min={0}
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled={isEmpty(shopName)}
                          placeholder="Quantity...."
                          name="Quantity"
                          value={item.quantity}
                          min={0}
                          onChange={(e) => handleChange(e, i, "intquantity")}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group>
                        <Form.Label>
                          <b>Price *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled
                          placeholder="Product Price"
                          name="Product Price"
                          value={item.productSellingPrice}
                          min={0}
                          onChange={(e) =>
                            handleChange(e, i, "productSellingPrice")
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="2" lg="2" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Total Price</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Total Price...."
                          name="TotalPrice"
                          disabled
                          value={item.productTotalAmount}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button
                            title="Click to Add Product"
                            onClick={handleAddInput}
                            style={{
                              marginRight: "1%",
                              color: "white",
                              float: 'right'
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button
                            title="Click to Remove Product"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              marginRight: "1%",
                              color: "white", float: 'right'
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <Row>
              <Col >
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Sub Total </b>
                  </Form.Label>
                  <Form.Control
                    value={subTotal}
                    disabled
                    type="number"
                  // onChange={(e) => {
                  //   setSubTotal(e.target.value);
                  // }}
                  />
                </Form.Group>
              </Col>
              <Col >
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Discount</b>
                  </Form.Label>
                  <Form.Control
                    value={totalDiscount}
                    disabled={inputField[0].productLabel == ""}
                    type="number"
                    min={0}
                    max={subTotal}
                    onChange={(e) => {
                      e.target.value < subTotal && setTotalDiscount(e.target.value);
                      e.target.value < subTotal && setGrandTotal(subTotal - e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col >
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Grand Total</b>
                  </Form.Label>
                  <Form.Control
                    value={grandTotal}
                    disabled
                    type="number"
                  // onChange={(e) => {
                  //   setGrandTotal(e.target.value);
                  // }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {console.log(inputField, "aaaaaaaaaa")}
              {/* <Button onClick={uploadOrder}>Save</Button> */}
              {isEmpty(areaId) || isEmpty(shopId) ||
                inputField[0].productLabel == ""
                ? (
                  <Button disabled>Save</Button>
                ) : (
                  <Button onClick={uploadOrder}>Save</Button>
                )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArea: () => dispatch(getArea()),
    getShop: () => dispatch(getShop()),
    addOrder: (data, handleVisible, handleDangerVisible) =>
      dispatch(addOrder(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    getProductData: () => dispatch(getProductData()),
    getShopByArea: (id) => dispatch(getShopByArea(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderBookingList: state.OrderBookingReducer.orderBookingList,
  isAddingOrderBooking: state.OrderBookingReducer.isAddingOrderBooking,
  shopsByAreaList: state.ShopReducer.shopsByAreaList,
  isFetchingShopsByArea: state.ShopReducer.isFetchingShopsByArea,
  shopDataList: state.ShopReducer.shopDataList,
  isFetchingShopData: state.ShopReducer.isFetchingShopData,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  orderDetailById: state.OrderBookingReducer.orderDetailById,
  isFetchingOrderDetailById: state.OrderBookingReducer.isFetchingOrderDetailById,
});
export default connect(mapStateToProps, mapDispatchToProps)(EditOrderBooking);
