import React, { useEffect, useState } from "react";
import {
  getEmployeeData,
  getEmployeeDataWithStatus,
} from "../AddDetails/HRDetails.actions";
import Swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { isEmpty, isNull, uniqBy } from "lodash";
import { connect } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";

import {
  addSalaryIncrement,
  getSalaryIncrement,
  deleteSalaryIncrement,
  getSalaryIncrementById,
} from "./salary.actions";
import { getSalaryById } from "../AddDetails/HRDetails.actions";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment/moment";
import { findAllByAltText } from "@testing-library/react";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";

const Increment = (props) => {
  console.log("props.salaryListById", props.salaryListById);

  const [employeeId, setEmployeeId] = useState(0);
  console.log("employeeId", employeeId);

  const [employeeName, setEmployeeName] = useState("");
  console.log("employeeName", employeeName);

  let name = "";
  console.log("name", name);

  const [incrementDecrement, setIncrementDecrement] = useState("Untouched");

  const [value, setValue] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [thisDisables1Field, setThisDisables1Field] = useState("");
  console.log("!isEmpty(props.salaryListById)", !isEmpty(props.salaryListById));

  const totalSalary =
    !isEmpty(props.salaryListById) &&
    Number(props.salaryListById.medicialAllowance) +
      Number(props.salaryListById.petrolAllowance) +
      Number(props.salaryListById.specialAllowance) +
      Number(props.salaryListById.otherAllowance) +
      Number(props.salaryListById.accomodationAllowance) +
      Number(props.salaryListById.basicSalary);

  // console.log("percentage !== 'Untouched'", (percentage !== 'Untouched'), "isEmpty(props.salaryListById)", isEmpty(props.salaryListById))

  const [incrementValue, setIncrementValue] = useState(0);
  const [decrementValue, setDecrementValue] = useState(0);

  const currentDate = new Date().toLocaleDateString();
  console.log("currentDate", currentDate);

  const [AlertVisible, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data }) : [];
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const getEmployeeList =
    !isEmpty(props.activeEmployeeList) &&
    props.activeEmployeeList.map((x) => {
      let data = {
        value: x._id,
          label: `${x.employeeName}/${x.fatherName}/${x.cnic}`,
      };
      return data;
    });
  // !isEmpty(getEmployeeList) &&
  //   getEmployeeList.push({ value: "All", label: "All" });

  console.log("props.employeeList", props.employeeList);

  const employeeList = props.employeeList;

  // const incDecData = props.IncDecDataGet.reverse();

  // console.log('incDecData', incDecData)
  console.log("props.IncDecDataGet", props.IncDecDataGet);

  const getincrementDecrementList = [
    { value: 1, label: "Increment" },
    { value: 0, label: "Decrement" },
  ];

  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    selectedOption.value === "All"
      ? handleGetIncrement()
      : handleGetIncrementById(selectedOption.value);
  };

  const handleChangeIncDec = (selectedOption) => {
    setIncrementDecrement(selectedOption.label);
  };

  const handleGetIncrement = () => {
    props.getSalaryIncrement();
  };

  const handleGetIncrementById = (id) => {
    console.log("id", id);
    props.getSalaryIncrementById(id);
    handleGetSalaryById(id);
  };
  const handleGetSalaryById = (id) => {
    console.log("id", id);
    props.getSalaryById(id);
  };
  const handleSave = () => {
    console.log("moment", moment(currentDate).format("DD-MM-YYYY"));

    props.addSalaryIncrement(
      {
        employeeId: employeeId,
        type: incrementDecrement,
        value: value,
        date: moment().format("YYYY-MM-DD"),
      },
      handleVisible,
      handleDangerVisible
    );
    props.getSalaryIncrementById(employeeId);
  };

  const clearStates = () => {
    setEmployeeId(0);
    setEmployeeName();
    setIncrementDecrement();
    setValue(0);
    setPercentage(0);
    setThisDisables1Field("");
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Salary Modificatiion successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Salary Modificatiion Failed...",
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    // props.getEmployeeData();
    props.getEmployeeDataWithStatus(1);
    props.setHeading("Salary Increment Add")
    //  props.getSalaryIncrement();
    // props.getAttendance();
  }, []);
  return (
    <Container fluid>
      {props.isFetchingEmployeeData ||
      props.isFetchingInsDec ||
      props.isAddingIncDec ||
      props.isDeletingIncDec ? (
        // || (props.isFetchingSalaryDataById && isEmpty(props.salaryListById))
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <div className="mainHr">
          <Row>
            <Col lg="6" md="6" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Employee:</b>
                </Form.Label>
                <Select
                  isDisabled={isEmpty(getEmployeeList)}
                  placeholder="Select Employee..."
                  onChange={handleChangeEmployee}
                  // onBlur={handleGetInc}
                  options={getEmployeeList}
                />
              </Form.Group>
            </Col>
            <Col lg="6" md="6" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Total Salary:</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Basic Salary...."
                  value={
                    !isEmpty(props.salaryListById)
                      ? totalSalary
                      : // Number(props.salaryListById[0].medicial_allowance) +
                        // Number(props.salaryListById[0].petrol_allowance) +
                        // Number(props.salaryListById[0].special_allowance) +
                        // Number(props.salaryListById[0].other_allowance) +
                        // Number(props.salaryListById[0].accomodation_allowance) +
                        // Number(props.salaryListById[0].basic_salary)
                        "-"
                  }
                  disabled={true}
                />
              </Form.Group>
            </Col>
            {/* </Row>
                    <div className="sendDiv">
                        <Button className="sendButton" onClick={() => { handleGetIncrement(); }}>Save</Button>
                    </div>
                    <Row> */}
          </Row>
          <Row>
            <Col lg="4" md="4" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Increment/Decrement:</b>
                </Form.Label>
                <Select
                  placeholder="Increment/Decrement..."
                  onChange={handleChangeIncDec}
                  options={getincrementDecrementList}
                />
              </Form.Group>
            </Col>
            <Col lg="4" md="4" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Value:</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Value...."
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setThisDisables1Field("IncrementValue");
                    setPercentage((e.target.value / totalSalary) * 100);

                    console.log("value", value);
                  }}
                  disabled={
                    thisDisables1Field === "IncrementPercentage" ||
                    isEmpty(props.salaryListById) ||
                    incrementDecrement === "Untouched"
                  }
                />
              </Form.Group>
            </Col>
            <Col lg="4" md="4" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Percentage %:</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Percentage...."
                  step=".01"
                  value={percentage}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                    setThisDisables1Field("IncrementPercentage");
                    setValue((e.target.value * totalSalary) / 100);

                    // console.log('percentage', percentage);
                  }}
                  disabled={
                    thisDisables1Field === "IncrementValue" ||
                    isEmpty(props.salaryListById) ||
                    incrementDecrement === "Untouched"
                  }
                />
              </Form.Group>
            </Col>
            <Col lg="4" md="4" xs="12">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Date:</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Decrement"
                  defaultValue={currentDate}
                  placeholder="Date...."
                  disabled
                />
              </Form.Group>
            </Col>
            {/* <Col lg="4" md="4" xs="12">
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Select Status:</b></Form.Label>
                                <Select
                                    // defaultValue={{ label:  activeStatus ? "Active" : "Non Active" }}
                                    placeholder="Select Status..."
                                    onChange={handleChangeStatus}
                                    options={getStatusList}

                                />
                            </Form.Group>
                        </Col> */}
          </Row>
          <div className="sendDiv">
          {
                isEmpty(employeeName) ||
                incrementDecrement === "Untouched" ||
                value === 0 ||
                isEmpty(props.salaryListById)
              ?
            <Button
              onClick={() => {
                handleSave();
              }}
              disabled
            >
              Save
            </Button>:
            <Button
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>}
          </div>
        </div>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    addSalaryIncrement: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSalaryIncrement(data, handleVisible, handleDangerVisible)),
    getSalaryIncrement: () => dispatch(getSalaryIncrement()),
    getSalaryIncrementById: (employee_id) =>
      dispatch(getSalaryIncrementById(employee_id)),
    deleteSalaryIncrement: (data) => dispatch(deleteSalaryIncrement(data)),
    getSalaryById: (id) => dispatch(getSalaryById(id)),
    getEmployeeDataWithStatus: (status) =>
      dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // employeeList: state.HrReducer.employeeList,

  isAddingIncDec: state.SalaryReducer.isAddingIncDec,

  isFetchingIncDec: state.SalaryReducer.isFetchingIncDec,
  IncDecDataGet: state.SalaryReducer.IncDecDataGet,
  isDeletingIncDec: state.SalaryReducer.isDeletingIncDec,
  isFetchingIncDecById: state.SalaryReducer.isFetchingIncDecById,

  isFetchingSalaryDataById: state.HrReducer.isFetchingSalaryDataById,
  salaryListById: state.HrReducer.salaryListById,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
  // increment: state.SalaryReducer.increment,
  // decrement: state.SalaryReducer.decrement,
  // activeStatus: state.SalaryReducer.activeStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(Increment);
