import API from "../../../../../global/api";
// src\global\api.js
import { get, isEmpty } from 'lodash';
// import logo from "../../../../Images/logo.png";
import jsPDF from 'jspdf';
import moment from 'moment';
import { Link } from "react-router-dom";
import { sumBy } from "lodash";
import { handleErrorVisible } from "../../../../../global/globalFunctions";

//  Add Opening

export const requestAddDayOpening = () => {
    return {
        type: "REQUEST_ADD_DAY_OPENING",
    };
};
export const successAddDayOpening = (data) => {
    return {
        type: "SUCCESS_ADD_DAY_OPENING",
        payload: data,
    };
};
export const errorAddDayOpening = () => {
    return {
        type: "ERROR_ADD_DAY_OPENING",
    };
};

export const addDayOpening = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDayOpening());
        API.post(`/dayOpen/add_day_open`, data).then((res) => {
            // dispatch(getWarehouseData());
            dispatch(successAddDayOpening(data));
            console.log("i am in success");
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddDayOpening());
            handleDangerVisible(error.response.data.message);
        })
    };
};

//  Get Opening

export const requestGetDayOpening = () => {
    return {
        type: "REQUEST_GET_DAY_OPENING",
    };
};
export const successGetDayOpening = (data) => {
    return {
        type: "SUCCESS_GET_DAY_OPENING",
        payload: data,
    };
};
export const errorGetDayOpening = () => {
    return {
        type: "ERROR_GET_DAY_OPENING",
    };
};

export const getDayOpening = () => {
    return (dispatch) => {
        dispatch(requestGetDayOpening());
        API.get(`/dayOpen/salesmen_wise_booking`).then((res) => {
            let data = res.data;
            // dispatch(getWarehouseData());
            dispatch(successGetDayOpening(data));
            console.log("i am in success");
            //handleVisible();
        }).catch((error) => {
            console.log(error, "rrrrrrrrrrrrrr")
            dispatch(errorGetDayOpening());
            handleErrorVisible(error.response.data.message);
        })
    };
};
//  Add Closing

export const requestAddDayClosing = () => {
    return {
        type: "REQUEST_ADD_DAY_CLOSING",
    };
};
export const successAddDayClosing = (data) => {
    return {
        type: "SUCCESS_ADD_DAY_CLOSING",
        payload: data,
    };
};
export const errorAddDayClosing = () => {
    return {
        type: "ERROR_ADD_DAY_CLOSING",
    };
};

export const addDayClosing = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDayClosing());
        API.post(`/dayOpen/closing_day`, data).then((res) => {
            // dispatch(getWarehouseData());
            dispatch(getDayOpening())
            dispatch(successAddDayClosing(data));
            console.log(res, "i am in success");
            handleVisible(res.data.message);
            // dispatch(getDayOpening())
        }).catch((error) => {
            dispatch(errorAddDayClosing());
            handleDangerVisible(error.response.data.message);
        })
    };
};
//salemen closing

export const requestAddDayClosingBySalemen = () => {
    return {
        type: "REQUEST_ADD_DAY_CLOSING_BY_SALEMEN",
    };
};
export const successAddDayClosingBySalemen = (data) => {
    return {
        type: "SUCCESS_ADD_DAY_CLOSING_BY_SALEMEN",
        payload: data,
    };
};
export const errorAddDayClosingBySalemen = () => {
    return {
        type: "ERROR_ADD_DAY_CLOSING_BY_SALEMEN",
    };
};

export const addDayClosingBySalemen = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDayClosingBySalemen());
        API.post(`/dayOpen/salesmen_wise_closing`, data).then((res) => {
            // dispatch(getWarehouseData());
            dispatch(successAddDayClosingBySalemen(data));
            console.log(res, "i am in success");
            handleVisible(res.data.message);
            // dispatch(getDayOpening())
        }).catch((error) => {
            dispatch(errorAddDayClosingBySalemen());
            handleDangerVisible(error.response.data.message);
        })
    };
};
export const setEditFields = (data) => {
    console.log('data', data)
    return {
        type: "SET_EDIT_FIELDS",
        payload: data
    };
}
