import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Navbar, Breadcrumb } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendorData } from "../Vendor/Vendor.action";
import { generateVendorReportPDF } from "./Receiving.action";
import { setHeading } from "../../../accounts/account.actions";
import IconMenu from "../../../drawer/IconMenu";
import { Link } from "react-router-dom";
// import { addReceiving, getPDFById, getReceiving } from "./Receiving.action";

const PurchaseReportWithDateFilter = (props) => {
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  function uploadPurchase() {
    //   props.getReceivingPdf(ReceivingId);
    props.generateVendorReportPDF({
      startDate:startDate,
      endDate:endDate
    });
    setStartDate("")
    setEndDate("")
  }
  // vendorDataList
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  useEffect(() => {
    props.setHeading("Purchase Report");
    // props.getVendorData();
  }, []);

  return (
    <>
      <Navbar style={{ backgroundColor: '#81abd3' }}  >
        <Container>
          <Navbar.Brand style={{ color: 'white' }}><b>Purchase Report</b></Navbar.Brand>
          <IconMenu />
        </Container>
      </Navbar>
      {props.isFetchingVendor ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingReceiving ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Purchase Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col >
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                  value={startDate}
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col >
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv" style={{marginTop:15}}>
              {startDate == '' || endDate == '' ? (
                <Button disabled>Generate PDF</Button>
              ) : (
                <Button onClick={uploadPurchase}>Generate PDF</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getReceiving:()=>dispatch(getReceiving()),
    // getReceivingPdf:(id)=>dispatch(getPDFById(id)),
    getVendorData: () => dispatch(getVendorData()),
    generateVendorReportPDF: (data) =>
      dispatch(generateVendorReportPDF(data)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // ReceivingList:state.RecivingReducer.ReceivingList,
  // isFetchingReceiving:state.RecivingReducer.isFetchingReceiving,
  purchaseVendorPDFList: state.RecivingReducer.purchaseVendorPDFList,
  isFetchingPurchaseVendorPDF:
    state.RecivingReducer.isFetchingPurchaseVendorPDF,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseReportWithDateFilter);
