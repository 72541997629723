const initialState = {
  externalReceivingList: [],
  isAddingExternalReceiving: false,
  externalReceivingDataList: [],
  isFetchingExternalReceivingList: false,
  isFetchingExternalReceivingListWithDate:false,
  externalReceivingListWithDate:[],
  tableShow:false
};

const ExternalReceivingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_EXTERNAL_RECEIVING":
      return { ...state, isAddingExternalReceiving: true };
    case "SUCCESS_ADD_EXTERNAL_RECEIVING":
      return {
        ...state,
        externalReceivingList: action.payload,
        isAddingExternalReceiving: false,
      };
    case "ERROR_ADD_EXTERNAL_RECEIVING":
      return { ...state, isAddingExternalReceiving: false };
    case "REQUEST_GET_EXTERNAL_RECEIVING":
      return { ...state, isFetchingExternalReceivingList: true };
    case "SUCCESS_GET_EXTERNAL_RECEIVING":
      return {
        ...state,
        externalReceivingDataList: action.payload.data,
        isFetchingExternalReceivingList: false,
      };
    case "ERROR_GET_EXTERNAL_RECEIVING":
      return { ...state, isFetchingExternalReceivingList: false };
      case "REQUEST_GET_EXTERNAL_RECEIVING_BY_DATE":
        return { ...state, isFetchingExternalReceivingListWithDate: true,tableShow:false };
      case "SUCCESS_GET_EXTERNAL_RECEIVING_BY_DATE":
        return {
          ...state,
          externalReceivingListWithDate: action.payload.data,
          isFetchingExternalReceivingListWithDate: false,
          tableShow:true
        };
      case "ERROR_GET_EXTERNAL_RECEIVING_BY_DATE":
        return { ...state, isFetchingExternalReceivingListWithDate: false,tableShow:false };
    default:
      return state;
  }
};
export default ExternalReceivingReducer;
