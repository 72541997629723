import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import {
  deleteCategory,
  getCategoryData,
  updateCategory,
  updateCategoryStatusApprove,
  updateCategoryStatusDisapprove,
} from "./Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";
const CategoryTableList = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    console.log(
      permissionsType,
      permissionsName,
      "typeeeeeeeeeeeeeeeeeeeeeeeee"
    );
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const categoryData = isEmpty(props.categoryList) ? [] : props.categoryList;

  useEffect(() => {
    // props.getCategoryData();

    props.setHeading("Category List");
  }, []);

  return (
    <>
      {props.isFetchingcategoryList ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Category Details"
              columns={[
                {
                  title: "WareHouse Name",
                  field: "wareHouseId.wareHouseName",
                  editable: "never",
                },
                //  { title: 'Category Code', field: 'key', editable: 'never' },
                { title: "Category Name", field: "name" },
                // { title: 'Image', field: 'image', editable:'never',render: item => <img src={item.image} alt="no image" border="3" height="100" width="100" />},
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/categories?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Category Edit"),
                isDeleteHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Category Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateCategory(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    console.log(oldData, "hmh");
                    setTimeout(() => {
                      resolve();
                      props.deleteCategory(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryData: () => dispatch(getCategoryData()),
    deleteCategory: (_Id) => dispatch(deleteCategory(_Id)),
    updateCategory: (newData, oldData) =>
      dispatch(updateCategory(newData, oldData)),
    updateStatusApprove: (newData, oldData) =>
      dispatch(updateCategoryStatusApprove(newData, oldData)),
    updateStatusDisApprove: (newData, oldData) =>
      dispatch(updateCategoryStatusDisapprove(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  categoryList: state.CategoryReducer.categoryList,
  isFetchingcategoryList: state.CategoryReducer.isFetchingcategoryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTableList);
