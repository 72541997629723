import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter } from "lodash";
import { getEmployeeData, addSTAccount, getEmployeeDataWithStatus, addSTReciving } from "./HRDetails.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import Swal from 'sweetalert';
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { setHeading } from "../../../accounts/account.actions";

const STReciving = (props) => {

  const [accountNo, setaccountNo] = useState();
  const [selectedAccount, setSelectedAccount] = useState('');
  const [cashAccountTitle, setCashAccountTitle] = useState();
  const [cashAccountTitleKey, setCashAccountTitleKey] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [employeeId, setEmployeeId] = useState('');
  const [descriptionLT, setLTDescription] = useState();
  const [amount, setAmount] = useState('');
  const [deduction, setDeduction] = useState('');
  const [accType, setAccType] = useState("");
  const [date, setDate] = useState('');
  const [stadv, setStAdv] = useState();
  const [voucher, setVoucher] = useState();
  const [datestate, setDatestate] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Something Went Wrong!...",
      icon: "error",
      button: "Ok",
    });
  }
  console.log(datestate, "sssssssssssssssss");

  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => {
    let data = {
      value: x._id,
      label: `${x.employeeName}/${x.fatherName}/${x.cnic}`,
    }; return data
  })

  const clearStates = () => {
    setEmployeeId('');
    setAmount('');
    setDeduction('');
    setDate('');
  }

  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label: x.levelFourData.allLevlKey + "/ " + x.levelFourData.levelFourName,
      };
      return data;
    });


  const cash = props.ltAccount;

  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
    setEmployeeName(selectedOption.label);
    console.log(selectedOption.value, selectedOption.label, 'dddd')
    setStAdv(find(props.activeEmployeeList, x => x._id == selectedOption.value).stAdv)
  };
  useEffect(() => {
    //props.getLTAccount();
    props.setHeading("Receive Short Term Advance")
    props.getEmployeeDataWithStatus();
  }, []);
  const SaveSTAccount = () => {
    props.addSTReciving({
      employeeId: employeeId,
      accType: accType,
      description: descriptionLT,
      creditValue: amount,
      //short_term_data_deduction_value: deduction,
      date: date,
    }, handleVisible, handleDangerVisible);
    //props.resetFunction();
    console.log(stadv, "adv")
  };
  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>

              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Select Employee</Form.Label>
                      <Select
                        //value={name}
                        placeholder="Select Employee..."
                        onChange={handleChangeEmployee}
                        options={getEmployeeList}
                        isDisabled={isEmpty(props.activeEmployeeList)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Method</Form.Label>
                      <Select
                        // isDisabled={isEmpty(getDepartmentNameList)}
                        placeholder="Select Method..."
                        onChange={(selectedOption) => { setAccType(selectedOption.label) }}
                        options={[{ value: "1", label: "Cash" }, { value: "2", label: "Bank" }]}
                      // value={{
                      //   value: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].levelFiveId : STAccounts.cashId, /*Sometimes the indexing is opposite, then both values get swapped*/
                      //   label: (STAccounts.cashId === "" && !isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].levelFiveName : STAccounts.cashname
                      // }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        id="descriptionID"
                        name="voucherDescription"
                        //value={values.voucherDescription}
                        onBlur={(e) => setLTDescription((e.target.value).toUpperCase())}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        //value={values.voucherAmount}
                        //maxValue={props.employeeList.lt_adv}

                        disabled={true}
                        value={stadv}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        //value={values.voucherAmount}
                        value={amount}
                        max={stadv}
                        onChange={
                          (e) => Number(e.target.value) <= stadv && setAmount(e.target.value)
                        }

                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12" sm="12" className="text-center">
                  {console.log((employeeId === '') || (amount === '') || (deduction === '') || (date === ''), "STAdvance")}
                  {((employeeId === '') || (amount === '') || (date === '')) ? <Button
                    disabled size="large" onClick={SaveSTAccount}>Save</Button>
                    : <Button onClick={SaveSTAccount}>Save</Button>}
                </Col>
              </Row>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    //getLTAccount: () => dispatch(getLTAccount()),
    getEmployeeData: () => dispatch(getEmployeeData()),
    addSTReciving: (data, handleVisible, handleDangerVisible) => dispatch(addSTReciving(data, handleVisible, handleDangerVisible)),
    // addCashPayment: (data, handleVisible, handleDangerVisible) => dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    // submitCashPayment: (data) => dispatch(submitCashPayment(data)),
    // deleteCashPayment: (id) => dispatch(deleteCashPayment(id)),
    // resetFunction: () => dispatch(resetFunction()),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  ltAccount: state.HrReducer.ltAccount,
  isFetchingLTAccount: state.accountsLevelReducer.isFetchingLTAccount,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  addSTAccount: state.HrReducer.addSTAccount,


  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,

});

export default connect(mapStateToProps, mapDispatchToProps)(STReciving);
