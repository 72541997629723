import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { addHRDepartment, GetHRDepartment, getHRDivision, updateHRDepartment, deleteHRDepartment } from "./HRDetails.actions";
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const DepartmentList = (props) => {
    const departmentData = isEmpty(props.departmentList) ? [] : props.departmentList.reverse();
    const tableRef = useRef();
    function updateTableQuery() {
      tableRef.current.onQueryChange()
    };
  
    useEffect(() => {
        // props.GetHRDepartment();
        props.setHeading("Department List")
    }, []);
    return (
        <>
            {(props.isFetchingDepartmentData ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>

                                <MaterialTable
                                    title="Departments"
                                    tableRef={tableRef}
                                    columns={[
                                        // {title:'Department Id',field:"departmentId",render:rowData =>(rowData.division_id+"-"+rowData.departmentId)},
                                        { title: 'Division Name', field: 'divisionDetails.name', editable: 'never' },
                                        { title: 'Department Name', field: 'department' },
                                        { title: 'Department Description', field: 'description' },
                                    ]}
                                    // data={departmentData}
                                    data={(query) =>
                                        new Promise((resolve, reject) => {
                                          let url = `${baseURL}/department?`;
                                          url += "limit=" + query.pageSize;
                                          url += "&page=" + query.page;
                                          url += "&search=" + query.search;
                                          fetch(url, {
                                            method: "GET",
                                            headers: headers,
                                          })
                                            .then((response) => response.json())
                                            .then((result) => {
                                              resolve({
                                                data: result.data,
                                                page: result.page,
                                                totalCount: result.total_results,
                                              });
                                            });
                                        })
                                      }
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: false,
                                        exportButton: true,
                                        exportAllData: true,
                                        sorting: true,
                                        paging: true,
                                        pageSize: 50, // make initial page size
                                        emptyRowsWhenPaging: false, // To avoid of having empty rows
                                        pageSizeOptions: [50, 100, 150, 200,500], // rows selection options
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },

                                    }}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {

                                                        props.updateHRDepartment(newData, oldData,updateTableQuery)
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteHRDepartment(oldData._id,updateTableQuery);
                                                }, 600);
                                            }),
                                    }}
                                />

                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetHRDepartment: () => dispatch(GetHRDepartment()),
        updateHRDepartment: (newData, oldData,updateTableQuery) => dispatch(updateHRDepartment(newData, oldData,updateTableQuery)),
        deleteHRDepartment: (departmentId,updateTableQuery) => dispatch(deleteHRDepartment(departmentId,updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.HrReducer.departmentList,
    divisionList: state.HrReducer.divisionList,
    isFetchingDepartmentData: state.HrReducer.isFetchingDepartmentData,
    isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
    isAddingDepartmentData: state.HrReducer.isAddingDepartmentData,
    isDeletingDepartmentData: state.HrReducer.isDeletingDepartmentData,
    
    

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentList);