import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Done from '@mui/icons-material/Done';
import { Link } from 'react-router-dom';
// import { socket } from '../../../socket';
import { useTranslation } from 'react-i18next';
import '../../../il8n'
export default function IconMenu() {
  const { t, i18n } = useTranslation();
  console.log(t, i18n, 'aaaaaaaaaaaaaaa')
  const [lang, setLang] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    console.log(event, 'eeeeeeeeeeeeeeeeeee')
    setAnchorEl(event.currentTarget);
  };


  const changeLanguage = (lng) => {
    setAnchorEl(null);
    console.log(lng, 'llllllllllllllllllllllllllll')
    i18n.changeLanguage(lng);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    window.localStorage.clear();
    // socket.disconnect();
    //window.location.replace('/login');
  }
  const handleHome = () => {
    window.location.replace('/home');
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Link to={"/home"}>
          <MenuItem >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Home
          </MenuItem>
        </Link>
        <Link onClick={() => handleLogout()} to={"/login"}>
          <MenuItem >
            <ListItemIcon >
              <Logout fontSize="small" />

            </ListItemIcon>
            Logout
          </MenuItem>
        </Link>
        <MenuItem onClick={() => changeLanguage('en')} style={{ marginLeft: 5 }}>
          English
          {i18n.language == 'en' && 
          <ListItemIcon style={{marginLeft:5}}>
            <Done fontSize="small" />
          </ListItemIcon>}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ur')} style={{ marginLeft: 5 }}>
          Urdu
          {i18n.language == 'ur' && 
          <ListItemIcon style={{marginLeft:5}}>
            <Done fontSize="small" />
          </ListItemIcon>}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ar')} style={{ marginLeft: 5 }}>
          Arabic
          {i18n.language == 'ar' && 
          <ListItemIcon style={{marginLeft:5}}>
            <Done fontSize="small" />
          </ListItemIcon>}
        </MenuItem>
        {/* <MenuItem onClick={()=>handleClick1()}>
          Select Language
        </MenuItem> */}

      </Menu>
      {/* <Menu
        anchorEl={anchorE}
        id="account-menu"
        open={openLang}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 80,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
          <MenuItem >
            Urdu
          </MenuItem>
          <MenuItem >
            Arabic
          </MenuItem>
      </Menu> */}
    </React.Fragment>
  );
}