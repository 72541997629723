import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, subtract, find, sumBy, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { getArea } from "../AddArea/Area.action";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { getProductData } from "../AddProduct/Product.action";
import { getShop, getShopByArea } from "../AddShop/Shop.action";
import { addReturn, searchReturn } from "./Return.action";
import {
  getIssuance,
  getIssuanceById,
  getIssuanceByStatus,
} from "../CreateIssuance/Issuance.action";
import { SafetyCheck } from "@mui/icons-material";

const AddReturnWithId = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState();
  const [shopName, setShopName] = useState("");
  const [shopId, setShopId] = useState();
  const [issuanceName, setIssuanceName] = useState("");
  const [issuanceId, setIssuanceId] = useState();
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState();
  const [subTotal, setSubTotal] = useState();
  const [issuaceNumber, setIssuanceNumber] = useState();
  const [tax, setTax] = useState();
  const [check, setCheck] = useState(false);
  const [inputField, setInputField] = useState([
    {
      product: "",
      productLabel: "",
      IssuenceQuantity: 0,
      quantity: 0,
      productSellingPrice: 0,
      totalSalePrice: 0,
      availableQuantity: 0,
    },
  ]);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadOrder() {
    props.addReturn(
      // {
      //   // areaId: areaId,
      //   // shopId: shopId,
      //   // issuanceId:issuanceId,
      //   // grandTotal: subTotal,
      //   data:inputField
      // },
      {
        returnDis: totalDiscount,
        data: inputField
      },
      props.generateIssuancePDFList[0]._id,
      handleVisible,
      handleDangerVisible
    );
    // setAreaName("");
    // setAreaId("");
    // setShopName("");
    // setShopId("");
    // setGrandTotal("");
    setIssuanceId();
    setIssuanceName("");
    setSubTotal("");
    // setTax("");
    setTotalDiscount(0);
    setInputField([
      {
        product: "",
        productLabel: "",
        quantity: 0,
        IssuenceQuantity: 0,
        productSellingPrice: 0,
        totalSalePrice: 0,
        availableQuantity: 0,
      },
    ]);
  }
  function searchOrder() {
    props.searchReturn(
      issuaceNumber,
      handleDangerVisible
    );
  }

  const product =
    !isEmpty(props.generateIssuancePDFList) &&
    props.generateIssuancePDFList[0].data.map((x) => {
      let data = {
        value: x.product,
        label: x.productLabel,
        price: x.productSellingPrice,
        quantity: x.quantity,
        issuanceId: x._id,
      };
      return data;
    });
  console.log(props.generateIssuancePDFList, "PDF");
  const [z, setZ] = useState();
  const handleChange = (e, index, type) => {
    console.log(e, index, type, props.generateIssuancePDFList[0].data, "test");
    // if (type == "intname"){

    // console.log(z, "zzzzzzzzzzzzzzzzz");
    // }
    const list = [...inputField];

    if (type == "intname") {
      list[index]["product"] = e.value;
      list[index]["productLabel"] = e.label;
      list[index]["quantity"] = 1;
      list[index]["productSellingPrice"] = Number(e.price);
      setZ(
        find(
          props.generateIssuancePDFList[0].data,
          (x) => x._id == e.issuanceId
        )
      );
      list[index]["IssuenceQuantity"] = Number(e.quantity);
    }
    if (type == "intquantity") {
      console.log(z, "zzzzzzzzzzzzzzzzz");
      console.log(typeof quantity, "qt");
      list[index]["quantity"] =
        e.target.value <= z.quantity ? e.target.value : z.quantity;
      // list[index]["totalSalePrice"] = e.price*e.target.value;
    }
    list[index]["availableQuantity"] =
      Number(list[index]["IssuenceQuantity"]) - Number(list[index]["quantity"]);
    console.log(list[index]["IssuenceQuantity"], "IssuenceQuantity");
    console.log(list[index]["quantity"], "quantity");
    console.log(list[index]["availableQuantity"], "availableQuantity");
    // if (type == "intname")
    //   list[index]["productSellingPrice"] = Number(z.salePrice);
    // if (type == "productSellingPrice")
    //   list[index]["productSellingPrice"] = Number(e.target.value);

    list[index]["totalSalePrice"] =
      list[index]["productSellingPrice"] * list[index]["quantity"];

    setSubTotal(sumBy(list, "totalSalePrice"));
    setInputField(list);
    handleCheck(list)
  };
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      {
        product: "",
        productLabel: "",
        quantity: 0,
        productSellingPrice: 0,
        IssuenceQuantity: 0,
        totalSalePrice: 0,
        availableQuantity: 0,
      },
    ]);
    handleCheck([
      ...inputField,
      {
        product: "",
        productLabel: "",
        quantity: 0,
        productSellingPrice: 0,
        IssuenceQuantity: 0,
        totalSalePrice: 0,
        availableQuantity: 0,
      },
    ])
  };
  const handleCheck = (data) => {
    console.log(data, "sssssssssssss")

    data.map((x) => {
      setCheck(false)
      if (x.productLabel != "") {
        setCheck(true)
      }
      // else{
      //   setCheck(true)
      // }
    })
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
    setSubTotal(
      // list.length === 1
      //   ? list[0].productTotalAmount
      sumBy(list, "totalSalePrice")
    );
    handleCheck(list)
  };
  const IssuanceStatusListData =
    !isEmpty(props.issuanceStatusList) &&
    props.issuanceStatusList.map((x) => {
      let data = {
        value: x._id,
        label: `${x.date}/${x.areaId.areaName}/${isUndefined(x.bookerDetails) ? "" : x.bookerDetails.userName}/${x.salesMenId == null ? "" : x.salesMenId.name}`,
      };
      return data;
    });
  console.log(props.issuanceStatusList, "Mappppp");
  const handleChangeIssuance = (selectedOption) => {
    setIssuanceId(selectedOption.value);
    setIssuanceName(selectedOption.label);
    props.getIssuanceById(selectedOption.value);
  };
  useEffect(() => {
    props.setHeading("Add Return");
    // props.getIssuance();
    // props.getIssuanceByStatus();
    // props.getArea();
    // props.getProductData();
  }, []);
  return (
    <>
      {props.isFetchingStatusIssuanceData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Issuance Number</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={issuaceNumber}
                    onChange={(e) => { setIssuanceNumber(e.target.value) }}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col style={{marginTop:25}}>
                {issuaceNumber == ""
                  ? (
                    <Button disabled>Search</Button>
                  ) : (
                    <Button onClick={searchOrder}>Search</Button>
                  )}
              </Col>
            </Row>
            {console.log(props.generateIssuancePDFList, "aaaaaaaaaaaaaa")}
            {/* {props.show == true ?
              <div> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label>
                        <b>Issuance Total</b>
                      </Form.Label>
                      <Form.Control
                        value={!isEmpty(props.generateIssuancePDFList) && sumBy(props.generateIssuancePDFList[0].data, 'totalSaleValue') - props.generateIssuancePDFList[0].bookingDiscount}
                        disabled
                        type="number"
                      // onChange={(e) => {
                      //   setGrandTotal(e.target.value);
                      // }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label>
                        <b>Issuance Total Discount</b>
                      </Form.Label>
                      <Form.Control
                        value={!isEmpty(props.generateIssuancePDFList) && props.generateIssuancePDFList[0].bookingDiscount}
                        disabled
                        type="number"
                      // onChange={(e) => {
                      //   setGrandTotal(e.target.value);
                      // }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>

                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label>
                        <b>Return Sub Total</b>
                      </Form.Label>
                      <Form.Control
                        value={subTotal}
                        disabled
                        type="number"
                      // onChange={(e) => {
                      //   setGrandTotal(e.target.value);
                      // }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label>
                        <b>Discount</b>
                      </Form.Label>
                      <Form.Control
                        value={totalDiscount}
                        max={!isEmpty(props.generateIssuancePDFList) && props.generateIssuancePDFList[0].bookingDiscount}
                        type="number"
                        onChange={(e) => {
                          Number(e.target.value) < props.generateIssuancePDFList[0].bookingDiscount && setTotalDiscount(e.target.value);
                          Number(e.target.value) < props.generateIssuancePDFList[0].bookingDiscount && setGrandTotal(Number(subTotal) - Number(e.target.value))
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label>
                        <b>Return Total Amount</b>
                      </Form.Label>
                      <Form.Control
                        value={grandTotal}
                        disabled
                        type="number"
                      // onChange={(e) => {
                      //   setGrandTotal(e.target.value);
                      // }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {inputField.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="6">
                          <Form.Group>
                            <Form.Label>
                              <b> Select Product *</b>
                            </Form.Label>
                            <Select
                              value={{ label: item.productLabel }}
                              isDisabled={
                                isEmpty(props.generateIssuancePDFList)
                              }
                              // id="inventoryId"
                              placeholder="Select Product..."
                              // name="inventoryId"
                              onChange={(e) => handleChange(e, i, "intname")}
                              options={product}
                              isOptionDisabled={(option) =>
                                !isEmpty(
                                  find(inputField, (x) => x.product == option.value)
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="6" sm="6">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Avaliable Quantity</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Avaliable Quantity...."
                              name="Quantity"
                              disabled
                              value={item.IssuenceQuantity}
                              min={0}
                              onChange={(e) => handleChange(e, i, "intquantity")}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="6" sm="6">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Quantity *</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Quantity...."
                              name="Quantity"
                              value={item.quantity}
                              min={1}
                              onChange={(e) => handleChange(e, i, "intquantity")}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="6" sm="6">
                          <Form.Group>
                            <Form.Label>
                              <b>Price *</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Product Price"
                              name="Product Price"
                              disabled
                              value={item.productSellingPrice}
                              min={0}
                              onChange={(e) =>
                                handleChange(e, i, "productSellingPrice")
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="2" lg="2" md="6" sm="6">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Total Price</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Total Price...."
                              name="TotalPrice"
                              disabled
                              value={item.totalSalePrice}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Row>
                          <Col lg="12">
                            {inputField.length - 1 == i && (
                              <Button
                                title="Click to Add Product"
                                onClick={handleAddInput}
                                style={{
                                  float: "right",
                                  color: "white",
                                }}
                              >
                                <AddIcon />
                              </Button>
                            )}

                            {inputField.length !== 1 && (
                              <Button
                                title="Click to Remove Product"
                                onClick={() => handleRemoveInput(i)}
                                style={{ float: "right", color: "white" }}
                              >
                                <RemoveIcon />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}

                <div className="sendDiv">
                  {/* <Button onClick={uploadOrder}>Save</Button> */}
                  {
                    check == false
                    ? (
                      <Button disabled>Save</Button>
                    ) : (
                      <Button onClick={uploadOrder}>Save</Button>
                    )}
                </div>
              {/* </div>
            } */}

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getIssuance: () => dispatch(getIssuance()),
    getIssuanceByStatus: () => dispatch(getIssuanceByStatus()),
    addReturn: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(addReturn(data, id, handleVisible, handleDangerVisible)),
    searchReturn: (id, handleDangerVisible) =>
      dispatch(searchReturn(id, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    getIssuanceById: (id) => dispatch(getIssuanceById(id)),
    // getProductData: () => dispatch(getProductData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  shopsByAreaList: state.ShopReducer.shopsByAreaList,
  isFetchingShopsByArea: state.ShopReducer.isFetchingShopsByArea,
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  issuanceStatusList: state.CreateIssuanceReducer.issuanceStatusList,
  isFetchingStatusIssuanceData:
    state.CreateIssuanceReducer.isFetchingStatusIssuanceData,
  // issuanceList: state.CreateIssuanceReducer.issuanceList,
  // isFetchingIssuanceData: state.CreateIssuanceReducer.isFetchingIssuanceData,
  // generateIssuancePDFList: state.CreateIssuanceReducer.generateIssuancePDFList,
  // isFetchingGenerateIssuancePDF:
  //   state.CreateIssuanceReducer.isFetchingGenerateIssuancePDF,
  generateIssuancePDFList: state.ReturnReducer.searchList,
  isSearchingReturn: state.ReturnReducer.isSearchingReturn,
  show: state.ReturnReducer.show,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddReturnWithId);
