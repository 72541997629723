import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  productList: [],
  productDataList: [],
  isFetchingProduct: false,
  productTypeList: [],
  isFetchingProductType: false,
  isAddingProduct: false,
  isAddingProductImage:false,
  ProductImageURL:[],
  productUomList: [],
  isFetchingProductUom: false,
  isFetchingCompleteProductList:false,
  CompleteProductList:[],
  isFetchingProductBalance:false,
  productBalanceList:[],
  productSearchList:[],
  isFetchingProductFilter:false,
  filterProductList:[],
  tableShow:false,
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_PRODUCT_GET_DATA":
      return { ...state, isFetchingProduct: true, };
  case "SUCCESS_PRODUCT_GET_DATA":
      return {
          ...state,
          productSearchList:action.payload.data,
          productDataList: action.payload.data,
          isFetchingProduct: false,
          tableShow:false,
      };
  case "ERROR_PRODUCT_GET_DATA":
      return { ...state,};
      case "FILTER_PRODUCT":
        return{
            ...state,
            productSearchList:isEmpty(action.payload) ? state.productDataList : filter(state.productDataList, x => toLower(x.name).includes(toLower(action.payload)))
        }

      case "REQUEST_PRODUCT_GET_BALANCE":
        return { ...state, isFetchingProductBalance: true, };
    case "SUCCESS_PRODUCT_GET_BALANCE":
        return {
            ...state,
            productBalanceList: action.payload,
            isFetchingProductBalance: false,
        };
    case "ERROR_PRODUCT_GET_BALANCE":
        return { ...state,isFetchingProductBalance:false};
      //get  product Type
      case "REQUEST_PRODUCT_TYPE":
      return { ...state, isFetchingProductType: true, };
  case "SUCCESS_PRODUCT_TYPE":
      return {
          ...state,
          productTypeList: action.payload.data,
          isFetchingProductType: false,
      };
  case "ERROR_PRODUCT_TYPE":
      return { ...state,};
      //get Uom
      case "REQUEST_UOM":
        return { ...state, isFetchingProductUom: true, };
    case "SUCCESS_UOM":
        return {
            ...state,
            productUomList: action.payload.data,
            isFetchingProductUom: false,
        };
    case "ERROR_UOM":
        return { ...state,};
      //add product
      case "REQUEST_ADD_PRODUCT":
        return { ...state, isAddingProduct: true};
      case "SUCCESS_ADD_PRODUCT":
        return {
          ...state,
          productList: action.payload,
          isAddingProduct: false,
        };
      case "ERROR_ADD_PRODUCT":
        return { ...state, isAddingProduct: false};
        //image
        case "REQUEST_ADD_PRODUCT_IMAGE":
        return { ...state, isAddingProductImage: true,};
      case "SUCCESS_ADD_PRODUCT_IMAGE":
        console.log(state.ProductImageURL,"ooooooooooooo1112222",action.payload)
        // console.log(state.ProductImageURL.push([action.payload]),"ooooooooooooo111222233333")
        return {
          ...state,
          ProductImageURL:state.ProductImageURL.concat(action.payload),
          isAddingProductImage: false,
        };
      case "ERROR_ADD_PRODUCT_IMAGE":
        return { ...state, isAddingProductImage: false};
        case "RESET_PRODUCT_URL":
          return { ...state, ProductImageURL: [] }

          case "REQUEST_GET_SIMPLE_COMPLETE_PRODUCT":
            return { ...state, isFetchingCompleteProductList: true};
          case "SUCCESS_GET_SIMPLE_COMPLETE_PRODUCT":
            return {
              ...state,
              CompleteProductList: action.payload,
              isFetchingCompleteProductList: false,
            };
          case "ERROR_GET_SIMPLE_COMPLETE_PRODUCT":
            return { ...state, isFetchingCompleteProductList: false};
            case "REQUEST_FILTER_PRODUCT":
              return { ...state, isFetchingProductFilter: true,tableShow:false };
            case "SUCCESS_FILTER_PRODUCT":
              return {
                ...state,
                filterProductList: action.payload.data,
                isFetchingProductFilter: false,
                tableShow:true
              };
            case "ERROR_FILTER_PRODUCT":
              return { ...state, isFetchingProductFilter: false,tableShow:false };
      default:
        return state;
    }
  };
  export default ProductReducer;