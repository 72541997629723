import { filter, isEmpty } from "lodash";

const initialState = {
  customersList: [],
  isFetchingCustomers: false,
  dataNotAddedCustomers: false,

  orderTypesList: [],
  isFetchingOrderTypes: false,
  tablesList: [],
  isFetchingTables: false,

  isDeletingTables: false,
  isAddingTables: false,
  isSelect:"",
  //order
  ordersList: [],
  isAddingOrders: false,
  ordersDataList: [],
  isFetchingOrdersData: false,
  todayOrdersDataList: [],
  searchTodayOrdersDataList: [],
  isFetchingTodayOrdersData: false,

  completeOrdersList: [],
  isFetchingCompleteOrdersData: false,
  pendingOrdersList: [],
  isFetchingPendingOrdersData: false,
  OrderDataByID:[],
  seatAvaliable: [],
  seatReservedx: [],
  seatSelected: [],
  orderEdit:[],
  tableSearchList:[],
};

const POSViewReducer = (state = initialState, action) => {
    console.log(action, "yyyyyuuuu")
  switch (action.type) {
    case "RESET_STATE_CUSTOMERS":
      return initialState;
    case "REQUEST_CUSTOMERS_GET_DATA":
      return {
        ...state,
        isFetchingCustomers: true,
        isState: false,
        dataNotAddedCustomers: false,
      };
    case "SUCCESS_CUSTOMERS_GET_DATA":
      console.log(action.payload, "action.payload");
      return {
        ...state,
        customersList:
          action.payload /*settingFlag(action.payload,state.customersList,'data')action.payload map(action.payload, x => ( x.data= action.payload.data.concat(state.customersList)))*/,
        isFetchingCustomers: false,
        isState: true,
        dataNotAddedCustomers: false,
      };
    case "ERROR_CUSTOMERS_GET_DATA":
      return { ...state, isFetchingCustomers: false, isState: false };

    case "REQUEST_ORDER_TYPES":
      return { ...state, isFetchingOrderTypes: true };
    case "SUCCESS_ORDER_TYPES":
      console.log(action.payload, "orderTypesList: action.payload");
      return {
        ...state,
        orderTypesList: action.payload,
        isFetchingOrderTypes: false,
      };
    case "ERROR_ORDER_TYPES":
      return { ...state, isFetchingOrderTypes: false };

    case "REQUEST_TABLES":
      return { ...state, isFetchingTables: true };
    case "SUCCESS_TABLES":
      return {
        ...state,
        tablesList: action.payload,
        isFetchingTables: false,
      };
    case "ERROR_TABLES":
      return { ...state, isFetchingTables: false };

    case "REQUEST_ADD_TABLES":
      return { ...state, isAddingTables: true };
    case "SUCCESS_ADD_TABLES":
      return {
        ...state,
        isAddingTables: false,
      };
    case "ERROR_ADD_TABLES":
      return { ...state, isAddingTables: false };
    case "REQUEST_DELETE_TABLES":
      return { ...state, isDeletingTables: true };
    case "SUCCESS_DELETE_TABLES":
      return {
        ...state,
        isDeletingTables: false,
      };
    case "ERROR_DELETE_TABLES":
      return { ...state, isDeletingTables: false };
    //order
    case "REQUEST_ADD_ORDERS":
      return { ...state, isAddingOrders: true };
    case "SUCCESS_ADD_ORDERS":
      return {
        ...state,
        ordersList: action.payload,
        isAddingOrders: false,
      };
    case "ERROR_ADD_ORDERS":
      return { ...state, isAddingOrders: false };
      //get all order
    case "REQUEST_GET_ORDERS":
      return { ...state, isFetchingOrdersData: true };
    case "SUCCESS_GET_ORDERS":
      return {
        ...state,
        ordersDataList: action.payload,
        isSelect:action.payload.data._id,
        isFetchingOrdersData: false,
      };
    case "ERROR_GET_ORDERS":
      return{
        ...state,
        isFetchingOrdersData: false};

        case "REQUEST_GET_TODAY_ORDERS":
          return { ...state, isFetchingTodayOrdersData: true };
        case "SUCCESS_GET_TODAY_ORDERS":
          return {
            ...state,
            todayOrdersDataList: action.payload,
            searchTodayOrdersDataList:action.payload.data,
            isSelect:action.payload.data._id,
            isFetchingTodayOrdersData: false,
          };
        case "ERROR_GET_TODAY_ORDERS":
          return{
            ...state,
            isFetchingTodayOrdersData: false};
            // case "REQUEST_FILTER_ORDERS":
            //   return { ...state, 
            //     // isFetchingTodayOrdersData: true 
            //   };
            // case "SUCCESS_FILTER_ORDERS":
            //   return {
            //     ...state,
            //     // todayOrdersDataList: action.payload,
            //     searchTodayOrdersDataList:action.payload.data,
            //     // isSelect:action.payload.data._id,
            //     // isFetchingTodayOrdersData: false,
            //   };
            // case "ERROR_FILTER_ORDERS":
            //   return{
            //     ...state,
            //     // isFetchingTodayOrdersData: false
            //   };
            
            case "FILTER_TYPE":
              // let data=state.todayOrdersDataList
              return {
                ...state,
                searchTodayOrdersDataList:filter(state.todayOrdersDataList.data, x => x.status == action.payload) 
              };
              case "FILTER_ORDER":
               console.log(state.searchTodayOrdersDataList,"ttttttttttttttt111")
                return {
                  ...state,
                  searchTodayOrdersDataList:action.payload==""?state.todayOrdersDataList.data:filter(state.todayOrdersDataList.data, x => x.number == action.payload) 
                };
                case "FILTER_ORDER_BY_TABLE":
                  console.log(state.searchTodayOrdersDataList,"ttttttttttttttt111")
                   return {
                     ...state,
                     searchTodayOrdersDataList:action.payload==""?state.todayOrdersDataList.data:filter(state.todayOrdersDataList.data, x => x.tableName == action.payload) 
                   };
          
      //get complete order
      case "REQUEST_GET_COMPLETE_ORDERS":
        return { ...state, isFetchingCompleteOrdersData: true };
      case "SUCCESS_GET_COMPLETE_ORDERS":
        return {
          ...state,
          ordersDataList: action.payload,
         // isSelect:action.payload.data._id,
          isFetchingCompleteOrdersData: false,
        };
      case "ERROR_GET_COMPLETE_ORDERS":
        return { ...state, isFetchingCompleteOrdersData: false };
        //get Pending order
        case "REQUEST_GET_PENDING_ORDER":
          return { ...state, isFetchingPendingOrdersData: true };
        case "SUCCESS_GET_PENDING_ORDER":
          return {
            ...state,
            ordersDataList:action.payload,
           // pendingOrdersList: action.payload,
           // isSelect:action.payload.data._id,
            isFetchingPendingOrdersData: false,
          };
        case "ERROR_GET_PENDING_ORDER":
          return { ...state, isFetchingPendingOrdersData: false };
      //order edit route
      
      case "SUCCESS_ORDER_EDIT_DATA":
        console.log(state.orderEdit,"hhhhhhh11111111")
      return { ...state, 
        OrderDataByID:action.payload,
        orderEdit:action.payload,
      };
      /// 
      case "EDIT_ORDER":
        let data= state.orderEdit;
        console.log(data,action.payload,"hhhhhhh")
         data.items=action.payload
        // data.subOrder=action.payload
        return {
            ...state,
            orderEdit: data,
            isFetchingEditOrder: false,
        };
        ///
        case "EDIT_ORDER_COOKING":
          let dataCooking= state.orderEdit;
          console.log(data,action.payload,"hhhhhhh")
          //  data.items=action.payload
          dataCooking.subOrder=action.payload
          return {
              ...state,
              orderEdit: dataCooking,
              isFetchingEditOrder: false,
          };
        // set edit order Customer
        
        case "EDIT_ORDER_CUSTOMER":
          let data1= state.orderEdit;
          console.log(data1,action.payload,"hhhhhhhcustomer")
         data1.customer._id=action.payload._id;
         data1.customer.name=action.payload.name;
          return {
              ...state,
              orderEdit: data1,
              isFetchingEditOrder: false,
          };
          //set edit order coupon
          case "EDIT_ORDER_COUPON":
            let dataCoupon= state.orderEdit;
            console.log(dataCoupon,action.payload,"hhhhhhhcustomer")
         //   let damycoupon=isEmpty(dataCoupon.coupon)?dataCoupon.co
        // isEmpty(dataCoupon.coupon)?dataCoupon.coupon=action.payload:{(dataCoupon.coupon._id=action.payload._id,dataCoupon.coupon.name=action.payload.name)};
        if(isEmpty(dataCoupon.coupon)){
          dataCoupon.coupon=action.payload
        }
        else{
          dataCoupon.coupon=action.payload;
          // dataCoupon.coupon.name=action.payload.name;
        }
            return {
                ...state,
                orderEdit: dataCoupon,
                isFetchingEditOrder: false,
            };
            ///total order edit
            case "EDIT_ORDER_TOTAL":
              let dataTotal= state.orderEdit;
              console.log(dataTotal,action.payload,"bill")
          dataTotal.grandTotal=action.payload
          console.log(dataTotal,"billafter")
              return {
                  ...state,
                  orderEdit: dataTotal,
                  isFetchingEditOrder: false,
              };
              ///discout order edit
            case "EDIT_ORDER_DISCOUNT":
              let dataDiscount= state.orderEdit;
              console.log(dataDiscount,action.payload,"bill11")
              dataDiscount.discount=action.payload
          console.log(dataDiscount,"billafter11")
              return {
                  ...state,
                  orderEdit: dataDiscount,
                  isFetchingEditOrder: false,
              };
              //order sub total edit
              case "EDIT_ORDER_SUB_TOTAL":
                let dataSubTotal= state.orderEdit;
                console.log(dataSubTotal,action.payload,"subbill")
                dataSubTotal.subTotal=action.payload
            console.log(dataSubTotal,"subbillafter")
                return {
                    ...state,
                    orderEdit: dataSubTotal,
                    isFetchingEditOrder: false,
                };
    case "REQUEST_WARE_HOUSE_GET_DATA":
      return {
        ...state,
        isFetchingwareHouseList: true,
        isState: false,
        dataNotAddedWarehouse: false,
      };
    case "SET_SEAT_SELECTED":
     console.log("eeeeeeeeee")
      return { ...state, seatSelected: action.payload };
    case "SET_SEAT_AVAILABLE":
      console.log(action.payload, "acccccc2");
      return { ...state, seatAvaliable: action.payload };
    case "SET_SEAT_RESERVEDX":
      console.log(action.payload, "acccccc1");
      return {...state,  seatReservedx: action.payload };
    
    default:
      return state;
  }
};
export default POSViewReducer;
