import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";


const InventoryLegderDetail = (props) => {
  useEffect(() => { }, []);
  return (
    <>
      {props.isFetchingSearchInventoryLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/inventory/SalesmenLedger">
                <Button> Back to Search</Button>
              </Link>
            </div>

            <div className="table">
              <h4>Product Name:{props.searchSalesmenLedgerList.code}-{props.searchSalesmenLedgerList.productName}</h4>
              <h4>Total Add Quantity: {props.searchSalesmenLedgerList.addQuantity}</h4>
              <h4>Total Less Quantity: {props.searchSalesmenLedgerList.lessQuantity}</h4>
              <h4>Closing Balance: {props.searchSalesmenLedgerList.closing_balance}</h4>
              <MaterialTable
                title={props.searchSalesmenLedgerList.productName}

                columns={[
                  { title: "Date", field: "transactionDate" },
                  { title: "Opening Balance", field: "openingBalance" },
                  { title: "Description", field: "description" },
                  { title: "Add Quantity", field: "addQuantity" },
                  { title: "Less Quantity", field: "lessQuantity" },
                  { title: "Closing Balance", field: "closingBalance" },
                  // { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") }
                ]}
                data={props.searchSalesmenLedgerList.data}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  searchSalesmenLedgerList: state.SalesmenLegderReducer.searchSalesmenLedgerList,
  isFetchingSearchSalesmenLedger: state.SalesmenLegderReducer.isFetchingSearchSalesmenLedger,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryLegderDetail);
