import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "lodash";
import Select from "react-select";
import { getHybridAndCompleteData, searchInventoryLedger, } from "./salesmenLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";
import { getUserRes } from "../../AddUser/user.actions";


const InventoryLedger = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");
  const [salesMenId, setSalesMenId] = useState();
  const [salesMenName, setSalesMenName] = useState("");


  const getHybridAndCompleteData = props.hybridAndCompleteData.map((x) => {
    let data = { value: x._id, label: `${x.code}-${x.name}` };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
  };


  const handleChangeSalesMen = (selectedOption) => {
    setSalesMenId(selectedOption.value);
    setSalesMenName(selectedOption.label);
  };
  const filterdUserList = !isEmpty(props.userList) && props.userList.filter((e) => e.role == "salesmen");
  const BookerList = !isEmpty(filterdUserList) && filterdUserList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });

  useEffect(() => {
    props.getHybridAndCompleteData();
    props.getUser();
    props.setHeading("Salesmen Ledger");
  }, []);

  function searchData() {
    props.searchInventoryLedgerList(
      {
        startDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        productId: item,
        salesMenId: salesMenId
      },
      startDate,
      endDate
    );
    return <Navigate to="/inventory/SalesmenLedger" />;
  }

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isFetchingUser ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingHybridAndCompleteData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label><b> Start Date </b></Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label><b>End Date</b></Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Select Salesmen *</b></Form.Label>
                  <Select
                    isDisabled={isEmpty(BookerList)}
                    value={{ label: salesMenName }}
                    placeholder="Select Salesmen..."
                    onChange={handleChangeSalesMen}
                    options={BookerList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label><b> Select Product *</b> </Form.Label>
                  <Select
                    placeholder="Select Product"
                    onChange={handleChangeLevel5Ledger}
                    options={getHybridAndCompleteData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                lg="12"
                sm="12"
                className="text-center"
                style={{ marginTop: "2%" }}
              >
                {item == "" || salesMenName == "" ? (
                  <Button
                    disabled


                  >
                    Search
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: "/inventory/SalesmenLedgerList",
                      state: { start: startDate, end: endDate },
                    }}
                  >
                    <Button

                      onClick={(e) => searchData()}
                    >
                      Search
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    searchInventoryLedgerList: (data, startDate, endDate) => dispatch(searchInventoryLedger(data, startDate, endDate)),
    setHeading: (data) => dispatch(setHeading(data)),
    getUser: () => dispatch(getUserRes()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  searchInventoryLedgerListReport: state.InventoryLegderReducer.searchInventoryLedgerListReport,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  isFetchingSearchInventoryLedgerReport: state.InventoryLegderReducer.isFetchingSearchInventoryLedgerReport,
  userList: state.UserReducer.userList,
  isFetchingUser: state.UserReducer.isFetchingUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLedger);
