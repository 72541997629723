const initialState = {
  areaList: [],
  isAddingArea: false,
  areaDataList: [],
  isFetchingAreaData: false,
};

const AreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_AREA":
      return { ...state, isAddingArea: true };
    case "SUCCESS_ADD_AREA":
      return {
        ...state,
        areaList: action.payload,
        isAddingArea: false,
      };
    case "ERROR_ADD_AREA":
      return { ...state, isAddingArea: false };
    case "REQUEST_GET_AREA":
      return { ...state, isFetchingAreaData: true };
    case "SUCCESS_GET_AREA":
      return {
        ...state,
        areaDataList: action.payload.data,
        isFetchingAreaData: false,
      };
    case "ERROR_GET_AREA":
      return { ...state, isFetchingAreaData: false };
    default:
      return state;
  }
};
export default AreaReducer;
