import { Container } from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";

import { setHeading } from "../../../accounts/account.actions";
import { useEffect, useRef } from "react";
import MaterialTable from "material-table";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  deleteExternalIssuance,
  deleteExternalIssuanceProduct,
  getExternalIssuance,
  pdfExternalIssuance,
  updateExternalIssuanceProduct,
  // updateExternalIssuance,
} from "./IssuanceByVendor.action";

const ViewIssuanceByVendor = (props) => {
  useEffect(() => {
    props.setHeading("External Issuance List");
  }, []);
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  return (
    <>
      {props.isFetchingExternalIssuanceList ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
            tableRef={tableRef}
              title="External Issuance Details"
              columns={[
                // { title: 'Flavors Id', field: 'table_id', editable: 'never' },
                { title: "External Issuance Date", field: "date" },
                { title: "Issued By", field: "issuedBy" },
                {
                  title: "External Issuance Description",
                  field: "issuanceDescription",
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/externalIssuance?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      { title: "Product Name ", field: "productLabel",editable:'never' },
                      {
                        title: "Product Price",
                        field: "productSellingPrice",
                        editable:'never'
                      },
                      {
                        title: "Add Quantity",
                        field: "quantity",
                      },
                      {
                        title: "Total Price",
                        field: "totalSaleValue",
                        editable:'never'
                      },
                    ]}
                    data={rowData.data}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         let url = `${baseURL}/receiving?`;
                    //         url += "limit=" + query.pageSize;
                    //         url += "&page=" + (query.page);
                    //         url += "&search=" + query.search;
                    //         fetch(url, {
                    //             method: 'GET',
                    //             headers: headers,
                    //         })
                    //             .then((response) => response.json())

                    //             .then((result) => {
                    //                 let a=-1;
                    //                 resolve({

                    //                     data: result.data[a+1].receivingData,
                    //                     page: result.page,
                    //                     totalCount: result.total_results,
                    //                 });
                    //             });

                    //     })
                    // }
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateExternalIssuanceProduct(newData, oldData,rowData,updateTableQuery);
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            props.deleteExternalIssuanceProduct(oldData,rowData,updateTableQuery);
                          }, 600);
                        }),
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50, // make initial page flavors
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.pdfExternalIssuance(rowData);
                  },
                },
              ]}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                // onRowUpdate: (newData, oldData) =>
                //   new Promise((resolve) => {
                //     setTimeout(() => {
                //       resolve();
                //       {
                //         props.updateExternalIssuance(newData, oldData);
                //       }
                //     }, 600);
                //   }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteExternalIssuance(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getExternalIssuance: () => dispatch(getExternalIssuance()),
    deleteExternalIssuance: (id) => dispatch(deleteExternalIssuance(id)),
    deleteExternalIssuanceProduct: (data,rowdata,updateTableQuery) => dispatch(deleteExternalIssuanceProduct(data,rowdata,updateTableQuery)),
    pdfExternalIssuance: (data) => dispatch(pdfExternalIssuance(data)),
    updateExternalIssuanceProduct: (newData, oldData,rowData,updateTableQuery) =>
      dispatch(updateExternalIssuanceProduct(newData, oldData,rowData,updateTableQuery)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  externalIssuanceDataList:
    state.ExternalIssuanceReducer.externalIssuanceDataList,
  isFetchingExternalIssuanceList:
    state.ExternalIssuanceReducer.isFetchingExternalIssuanceList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewIssuanceByVendor);
