import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import ImageUploader from "react-images-upload";
import useScanDetection from "use-scan-detection";

import {
  getProductTypeById,
  getProductTypeProduct,
  getServiceAndHybridProduct,
} from "../Recipe/Recipe.action";
import { addProductImage } from "../AddProduct/Product.action";
import { addDeal } from "./createDeal.action";
import { setHeading } from "../../../accounts/account.actions";

const CreateDeal = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      product: 0,
      ProductLabel: "",
      ProductVariationId: 0,
      ProductVariationLabel: "",
      quantity: 0,
    },
  ]);
  const [dealName, setDealName] = useState("");
  const [dealPrice, setDealPrice] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [GreenColorTime, setGreenColorTime] = useState(0);
  const [yellowColorTime, setYellowColorTime] = useState(0);
  const [redColorTime, setRedColorTime] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [disabel, setDisabel] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] =
    useState(false);
  const [barcode, setBarocode] = useState("");
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.ProductImage({
      image: pictureFiles[0],
    });
  };
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.addDeal(
      {
        name: dealName,
        productTypeName: "Deal",
        productType: "6433dd117aad3eb039c1744f",
        salePrice: dealPrice,
        greenColorTime: GreenColorTime,
        redColorTime: redColorTime,
        yellowColorTime: yellowColorTime,
        desc: productDesc,
        variation: inputField1,
        productBalance: "9999999999999",
        images: props.ProductImageURL.file,
      },
      handleVisible,
      handleDangerVisible
    );
    setInputField1([
      {
        product: 0,
        ProductLabel: "",
        ProductVariationId: 0,
        ProductVariationLabel: "",
        quantity: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        product: 0,
        ProductLabel: "",
        ProductVariationId: 0,
        ProductVariationLabel: "",
        quantity: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type, itemId) => {
    const list = [...inputField1];
    if (type == "master") {
      list[index]["product"] = e.value;
      list[index]["ProductLabel"] = e.label;
      let dumy =
        e.type == "6433dd117aad3eb039c1744f"
          ? setDisabel(true)
          : setDisabel(false);
      props.getProductTypeByID(e.value);
    }
    if (type == "variation") list[index]["ProductVariationId"] = e.value;
    if (type == "variation") list[index]["ProductVariationLabel"] = e.label;
    if (type == "intquantity")
      list[index]["quantity"] =
        e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
    setInputField1(list);
  };

  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };
  const gettypeList =
    !isEmpty(props.serviceAndHybridProductList) &&
    props.serviceAndHybridProductList.map((x) => {
      let data = { value: x._id, label: x.name, type: x.productType._id };
      return data;
    });
  const getVariationList =
    !isEmpty(props.productTypeListById) &&
    props.productTypeListById[0].variation.map((x) => {
      let data = { value: x._id, label: `${x.flavorLabel} / ${x.sizeLabel}` };
      return data;
    });
    // const getVariationList =
    // !isEmpty(props.productTypeListById) &&
    // props.productTypeListById[0].variation.map((x) => {
    //   let data = { value: x._id, label: x.flavour };
    //   return data;
    // });
  useEffect(() => {
    props.setHeading("Create Deal")
    props.getProductSerivceAndHybrid();
  }, []);
  return (
    <>
      {props.isFetchingServiceAndHybridProductList || props.isAddingDeal ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingDeal ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Deal Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Deal Name...."
                    onChange={(e) => setDealName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Deal Price *</b>
                  </Form.Label>
                  <Form.Control
                    type="Number"
                    placeholder="Deal Price...."
                    onChange={(e) => setDealPrice(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Deal Description :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={productDesc}
                    placeholder="Deal Description...."
                    onChange={(e) => setProductDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Green Color Time :</b>
                  </Form.Label>
                  <Form.Control
                    type="Number"
                    value={GreenColorTime}
                    placeholder="Green Color Time...."
                    onChange={(e) => setGreenColorTime(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Yellow Color Time :</b>
                  </Form.Label>
                  <Form.Control
                    type="Number"
                    value={yellowColorTime}
                    placeholder="Yellow Color Time...."
                    onChange={(e) => setYellowColorTime(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Red Color Time :</b>
                  </Form.Label>
                  <Form.Control
                    type="Number"
                    value={redColorTime}
                    placeholder="Red Color Time...."
                    onChange={(e) => setRedColorTime(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField1.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>

                        <Select
                          style={{ zIndex: 1 }}
                          value={{
                            label: item.ProductLabel,
                          }}
                          placeholder="Select Items..."
                          name="master"
                          onChange={(e) => handleChange1(e, i, "master")}
                          options={gettypeList}
                          isDisabled={isEmpty(gettypeList) ? true : false}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField1,
                                (x) => x.product == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="12" xs="12">
                      <Form.Group className="mb-3" controlId="garana">
                        <Form.Label>
                          <b>Select Variation *</b>
                        </Form.Label>
                        <Select
                          isDisabled={
                            disabel == true || isEmpty(getVariationList)
                          }
                          placeholder="Select Variation..."
                          onChange={(e) =>
                            handleChange1(e, i, "variation", item.product)
                          }
                          options={getVariationList}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col xl="3" lg="3" md="3" xs="3">
                      <Form.Group className="mb-3" controlId="garana">
                        <Form.Label>
                          <b>Select Flavour *</b>
                        </Form.Label>
                        <Select
                          isDisabled={
                            disabel == true || isEmpty(getVariationList)
                          }
                          placeholder="Select Flavour..."
                          onChange={(e) =>
                            handleChange1(e, i, "variation", item.product)
                          }
                          options={getVariationList}
                        />
                      </Form.Group>
                    </Col> */}

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          value={item.quantity}
                          onChange={(e) =>
                            handleChange1(e, i, "intquantity", item.product)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>Upload Deal Image *</b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {inputField1[0].product == 0 ||
              inputField1[0].quantity == 0 ||
              isEmpty(props.ProductImageURL) ||
              isEmpty(dealName) ||
              isEmpty(dealPrice) ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadPurchase}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addDeal: (data, handleVisible, handleDangerVisible) =>
      dispatch(addDeal(data, handleVisible, handleDangerVisible)),
    getProductSerivceAndHybrid: () => dispatch(getServiceAndHybridProduct()),
    ProductImage: (data) => dispatch(addProductImage(data)),
    getProductTypeByID: (data) => dispatch(getProductTypeById(data)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  serviceAndHybridProductList: state.RecipeReducer.serviceAndHybridProductList,
  isFetchingServiceAndHybridProductList:
    state.RecipeReducer.isFetchingServiceAndHybridProductList,
  ProductImageURL: state.ProductReducer.ProductImageURL,
  productTypeListById: state.RecipeReducer.productTypeListById,
  isAddingDeal: state.CreateDealReducer.isAddingDeal,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDeal);
