import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, sumBy, filter, ceil, floor } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import "jspdf-autotable";
import Swal from "sweetalert";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import useScanDetection from "use-scan-detection";
import {
  addProduct,
  addProductImage,
  getProductType,
  getUOM,
  resetReducer,
} from "./Product.action";
import { getFlavorsData } from "../AddFlavour/Flavour.action";
import { getSizeData } from "../AddSize/Size.action";
import { getMasterControlData } from "../AddMasterControl/MasterControl.action";
import { getCategoryData } from "../categories/Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
import { AsyncPaginate } from "react-select-async-paginate";
import { baseURL, headers } from "../../../../../global/api";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import {
  getCategoryByWarehouseId,
  getSubCategoryByCategoryId,
} from "../SubCategories/SubCategory.action";
import { getVendorData } from "../Vendor/Vendor.action";

const AddProduct = (props) => {

  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedImages]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleUpload = async () => {
    // const formData = new FormData();
    images.forEach((image) => {
      props.ProductImage({
        image: image,
      })
    });
    console.log(images, 'formData')

    // try {
    //   // Replace with your backend API URL
    //   const response = await fetch("YOUR_BACKEND_URL/upload", {
    //     method: "POST",
    //     body: formData,
    //   });

    //   if (response.ok) {
    //     console.log("Images uploaded successfully!");
    //   } else {
    //     console.error("Failed to upload images");
    //   }
    // } catch (error) {
    //   console.error("Error uploading images:", error);
    // }
  };
  const [product, setProduct] = useState("");

  const [uomId, setUomId] = useState("");
  const [uomname, setUomName] = useState("");
  const [TypeId, setTypeId] = useState("");
  const [salePrice, setsalePrice] = useState(0);
  const [wholesalePrice, setWholesalePrice] = useState(0);
  const [code, setCode] = useState("");
  const [minimumBalance, setMinimumBalance] = useState(0);
  const [maximumBalance, setMaximumBalance] = useState(0);
  const [piecePerBox, setPiecePerBox] = useState(0);

  const [purchasePrice, setPurchasePrice] = useState(0);
  const [productQuantity, setProductQuantity] = useState("");
  const [Typename, setTypeName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productNameArabic, setProductNameArabic] = useState("");
  const [GreenColorTime, setGreenColorTime] = useState(0);
  const [yellowColorTime, setYellowColorTime] = useState(0);
  const [redColorTime, setRedColorTime] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [barcode, setBarocode] = useState("");
  const [value, setValue] = useState();

  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryname, setSubCategoryName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  // const [warehouseKey, setWarehouseKey] = useState();

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [inputField, setInputField] = useState([
    {
      flavorLabel: "",
      sizeLabel: "",
      sellingPrice: 0,
      isRecipe: false,
    },
  ]);
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { flavorLabel: "", sizeLabel: "", sellingPrice: 0, isRecipe: false },
    ]);
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    console.log(inputField, "hmhm");
    let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    // if (type == "flavor") list[index]["flavorid"] = e.value;
    if (type == "flavor") list[index]["flavorLabel"] = e.label;
    // if (type == "size") list[index]["sizeId"] = e.value;
    if (type == "size") list[index]["sizeLabel"] = e.label;
    // if (type == "size") list[index]["isRecipe"]=false;
    if (type == "price") list[index]["sellingPrice"] = Number(e.target.value);
    setInputField(list);
  };
  const inventoryFlavor = props.flavorsDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.flavourName,
    };
    return data;
  });
  const inventorySize = props.sizeDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.sizeName,
    };
    return data;
  });
  const [inputField1, setInputField1] = useState([
    {
      master: "",
      quantity: 0,
      price: 0,
    },
  ]);
  const handleAddInput1 = () => {
    setInputField1([...inputField1, { master: "", quantity: 0, price: 0 }]);
  };
  const handleRemoveInput1 = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type) => {
    let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    if (type == "master") list[index]["master"] = e.value;
    if (type == "master") list[index]["masterLabel"] = e.label;
    if (type == "quantity") list[index]["quantity"] = e.value;
    if (type == "quantity") list[index]["quantityLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "price") list[index]["price"] = Number(e.target.value);
    setInputField1(list);
  };
  const inventoryMaster = props.masterControlDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.masterControlName,
    };
    return data;
  });
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
    setProduct("")
setUomId("")
setUomName("")
setTypeId("")
setsalePrice(0)
setWholesalePrice(0)
setCode("")
setMinimumBalance(0)
setMaximumBalance(0)
setPiecePerBox(0)
setPurchasePrice(0)
setProductQuantity(0)
setProductDesc("")
setWarehouseId("")
setWarehouseName("")
setCategoryId("")
setCategoryName("")
setSubCategoryId("")
setSubCategoryName("")
setVendorId("")
setVendorName("")
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadCompleteProduct() {
    console.log(props.ProductImageURL, "chech123");
    props.addProduct(
      {
        wareHouseId: warehouseId,
        category: CategoryId,
        subCategoryId: props.subCategoryListByCategoryId[0]._id,
        vendorId: vendorId,
        name: product,
        productNameArabic:productNameArabic,
        desc: productDesc,
        uom: uomId,
        uomLabel: uomname,
        // productType: TypeId,
        code: code,
        salePrice: salePrice,
        wholesalePrice: wholesalePrice,
        maximumBalance: maximumBalance,
        minimumBalance: minimumBalance,
        piecePerBox: piecePerBox,
        purchasePrice: purchasePrice,
        productBalance: productQuantity,
        feeds: props.ProductImageURL,
        barcodeId: barcode,
      },
      handleVisible,
      handleDangerVisible
    );

    props.reset();
  }

  function uploadProduct() {
    console.log(props.ProductImageURL, "chech123");
    props.addProduct(
      {
        name: product,
        nameInArabic:productNameArabic,
        discount: discount,
        category: CategoryId,
        desc: productDesc,
        images: props.ProductImageURL,
        greenColorTime: GreenColorTime,
        yellowColorTime: yellowColorTime,
        redColorTime: redColorTime,
        variation: inputField,
        code: code,
        //  uom:uomId,
        productType: TypeId,
        // barcodeId:barcode,
        //  uomPrice:uomPrice
      },
      handleVisible,
      handleDangerVisible
    );
    props.reset();
  }
  console.log(props.ProductImageURL, 'aaaaaaaaaaawwwwwwwwww')
  const wareHouseData = props.productDataList;

  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles,'pictureFiles')

    props.ProductImage({
      image: pictureFiles[pictureFiles.length-1],
    })
    //setUploadImage(pictureFiles);
  };

  const handleChangeUom = (selectedOption) => {
    console.log(selectedOption, "sop");
    setUomId(selectedOption.value);
    setUomName(selectedOption.label);
  };
  const getUomList =
    !isEmpty(props.productUomList) &&
    props.productUomList.map((x) => {
      let data = { value: x._id, label: x.unitName };
      return data;
    });
  const handleChangeType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setTypeId(selectedOption.value);
    setTypeName(selectedOption.label);
  };
  const gettypeList =
    !isEmpty(props.productTypeList) &&
    props.productTypeList.map((x) => {
      let data = { value: x._id, label: x.productTypeName };
      return data;
    });
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? true
      : false;
  };

  useScanDetection(
    Checkpermissions("permissionsCatName", "Enable Barcode") && {
      onComplete: setBarocode,
      //  minLength: 13 // EAN13
    }
  );

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
    props.getCategoryByWarehouseId(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  const getCategoryList =
    !isEmpty(props.categoryListByWarehouseId) &&
    props.categoryListByWarehouseId.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  const handleChangeCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
    props.getSubCategoryByCategoryId(selectedOption.value);
  };

  const getSubCategoryList =
    !isEmpty(props.subCategoryListByCategoryId) &&
    props.subCategoryListByCategoryId.map((x) => {
      let data = { value: x._id, label: x.subCategoryName };
      return data;
    });
  const handleChangeSubCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setSubCategoryId(selectedOption.value);
    setSubCategoryName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption, "sop");
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };

  useEffect(() => {
    // props.getCategoryData();
    // props.getFlavor();
    // props.getSize();
    // props.getMaster();
    props.getProductUom();
    // props.getProductType();
    props.getVendorData();
    props.setHeading("Add Product");
    props.getRestaurantData();
    props.reset();
  }, []);
  async function loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(
      `${baseURL}/categories/?page=${!isEmpty(search) ? "" : page}&limit=${!isEmpty(search) ? "" : 5
      }&search=${search}`,
      { headers: headers }
    );
    const responseJSON = await response.json();

    let check = responseJSON.total_results / responseJSON.limit;
    console.log(check, responseJSON.page, "tttttttttttt");
    let check1 = parseInt(check) != responseJSON.page ? true : false;
    return {
      options: isEmpty(responseJSON.data)
        ? []
        : getCategoryList(responseJSON.data),
      hasMore: isEmpty(responseJSON.data) ? false : check1,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <>
      {props.isAddingProduct ||
        props.isFetchingVendor ||
        props.isFetchingCategoryByWarehouseId ||
        props.isFetchingSubCategoryByCategoryId ||
        props.isFetchingRestaurant ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col xl="4" lg="4" md="6" xs="12">
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      <b>Select WareHouse *</b>
                    </Form.Label>
                    <Select
                      value={{ label: warehouseName }}
                      isDisabled={isEmpty(props.restaurantDataList)}
                      placeholder="Select WareHouse..."
                      onChange={handleChangeWarehouse}
                      options={getRestaurantList}
                    />
                  </Form.Group>
                </Col>
                <Col xl="4" lg="4" md="6" xs="12">
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      <b>Select Category *</b>
                    </Form.Label>
                    <Select
                      value={{ label: Categoryname }}
                      isDisabled={
                        isEmpty(props.categoryListByWarehouseId) ||
                        isEmpty(warehouseId)
                      }
                      placeholder="Select Category..."
                      onChange={handleChangeCategory}
                      options={getCategoryList}
                    />
                  </Form.Group>
                </Col>
                {/* <Col xl="4" lg="4" md="6" xs="12">
                  <Form.Group className="mb-3 mt-3" >
                    <Form.Label>
                      <b>Select Sub Category *</b>
                    </Form.Label>
                    <Select
                    value={{ label: subCategoryname }}
                      isDisabled={isEmpty(props.subCategoryListByCategoryId) || isEmpty(CategoryId)}
                      placeholder="Select Sub Category..."
                      onChange={handleChangeSubCategory}
                      options={getSubCategoryList}
                    />
                  </Form.Group>
                </Col> */}
                <Col xl="4" lg="4" md="6" xs="12">
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>
                      <b>Select Vendor *</b>
                    </Form.Label>
                    <Select
                      value={{ label: vendorName }}
                      isDisabled={
                        isEmpty(props.vendorDataList) || isEmpty(CategoryId)
                      }
                      placeholder="Select Vendor..."
                      onChange={handleChangeVendor}
                      options={getVendorList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Code *</b>
                    </Form.Label>
                    <Form.Control
                      disabled={isEmpty(vendorId) ? true : false}
                      type="text"
                      value={code}
                      placeholder="Code...."
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Name *</b>
                    </Form.Label>
                    <Form.Control
                      disabled={isEmpty(vendorId) ? true : false}
                      type="text"
                      value={product}
                      placeholder="Product Name...."
                      onChange={(e) => setProduct(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Name In Arabic </b>
                    </Form.Label>
                    <Form.Control
                      disabled={isEmpty(vendorId) ? true : false}
                      type="text"
                      value={productNameArabic}
                      placeholder="Product Name In Arabic...."
                      onChange={(e) => setProductNameArabic(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select UOM *</b>
                    </Form.Label>
                    <Select
                    value={{label:uomname}}
                      isDisabled={
                        isEmpty(props.productUomList) || isEmpty(vendorId)
                      }
                      placeholder="Select UOM..."
                      onChange={handleChangeUom}
                      options={getUomList}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Box Per Carton *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      value={piecePerBox}
                      disabled={isEmpty(vendorId) ? true : false}
                      placeholder="Box Per Carton...."
                      onChange={(e) => setPiecePerBox(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Purchase Price *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      value={purchasePrice}
                      disabled={isEmpty(vendorId) ? true : false}
                      placeholder="Purchase Price...."
                      onChange={(e) => setPurchasePrice(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Sale Price *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      value={salePrice}
                      disabled={isEmpty(vendorId) ? true : false}
                      placeholder="sale Price...."
                      onChange={(e) => setsalePrice(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Wholesale Price *</b>
                    </Form.Label>
                    <Form.Control
                      type="Number"
                      value={wholesalePrice}
                      disabled={isEmpty(vendorId) ? true : false}
                      placeholder="Wholesale Price...."
                      onChange={(e) => setWholesalePrice(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Quantity *</b>
                    </Form.Label>
                    <Form.Control
                      disabled={isEmpty(vendorId) ? true : false}
                      type="Number"
                      value={productQuantity}
                      placeholder="Product Quantity...."
                      onChange={(e) => setProductQuantity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Minimum Balance *</b>
                    </Form.Label>
                    <Form.Control
                    value={minimumBalance}
                      disabled={isEmpty(vendorId) ? true : false}
                      type="Number"
                      placeholder="Minimum Balance...."
                      onChange={(e) => setMinimumBalance(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Maximum Balance *</b>
                    </Form.Label>
                    <Form.Control
                    value={maximumBalance}
                      disabled={isEmpty(vendorId) ? true : false}
                      type="Number"
                      placeholder="Maximum Balance...."
                      onChange={(e) => setMaximumBalance(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="4" md="4" xs="4">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Product Description </b>
                    </Form.Label>
                    <Form.Control
                      disabled={isEmpty(vendorId) ? true : false}
                      type="text"
                      value={productDesc}
                      placeholder="Product Description...."
                      onChange={(e) => setProductDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                {Checkpermissions("permissionsCatName", "Enable Barcode") &&
                  <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Bar Code Scaner</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Bar Code number...."
                        value={barcode}
                        disabled
                        onBlur={(e) => setBarocode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>}
              </Row> */}
   
              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b style={{ fontSize: "large" }}>Upload Product Image</b>
                    </Form.Label>
                    <ImageUploader
                      withIcon={false}
                      buttonText="Upload Image"
                      onChange={onDrop}
                      
                      // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={999999999999999999999}
                      singleImage={false}
                      withPreview={true}
                    />
                  </Form.Group>
                </Col>
              </Row> 
              <div className="sendDiv">
                {isEmpty(code) ||
                  isEmpty(uomId) ||
                  isEmpty(product) ||
                  isEmpty(CategoryId) ||
                  isEmpty(vendorId) ||
                  productQuantity == "" ||
                  props.isAddingProductImage||
                  purchasePrice == 0 ||
                  minimumBalance == 0 ||
                  maximumBalance == 0 ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "#1976d2",
                      border: "1px solid #1976d2",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button onClick={uploadCompleteProduct}>Save</Button>
                )}
              </div>
              {/* </div> */}
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (data, handleVisible, handleDangerVisible) =>
      dispatch(addProduct(data, handleVisible, handleDangerVisible)),
    getFlavor: () => dispatch(getFlavorsData()),
    getSize: () => dispatch(getSizeData()),
    getMaster: () => dispatch(getMasterControlData()),
    getCategoryData: () => dispatch(getCategoryData()),
    getProductUom: () => dispatch(getUOM()),
    getProductType: () => dispatch(getProductType()),
    ProductImage: (data) => dispatch(addProductImage(data)),
    reset: () => dispatch(resetReducer()),
    getVendorData: () => dispatch(getVendorData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryByWarehouseId: (id) => dispatch(getCategoryByWarehouseId(id)),
    getSubCategoryByCategoryId: (id) =>
      dispatch(getSubCategoryByCategoryId(id)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productList: state.ProductReducer.productList,
  productTypeList: state.ProductReducer.productTypeList,
  productUomList: state.ProductReducer.productUomList,
  ProductImageURL: state.ProductReducer.ProductImageURL,
  isAddingProductImage:state.ProductReducer.isAddingProductImage,
  isAddingProduct: state.ProductReducer.isAddingProduct,
  categoryList: state.CategoryReducer.categoryList,
  flavorsDataList: state.FlavourReducer.flavorsDataList,
  sizeDataList: state.sizeReducer.sizeDataList,
  masterControlDataList: state.MasterControlReducer.masterControlDataList,

  categoryListByWarehouseId: state.subCategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId:
    state.subCategoryReducer.isFetchingCategoryByWarehouseId,
  subCategoryListByCategoryId:
    state.subCategoryReducer.subCategoryListByCategoryId,
  isFetchingSubCategoryByCategoryId:
    state.subCategoryReducer.isFetchingSubCategoryByCategoryId,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
