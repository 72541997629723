import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import useScanDetection from "use-scan-detection";

import { getProductData } from "../AddProduct/Product.action";
import { setHeading } from "../../../accounts/account.actions";
import { addExternalIssuance } from "./IssuanceByVendor.action";

const AddIssuanceByVendor = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      // productId: 0,
      // ProductName: "",
      // availableProductQuantity: 0,
      // requiredProductQuantity: 0,
      // productPrice: 0,
      // marketPrice: 0,
      product: "",
      productLabel: "",
      quantity: "",
      productSellingPrice: 0,
      totalSaleValue: 0,
      avaliableQuantity: 0,
    },
  ]);
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [receivedBy, setReceivedBy] = useState("");
  const [requistionType, setRequistionType] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] =
    useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadExternalIssuance() {
    props.addExternalIssuance(
      {
        date: date,
        issuedBy: receivedBy,
        issuanceDescription: requistionType,
        data: inputField1,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setInputField1([
      {
        product: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        totalSaleValue: 0,
        avaliableQuantity: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        product: "",
        productLabel: "",
        quantity: "",
        productSellingPrice: 0,
        totalSaleValue: 0,
        avaliableQuantity: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  // const handleChange1 = (e, index, type, itemId) => {
  //   console.log(e, index, type, "checkBar");
  //   let z = find(props.productDataList, (x) => x._id == e.value);
  //   const { name, value } = e;
  //   const list = [...inputField1];
  //   console.log(z, "lllllll");
  //   if (type == "master") list[index]["productId"] = e.value;
  //   if (type == "master") list[index]["ProductName"] = e.label;
  //   console.log(e.label1, e.labelPrice, "hhhhh");
  //   if (type == "master")
  //     list[index]["availableProductQuantity"] = e.productQuantityCount;
  //   if (type == "master") list[index]["productPrice"] = e.productPrice;
  //   if (type == "master") setMaximumQuantity(Number(z.productBalance));
  //   if (type == "intquantity")
  //     list[index]["requiredProductQuantity"] =
  //       e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
  //   if (type == "intPrice") list[index]["marketPrice"] = e.target.value;
  //   setInputField1(list);
  // };

  const handleChange1 = (e, index, type) => {
    console.log(e, index, type, "test");
    const list = [...inputField1];
    if (type == "intquantity")
      list[index]["quantity"] =
        Number(e.target.value) <= list[index]["avaliableQuantity"]
          ? Number(e.target.value)
          : list[index]["avaliableQuantity"];
    let z = find(props.productDataList, (x) => x._id == e.value);
    console.log(z, "zzzzzzzzzzzzzzzzz");
    if (type == "intname") {
      list[index]["product"] = e.value;
      list[index]["productLabel"] = e.label;
      // list[index]["code"] = e.code;
      list[index]["avaliableQuantity"] = e.avaliableQuantity;
      list[index]["quantity"] = 1;
    }

    if (type == "intname")
      list[index]["productSellingPrice"] = Number(z.salePrice);
    if (type == "productSellingPrice")
      list[index]["productSellingPrice"] = Number(e.target.value);

    list[index]["totalSaleValue"] =
      list[index]["productSellingPrice"] * list[index]["quantity"];

    // setSubTotal(sumBy(list, "productTotalAmount"));
    // setGrandTotal(sumBy(list, "productTotalAmount") - totalDiscount);
    setInputField1(list);
  };
  const getMList =
    !isEmpty(props.productDataList) &&
    props.productDataList.map((x) => {
      let data = {
        value: x._id,
        label: `${x.code}-${x.name}`,
        avaliableQuantity: x.productBalance,
        productSellingPrice: x.salePrice,
      };
      return data;
    });
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("Add External Issuance");
    props.getProduct();
  }, []);
  return (
    <>
      {props.isFetchingProduct ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="4" lg="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date:</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Issued By *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Issued By...."
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="4" sm="4">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField1.map((item, i) => {
              console.log(item, "check");
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>

                        <Select
                          style={{ zIndex: 1 }}
                          value={{
                            label: item.productLabel,
                          }}
                          placeholder="Select Items..."
                          // name="master"
                          onChange={(e) => handleChange1(e, i, "intname")}
                          //   isDisabled={isEmpty(vendorId)}
                          options={getMList}
                          isOptionDisabled={(option) => (
                            console.log(
                              option,
                              inputField1,
                              "kkkkkkkkkkkkkkkkkkkk"
                            ),
                            !isEmpty(
                              find(
                                inputField1,
                                (x) => x.product == option.value
                              )
                            )
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Available Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity...."
                          name="avaliableQuantity"
                          disabled
                          value={item.avaliableQuantity}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Purchase Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Purchase Price...."
                          name="productSellingPrice"
                          disabled
                          value={item.productSellingPrice}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) =>
                            handleChange1(e, i, "intquantity", item.product)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Total Price</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Total Price...."
                          name="TotalPrice"
                          disabled
                          value={item.totalSaleValue}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {isEmpty(receivedBy) ||
              inputField1[0].productLabel == "" ||
              inputField1[0].quantity == 0 ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadExternalIssuance}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    addExternalIssuance: (data, handleVisible, handleDangerVisible) =>
      dispatch(addExternalIssuance(data, handleVisible, handleDangerVisible)),
    getProduct: () => dispatch(getProductData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  externalIssuanceList: state.ExternalIssuanceReducer.externalIssuanceList,
  isAddingExternalIssuance:
    state.ExternalIssuanceReducer.isAddingExternalIssuance,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddIssuanceByVendor);
