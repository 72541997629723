import API from "../../../../../global/api";
import { isEmpty, split, sumBy } from "lodash";
import jsPDF from "jspdf";
import moment from "moment";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
import { textData } from "../../../../../global/test";

export const requestFilterOrder = () => {
  return {
    type: "REQUEST_FILTER_ORDER",
  };
};
export const successFilterOrder = (data) => {
  return {
    type: "SUCCESS_FILTER_ORDER",
    payload: data,
  };
};
export const errorFilterOrder = () => {
  return {
    type: "ERROR_FILTER_ORDER",
  };
};
export const filterOrder = (data) => {
  return (dispatch) => {
    dispatch(requestFilterOrder());
    API.post(`/booking/booking_report`, data)
      .then((res) => {
        let getData = res.data
        dispatch(successFilterOrder(getData));
        // handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorFilterOrder());
        // handleDangerVisible(error.response.data.message);
      });
  };
};

export const requestAddOrder = () => {
  return {
    type: "REQUEST_ADD_ORDER",
  };
};
export const successAddOrder = (data) => {
  return {
    type: "SUCCESS_ADD_ORDER",
    payload: data,
  };
};
export const errorAddOrder = () => {
  return {
    type: "ERROR_ADD_ORDER",
  };
};
export const addOrder = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddOrder());
    API.post(`/booking`, data)
      .then((res) => {
        dispatch(successAddOrder(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddOrder());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetOrder = () => {
  return {
    type: "REQUEST_GET_ORDER",
  };
};
export const successGetOrder = (data) => {
  return {
    type: "SUCCESS_GET_ORDER",
    payload: data,
  };
};
export const errorGetAllOrder = () => {
  return {
    type: "ERROR_GET_ORDER",
  };
};
export const getOrder = (data) => {
  return (dispatch) => {
    dispatch(requestGetOrder());
    API.get(`/booking?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetOrder(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllOrder());
      });
  };
};

export const UpdateRequestOrder = () => {
  return {
    type: "UPDATE_REQUEST_ORDER",
  };
};
export const UpdateSuccessOrder = (data) => {
  return {
    type: "UPDATE_SUCCESS_ORDER",
    payload: data,
  };
};

export const UpdateErrorOrder = () => {
  return {
    type: "UPDATE_ERROR_ORDER",
  };
};

export const updateOrder = (data, oldData, rowData, updateTableQuery) => {
  console.log(data, oldData, "testttttttttt", rowData)
  return (dispatch) => {
    dispatch(UpdateRequestOrder());
    API.put(
      `/booking/${rowData._id}`,
      {

        _id: data._id,
        product: data.product,
        quantity: Number(data.quantity),
        productSellingPrice: Number(data.productSellingPrice)
      },
    )
      .then((res) => {
        dispatch(UpdateSuccessOrder([data]));
        // dispatch(getOrder())
        updateTableQuery()
      })
      .catch((error) => dispatch(UpdateErrorOrder()));
  };
};
export const DeleteRequestOrder = () => {
  return {
    type: "DELETE_REQUEST_ORDER",
  };
};
export const DeleteSuccessOrder = (id) => {
  return {
    type: "DELETE_SUCCESS_ORDER",
    id: id,
  };
};
export const DeleteErrorOrder = () => {
  return {
    type: "DELETE_ERROR_ORDER",
  };
};

export const deleteOrder = (id, updateTableQuery) => {
  return (dispatch) => {
    dispatch(DeleteRequestOrder());
    API.delete(`/booking/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessOrder(id));
        updateTableQuery()
      })
      .catch((error) => dispatch(DeleteErrorOrder()));
  };
};

export const DeleteRequestOrderProduct = () => {
  return {
    type: "DELETE_REQUEST_ORDER",
  };
};
export const DeleteSuccessOrderProduct = (id) => {
  return {
    type: "DELETE_SUCCESS_ORDER",
    id: id,
  };
};
export const DeleteErrorOrderProduct = () => {
  return {
    type: "DELETE_ERROR_ORDER",
  };
};

export const deleteOrderProduct = (data, rowData, updateTableQuery) => {
  return (dispatch) => {
    dispatch(DeleteRequestOrder());
    API.post(`/booking/delete_booking_products/${rowData._id}`, {
      _id: data._id,
      // product:data.product
    })
      .then((res) => {
        dispatch(DeleteSuccessOrder(rowData._id));
        updateTableQuery()
        getOrder()
      })
      .catch((error) => dispatch(DeleteErrorOrder()));
  };
};

export const requestGenerateOrderPDF = () => {
  return {
    type: "REQUEST_GENERATE_ORDER_PDF",
  };
};
export const successGenerateOrderPDF = (data) => {
  return {
    type: "SUCCESS_GENERATE_ORDER_PDF",
    payload: data,
  };
};
export const errorGenerateOrderPDF = () => {
  return {
    type: "ERROR_GENERATE_ORDER_PDF",
  };
};


export const generateOrderPDF = (id) => {
  return (dispatch) => {
    // let name = data.name;
    dispatch(requestGenerateOrderPDF());
    API.get(`/booking/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "getDataaaaa");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);
        doc.addFileToVFS('NotoSansArabic.ttf', textData);
        doc.addFont('NotoSansArabic.ttf', 'NotoSansArabic', 'normal');
        doc.setFont('NotoSansArabic');
        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const companyNTN = `${process.env.REACT_APP_COMPANY_NTN}`;

        doc.setFontSize(6);
        const headers = [
          [
            "Sr#",
            "Code",
            "Product Label",
            // "Packing",
            "Qty",
            "Rate",
            // "Dis%",
            // "Disc",
            "Total",
          ],
        ];
        const data = getData.data[0].items.map((x, i) => [
          i + 1,
          x.code,
          x.productLabel,
          x.quantity,
          x.productSellingPrice,
          x.productTotalAmount,
        ]);

        let content = {
          margin: {
            left: 12,
            right: 35,
            // size:2
          },
          startY: 150,
          // styles: { fontSize: 9 },
          // setFontSize(6),
          head: headers,
          body: data,
          styles: {
            font: 'NotoSansArabic',
            fontStyle: 'normal',
            fontWeight:'bold',
            fontSize:12,
          }
        };
        doc.setFontSize(13);
        doc.setFont("bold");
        doc.rect(15, 20, 280, 80);
        doc.text(companyName, 25, 35, 0, 20);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        doc.setFont('NotoSansArabic');
        doc.text(companyAddress, 25, 55, 0, 20);
        doc.text(`PH: ${companyPhone}`, 25, 75, 0, 20);
        doc.text(`VAT: ${companyNTN}`, 25, 95, 0, 20);
        doc.setFontSize(10);
        // doc.setFont("bold");
        doc.rect(15, 105, 280, 40);
        doc.text(`Invoice # : ${getData.data[0].number}`, 25, 120, 0, 20);
        doc.text(`Date : ${getData.data[0].date} `, 25, 135, 0, 20);
        doc.setFontSize(13);
        doc.rect(305, 20, 280, 125);
        doc.text(`Customer: `, 310, 40, 0, 20);
        doc.setFontSize(11);
        const shop = doc.splitTextToSize(getData.data[0].shopId.shopName, 220)
        let y = 40
        shop.map((x, i) => { doc.text(`${x}`, 375, y, 0, 20); y = y + 15 })
        doc.setFontSize(13);
        doc.text(`Customer PH: ${getData.data[0].shopId.ownerNumber}`, 310, y, 0, 20);
        doc.text(`Customer VAT: ${getData.data[0].shopId.ownerCnic}`, 310, y + 20, 0, 20);
        doc.text(`Area: ${getData.data[0].areaId.areaName}`, 310, y + 40, 0, 20);
        doc.text(`Sales Man: ${getData.data[0].salesmenId.userName}`, 310, y + 60, 0, 20);


        doc.setFontSize(6);
        doc.autoTable(content);

        doc.setFontSize(12);
        let finalY = doc.lastAutoTable.finalY;
        // doc.text(
        //   ` ${sumBy(getData.data[0].items, "quantity")}`,
        //   350,
        //   finalY + 10
        // );
        // doc.line(20, finalY + 40, 170, finalY + 40);
        // doc.text("Customer Signature", 40, finalY + 60);
        // doc.line(210, finalY + 40, 340, finalY + 40);
        doc.addImage(getData.data[0].zatcaInvoiceBase64, "PNG", 200, finalY + 20);
        // doc.text("Salesman Signature", 220, finalY + 60);
        doc.rect(420, finalY + 10, 140, 90);
        doc.text(`Sub Total: ${getData.data[0].subTotal}`, 430, finalY + 30);
        doc.text(`Discount: ${getData.data[0].discount}`, 430, finalY + 50);
        doc.text(`Tax: ${getData.data[0].tax}`, 430, finalY + 70);
        doc.text(`Grand Total: ${getData.data[0].grandTotal}`, 430, finalY + 90);
        // doc.save(
        //   `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        // );
        doc.setProperties(
          `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGenerateOrderPDF(getData));
      })
      .catch((error) => {
        console.log(error, "eeeeeeeeeeeeeeeeeeeee")
        dispatch(errorGenerateOrderPDF());
      });
  };
};
export const requestGetOrderById = () => {
  return {
    type: "REQUEST_GET_ORDER_BY_ID",
  };
};
export const successGetOrderById = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_BY_ID",
    payload: data,
  };
};
export const errorGetAllOrderById = () => {
  return {
    type: "ERROR_GET_ORDER_BY_ID",
  };
};
export const getOrderById = (id) => {
  return (dispatch) => {
    dispatch(requestGetOrderById());
    API.get(`/booking/${id}`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetOrderById(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllOrderById());
      });
  };
};
export const requestGetAllOrderByBookerId = () => {
  return {
    type: "REQUEST_GET_ALL_ORDER_BY_BOOKER_ID",
  };
};
export const successGetAllOrderByBookerId = (data) => {
  return {
    type: "SUCCESS_GET_ALL_ORDER_BY_BOOKER_ID",
    payload: data,
  };
};
export const errorGetAllOrderByBookerId = () => {
  return {
    type: "ERROR_GET_ALL_ORDER_BY_BOOKER_ID",
  };
};
export const generateAllOrderPDF = (data) => {
  return (dispatch) => {
    dispatch(requestGetAllOrderByBookerId());
    API.post(`/booking/booking_report`, data)
      .then((res) => {
        console.log(res.data, "getDataaaaa111");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const marginLeft = 4;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyPhone = `${process.env.REACT_APP_COMPANY_PHONE}`;
        isEmpty(res.data.data) ? handleNoDataVisible()
          : res.data.data.map((x, i) => {
            console.log(x, "tessssstttttt")
            doc.setFontSize(6);
            const headers = [
              [
                "Sr#",
                "Code",
                "Product Label",
                "Qty",
                "Rate",
                "Total",
              ],
            ];
            const data = x.items.map((y, j) => [
              j + 1,
              y.code,
              y.productLabel,
              y.quantity,
              y.productSellingPrice,
              y.productTotalAmount,
            ]);

            let content = {
              margin: {
                left: 12,
                right: 35,
              },
              startY: 150,
              styles: { fontSize: 9 },
              head: headers,
              body: data,
            };
            doc.setFontSize(20);
            doc.setFont("bold");
            doc.rect(15, 20, 280, 80);
            doc.text(companyName, 25, 50, 0, 20);
            doc.setFontSize(11);
            doc.setFont("TimesNewRoman", "Normal");
            doc.text(companyAddress, 25, 70, 0, 20);
            doc.text(`Ph: ${companyPhone}`, 25, 90, 0, 20);
            doc.setFontSize(10);
            doc.rect(15, 105, 280, 40);
            doc.text(`Invoice # : ${x.number}`, 25, 120, 0, 20);
            doc.text(`Date : ${x.date} `, 25, 135, 0, 20);
            doc.setFontSize(13);
            doc.rect(305, 20, 280, 105);
            doc.text(
              `Customer: `,
              310,
              40,
              0,
              20
            );
            doc.setFontSize(13);
            doc.text(
              `${x.shopId.shopName}`,
              365,
              40,
              0,
              20
            );
            doc.setFontSize(13);
            doc.text(
              `Customer Ph: ${x.shopId.ownerNumber}`,
              310,
              60,
              0,
              20
            );
            doc.text(`Area: ${x.areaId.areaName}`, 310, 80, 0, 20);
            doc.text(`Booker: ${x.bookerId.userName}`, 310, 100, 0, 20);
            doc.text(`Sales Man: ${x.salesMenId.name}`, 310, 120, 0, 20);
            doc.setFontSize(6);
            doc.autoTable(content);
            doc.setFontSize(12);
            let finalY = doc.lastAutoTable.finalY;
            doc.line(20, finalY + 40, 170, finalY + 40);
            doc.text("Customer Signature", 40, finalY + 60);
            doc.line(210, finalY + 40, 340, finalY + 40);
            doc.text("Salesman Signature", 220, finalY + 60);
            doc.rect(420, finalY + 10, 140, 70);
            doc.text(`Grand Total: ${x.subTotal}`, 430, finalY + 30);
            doc.text(`Discount: ${x.discount}`, 430, finalY + 50);
            doc.text(`Net Total: ${x.grandTotal}`, 430, finalY + 70);
            console.log(res.data.data.length, i, "tttttttttttt")
            res.data.data.length - 1 != i && doc.addPage()
          })
        doc.setProperties(
          `Order Booking Report ${moment(new Date()).format("YYYY-MM-DD")}`
        );
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
        dispatch(errorGetAllOrderByBookerId(res.data));
      })
      .catch((error) => {
        console.log(error, "eeeeeeeeeeeeeeeeee")
        dispatch(errorGetAllOrderByBookerId());
        // handleDangerVisible(error.response.data.message);
      });
  }

};


export const requestGetProductWithSalesMenID = () => {
  return {
    type: "REQUEST_GET_PRODUCT_WITH_SALESMENID",
  };
};
export const successGetProductWithSalesMenID = (data) => {
  return {
    type: "SUCCESS_GET_PRODUCT_WITH_SALESMENID",
    payload: data,
  };
};
export const errorGetProductWithSalesMenID = () => {
  return {
    type: "ERROR_GET_PRODUCT_WITH_SALESMENID",
  };
};
export const GetProductWithSalesMenIDAPI = (data) => {
  return (dispatch) => {
    dispatch(requestGetProductWithSalesMenID());
    API.post(`/booking/get_salesmen_wise_product`, data).then((res) => {
      let data1 = res.data;
      dispatch(successGetProductWithSalesMenID(data1));
    }).catch((error) => {
      dispatch(errorGetProductWithSalesMenID());
    });
  };
};
