
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty, get } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus, getInfoSTLedger, getStLedger } from "./HRDetails.actions";
import Select from 'react-select';
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";


const HRSTLedger = (props) => {

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState();
  console.log(item, 'item')
  /* const [state, setState] = React.useState({
     columns: [
       { title: 'Account Name', field: 'acc_name' },
       { title: 'Account Type', field: 'acc_type' },
       { title: 'Opening Balance', field: 'opening_balance' },
       { title: 'Credit Value', field: 'credit_value' },
       { title: 'Debit Value', field: 'debit_value' },
       { title: 'Closing Balance', field: 'closing_balance' },
       { title: 'Date', field: 'short_term_ledger_date' }
     ],
   });*/

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
  !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const handleChangeEmployee = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const STLedger = props.stLedger;
  console.log(STLedger, 'stledger')
  const exportPDF = () => {
    props.getStLedger({
      startDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
    });
    setShow(true);
  }
  useEffect(() => {
    props.setHeading("Short Term Advance Detail")
    // props.getEmployeeData();
    // props.getEmployeeDataWithStatus(1);

  }, []);

  function searchData() {
    props.getInfoSTLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      employee_id: item,
    });

  }

  return (
    <>
      {props.isFetchingStLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingStLedger ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) :
        (
          <Container fluid>
            <div className="main">
              <Row>
                <Col >
                  <Form.Group>
                    <Form.Label>
                      <b> Start Date </b>

                    </Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col >
                  <Form.Group>
                    <Form.Label>
                      <b> End Date</b>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>

              </Row>

              <Row style={{ marginTop: 15 }}>

                <Col lg="12" sm="12" className="text-center">
                  {startDate == "" || endDate == "" ?
                    <Button disabled >Search</Button> :

                    <Button onClick={() => exportPDF()}>Search</Button>

                  }
                </Col>

              </Row>
              {show &&
                <MaterialTable
                  title={`Short Term Advance Report ${startDate} - ${endDate}`}
                  columns={[
                    { title: 'Emloyee Name', field: 'employeeDetails.employeeName' },
                  { title: 'Department Name', field: 'employeeDetails.departmentDetails.department' },
                  { title: 'Designation Name', field: 'employeeDetails.designationDetails.designation' },
                  { title: 'Date', field: 'date' },
                  { title: 'Advance Given', field: 'debitValue' },
                  { title: 'Advance Deduction', field: 'creditValue' },
                  { title: 'Account Type', field: 'accType' },
                  ]}
                  data={props.stLedger}
                  // data={
                  //   // STLedger
                  //   (query) =>
                  //   new Promise((resolve, reject) => {
                  //       let url = `${baseURL}short_term_adv/get_st_employee_ledger?`;
                  //       url += "size=" + query.pageSize;
                  //       url += "&page=" + (query.page + 1);
                  //       url += "&search=" + query.search;
                  //       fetch(url, {
                  //           method: 'GET',
                  //           headers: headers,
                  //       })
                  //           .then((response) => response.json())
                  //           .then((result) => {
                  //               resolve({
                  //                   data: result.data,
                  //                   page: result.page - 1,
                  //                   totalCount: result.total_results,
                  //               });
                  //           });
                  //   })

                  // }
                  options={{
                    actionsColumnIndex: -1,
                    filtering: false,
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                // editable={{
                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         {
                //           props.updateFine(newData, oldData)
                //         }
                //       }, 600);
                //     }),
                //   onRowDelete: (oldData) =>
                //     new Promise((resolve) => {
                //       setTimeout(() => {
                //         resolve();
                //         props.deleteFine(oldData.fine_and_plenties_id);
                //         console.log(oldData, 'old')
                //       }, 600);
                //     }),
                // }}
                />}
            </div>
          </Container>
        )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    // getInfoSTLedger: (data) => dispatch(getInfoSTLedger(data)),
    getStLedger: (data) => dispatch(getStLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    // searchAccountLedgerReport: (data, startDate, endDate) => dispatch(searchAccountLedgerReport(data, startDate, endDate)),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingStLedger: state.HrReducer.isFetchingStLedger,
  stLedger: state.HrReducer.stLedger,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  stLedger: state.HrReducer.stLedger,
  isFetchingSTLedger: state.HrReducer.isFetchingSTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger: state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HRSTLedger);