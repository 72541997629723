import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import Select from 'react-select';
import Swal from 'sweetalert';
import { deleteShiftControl, getEmployeeData, getShiftControl, updateShiftControl } from "./HRDetails.actions";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const ShiftControlList = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };
    const employeeDataList = (!isEmpty(props.employeeList)) ? props.employeeList : [];
    const shiftIsAllocated = (shift_control_id) => {
        if ((!isEmpty(employeeDataList))) {
            for (let index = 0; index < employeeDataList.length; index++) {
                if (!isEmpty(employeeDataList[index].salaries)) {
                    if (employeeDataList[index].salaries[0].salary_creation_based === "Shift") {
                        if (employeeDataList[index].salaries[0].shift_id === shift_control_id) {
                            console.log("employeeWithThisShift", employeeDataList[index])
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }


    const shiftData = props.shiftControl;

    useEffect(() => {
        props.setHeading("Shift Control List")
        // props.getShiftControl();
        // props.getEmployeeData();
    }, []);
    return (
        <>
            {(props.isFetchingShiftControlData ||
                props.isFetchingShiftControlData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <MaterialTable
                        title="Shift Details"
                        columns={[
                            { title: 'Shift Name', field: 'shiftName' },
                            { title: 'Shift Mangement', field: 'shiftMangement',render:rowData=>(rowData.shiftMangement=="1"?"Two Time":rowData.shiftMangement=="2"?"Four Time":"") },
                            
                            { title: 'Arrival In', field: 'arrivalIn' },
                            { title: 'Break Out', field: 'breakOut' },
                            { title: 'Break In', field: 'breakIn' },
                            { title: 'Departure', field: 'departure' },
                            { title: 'Allowed Late Arrival In', field: 'allowLateArrivalIn' },
                            { title: 'Allowed Early Break Out', field: 'allowEarlyBreakOut' },
                            { title: 'Allowed Early Break In', field: 'allowEarlyBreakIn' },
                            { title: 'Allowed Late Departure', field: 'allowLateDeparture' },
                            { title: 'Jummah Arrival In', field: 'jummahArrivelIn' },
                            { title: 'Jummah Break Out', field: 'breakJummahOut' },
                            { title: 'Jummah Break In', field: 'breakJummahIn' },
                            { title: 'Jummah Departure', field: 'jummahDeparture' },
                            { title: 'Jummah Allowed Late Arrival In', field: 'jummahAllowLateArrivalIn' },
                            { title: 'Jummah Allowed Early Break Out', field: 'jummahAllowEarlyBreakOut' },
                            { title: 'Jummah Allowed Early Break In', field: 'jummahAllowEarlyBreakIn' },
                            { title: 'Jummah Allowed Late Departure', field: 'jummahAllowLateDeparture' },
                        ]}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                let url = `${baseURL}/shiftControl?`;
                                url += "limit=" + query.pageSize;
                                url += "&page=" + query.page;
                                url += "&search=" + query.search;
                                fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                })
                                    .then((response) => response.json())
                                    .then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                            })
                        }
                        options={{
                            actionsColumnIndex: -1,
                            filtering: false,
                            exportButton: true,
                            exportAllData: true,
                            sorting: true,
                            paging: true,
                            pageSize: 50, // make initial page size
                            emptyRowsWhenPaging: false, // To avoid of having empty rows
                            pageSizeOptions: [50, 100, 150, 200, 500], // rows selection options
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },

                        }}
                        editable={{
                            // isEditable: rowData => false,
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        {
                                            props.updateShiftControl(newData, oldData, updateTableQuery)
                                        }
                                    }, 600);
                                }),

                            isDeletable: rowData => shiftIsAllocated(rowData._id), //false to disable
                            // isDeleteHidden: rowData => true,
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.deleteShiftControl(oldData._id, updateTableQuery);
                                    }, 600);
                                }),
                        }}
                    />
                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getShiftControl: () => dispatch(getShiftControl()),
        deleteShiftControl: (shift_control_id,updateTableQuery) => dispatch(deleteShiftControl(shift_control_id,updateTableQuery)),
        updateShiftControl: (newData, oldData,updateTableQuery) => dispatch(updateShiftControl(newData, oldData,updateTableQuery)),
        getEmployeeData: () => dispatch(getEmployeeData()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isFetchingShiftControlData: state.HrReducer.isFetchingShiftControlData,
    isFetchingShiftControlData: state.HrReducer.isFetchingShiftControlData,
    shiftControl: state.HrReducer.shiftControl,



});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShiftControlList);