import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Navbar, Breadcrumb } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getVendorData } from "../Vendor/Vendor.action";
import { setHeading } from "../../../accounts/account.actions";
import IconMenu from "../../../drawer/IconMenu";
import { Link } from "react-router-dom";
import { getCustomerData } from "../AddCustomer/Customer.action";
import { areaWiseShops, generatePaymentReportPDF } from "./Reporting.action";
import { getArea } from "../AddArea/Area.action";
// import { addReceiving, getPDFById, getReceiving } from "./Receiving.action";

const AreaListing = (props) => {
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState('');

  function uploadPurchase() {
    //   props.getReceivingPdf(ReceivingId);
    props.areaWiseShops(areaId);
    setAreaId("")
    setAreaName("")
  }
  const AreaListData =
  !isEmpty(props.areaDataList) &&
  props.areaDataList.map((x) => {
    let data = { value: x._id, label: x.areaName };
    return data;
  });
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
  };
  useEffect(() => {
    props.setHeading("Payment Report");
    props.getArea();
    // props.getCustomerData();
  }, []);

  return (
    <>
      <Navbar style={{ backgroundColor: '#81abd3' }}  >
        <Container>
          <Navbar.Brand style={{ color: 'white' }}><b>Area Wise Shops</b></Navbar.Brand>
          <IconMenu />
        </Container>
      </Navbar>
      {props.isFetchingAreaData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingReceiving ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Area Wise Shops</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
            <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Area *</b>
                  </Form.Label>
                  <Select
                    value={{ label: areaName }}
                    isDisabled={isEmpty(AreaListData)}
                    placeholder="Select Area..."
                    onChange={handleChangeArea}
                    options={AreaListData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: 15 }}>
              {areaId == '' ? (
                <Button disabled>Generate PDF</Button>
              ) : (
                <Button onClick={uploadPurchase}>Generate PDF</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getReceiving:()=>dispatch(getReceiving()),
    // getReceivingPdf:(id)=>dispatch(getPDFById(id)),
    getArea: () => dispatch(getArea()),
    getVendorData: () => dispatch(getVendorData()),
    areaWiseShops: (id) =>
      dispatch(areaWiseShops(id)),
    setHeading: (data) => dispatch(setHeading(data)),
    getCustomerData: () => dispatch(getCustomerData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaListing);
