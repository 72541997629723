// reducers.js
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountReducer from "../views/user_views/accounts/account.reducers";
import dashboardReducer from "../views/user_views/forms/dashboard/dashboard.reducers";
// import adminAccountReducer from "../views/admin_views/accounts/account.reducers";
// import addLedgerReducer from "../views/user_views/forms/Ledger/level.reducer";
import usersReducer from "../views/admin_views/forms/users/users.reducer";
import LegderReducer from "../views/admin_views/forms/ledger/ledger.reducers";

// import ReturnReducer from "../views/user_views/forms/returnToVendors/returntovendors.reducer";
import VendorReducer from "../views/user_views/forms/AddDetails/Vendor/Vendor.reducer";
import VoucherReducer from "../views/user_views/forms/Vouchers/Voucher.reducers";
import POSViewReducer from "../views/user_views/forms/AddDetails/POS/POSView.reducers";
import accountsLevelReducer from "../views/user_views/forms/accountsLevels/accountsLevel.reducer";
import adminDemandDetailReducer from "../views/admin_views/AdminDetails/adminDetails.reducer";
// import productionReducer from '../views/user_views/forms/production/production.reducers';
import AccountLegderReducer from "../views/admin_views/accounts/ledger/Ledger.reducer";
import HrReducer from "../views/user_views/forms/HRDetails/AddDetails/HRDetails.reducers";
//import AddArticleReducer from '../views/user_views/forms/production/AddArticleFolder/AddArticles.reducers';
import MaterialLegderReducer from "../views/user_views/forms/materialLedger/materialledger.reducers";
//import AddArticleMaterialReducer from "../views/user_views/forms/production/AddArticleMaterialFolder/AddArticleMaterial.reducers"
// import AddLabourReducer from "../views/user_views/forms/production/AddLabourFolder/AddLabour.reducers";
// import COnversionCostReducer from "../views/user_views/forms/production/ConversionCostFolder/Conversion.reducers";
// import OrderCreationReducer from "../views/user_views/forms/production/OrderCreationFolder/OrderCreation.reducer"
// import AddAdminCostReducer from "../views/user_views/forms/production/AdminCost/AddAdminCost.reducers";
// import AddIssuanceReducer from "../views/user_views/forms/production/IssuanceFolder/Issuance.reducers";
import TransactitonSummaryReducer from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryReducer.reducers";
// import AddAgingReducer from "../views/user_views/forms/accountsAging/Aging.reducers";
// import productionDetailReducer from "../views/user_views/forms/production/ProductionDetails/ProductionDetail.reducer";
// import userReducerAdmin from "../views/admin_views/accounts/account.reducers";
import userReducer from "../views/user_views/accounts/account.reducers";
import userReducerAdmin from "../views/admin_views/accounts/account.reducers";
import profitLoss from "../views/user_views/accounts/ProfitLoss/ProfitLoss.reducer";
import permissionReducer from "../views/admin_views/forms/Permissions/UserPermissions.reducer";
import SalaryReducer from "../views/user_views/forms/HRDetails/salary/salary.reducers";
import GenerateSalaryReducer from "../views/user_views/forms/HRDetails/SalaryManagment/generateSalary.reducer";
import UserReducer from "../views/user_views/forms/AddUser/user.reducers";
import CouponReducer from "../views/user_views/forms/AddDetails/AddCoupons/Coupon.reducer";
import AreaReducer from "../views/user_views/forms/AddDetails/AddArea/Area.reducer";
import ShopReducer from "../views/user_views/forms/AddDetails/AddShop/Shop.reducer";
import OrderBookingReducer from "../views/user_views/forms/AddDetails/OrderBooking/OrderBooking.reducer";
import CreateIssuanceReducer from "../views/user_views/forms/AddDetails/CreateIssuance/Issuance.reducer";
import sizeReducer from "../views/user_views/forms/AddDetails/AddSize/size.reducer";
import FlavourReducer from "../views/user_views/forms/AddDetails/AddFlavour/Flavour.reducer";
import CustomerReducer from "../views/user_views/forms/AddDetails/AddCustomer/Customer.reducer";
import hallReducer from "../views/user_views/forms/AddDetails/AddHall/Hall.reducer";
import ResturantReducer from "../views/user_views/forms/AddDetails/AddResturant/Resturant.reducer";
import TableReducer from "../views/user_views/forms/AddDetails/AddTable/Table.reducer";
import DayReducer from "../views/user_views/forms/AddDetails/Day/Day.reducer";
import KDSReducer from "../views/user_views/forms/AddDetails/KDS/KDSView.reducers";
import MasterControlReducer from "../views/user_views/forms/AddDetails/AddMasterControl/MasterControl.reducer";
import ProductReducer from "../views/user_views/forms/AddDetails/AddProduct/Product.reducer";
import CategoryReducer from "../views/user_views/forms/AddDetails/categories/Catagory.reducer";
import RecipeReducer from "../views/user_views/forms/AddDetails/Recipe/Recipe.reducer";
import ReportReducer from "../views/user_views/forms/AddDetails/Reports/Report.resucer";
import RecivingReducer from "../views/user_views/forms/AddDetails/AddReciving/Receiving.reducer";
import InventoryLegderReducer from "../views/user_views/forms/AddDetails/InventoryLedger/InventoryLedger.reducer";
import BackUpReducer from "../views/user_views/forms/BackUp/BackUp.reducers";
import CreateDealReducer from "../views/user_views/forms/AddDetails/AddDeal/CreateDeal.reducer";
import IssuanceReducer from "../views/user_views/forms/AddDetails/Issuance/Issuance.reducer";
import uomReducer from "../views/user_views/forms/AddDetails/AddUOM/Uom.reducer";
import barCodeReducer from "../views/user_views/forms/AddDetails/Barcode/Barcode.reducer";
import subCategoryReducer from "../views/user_views/forms/AddDetails/SubCategories/SubCategory.reducer";
import ReportingReducer from "../views/user_views/forms/AddDetails/AddReporting/Reporting.reducer";
import ReturnReducer from "../views/user_views/forms/AddDetails/AddReturn/Return.reducer";
import ExternalIssuanceReducer from "../views/user_views/forms/AddDetails/IssuanceByVendor/IssuanceByVendor.reducer";
import ExternalReceivingReducer from "../views/user_views/forms/AddDetails/ReceivingByVendor/ReceivingByVendor.reducer";
import SalesmenLegderReducer from "../views/user_views/forms/AddDetails/SalesmenLedger/salesmenLedger.reducer";



// rootReducer.js
// Use ES6 object literal shorthand syntax to define the object shape
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    accountReducer,
    // adminAccountReducer,
    userReducerAdmin,
    userReducer,
    //addLedgerReducer,
    GenerateSalaryReducer,
    MaterialLegderReducer,
    usersReducer,
    LegderReducer,
    // AddDataReducer,
    // ReturnReducer,
    // ProcessedPurchaseOrderReducer,
    VoucherReducer,
    accountsLevelReducer,
    adminDemandDetailReducer,
    // productionReducer,
    AccountLegderReducer,
    HrReducer,
    // AddArticleReducer,
    // AddArticleMaterialReducer,
    // AddLabourReducer,
    // COnversionCostReducer,
    // OrderCreationReducer,
    // AddAdminCostReducer
    // AddAdminCostReducer,
    // AddIssuanceReducer,
    TransactitonSummaryReducer,
    // AddAgingReducer,
    // productionDetailReducer,
    SalesmenLegderReducer,
    profitLoss,
    permissionReducer,
    SalaryReducer,
    POSViewReducer,
    UserReducer,
    CouponReducer,
    AreaReducer,
    ShopReducer,
    OrderBookingReducer,
    CreateIssuanceReducer,
    ReportingReducer,
    sizeReducer,
    FlavourReducer,
    CustomerReducer,
    hallReducer,
    ResturantReducer,
    TableReducer,
    DayReducer,
    ReportReducer,
    MasterControlReducer,
    ProductReducer,
    CategoryReducer,
    RecipeReducer,
    KDSReducer,
    RecivingReducer,
    InventoryLegderReducer,
    VendorReducer,
    BackUpReducer,
    CreateDealReducer,
    IssuanceReducer,
    uomReducer,
    barCodeReducer,
    subCategoryReducer,
    ReturnReducer,
    ExternalIssuanceReducer,
    ExternalReceivingReducer,
    dashboardReducer
    
  });
