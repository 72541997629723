
import React, { useEffect, useRef, useState } from "react";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import moment from "moment";
import { setHeading } from "../../../accounts/account.actions";
import MaterialTable from "material-table";
import {
  deleteExternalReceiving,
  deleteExternalReceivingProduct,
  getExternalReceiving,
  getExternalReceivingByDate,
  pdfExternalReceiving,
  updateExternalReceiving,
  updateExternalReceivingProduct,
} from "./ReceivingByVendor.action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { isEmpty } from "lodash";
const ViewReceivingByVendorWithDate = (props) => {
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  function uploadOrder() {
    props.getExternalReceivingByDate(
      {
        startDate: startDate,
        endDate: endDate
      },
    );

    setStartDate(moment(new Date()).format("YYYY-MM-DD"))
    setEndDate(moment(new Date()).format("YYYY-MM-DD"))
  }
  useEffect(() => {
    // props.getReciving();
    props.setHeading("External Receiving List");
    console.log(props.ReceivingList, "uuuuuuuu");
  }, []);
  return (
    <>
      {props.isFetchingExternalReceivingList ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
              <Row>
            <Col >
              <FormGroup>
                <Form.Label>
                  <b>Start Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
            <Col >
              <FormGroup>
                <Form.Label>
                  <b>End Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>

          </Row>
          <div className="sendDiv" style={{marginTop:10}}>
            {isEmpty(startDate)||isEmpty(endDate) ? (
                <Button disabled>Get External Receiving</Button>
            ) : (
                <Button onClick={uploadOrder}>Get External Receiving</Button>
               
            )}
          </div>
          <div className="main" hidden={props.tableShow == false ? true : false}>
            <MaterialFlavors
              title="External Receiving Details"
              columns={[
                { title: "External Receiving Date", field: "receivingDate" },
                { title: "User", field: "userDetails.userName",editable:'never' },
                { title: "Received By", field: "receivedBy" },
                {
                  title: "External Receiving Description",
                  field: "receivingDescription",
                },
              ]}
              data={props.externalReceivingListWithDate}
              // data={(query) =>
              //   new Promise((resolve, reject) => {
              //     let url = `${baseURL}/externalReceving?`;
              //     url += "limit=" + query.pageSize;
              //     url += "&page=" + query.page;
              //     url += "&search=" + query.search;
              //     fetch(url, {
              //       method: "GET",
              //       headers: headers,
              //     })
              //       .then((response) => response.json())
              //       .then((result) => {
              //         resolve({
              //           data: result.data,
              //           page: result.page,
              //           totalCount: result.total_results,
              //         });
              //       });
              //   })
              // }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      { title: "Product Name ", field: "productLabel",editable:'never' },
                      {
                        title: "Add Quantity",
                        field: "quantity",
                      },
                      {
                        title: "Product Price",
                        field: "productSellingPrice",
                        editable:'never'
                      },
                    ]}
                    data={rowData.data}
                    // data={(query) =>
                    //     new Promise((resolve, reject) => {
                    //         let url = `${baseURL}/receiving?`;
                    //         url += "limit=" + query.pageSize;
                    //         url += "&page=" + (query.page);
                    //         url += "&search=" + query.search;
                    //         fetch(url, {
                    //             method: 'GET',
                    //             headers: headers,
                    //         })
                    //             .then((response) => response.json())

                    //             .then((result) => {
                    //                 let a=-1;
                    //                 resolve({

                    //                     data: result.data[a+1].receivingData,
                    //                     page: result.page,
                    //                     totalCount: result.total_results,
                    //                 });
                    //             });

                    //     })
                    // }
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateExternalReceivingProduct(newData, oldData,rowData,updateTableQuery);
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            props.deleteExternalReceivingProduct(oldData,rowData,updateTableQuery);
                          }, 600);
                        }),
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50, // make initial page flavors
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
                actions={[
                  {
                    icon: () => <PictureAsPdfIcon />,
                    tooltip: "Generate PDF",
                    onClick: (event, rowData) => {
                      props.pdfExternalReceiving(rowData);
                    },
                  },
                ]}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateExternalReceiving(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteExternalReceiving(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getExternalReceivingByDate: (data) => dispatch(getExternalReceivingByDate(data)),
    deleteExternalReceiving: (id) => dispatch(deleteExternalReceiving(id)),
    pdfExternalReceiving: (data) => dispatch(pdfExternalReceiving(data)),
    updateExternalReceiving: (newData, oldData) =>
      dispatch(updateExternalReceiving(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
    updateExternalReceivingProduct: (newData, oldData,rowData,updateTableQuery) =>
      dispatch(updateExternalReceivingProduct(newData, oldData,rowData,updateTableQuery)),
    deleteExternalReceivingProduct: (data,rowData,updateTableQuery) => dispatch(deleteExternalReceivingProduct(data,rowData,updateTableQuery)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  externalReceivingListWithDate:
    state.ExternalReceivingReducer.externalReceivingListWithDate,
    isFetchingExternalReceivingListWithDate:
    state.ExternalReceivingReducer.isFetchingExternalReceivingListWithDate,
    tableShow:
    state.ExternalReceivingReducer.tableShow,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReceivingByVendorWithDate);
