import React, { useEffect, useRef, useState } from "react";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import { setHeading } from "../../../accounts/account.actions";
import { deleteOrder, deleteOrderProduct, filterOrder, generateAllOrderPDF, generateOrderPDF, getOrder, getOrderById, updateOrder } from "./OrderBooking.action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import { getUserRes } from "../../AddUser/user.actions";
import { getArea } from "../AddArea/Area.action";
import { isEmpty, sumBy } from "lodash";
import Select from "react-select";
import moment from "moment";

const ViewBookingList = (props) => {
  const navigate = useNavigate();
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [bookerId, setBookerId] = useState("");
  const [bookerName, setBookerName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [areaId, setAreaId] = useState("");
  const filterdUserList =
    !isEmpty(props.userList) &&
    props.userList.filter((e) => e.role == "booker" || "bookerwholesale");
  const BookerList =
    !isEmpty(filterdUserList) &&
    filterdUserList.map((x) => {
      let data = { value: x._id, label: x.userName };
      return data;
    });
    const tableRef = useRef();
    function updateTableQuery () {
      tableRef.current.onQueryChange()
    };
  const handleChangeBooker = (selectedOption) => {
    setBookerId(selectedOption.value);
    setBookerName(selectedOption.label);
  };
  const handleChangeArea = (selectedOption) => {
    setAreaId(selectedOption.value);
    setAreaName(selectedOption.label);
  };
  const AreaListData =
    !isEmpty(props.areaDataList) &&
    props.areaDataList.map((x) => {
      let data = { value: x._id, label: x.areaName };
      return data;
    });
  function uploadOrder() {
    props.filterOrder(
      {
        areaId: areaId,
        bookerId: bookerId,
        startDate: startDate,
        endDate: endDate
      },
    );
    setAreaName("");
    setAreaId("");
    setBookerId("");
    setBookerName("")
    setStartDate(moment(new Date()).format("YYYY-MM-DD"))
    setEndDate(moment(new Date()).format("YYYY-MM-DD"))
  }
  useEffect(() => {
    props.setHeading("Order List");
    props.getArea();
    props.getUser()
  }, []);
  return (
    <>
      {props.isFetchingAreaData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col xl="3" lg="3" md="3" sm="12">
              <FormGroup>
                <Form.Label>
                  <b>Start Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
            <Col xl="3" lg="3" md="3" sm="12">
              <FormGroup>
                <Form.Label>
                  <b>End Date :</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </FormGroup>
            </Col>
            <Col style={{ zIndex: 5 }}>
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Booker *</b>
                </Form.Label>
                <Select
                  isDisabled={isEmpty(BookerList)}
                  value={{ label: bookerName }}
                  placeholder="Select Booker..."
                  onChange={handleChangeBooker}
                  options={BookerList}
                />
              </Form.Group>
            </Col>
            <Col style={{ zIndex: 5 }}>
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Area *</b>
                </Form.Label>
                <Select
                  style={{ zIndex: 5 }}
                  isDisabled={isEmpty(props.areaDataList)}
                  value={{ label: areaName }}
                  placeholder="Select Area..."
                  onChange={(e) => {
                    handleChangeArea(e);
                  }}
                  options={AreaListData}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="sendDiv">
            {isEmpty(bookerId) ? (
              <>
                <Button disabled>Get Booking</Button>
                <Button disabled style={{ marginLeft: 20 }} >Print All</Button></>
            ) : (
              <>
                <Button onClick={uploadOrder}>Get Booking</Button>
                <Button style={{ marginLeft: 20 }} onClick={() => {
                  props.generateAllOrderPDF(
                    {
                      areaId: areaId,
                      bookerId: bookerId,
                      startDate: startDate,
                      endDate: endDate
                    },
                  );
                }}>Print All</Button></>
            )}
          </div>

          <div className="main" hidden={props.tableShow == false ? true : false} >
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Number of order</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    placeholder="Number of order...."
                    value={isEmpty(props.filterBooking)?0:props.filterBooking.length}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Amount</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    placeholder="Total Amount...."
                    value={isEmpty(props.filterBooking)?0:sumBy(props.filterBooking,"subTotal")}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Discount</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    placeholder="Total Discount...."
                    value={isEmpty(props.filterBooking)?0:sumBy(props.filterBooking,"discount")}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Net Total</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    disabled
                    placeholder="Total Discount...."
                    value={isEmpty(props.filterBooking)?0:sumBy(props.filterBooking,"grandTotal")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <MaterialTable
            tableRef={tableRef}
              style={{ zIndex: 1 }}
              title="Order Booking Details"
              columns={[
                {
                  title: "Booking ID",
                  field: "number",
                  editable: "never",
                },
                {
                  title: "Area Name",
                  field: "areaId.areaName",
                  editable: "never",
                },
                {
                  title: "Shop Name",
                  field: "shopId.shopName",
                  editable: "never",
                },
                { title: "Sub Total", field: "subTotal" },
                { title: "Discount", field: "discount" },
                // { title: "Tax", field: "tax" },
                { title: "Grand Total", field: "grandTotal" },
                { title: "Status", field: "status" },
                { title: "Date", field: "dateShow" },
                { title: "Booker", field: "bookerId.userName" },
                { title: "Salesman", field: "salesMenId.name" },
              ]}
              // data={(query) =>
              //   new Promise((resolve, reject) => {
              //     let url = `${baseURL}/booking?`;
              //     url += "limit=" + query.pageSize;
              //     url += "&page=" + query.page;
              //     url += "&search=" + query.search;
              //     fetch(url, {
              //       method: "GET",
              //       headers: headers,
              //     })
              //       .then((response) => response.json())
              //       .then((result) => {
              //         resolve({
              //           data: result.data,
              //           page: result.page,
              //           totalCount: result.total_results,
              //         });
              //       });
              //   })}
              data={props.filterBooking}

              editable={{
                isDeletable:(rowData)=>rowData.status=='Booking Created'?true:false,
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                        props.deleteOrder(oldData._id,updateTableQuery);
                    }, 600);
                  }),
              }}
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    columns={[
                      {
                        title: "Code",
                        field: "code",
                      },
                      {
                        title: "Product Name",
                        field: "productLabel",
                      },
                      {
                        title: "Quantity",
                        field: "quantity",
                      },
                      {
                        title: "Product Price",
                        field: "productSellingPrice",
                      },

                      {
                        title: "Total Amount",
                        field: "productTotalAmount",
                      },
                    ]}
                    data={rowData.items}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      //   exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 50, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      // isEditHidden: (rowData) =>
                      //   Checkpermissions("permissionsSubCatName", "Area Edit"),
                      // isDeleteHidden: (rowData) =>
                      //   Checkpermissions("permissionsSubCatName", "Area Delete"),
                      isEditable:(rowData1)=>rowData.status=='Booking Created'?true:false,
                      isDeletable:(rowData1)=>rowData.status=='Booking Created'?true:false,
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateOrder(newData, oldData,rowData,updateTableQuery);
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                              props.deleteOrderProduct(oldData,rowData,updateTableQuery);
                          }, 600);
                        }),
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                paging: false,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.generateOrderPDF(rowData._id);
                  },
                },
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getArea: () => dispatch(getArea()),
    // getShop: () => dispatch(getShop()),
    // getOrder: () => dispatch(getOrder()),
    // updateArea: (newData, oldData) => dispatch(updateArea(newData, oldData)),
    // deleteArea: (id) => dispatch(deleteArea(id)),
    deleteOrder: (id) => dispatch(deleteOrder(id)),
    getOrderById: (id) => dispatch(getOrderById(id)),
    setHeading: (data) => dispatch(setHeading(data)),
    generateOrderPDF: (_id) => dispatch(generateOrderPDF(_id)),
    generateAllOrderPDF: (data) => dispatch(generateAllOrderPDF(data)),
    getUser: () => dispatch(getUserRes()),
    getArea: () => dispatch(getArea()),
    filterOrder: (data) => dispatch(filterOrder(data)),
    updateOrder:(data,oldData,rowData,updateTableQuery)=>dispatch(updateOrder(data,oldData,rowData,updateTableQuery)),
    deleteOrderProduct:(data,rowData,updateTableQuery)=>dispatch(deleteOrderProduct(data,rowData,updateTableQuery))
  };
};
const mapStateToProps = (state, ownProps) => ({
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  shopDataList: state.ShopReducer.shopDataList,
  isFetchingShopData: state.ShopReducer.isFetchingShopData,
  orderBookingDataList: state.OrderBookingReducer.orderBookingDataList,
  isFetchingOrderBookingData:
    state.OrderBookingReducer.isFetchingOrderBookingData,
  tableShow: state.OrderBookingReducer.tableShow,
  filterBooking: state.OrderBookingReducer.filterBooking,
  isFetchingOrderFilter:
    state.OrderBookingReducer.isFetchingOrderFilter,
  generateOrderPDFList: state.OrderBookingReducer.generateOrderPDFList,
  isFetchingGenerateOrderPDF:
    state.OrderBookingReducer.isFetchingGenerateOrderPDF,
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  // areaDataList: state.AreaReducer.areaDataList,
  // isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBookingList);
