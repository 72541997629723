import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getVendorWiseReport, getVendorWiseSaleReport } from "./Report.action";

import { Link } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";
import { getVendorData } from "../Vendor/Vendor.action";
import { getProductData } from "../AddProduct/Product.action";
const VendorWiseReportWithoutProfit = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const tableRef = useRef();
  function updateTableQuery() {
    tableRef.current.onQueryChange();
  }

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Report Generated Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const [state, setState] = React.useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportPDF = () => {
    props.getVendorWiseSaleReport(
      {
        startDate: startDate,
        endDate: endDate,
        vendorId: vendorId !== 0 ? vendorId : "All",
        product: productId !== 0 ? productId : "All",
      },
      startDate,
      endDate,
      vendorName,
      handleDangerVisible
    );
  };
  const handleChangeVendor = (selectedOption) => {
    console.log(selectedOption,"aaa")
    setProductId(0)
    selectedOption.value==0&&setProductName(selectedOption.label)
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };

      return data;
    });
  !isEmpty(getVendorList) &&
    getVendorList.push({
      label: "All",
      value: 0,
    });

  const handleChangeProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductName(selectedOption.label);
  };

  const filterdProductList =
    !isEmpty(props.productDataList) &&
    props.productDataList.filter((e) => e.vendorId == vendorId);

  const getProductList =
    !isEmpty(filterdProductList) &&
    filterdProductList.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  !isEmpty(getProductList) &&
    getProductList.push({
      label: "All",
      value: 0,
    });
  useEffect(() => {
    props.getProductData();
    props.getVendorData();
  }, []);
  return (
    <>
      <Navbar style={{ backgroundColor: "#81abd3" }}>
        <Container>
          <Navbar.Brand style={{ color: "white" }}>
            <b> Vendor Wise Sale Report</b>
          </Navbar.Brand>
          <IconMenu />
        </Container>
      </Navbar>
      {props.isFetchingVendor || props.isFetchingProduct ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/home"> Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/report">Report</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Vendor Wise Sale Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="3" lg="3" md="3" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>End Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Select Vendor :</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.vendorDataList)}
                    value={{ label: vendorName }}
                    placeholder="Select Vendor..."
                    onChange={handleChangeVendor}
                    options={getVendorList}
                  />
                </FormGroup>
              </Col>
              <Col xl="3" lg="3" md="3" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Select Product :</b>
                  </Form.Label>
                  <Select
                    isDisabled={vendorName == "All"}
                    value={{ label: productName }}
                    placeholder="Select Product..."
                    onChange={(e) => {
                      handleChangeProduct(e);
                    }}
                    options={getProductList}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "2%" }}>
              {vendorName==""||productName==""? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    border: "1px solid",
                  }}
                >
                  Generate Sale Report
                </Button>):
              // ) : props.isFetchingVendorWiseSaleReport ? (
              //   <Button>Generating PDF</Button>
              // ) : 
              (
                <Button onClick={() => exportPDF()}>
                  Generate Sale Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorData: () => dispatch(getVendorData()),
    getProductData: () => dispatch(getProductData()),
    getVendorWiseSaleReport: (data, startDate, endDate,vendorName, handleDangerVisible) =>
      dispatch(
        getVendorWiseReport(data, startDate, endDate,vendorName,handleDangerVisible)
      ),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  vendorWiseSaleReportData: state.ReportReducer.vendorWiseSaleReportData,
  isFetchingVendorWiseSaleReport:
    state.ReportReducer.isFetchingVendorWiseSaleReport,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorWiseReportWithoutProfit);
