import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { baseURL, headers } from "../../../../../global/api";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";

import { setHeading } from "../../../accounts/account.actions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { deleteIssuance, generateIssuancePDF, generatePaymentPDF, getIssuanceById, updatePayment } from "./Issuance.action";
import Modal from "react-bootstrap/Modal";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { filter, isEmpty } from "lodash";
const ViewPayments = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  useEffect(() => {
    props.setHeading("View Payments");
    // props.getArea();
  }, []);
  return (
    <>
      {props.isFetchingAreaData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
            tableRef={tableRef}
              title="Payment Details"
              columns={[
                {title:"Payment Id",field:'number',editable:'never'},
                { title: "Date", field: "date" ,editable:'never'},
                {
                  title: "Area Name",
                  field: "areaId.areaName",
                  editable: "never",
                },
                {
                  title: "SalesMen Name",
                  field: "salesMenId.name",
                  editable: "never",
                },
                {
                  title: "Booker Name",
                  field: "bookerDetails.userName",
                  editable: "never",
                },
                {
                  title:'Sub Total',
                  field:'subTotal',editable:'never'
                },
                 {
                  title:'Return',
                  field:'returnAmount',editable:'never'
                },
                {
                  title:'Discount',
                  field:'bookingDiscount',editable:'never'
                },
                {
                  title:'Credit',
                  field:'credit'
                },
                {
                  title:'Credit Receive',
                  field:'creditReceive'
                },
                {
                  title:'Grand Total',
                  field:'finalPaidAmount',editable:'never'
                }
                // { title: "Discount", field: "discount" },
                // // { title: "Tax", field: "tax" },
                // { title: "Grand Total", field: "grandTotal" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/issuance/payment_history?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    title="Product Detail"
                    columns={[
                      // {
                      //   title: "Code",
                      //   field: "product.code",
                      // },
                      {
                        title: "Product Name",
                        field: "productLabel",
                      },
                      {
                        title: "Quantity",
                        field: "quantity",
                      },

                      //   {
                      //     title: "Total Amount",
                      //     field: "productTotalAmount",
                      //   },
                    ]}
                    data={rowData.finalData}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      //   exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 50, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                paging: true,
                pageSize: 50, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              actions={[
                // {
                //   icon: () => <AutorenewIcon />,
                //   tooltip: "View Return Products",
                //   onClick: (event, rowData) => {
                //     props.getIssuance(rowData._id);
                //     setOpen(true);
                //   },
                // },
                {
                  icon: () => <PictureAsPdfIcon />,
                  tooltip: "Generate PDF",
                  onClick: (event, rowData) => {
                    props.generatePaymentPDF(rowData);
                  },
                }
                // (rowData) => {
                //   return rowData.status == "Close"
                //     ? {
                //       icon:'delete',
                //       disabled:true
                //     }:
                // {
                //   icon: 'delete',
                //   tooltip: "Delete Issuance",
                //   disabled: false,
                //   onClick: (event, rowData) => {
                //     props.deleteIssuance(rowData._id,updateTableQuery);
                //   },
                // }}
              ]}
              editable={{
                // isEditHidden: (rowData) =>
                //   Checkpermissions("permissionsSubCatName", "Issuance Edit"),
                // isDeleteHidden: (rowData) =>
                //   Checkpermissions("permissionsSubCatName", "Issuance Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updatePayment(newData, oldData,updateTableQuery);
                      }
                    }, 600);
                  }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     setTimeout(() => {
                //       resolve();
                //         props.deleteIssuance(oldData._id);
                //     }, 600);
                //   }),
              }}
            />
          </div>
          <Modal
            show={open}
            onHide={handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ marginLeft: "15%" }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Return Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {props.isFetchingGenerateIssuancePDF ? (
                <div className="loader-div">
                  <Button variant="info" disabled>
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="info"
                    />
                    Loading...
                  </Button>
                </div>
              ) : (
                <MaterialTable
                  title="Product Detail"
                  columns={[
                    // {
                    //   title: "Code",
                    //   field: "product.code",
                    // },
                    {
                      title: "Product Name",
                      field: "productLabel",
                    },
                    {
                      title: "Quantity",
                      field: "quantity",
                    },
                  ]}
                  data={
                    !isEmpty(props.generateIssuancePDFList) &&
                    props.generateIssuancePDFList[0].returnProducts
                  }
                  options={{
                    actionsColumnIndex: -1,
                    toolbar: false,
                    exportButton: true,
                    sorting: true,
                    // filtering:true,
                    paging: true,
                    maxBodyHeight: "600px",
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                />
              )}
            </Modal.Body>
            {/* <Modal.Footer>
              <Button onClick={handleClose}>Close</Button>
            </Modal.Footer> */}
          </Modal>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getArea: () => dispatch(getArea()),
    // getShop: () => dispatch(getShop()),
    // getOrder: () => dispatch(getOrder()),
    // updateArea: (newData, oldData) => dispatch(updateArea(newData, oldData)),
    deleteIssuance: (id,updateTableQuery) => dispatch(deleteIssuance(id,updateTableQuery)),
    setHeading: (data) => dispatch(setHeading(data)),
    generateIssuancePDF: (_id) => dispatch(generateIssuancePDF(_id)),
    generatePaymentPDF:(data)=>dispatch(generatePaymentPDF(data)),
    getIssuance: (id) => dispatch(getIssuanceById(id)),
    updatePayment:(data,oldData,updateTableQuery)=>dispatch(updatePayment(data,oldData,updateTableQuery)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  areaDataList: state.AreaReducer.areaDataList,
  isFetchingAreaData: state.AreaReducer.isFetchingAreaData,
  shopDataList: state.ShopReducer.shopDataList,
  isFetchingShopData: state.ShopReducer.isFetchingShopData,
  orderBookingDataList: state.OrderBookingReducer.orderBookingDataList,
  isFetchingOrderBookingData:
    state.OrderBookingReducer.isFetchingOrderBookingData,
  generateIssuancePDFList: state.CreateIssuanceReducer.generateIssuancePDFList,
  isFetchingGenerateIssuancePDF:
    state.CreateIssuanceReducer.isFetchingGenerateIssuancePDF,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPayments);
