import { Container } from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";

import { setHeading } from "../../../accounts/account.actions";
import { useEffect, useRef } from "react";
import MaterialTable from "material-table";
import {
  deleteExternalReceiving,
  deleteExternalReceivingProduct,
  getExternalReceiving,
  pdfExternalReceiving,
  updateExternalReceiving,
  updateExternalReceivingProduct,
} from "./ReceivingByVendor.action";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const ViewReceivingByVendor = (props) => {
  const tableRef = useRef();
  function updateTableQuery () {
    tableRef.current.onQueryChange()
  };
  useEffect(() => {
    // props.getReciving();
    props.setHeading("External Receiving List");
    console.log(props.ReceivingList, "uuuuuuuu");
  }, []);
  return (
    <>
      {props.isFetchingExternalReceivingList ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
            tableRef={tableRef}
              title="External Receiving Details"
              columns={[
                { title: "External Receiving Date", field: "receivingDate" },
                { title: "User", field: "userDetails.userName" ,editable:'never'},
                { title: "Received By", field: "receivedBy" },
                {
                  title: "External Receiving Description",
                  field: "receivingDescription",
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/externalReceving?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      { title: "Product Name ", field: "productLabel",editable:'never' },
                      {
                        title: "Add Quantity",
                        field: "quantity",
                       
                      },
                      {
                        title: "Product Price",
                        field: "productSellingPrice",
                        editable:'never' 
                      },
                    ]}
                    data={rowData.data}
                   
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: false,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            {
                              props.updateExternalReceivingProduct(newData, oldData,rowData,updateTableQuery);
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            props.deleteExternalReceivingProduct(oldData,rowData,updateTableQuery);
                          }, 600);
                        }),
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50, // make initial page flavors
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100, 150, 200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
                actions={[
                  {
                    icon: () => <PictureAsPdfIcon />,
                    tooltip: "Generate PDF",
                    onClick: (event, rowData) => {
                      props.pdfExternalReceiving(rowData);
                    },
                  },
                ]}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateExternalReceiving(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteExternalReceiving(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getExternalReceiving: () => dispatch(getExternalReceiving()),
    deleteExternalReceiving: (id) => dispatch(deleteExternalReceiving(id)),
    deleteExternalReceivingProduct: (data,rowData,updateTableQuery) => dispatch(deleteExternalReceivingProduct(data,rowData,updateTableQuery)),
    pdfExternalReceiving: (data) => dispatch(pdfExternalReceiving(data)),
    updateExternalReceiving: (newData, oldData) =>
      dispatch(updateExternalReceiving(newData, oldData)),
    updateExternalReceivingProduct: (newData, oldData,rowData,updateTableQuery) =>
      dispatch(updateExternalReceivingProduct(newData, oldData,rowData,updateTableQuery)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  externalReceivingDataList:
    state.ExternalReceivingReducer.externalReceivingDataList,
  isFetchingExternalReceivingList:
    state.ExternalReceivingReducer.isFetchingExternalReceivingList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReceivingByVendor);
