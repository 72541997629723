import API from "../../../../../global/api";
export const requestAddArea = () => {
  return {
    type: "REQUEST_ADD_AREA",
  };
};
export const successAddArea = (data) => {
  return {
    type: "SUCCESS_ADD_AREA",
    payload: data,
  };
};
export const errorAddArea = () => {
  return {
    type: "ERROR_ADD_AREA",
  };
};
export const addArea = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddArea());
    API.post(`/area/`, data)
      .then((res) => {
        dispatch(successAddArea(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddArea());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const requestGetArea = () => {
  return {
    type: "REQUEST_GET_AREA",
  };
};
export const successGetArea = (data) => {
  return {
    type: "SUCCESS_GET_AREA",
    payload: data,
  };
};
export const errorGetAllArea = () => {
  return {
    type: "ERROR_GET_AREA",
  };
};
export const getArea = (data) => {
  return (dispatch) => {
    dispatch(requestGetArea());
    API.get(`/area/?limit=1000&page=0&search`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successGetArea(data1));
      })
      .catch((error) => {
        dispatch(errorGetAllArea());
      });
  };
};

export const UpdateRequestArea = () => {
  return {
    type: "UPDATE_REQUEST_AREA",
  };
};
export const UpdateSuccessArea = (data) => {
  return {
    type: "UPDATE_SUCCESS_AREA",
    payload: data,
  };
};

export const UpdateErrorArea = () => {
  return {
    type: "UPDATE_ERROR_AREA",
  };
};

export const updateArea = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestArea());
    API.put(
      `/area/${oldData._id}`,
      {
        areaName: data.areaName,
        areaDesc: data.areaDesc,
        location: data.location,
      },
      {}
    )
      .then((res) => {
        dispatch(UpdateSuccessArea([data]));
      })
      .catch((error) => dispatch(UpdateErrorArea()));
  };
};
export const DeleteRequestArea = () => {
  return {
    type: "DELETE_REQUEST_AREA",
  };
};
export const DeleteSuccessArea = (id) => {
  return {
    type: "DELETE_SUCCESS_AREA",
    id: id,
  };
};
export const DeleteErrorArea = () => {
  return {
    type: "DELETE_ERROR_AREA",
  };
};

export const deleteArea = (id) => {
  return (dispatch) => {
    dispatch(DeleteRequestArea());
    API.delete(`/area/${id}`)
      .then((res) => {
        dispatch(DeleteSuccessArea(id));
      })
      .catch((error) => dispatch(DeleteErrorArea()));
  };
};
