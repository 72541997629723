import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, upperCase } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import Select from 'react-select';
import Swal from 'sweetalert';
import { baseURL, headers } from "../../../../../global/api";
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import { deleteFine, getFine, updateFine } from "./HRDetails.actions";
import { setHeading } from "../../../accounts/account.actions";

const FineList = (props) => {



    useEffect(() => {
        props.setHeading("Fine & Penaltiess List");
    }, []);
    return (
        <>
            {(props.isFetchingFineData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <MaterialTable
                            title="Fine & Penalties"
                            columns={[
                                // { title: 'Employee Id', field: 'employee_id', render: rowData => appendNonSignificantZeros(rowData.employee_id) },
                                { title: 'Employee', field: 'employeeDetails.employeeName', editable: 'never' },
                                { title: 'Reason', field: 'reasonFine' },
                                { title: 'Amount Deducted', field: 'amountDeduct' },
                                { title: 'Status', field: 'status', editable: 'never' },
                                { title: 'Date', field: 'date' },
                            ]}
                            data={
                                // fineData
                                (query) =>
                                    new Promise((resolve, reject) => {
                                        let url = `${baseURL}/fineAndPenalties?`;
                                        url += "limit=" + query.pageSize;
                                        url += "&page=" + query.page;
                                        url += "&search=" + query.search;
                                        fetch(url, {
                                            method: 'GET',
                                            headers: headers,
                                        })
                                            .then((response) => response.json())
                                            .then((result) => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.page,
                                                    totalCount: result.total_results,
                                                });
                                            });
                                    })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                // filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updateFine(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteFine(oldData._id);
                                            console.log(oldData, 'old')
                                        }, 600);
                                    }),
                            }}
                        />
                    </Form>
                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFine: () => dispatch(getFine()),
        deleteFine: (fine_and_plenties_id) => dispatch(deleteFine(fine_and_plenties_id)),
        updateFine: (newData, oldData) => dispatch(updateFine(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    fine: state.HrReducer.fine,
    isFetchingFineData: state.HrReducer.isFetchingFineData,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FineList);