const initialState = {
  isAddingReporting: false,
  reportingData: [],
  isFetchingReporting: false,
  reportingDataList: [],
};

const ReportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_REPORTING":
      return { ...state, isAddingReporting: true };
    case "SUCCESS_ADD_REPORTING":
      return {
        ...state,
        reportingData: action.payload,
        isAddingReporting: false,
      };
    case "ERROR_ADD_REPORTING":
      return { ...state, isAddingReporting: false };

    case "REQUEST_GET_REPORTING":
      return { ...state, isFetchingReporting: true };
    case "SUCCESS_GET_REPORTING":
      return {
        ...state,
        reportingDataList: action.payload.data,
        isFetchingReporting: false,
      };
    case "ERROR_GET_REPORTING":
      return { ...state, isFetchingReporting: false };

    default:
      return state;
  }
};
export default ReportingReducer;
