import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addRestaurant } from "./Resturant.action";
import { setHeading } from "../../../accounts/account.actions";
import ImageUploader from "react-images-upload";
import { addProductImage } from "../AddProduct/Product.action";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import '../../../../../il8n';
const AddResturant = (props) => {
  const { t, i18n } = useTranslation();
  const [restaurant, setRestaurant] = useState("");
  const [restaurantDesc, setRestaurantDesc] = useState("");
  const [tax, setTax] = useState(0);
  const [ntnNumber, setNTNNumber] = useState(0);
  const [praOnCard, setPRAOnCard] = useState(0);
  const [praOnCash, setPRAOnCash] = useState(0);
  const [fbrOnCash, setFBROnCash] = useState(0);
  const [fbrOnCard, setFBROnCard] = useState(0);
  const [restaurantLocation, setRestaurantLocation] = useState("");
  // const [warehouseKey, setWarehouseKey] = useState();
  const [s1, setS1] = useState("PRATax");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
    setRestaurant("")
    setRestaurantDesc("")
    setTax(0)
    setNTNNumber(0)
    setPRAOnCard(0)
    setPRAOnCash(0)
    setFBROnCash(0)
    setFBROnCard(0)
    setRestaurantLocation("")
    setS1("PRATax")
  }

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
  const handleSwitch = () => {
    s1 == "FBRTax" ? setS1("PRATax") : setS1("FBRTax")
  }
  console.log(s1, "testswitch")
  // const label1 = { inputProps: { 'aria-label': 'Yes' } };
  // console.log(label1,"testswitch")
  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles, pictureDataURLs, "yyy")
    props.ProductImage({
      image: pictureFiles[0],
    })
    //setUploadImage(pictureFiles);
  };
  function uploadRestaurant() {
    props.addRestaurant({
      wareHouseName: restaurant,
      wareHouseLocation: restaurantLocation,
      wareHouseDesc: restaurantDesc,
      tax: tax,
      image: props.ProductImageURL.file,
      ntn: ntnNumber,
      praTaxCash: praOnCash,
      praTaxCard: praOnCard,
      fbrTaxCard: fbrOnCard,
      fbrTaxCash: fbrOnCash,
      taxType: 'FBRTax',
    }, handleVisible, handleDangerVisible);
    setRestaurantDesc("");
    setS1("PRATax")
  }
  useEffect(() => {
    props.setHeading("Add Warehouse");
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isAddingRes ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('warehouseName')} *</b></Form.Label>
                      <Form.Control type="text" placeholder={t('warehouseName')} value={restaurant} onChange={(e) =>
                        setRestaurant(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('warehouseLoc')} *</b></Form.Label>
                      <Form.Control type="text" placeholder={t('warehouseLoc')}
                        value={restaurantLocation}
                        onChange={(e) => {
                          setRestaurantLocation(e.target.value)
                        }
                        } />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('warehouseDescription')} :</b></Form.Label>
                      <Form.Control type="text" value={restaurantDesc} placeholder={t('warehouseDescription')} onChange={(e) =>
                        setRestaurantDesc(e.target.value)} />
                    </Form.Group>
                  </Col>
                  {/* <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('warehouseTaxAmount')} :</b></Form.Label>
                      <Form.Control type="Number" max={100} value={tax} placeholder={t('warehouseTaxAmount')} onChange={(e) =>
                        setTax(e.target.value)} />
                    </Form.Group>
                  </Col> */}
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('NTNNumber')} :</b></Form.Label>
                      <Form.Control type="Number" value={ntnNumber} placeholder={t('NTNNumber')} onChange={(e) =>
                        setNTNNumber(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('NTNNumber')} :</b></Form.Label>
                      <Form.Control type="Number" value={ntnNumber} placeholder={t('NTNNumber')} onChange={(e) =>
                        setNTNNumber(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="1" md="1" xs="1"></Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: '31px' }}>
                    <Form.Group >
                      <Form.Label><b>{t('PRATax')}</b></Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg="1" md="1" xs="1" className="mt-4" style={{ marginRight: '2%', marginLeft: '-5%' }}>
                    <Switch onClick={handleSwitch} />
                  </Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: '31px' }}>
                    <Form.Group  >
                      <Form.Label><b>{t('FBRTax')}</b></Form.Label>
                    </Form.Group>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('PRATaxOnCash')} :</b></Form.Label>
                      <Form.Control type="Number" disabled={s1 == "FBRTax" ? true : false} value={praOnCash} max={100} placeholder={t('PRATaxOnCash')} onChange={(e) =>
                        setPRAOnCash(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('PRATaxOnCard')} :</b></Form.Label>
                      <Form.Control type="Number" value={praOnCard} disabled={s1 == "FBRTax" ? true : false} max={100} placeholder={t('PRATaxOnCard')} onChange={(e) =>
                        setPRAOnCard(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('FBRTaxOnCash')} :</b></Form.Label>
                      <Form.Control type="Number" value={fbrOnCash}  max={100} placeholder={t('FBRTaxOnCash')} onChange={(e) =>
                        setFBROnCash(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('FBRTaxOnCard')} :</b></Form.Label>
                      <Form.Control type="Number" value={fbrOnCard} max={100} placeholder={t('FBRTaxOnCard')} onChange={(e) =>
                        setFBROnCard(e.target.value)} />
                    </Form.Group>
                    {/* <FormControlLabel control={<Switch defaultChecked />} label="Label" /> */}
                  </Col>

                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          {t('UploadProductImage')} *
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText={t('uploadImage')}
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {isEmpty(restaurant) || isEmpty(restaurantLocation) || isEmpty(props.ProductImageURL) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "#1976d2", border: "1px solid #1976d2" }}>{t('save')}</Button> : <Button onClick={uploadRestaurant}>{t('save')}</Button>}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRestaurant: (data, handleVisible, handleDangerVisible) => dispatch(addRestaurant(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    ProductImage: (data) => dispatch(addProductImage(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restaurantList: state.ResturantReducer.restaurantList,
  isAddingRestaurant: state.ResturantReducer.isAddingRestaurant,
  ProductImageURL: state.ProductReducer.ProductImageURL,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResturant);
