import React, { useEffect, useState } from "react";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { setHeading } from "../../../accounts/account.actions";
import { addProductFile } from "./Issuance.action";
import { getCategoryData } from "../categories/Catagory.action";
import { isEmpty } from "lodash";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import {
  getCategoryByWarehouseId,
  getSubCategoryByCategoryId,
} from "../SubCategories/SubCategory.action";
import { getVendorData } from "../Vendor/Vendor.action";

const UploadIssuance = (props) => {
  const [file, setProductFile] = useState();
  // console.log('attendanceFile', productFile);
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [CategoryId, setCategoryId] = useState("");
  const [Categoryname, setCategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryname, setSubCategoryName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [AlertVisible, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "File Upload Failed...",
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
    props.getCategoryByWarehouseId(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.wareHouseName };
      return data;
    });
  const getCategoryList =
    !isEmpty(props.categoryListByWarehouseId) &&
    props.categoryListByWarehouseId.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  const handleChangeCategory = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
    props.getSubCategoryByCategoryId(selectedOption.value);
  };

  // const getSubCategoryList =
  //   !isEmpty(props.subCategoryListByCategoryId) &&
  //   props.subCategoryListByCategoryId.map((x) => {
  //     let data = { value: x._id, label: x.subCategoryName };
  //     return data;
  //   });
  // const handleChangeSubCategory = (selectedOption) => {
  //   console.log(selectedOption, "sop");
  //   setSubCategoryId(selectedOption.value);
  //   setSubCategoryName(selectedOption.label);
  // };

  const handleChangeVendor = (selectedOption) => {
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
  };
  const getVendorList =
    !isEmpty(props.vendorDataList) &&
    props.vendorDataList.map((x) => {
      let data = { value: x._id, label: x.vendorName };
      return data;
    });

  const handleGenerate = () => {
    props.addProductFile(
      {
        file: file,
      },
      warehouseId,
      CategoryId,
      // subCategoryId,
      vendorId,
      handleVisible,
      handleDangerVisible
    );
  };

  useEffect(() => {
    props.getRestaurantData();
    props.getVendorData();
    props.setHeading("Upload Product");
    //props.resetState();
  }, []);
  return (
    <>
      {props.isAddingProduct ||
      props.isFetchingCategoryByWarehouseId ||
      props.isFetchingSubCategoryByCategoryId ||
      props.isFetchingRestaurant ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="mainHr">
            <Row>
              <Col xl="4" lg="4" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select WareHouse *</b>
                  </Form.Label>
                  <Select
                    value={{ label: warehouseName }}
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select WareHouse..."
                    onChange={handleChangeWarehouse}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col xl="4" lg="4" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select Category *</b>
                  </Form.Label>
                  <Select
                    value={{ label: Categoryname }}
                    isDisabled={
                      isEmpty(props.categoryListByWarehouseId) ||
                      isEmpty(warehouseId)
                    }
                    placeholder="Select Category..."
                    onChange={handleChangeCategory}
                    options={getCategoryList}
                  />
                </Form.Group>
              </Col>
              {/* <Col xl="4" lg="4" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select Sub Category *</b>
                  </Form.Label>
                  <Select
                    value={{ label: subCategoryname }}
                    isDisabled={
                      isEmpty(props.subCategoryListByCategoryId) ||
                      isEmpty(CategoryId)
                    }
                    placeholder="Select Sub Category..."
                    onChange={handleChangeSubCategory}
                    options={getSubCategoryList}
                  />
                </Form.Group>
              </Col> */}
              <Col xl="4" lg="4" md="6" xs="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Select Vendor *</b>
                  </Form.Label>
                  <Select
                    value={{ label: vendorName }}
                    isDisabled={isEmpty(props.vendorDataList)}
                    placeholder="Select Vendor..."
                    onChange={handleChangeVendor}
                    options={getVendorList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group controlId="garana" className="mb-3">
                  <Form.Label>Upload File Here *</Form.Label>
                  <Form.Control
                    type="file"
                    className="form-control"
                    onBlur={(e) => setProductFile(e.target.files[0])}
                    // multiple
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {isEmpty(warehouseId) ||
              isEmpty(CategoryId) ||
              isEmpty(vendorId) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "#1976d2",
                    border: "1px solid #1976d2",
                  }}
                >
                  Upload File
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    handleGenerate();
                  }}
                >
                  Upload File
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getProductType:()=>dispatch(getProductType()),
    addProductFile: (
      file,
      warehouseId,
      CategoryId,
      vendorId,
      // subCategoryId,
      handleVisible,
      handleDangerVisible
    ) =>
      dispatch(
        addProductFile(
          file,
          warehouseId,
          CategoryId,
          // subCategoryId,
          vendorId,
          handleVisible,
          handleDangerVisible
        )
      ),
    setHeading: (data) => dispatch(setHeading(data)),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryByWarehouseId: (id) => dispatch(getCategoryByWarehouseId(id)),
    getSubCategoryByCategoryId: (id) =>
      dispatch(getSubCategoryByCategoryId(id)),
    getVendorData: () => dispatch(getVendorData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  categoryListByWarehouseId: state.subCategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId:
    state.subCategoryReducer.isFetchingCategoryByWarehouseId,
  subCategoryListByCategoryId:
    state.subCategoryReducer.subCategoryListByCategoryId,
  isFetchingSubCategoryByCategoryId:
    state.subCategoryReducer.isFetchingSubCategoryByCategoryId,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  vendorDataList: state.VendorReducer.vendorDataList,
  isFetchingVendor: state.VendorReducer.isFetchingVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadIssuance);
