const initialState = {
  externalIssuanceList: [],
  isAddingExternalIssuance: false,
  externalIssuanceDataList: [],
  isFetchingExternalIssuanceList: false,
  isFetchingExternalIssuanceListWithDate:false,
  externalIssuanceListWithDate:[],
  tableShow:false
};

const ExternalIssuanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_EXTERNAL_ISSUANCE":
      return { ...state, isAddingExternalIssuance: true };
    case "SUCCESS_ADD_EXTERNAL_ISSUANCE":
      return {
        ...state,
        externalIssuanceList: action.payload,
        isAddingExternalIssuance: false,
      };
    case "ERROR_ADD_EXTERNAL_ISSUANCE":
      return { ...state, isAddingExternalIssuance: false };
    case "REQUEST_GET_EXTERNAL_ISSUANCE":
      return { ...state, isFetchingExternalIssuanceList: true };
    case "SUCCESS_GET_EXTERNAL_ISSUANCE":
      return {
        ...state,
        externalIssuanceDataList: action.payload.data,
        isFetchingExternalIssuanceList: false,
      };
    case "ERROR_GET_EXTERNAL_ISSUANCE":
      return { ...state, isFetchingExternalIssuanceList: false };
      case "REQUEST_GET_EXTERNAL_ISSUANCE_BY_DATE":
        return { ...state, isFetchingExternalIssuanceListWithDate: true,tableShow:false };
      case "SUCCESS_GET_EXTERNAL_ISSUANCE_BY_DATE":
        return {
          ...state,
          externalIssuanceListWithDate: action.payload.data,
          isFetchingExternalIssuanceListWithDate: false,
          tableShow:true
        };
      case "ERROR_GET_EXTERNAL_ISSUANCE_BY_DATE":
        return { ...state, isFetchingExternalIssuanceListWithDate: false,tableShow:false };
    default:
      return state;
  }
};
export default ExternalIssuanceReducer;
