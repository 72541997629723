import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import moment from "moment";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { isEmpty, filter, find } from "lodash";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import {
  getUsers,
  getPermissions,
  addPermissions,
} from "./UserPermissions.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { setHeading } from "../../../accounts/account.actions";

const UserPermissions = (props) => {
  const userData = props.usersList;

  const [show, setShow] = useState(false);

  const [users, setUsers] = useState("");
  const [mainCategory1, setMainCategory1] = useState();
  const [mainCategory2, setMainCategory2] = useState();
  const [mainCategory3, setMainCategory3] = useState();
  const [mainCategoryMaster, setMainCategoryMaster] = useState();
  const [checkedData, setChecked] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleChangeUsers = (selectedOption) => {
    setUsers(selectedOption.value);
  };

  const handleInventory = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats.map((x) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[0].permissionsName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsCatName: x.permissionsCatName,
                permissionsName: props.permissionsList[0].permissionsName,
                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheck = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      isEmpty(data)
        ? updatedList.push({
            permissionsCatName: event.target.value,
            permissionsName: props.permissionsList[0].permissionsName,
          })
        : data.map((z) => {
            updatedList.push({
              permissionsCatName: event.target.value,
              permissionsName: props.permissionsList[0].permissionsName,
              permissionsSubCatName: z,
            });
          });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSub = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[0].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleMaster = (event) => {
    setMainCategoryMaster(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[1].permissionsName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsCatName: x.permissionsCatName,
                permissionsName: props.permissionsList[1].permissionsName,
                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckMaster = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      isEmpty(data)
        ? updatedList.push({
            permissionsCatName: event.target.value,
            permissionsName: props.permissionsList[1].permissionsName,
          })
        : data.map((z) => {
            updatedList.push({
              permissionsCatName: event.target.value,
              permissionsName: props.permissionsList[1].permissionsName,
              permissionsSubCatName: z,
            });
          });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSubMaster = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[1].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleAccounts = (event) => {
    setMainCategory2(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          updatedList.push({
            permissionsName: props.permissionsList[1].permissionsName,
            permissionsCatName: x.permissionsCatName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsName: props.permissionsList[1].permissionsName,
                permissionsCatName: x.permissionsCatName,

                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheck1 = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      data.map((z) => {
        updatedList.push({
          permissionsName: props.permissionsList[1].permissionsName,
          permissionsCatName: event.target.value,

          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSub1 = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: props.permissionsList[1].permissionsName,
        permissionsCatName: pt.permissionsCatName,

        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleSetting = (event) => {
    setMainCategory3(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[4].permission_cats.map((x) => {
          updatedList.push({
            permissionsName: props.permissionsList[4].permissionsName,
            permissionsCatName: x.permissionsCatName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsName: props.permissionsList[4].permissionsName,
                permissionsCatName: x.permissionsCatName,

                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[4].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSetting = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[4].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      data.map((z) => {
        updatedList.push({
          permissionsName: props.permissionsList[4].permissionsName,
          permissionsCatName: event.target.value,

          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSubSetting = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: props.permissionsList[4].permissionsName,
        permissionsCatName: pt.permissionsCatName,

        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const [mainCategoryPos, setMainCategoryPos] = useState();
  const handlePos = (event) => {
    setMainCategoryPos(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const [mainCategoryKds, setMainCategoryKds] = useState();
  const handleKds = (event) => {
    setMainCategoryKds(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const [mainCategoryTable, setMainCategoryTable] = useState();
  const handleTable = (event) => {
    console.log(event, "testttttttt");
    setMainCategoryTable(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const [mainCategoryBackUp, setMainCategoryBackUp] = useState();
  const handleBackUp = (event) => {
    console.log(event, "testttttttt");
    setMainCategoryTable(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const [mainCategoryViewOrder, setMainCategoryViewOrder] = useState();
  const handleViewOrder = (event) => {
    setMainCategoryViewOrder(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const [mainCategoryReport, setMainCategoryReport] = useState();
  const handleReport = (event) => {
    setMainCategoryReport(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const [state, setState] = React.useState({
    columns: [{ title: "User Name", field: "username" }],

    data: [{ username: "Zahra" }],
  });

  const data = {
    MainCategory1: "Inventory",
    MainCategory1Id: 1,
    MainCategory2: "Accounts",
    MainCategory3: "HR",
    inventory: [
      {
        id: "2",
        name: "warehouse",
        arr: [
          {
            id: "2",
            name: "warehouse",
          },
          {
            id: "2",
            name: "warehouse",
          },
        ],
      },
      {
        id: "3",
        name: "Category",
      },
    ],
    accounts: [
      {
        id: "4",
        name: "Level 2",
      },
      {
        id: "5",
        name: "Level 3",
      },
    ],
    hr: [
      {
        id: "6",
        name: "Employee",
      },
    ],
  };
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  useEffect(() => {
    props.getUsers();
    props.getPermissions();
    props.setHeading("Permission");
  }, []);

  const sendData = () => {
    props.addPermissions(
      {
        user: users,
        permission: checkedData,
      },
      handleVisible,
      handleDangerVisible
    );
  };
  console.log(props.usersList, "listttttttttttttt");
  const getUsersData =
    !isEmpty(props.usersList) &&
    props.usersList.data.map((x) => {
      let data = { value: x._id, label: x.userName };
      return data;
    });

  return (
    <>
      {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel2 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select User</b>
                  </Form.Label>
                  <Select
                    placeholder="Select User..."
                    // value={{label:level1label}}
                    onChange={(e) => handleChangeUsers(e)}
                    options={getUsersData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <h2>User Permissions</h2>

            <Row>
              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[0].permissionsName
                    }
                    onChange={(event) => handleInventory(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[0].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[0].permission_cats.map((inv, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={inv.permissionsCatName}
                              checked={
                                !isEmpty(checkedData)
                                  ? find(
                                      checkedData,
                                      (x) =>
                                        x.permissionsCatName ==
                                        inv.permissionsCatName
                                    )
                                  : false
                              }
                              id="flexCheckDefault"
                              onChange={(e) => handleCheck(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {inv.permissionsCatName}
                            </label>
                            {!isEmpty(inv.permissionSubCats) &&
                              inv.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={
                                            !isEmpty(checkedData)
                                              ? find(
                                                  checkedData,
                                                  (x) =>
                                                    x.permissionsSubCatName ==
                                                    sub.permissionsSubCatName
                                                )
                                              : false
                                          }
                                          id="flexCheckDefault"
                                          onChange={(e) =>
                                            handleCheckSub(e, inv)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>

              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[1].permissionsName
                    }
                    permissionsCatName={""}
                    onChange={(event) => handleAccounts(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[1].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[1].permission_cats.map((acc, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={acc.permissionsCatName}
                              permissionsCatName="flexCheckDefault"
                              checked={
                                !isEmpty(checkedData)
                                  ? find(
                                      checkedData,
                                      (x) =>
                                        x.permissionsCatName ==
                                        acc.permissionsCatName
                                    )
                                  : false
                              }
                              onChange={(e) => handleCheck1(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {acc.permissionsCatName}
                            </label>
                            {!isEmpty(acc.permissionSubCats) &&
                              acc.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={
                                            !isEmpty(checkedData)
                                              ? find(
                                                  checkedData,
                                                  (x) =>
                                                    x.permissionsSubCatName ==
                                                    sub.permissionsSubCatName
                                                )
                                              : false
                                          }
                                          permissionsCatName="flexCheckDefault"
                                          onChange={(e) =>
                                            handleCheckSub1(e, acc)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>

              <Col lg="3" md="3" sm="12">
                {/*  <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[2].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleViewOrder(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[2].permissionsName}
                  </label>
                </div> */}
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[2].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleReport(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[2].permissionsName}
                  </label>
                </div>
              </Col>
              {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[4].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handlePos(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[4].permissionsName}
                  </label>
                </div> */}
              {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[6].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleKds(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[6].permissionsName}
                  </label>
                </div> */}
              {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[7].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleTable(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[7].permissionsName}
                  </label>
                </div> */}
              {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[5].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleBackUp(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[5].permissionsName}
                  </label>
                </div> */}
              {/* </Col> */}
              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[4].permissionsName
                    }
                    permissionsCatName={""}
                    onChange={(event) => handleSetting(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[4].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[4].permission_cats.map((acc, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={acc.permissionsCatName}
                              permissionsCatName="flexCheckDefault"
                              checked={
                                !isEmpty(checkedData)
                                  ? find(
                                      checkedData,
                                      (x) =>
                                        x.permissionsCatName ==
                                        acc.permissionsCatName
                                    )
                                  : false
                              }
                              onChange={(e) => handleCheckSetting(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {acc.permissionsCatName}
                            </label>
                            {!isEmpty(acc.permissionSubCats) &&
                              acc.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={
                                            !isEmpty(checkedData)
                                              ? find(
                                                  checkedData,
                                                  (x) =>
                                                    x.permissionsSubCatName ==
                                                    sub.permissionsSubCatName
                                                )
                                              : false
                                          }
                                          permissionsCatName="flexCheckDefault"
                                          onChange={(e) =>
                                            handleCheckSubSetting(e, acc)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
            </Row>

            <Row style={{ textAlign: "center" }}>
              <Col lg="12" md="12" sm="12">
                <Button
                  disabled={users == "" ? true : false}
                  onClick={() => sendData()}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>

    // <>
    //     {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
    //         <div className="loader-div">
    //             <Button variant="info" disabled>
    //                 <Spinner
    //                     animation="grow"
    //                     size="sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                     variant="info"
    //                 />
    //                 {props.isSavingLevel2 ? "Saving" : "Loading"}
    //             </Button>
    //         </div>
    //     ) : (
    //         <Container fluid>
    //             <div className="main">
    //                 <MaterialTable
    //                     title="Users"
    //                     columns={state.columns}
    //                     data={userData}
    //                     //data={state.data}
    //                     actions={[
    //                         {
    //                             tooltip: 'User Permissions',
    //                             onClick: (event, rowData) => alert("You saved")
    //                         }
    //                     ]}
    //                     components={{
    //                         Action: props => (
    //                             <Button
    //                                 onClick={() => handleShow()}
    //                             >
    //                                 Permissions
    //                             </Button>
    //                         ),
    //                     }}
    //                     options={{
    //                         actionsColumnIndex: -1,
    //                         paging: true,
    //                         pageSize: 200,       // make initial page size
    //                         emptyRowsWhenPaging: false,   // To avoid of having empty rows
    //                         pageSizeOptions: [50, 100, 150, 200],
    //                         headerStyle: {
    //                             position: 'sticky', top: 0,
    //                             color: '#00BBBB',
    //                             fontWeight: '550',
    //                             onRowAdd: 'none',
    //                         }
    //                     }}
    //                 />

    //                 <Modal size="lg" show={show} onHide={handleClose} animation={true}>
    //                     <Modal.Header closeButton>
    //                         <Modal.Title>User Permissions</Modal.Title>
    //                     </Modal.Header>
    //                     <Modal.Body>
    //                         <Row>
    //                             <Col lg="4" md="6" sm="12">
    //                                 <div style={{ marginLeft: "5%" }}>
    //                                     <input
    //                                         className="form-check-input"
    //                                         type="checkbox"
    //                                         id="flexCheckDefault"
    //                                         value={!isEmpty(props.permissionsList) && props.permissionsList[0].permissions_id}
    //                                         onChange={(event) => handleInventory(event)}
    //                                     />
    //                                     <label className="form-check-label" for="flexCheckDefault">
    //                                     {!isEmpty(props.permissionsList) && props.permissionsList[0].permissionsName}
    //                                     </label>
    //                                 </div>
    //                                 {!isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((inv, index) => {
    //                                     return (
    //                                         <Row>
    //                                             <Col lg="12" md="12" sm="12">
    //                                                 <div style={{ marginLeft: "15%" }}>
    //                                                     <input
    //                                                         className="form-check-input"
    //                                                         type="checkbox"
    //                                                         value={inv.permissions_cat_id}
    //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == inv.permissions_cat_id) : false}
    //                                                         id="flexCheckDefault"
    //                                                         onChange={(e) => handleCheck(e)}
    //                                                     />
    //                                                     <label className="form-check-label" for="flexCheckDefault">
    //                                                         {inv.permissionsCatName}
    //                                                     </label>
    //                                                 </div>
    //                                             </Col>
    //                                         </Row>
    //                                     );
    //                                 })}
    //                             </Col>

    //                             <Col lg="4" md="6" sm="12">
    //                                 <div style={{ marginLeft: "5%" }}>
    //                                     <input
    //                                         className="form-check-input"
    //                                         type="checkbox" value={!isEmpty(props.permissionsList) && props.permissionsList[1].permissions_id}
    //                                         id={""}
    //                                         onChange={(event) => handleAccounts(event)}
    //                                         />
    //                                     <label className="form-check-label" for="flexCheckDefault">
    //                                         {!isEmpty(props.permissionsList) && props.permissionsList[1].permissionsName}
    //                                     </label>
    //                                 </div>
    //                                 {!isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((acc, index) => {
    //                                     return (
    //                                         <Row>
    //                                             <Col lg="12" md="12" sm="12">
    //                                                 <div style={{ marginLeft: "15%" }}>
    //                                                     <input
    //                                                         className="form-check-input"
    //                                                         type="checkbox"
    //                                                         value={acc.permissions_cat_id}
    //                                                         id="flexCheckDefault"
    //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == acc.permissions_cat_id) : false}
    //                                                         onChange={(e) => handleCheck1(e)}
    //                                                     />
    //                                                     <label className="form-check-label" for="flexCheckDefault">
    //                                                         {acc.permissionsCatName}
    //                                                     </label>
    //                                                 </div>
    //                                             </Col>
    //                                         </Row>
    //                                     );
    //                                 })}
    //                             </Col>

    //                             <Col lg="4" md="6" sm="12">
    //                                 <div style={{ marginLeft: "5%" }}>
    //                                     <input
    //                                         className="form-check-input"
    //                                         type="checkbox"
    //                                         value={!isEmpty(props.permissionsList) && props.permissionsList[2].permissions_id}
    //                                         id="flexCheckDefault"
    //                                         onChange={(event) => handleHR(event)}
    //                                     />
    //                                     <label className="form-check-label" for="flexCheckDefault">
    //                                         {!isEmpty(props.permissionsList) && props.permissionsList[2].permissionsName}
    //                                     </label>
    //                                 </div>
    //                                 {!isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((val, index) => {
    //                                     return (
    //                                         <Row>
    //                                             <Col lg="12" md="12" sm="12">
    //                                                 <div style={{ marginLeft: "15%" }}>
    //                                                     <input
    //                                                         className="form-check-input"
    //                                                         type="checkbox"
    //                                                         value={val.permissions_cat_id}
    //                                                         id="flexCheckDefault"
    //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == val.permissions_cat_id) : false}
    //                                                         onChange={(e) => handleCheck2(e)}
    //                                                     />
    //                                                     <label className="form-check-label" for="flexCheckDefault">
    //                                                         {val.permissionsCatName}
    //                                                     </label>
    //                                                 </div>
    //                                             </Col>
    //                                         </Row>
    //                                     );
    //                                 })}
    //                             </Col>
    //                         </Row>

    //                     </Modal.Body>
    //                     <Modal.Footer>
    //                         <Button variant="secondary" onClick={handleClose}>
    //                             Close
    //                         </Button>
    //                         <Button variant="primary" onClick={handleClose}>
    //                             Send
    //                         </Button>
    //                     </Modal.Footer>
    //                 </Modal>
    //             </div>

    //         </Container>

    //     )}
    // </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    getPermissions: () => dispatch(getPermissions()),
    addPermissions: (data, handleVisible, handleDangerVisible) =>
      dispatch(addPermissions(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  usersList: state.permissionReducer.usersList,
  permissionsList: state.permissionReducer.permissionsList,
  isFetchingUsersList: state.permissionReducer.isFetchingUsersList,
  isFetchingPermissionsList: state.permissionReducer.isFetchingPermissionsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
