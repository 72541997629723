import API from "../../../../../global/api";
import { get, isEmpty, isNull, isUndefined, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";

export const requestAddIssuance = () => {
  return {
    type: "REQUEST_ADD_ISSUANCE",
  };
};
export const successAddIssuance = (data) => {
  return {
    type: "SUCCESS_ADD_ISSUANCE",
    payload: data,
  };
};
export const errorAddIssuance = () => {
  return {
    type: "ERROR_ADD_ISSUANCE",
  };
};

export const addIssuance = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddIssuance());
    API.post(`/issuance/`, data)
      .then((res) => {
        dispatch(getIssuance());
        dispatch(successAddIssuance(data));
        dispatch(getHybridAndCompleteData());
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddIssuance());
        handleDangerVisible();
      });
  };
};

export const requestGetIssuance = () => {
  return {
    type: "REQUEST_GET_ISSUANCE",
  };
};
export const successGetIssuance = (data) => {
  return {
    type: "SUCCESS_GET_ISSUANCE",
    payload: data,
  };
};
export const errorGetIssuance = () => {
  return {
    type: "ERROR_GET_ISSUANCE",
  };
};

export const getIssuance = () => {
  return (dispatch) => {
    dispatch(requestGetIssuance());
    API.get(`/issuance/`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetIssuance(getData));
      })
      .catch((error) => {
        dispatch(errorGetIssuance());
      });
  };
};
//issuance Report by id

export const requestSearchIssuance = () => {
  return {
    type: "REQUEST_SEARCH_ISSUANCE",
  };
};
export const successSearchIssuance = (data) => {
  return {
    type: "SUCCESS_SEARCH_ISSUANCE",
    payload: data,
  };
};
export const errorSearchIssuance = () => {
  return {
    type: "ERROR_SEARCH_ISSUANCE",
  };
};

export const searchIssuanceReport = (data, issuanceName) => {
  return (dispatch) => {
    dispatch(requestSearchIssuance());
    API.get(`/issuance/${data.issuanceId}`)
      .then((res) => {
        let resData = res.data.data;
        let resData1 = res.data;
        console.log(resData, "testtttttttttttt");
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Issuance Report";
        const companyName = `${resData1.resImage.restaurantName}`;
        const companyAddress = `${resData1.resImage.restaurantLocation}`;
        const companyNtn = `${resData1.resImage.ntn}`;
        const logo1 = resData1.restaurantImage;
        const demandName = "Issuance #: " + issuanceName;
        const IssuanceDate = resData[0].issuanceDate;
        const issuedBy = resData[0].issuedBy;
        const receivedBy = resData[0].receivedBy;
        const supervisor = resData[0].supervisor;
        const department = resData[0].department.department;
        // const IGPNumber = "Issuance #: " + resData.map((x) => x.issuanceId);
        //  const Dates = !isEmpty(startDate) || !isEmpty(endDate) ? "Issuance Date " + " " + startDate + " to " + endDate : "Issuance Date - ";
        const headers = [
          ["code", "PRODUCT NAME", "UOM", "PRICE", "QUANTITY", "TOTAL"],
        ];
        const data2 = resData[0].data.map((elt, index) => [
          // elt.inventory.warehouse.whKey +
          //   "-" +
          //   elt.inventory.category.cateKey +
          //   "-" +
          //   elt.inventory.sub_category.subcateKey +
          //   "-" +
          //   elt.inventory.invKey,
          isNull(elt.inventoryId.code) ? "" : elt.inventoryId.code,
          elt.inventoryLabel,
          elt.inventoryId.uomLabel,
          elt.stockPrice,
          elt.issuanceQuantity,
          elt.stockPrice * elt.issuanceQuantity,
        ]);
        console.log(data2, "kkkkkkkkkkkkkkkkkk");
        const Total = sumBy(data2, "5");
        // const shortTotal=Total.slice(12);
        let content = {
          startY: 190,
          startX: 10,
          head: headers,
          body: data2,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
        doc.text(title, 240, 120);
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.text(demandName, 40, 140, 0, 20);
        doc.text(`Issuance Date: ${IssuanceDate}`, 420, 140, 0, 20);
        doc.text(`Issued By:${issuedBy}`, 40, 160, 0, 20);
        doc.text(`Received By:${receivedBy}`, 420, 160, 0, 20);
        doc.text(`Department :${department}`, 40, 180, 0, 20);
        doc.text(`Supervisor :${supervisor}`, 420, 180, 0, 20);
        // doc.text(Dates, 380, 120, 0, 20);

        doc.autoTable(content);
        doc.text(
          `Total: ${Total.toFixed(2)}`,
          440,
          doc.lastAutoTable.finalY + 20,
          0,
          20
        );
        doc.save(
          `${issuanceName} IssuanceReport ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
        dispatch(successSearchIssuance(resData));
      })
      .catch((error) => {
        console.log(error, "testtttttttttttt");
        dispatch(errorSearchIssuance());
      });
  };
};

// issuance report by date
export const requestSearchIssuanceByDate = () => {
  return {
    type: "REQUEST_SEARCH_ISSUANCE_BYDATE",
  };
};
export const successSearchIssuanceByDate = (data) => {
  return {
    type: "SUCCESS_SEARCH_ISSUANCE_BYDATE",
    payload: data,
  };
};
export const errorSearchIssuanceByDate = () => {
  return {
    type: "ERROR_SEARCH_ISSUANCE_BYDATE",
  };
};

export const searchIssuanceReportByDate = (
  data,
  startDate,
  endDate,
  departmentLabel,
  handleVisible
) => {
  return (dispatch) => {
    dispatch(requestSearchIssuanceByDate());
    API.post(`/issuance/get_issuance_with_dates`, data)
      .then((res) => {
        let resData = res.data.data;
        let resData1 = res.data;
        if (isEmpty(resData)) {
          handleVisible();
        } else {
          console.log(resData, resData1, "datessssss");
          const unit = "pt";
          const size = "A4";
          const orientation = "potrait";

          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);

          doc.setFontSize(11);
          const title = "Issuance Report";
          const companyName = `${resData1.resImage.restaurantName}`;
          const companyAddress = `${resData1.resImage.restaurantLocation}`;
          const companyNtn = `${resData1.resImage.ntn}`;
          const logo1 = resData1.restaurantImage;
          // const demandName = "Issuance #: " + issuanceName;
          const IssuanceDate = resData[0].issuanceDate;
          const issuedBy = resData[0].issuedBy;
          const receivedBy = resData[0].receivedBy;
          const supervisor = resData[0].supervisor;
          const department = isUndefined(departmentLabel)
            ? ""
            : departmentLabel;
          // const IGPNumber = "Issuance #: " + resData.map((x) => x.issuanceId);
          const Dates =
            !isEmpty(startDate) || !isEmpty(endDate)
              ? "Issuance Date " + " " + startDate + " to " + endDate
              : "Issuance Date - ";
          const headers = [
            ["Code", "Product NAME", "Quantity", "Rate", "Amount"],
          ];
          const data2 = resData.map((elt, index) => [
            elt.inventoryId.code,
            elt.inventoryLabel,
            elt.issuanceQuantity,
            elt.inventoryId.purchasePrice,
            elt.inventoryId.purchasePrice * elt.issuanceQuantity,
            //    (elt.inventory.productPrice*elt.productQuantity)
          ]);
          const Total = sumBy(resData, "issuanceQuantity");
          const TotalAmount = sumBy(data2, "4");
          // const shortTotal=Total.slice(12);
          let content = {
            startY: 190,
            startX: 10,
            head: headers,
            body: data2,
          };
          doc.addImage(logo1, "PNG", 40, 15, 100, 100);
          doc.setFontSize(20);
          doc.setFont(undefined, "bold");
          doc.text(companyName, 150, 40, 0, 20);
          doc.setFontSize(13);
          doc.setFont("TimesNewRoman", "Normal");
          doc.text(companyAddress, 150, 60, 0, 20);
          doc.text(companyNtn, 150, 80, 0, 20);
          doc.setFontSize(14);
          doc.setFont(undefined, "bold");
          doc.text(title, 240, 120);
          doc.setFontSize(12);
          doc.setFont(undefined, "normal");
          doc.text(`From ${startDate} to ${endDate}`, 220, 140);
          doc.text(`Department :${department}`, 250, 160, 0, 20);
          doc.autoTable(content);
          doc.text(
            `Total Quantity: ${Total.toFixed(2)}`,
            400,
            doc.lastAutoTable.finalY + 20,
            0,
            20
          );
          doc.text(
            `Total Amount: ${TotalAmount.toFixed(2)}`,
            400,
            doc.lastAutoTable.finalY + 40,
            0,
            20
          );
          doc.save(
            `Issuance Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
          );
        }
        dispatch(successSearchIssuanceByDate(resData));
      })
      .catch((error) => {
        console.log(error, "uuuuuuuuuuuuuuuuuu");
        dispatch(errorSearchIssuanceByDate());
      });
  };
};
export const requestAddProductFile = () => {
  return {
    type: "REQUEST_ADD_PRODUCT_FILE",
  };
};

export const successAddProductFile = (data) => {
  return {
    type: "SUCCESS_ADD_PRODUCT_FILE",
    // payload: data
  };
};

export const errorAddProductFile = () => {
  return {
    type: "ERROR_ADD_PRODUCT_FILE",
  };
};
export const addProductFile = (
  file,
  warehouseId,
  categoryId,
  // subCategoryId,
  vendorId,
  handleVisible,
  handleDangerVisible
) => {
  console.log(file, "tttttttttttttttttttt");

  const formData = new FormData();
  // formData.append("file", `${data.file}`);
  formData.append("file", file.file);
  formData.append("wareHouseId", `${warehouseId}`);
  formData.append("category", `${categoryId}`);
  // formData.append("subCategoryId", `${subCategoryId}`);
  formData.append("vendorId", `${vendorId}`);

  console.log(formData, "ttttttttttttttttttttttt");
  // forEach(data.uploadCnicImage, (x, index) => {
  //     formData.append("uploadCnicImage", data.uploadCnicImage[index]);
  // })
  // forEach(data.uploadDocument, (x, index) => {
  //     formData.append("uploadDocument", data.uploadDocument[index]);
  // })
  // // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
  // //formData.append("uploadDocument", get(data, 'uploadDocument'));

  return (dispatch) => {
    dispatch(requestAddProductFile());
    API.post(
      `/products/upload_products`,
      formData
      // , {
      //     headers: {
      //         'Content-Type': 'multipart/form-data'
      //     }
      // }
    )
      .then((res) => {
        let getData = res.data;
        dispatch(successAddProductFile(getData));
        handleVisible();
      })
      .catch((error) => {
        dispatch(errorAddProductFile());
        handleDangerVisible();
      });
  };
};
